import "../Css/HomePage.css";
import "../Css/Edit.css";
import "../Css/Draggable.css";
import Edit from "../Components/Edit";
import About from "../Components/About";
import { React, useContext, useEffect, useState } from "react";
import Viewcode from "../Components/ViewCode";
import "react-device-emulator/lib/styles/style.css";
import MultiScreen from "../Components/MultiScreen";
import AddComponent from "../Components/AddComponent";
import ShowStructure from "../Components/ShowStructure";
import DeleteModal from "../Components/ChildComponents/ModalDelete";
import WebTemplate from "../../API/APIServices/WebTemplate";
import AddTemplate from "../Components/ChildComponents/AddTemplate";
import MyContext from "../../Inventory/MyContext";
import { Link } from "react-router-dom";
// import IconLibrary from "../Components/DraggableComponent/IconLibrary";
// import TemplateOne from "../AppRoutes";
// import { Link } from "react-router-dom";

function HomePage() {
  const [edit, setEdit] = useState(false);
  const [viewcode, setViewcode] = useState(false);
  const [AddNewTemplateModal, setAddNewTemplateModal] = useState(false);
  const [openabout, setOpenAbout] = useState(false);
  const [screenValue, setScreenValue] = useState();
  const [showstructure, setShowStructure] = useState(false);
  const [addComponent, setOpenAddComponent] = useState(true);
  const [openDltModal, setOpenDltModal] = useState(false);
  const [templateId, setTemplateId] = useState();
  const [isClassApplied, setIsClassApplied] = useState(true);

  const { TemplateData, setTemplateData, droppedItems, setDroppedItems, stylesArray, setStylesArray, setTextValue } = useContext(MyContext)


  useEffect(() => {
    console.log(TemplateData);
  }, [TemplateData])

  const openDeleteModalBox = () => {
    setOpenDltModal(true);

  };

  const OpenTemplateModal = () => {
    setAddNewTemplateModal(!AddNewTemplateModal);
  };

  const [WebTempates, setWebtemplates] = useState([])
  const fetchTemplates = async () => {
    var FetchedData = await WebTemplate.GetCustomizedPageTemplates()
    if (FetchedData) {
      setWebtemplates(FetchedData)
    } else {
      alert("error")
    }

  }
  useEffect(() => {
    fetchTemplates()
  }, [])


  const
    UpdateTemplate = async () => {
      debugger
      // console.log(document.getElementById("drop-target-innerHtml").innerHTML);
      console.log(TemplateData);
      TemplateData.jsonStructure = JSON.stringify(droppedItems)
      TemplateData.textContents = JSON.stringify(stylesArray)
      var updateData = await WebTemplate.UpdateCustomizedPageTemplate(TemplateData)
      if (updateData) {
        alert("Template updated successfully")
        // fetchTemplates()
      }
    }


  const AddNewTemplate = () => {
    OpenTemplateModal()
  }


  const showTemplateData = async (e) => {
    // alert(e.target.value)
    // debugger
    setTemplateId(e.target.value)

    if (e.target.value === 'default') {
      localStorage.removeItem('templateId');
      localStorage.removeItem('templateData');
      setDroppedItems([]);
      setStylesArray([]);
      setTemplateData(null);
      return;
    }

    var getdata = await WebTemplate.GetCustomizedPageTemplate(e.target.value);
    debugger
    if (getdata) {
      try {
        if (getdata.jsonStructure) {
          const decodedData = decodeURIComponent(getdata.jsonStructure)
          const parsedData = JSON.parse(decodedData);
          console.log("JSON Structure Data", parsedData);
          setDroppedItems(parsedData);
        }

        if (getdata.textContents) {
          const decodedData = decodeURIComponent(getdata.textContents)
          const parsedData = JSON.parse(decodedData);
          console.log("Text Contents Data", parsedData);
          setStylesArray(parsedData);
        }
        if (getdata.textContents && getdata.jsonStructure) {
          const templateInnerHtml = decodeURIComponent(getdata.textContents);
          const JsonInnerHtml = decodeURIComponent(getdata.jsonStructure);
          const parsedText = JSON.parse(templateInnerHtml);
          const parsedJson = JSON.parse(JsonInnerHtml);

          setTemplateData({ ...getdata, textContents: parsedText, jsonStructure: parsedJson });
          // localStorage.setItem('templateData', JSON.stringify(templateData));
        }
        else {
          setDroppedItems([]);
          setTemplateData({
            id: e.target.value,
            name: getdata.name,
            jsonStructure: "",
            textContents: "",
          })
        }

      } catch (error) {
        console.error('Error parsing JSON:', error);
        setDroppedItems([]);
      }
      // var templateInnerHtml = getdata.textContents;
      // var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
      // getdata.textContents = templateInnerHtmlDecode

      // setTemplateData(getdata);

      // setDataHtml(getdata)
    } else {
      document.getElementById("drop-target-innerHtml").outerHTML = "";
      setDroppedItems([]);
    }
    localStorage.setItem('templateId', e.target.value);
  }
  useEffect(() => {
    const savedTemplateId = localStorage.getItem('templateId');
    if (savedTemplateId) {
      setTemplateId(savedTemplateId);
      const event = { target: { value: savedTemplateId } };
      showTemplateData(event);
    }
  }, []);

  const handleResizerView = () => {
    debugger
    const removeClassFromElements = (classNameToRemove) => {

      const elements = document.querySelectorAll('div.column-one-content-web-template.resizers-web-template');

      elements.forEach(element => {
        element.classList.remove(classNameToRemove);
      });
    };

    const removeAllButtonsAssociatedWithDivisions = () => {
      // Select all division elements with the class 'column-one-content resizers'
      const divisions = document.querySelectorAll('div.column-one-content-delete-web-template.resizers-delete-web-template');

      divisions.forEach(div => {
        // Find the delete button associated with the division
        const deleteButton = div.querySelector('.component-delete-web-template');
        if (deleteButton) {
          deleteButton.remove();
        }
      });
    };

    const addClassToElements = (classNameToAdd) => {
      const elements = document.querySelectorAll('div.resizers-web-template');

      elements.forEach(element => {
        element.classList.add(classNameToAdd);
      });
    };

    if (isClassApplied) {
      removeClassFromElements('column-one-content-web-template');
      removeAllButtonsAssociatedWithDivisions();
    } else {
      addClassToElements('column-one-content-web-template');
    }

    setIsClassApplied(!isClassApplied);

    const extractIds = (data) => {
      const ids = new Set();
      data.forEach(item => {
        ids.add(item.id); // Add the current item's ID
        item.JSONChildComponents.forEach(child => {
          ids.add(child.id); // Add child component IDs
        });
      });
      return Array.from(ids); // Convert the Set to an Array
    };

    const handleJsonStructure = (data) => {
      let jsonData;

      if (data) {
        try {
          // Check if the data contains URI encoding
          if (data.includes('%')) {
            // Decode the URI-encoded data
            const decodedData = decodeURIComponent(data);
            // Parse the JSON data
            jsonData = JSON.parse(decodedData);
          } else {
            // Parse the JSON data directly
            jsonData = data
          }

          console.log("JSON Structure Data", jsonData);

          // Extract IDs from the parsed data
          const ids = extractIds(jsonData);
          console.log("Extracted IDs:", ids);
          return ids;
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }
      } else {
        console.error("No data provided");
      }
    };


    const updateTextContents = () => {
      const updatedTextContents = {};
      // const ids = Object.keys(TemplateData.jsonStructure.id);
      const ids = handleJsonStructure(TemplateData.jsonStructure);

      ids.forEach(id => {
        debugger

        const element = document.getElementById(id);

        if (element) {
          const getRemainingData = (str) => {
            if (str.endsWith("CHILD")) {
              return str.replace("CHILD", "");
            } else if (str.endsWith("CHILD1")) {
              return str.replace("CHILD1", "");
            }
            return str;
          };

          var newID = getRemainingData(id)
          if (id.endsWith('CHILD')) {
            let element = document.getElementById(newID.toString() + "innerHTML");
            updatedTextContents[newID] = element.outerHTML;
          } else if (id.endsWith('CHILD1')) {
            let element = document.getElementById(newID.toString() + "innerHTML");
            updatedTextContents[newID] = element.outerHTML;
          } else {
            updatedTextContents[id] = element.outerHTML;
          }
        }
      });
      setTemplateData(prevData => {
        // Update the textContents for each item in jsonStructure
        const updatedJsonStructure = prevData.jsonStructure.map(item => {
          // Check if there's a corresponding updatedTextContents for the item
          if (updatedTextContents[item.id]) {
            return {
              ...item,
              textContents: updatedTextContents[item.id]
            };
          }
          return item;
        });

        return {
          ...prevData,
          jsonStructure: updatedJsonStructure
        };
      });
      setDroppedItems(prevData => {
        // Map over the droppedItems array to update the textContents for each item
        const updatedDroppedItems = prevData.map(item => {
          // Check if the item's ID matches one in the updatedTextContents object
          if (updatedTextContents[item.id]) {
            return {
              ...item,
              textContents: updatedTextContents[item.id]
            };
          }
          // If there's no matching update, return the item unchanged
          return item;
        });

        // Return the updated droppedItems array
        return updatedDroppedItems;
      });

    };

    updateTextContents();


  }

  // const setDataHtml = (getdata) => {
  //   debugger
  //   console.log('Original data:', templateInnerHtmlDecode);
  //   var templateInnerHtml = getdata.textContents;
  //   templateInnerHtml = decodeURIComponent(templateInnerHtml); 
  //   // setTemplateData(templateInnerHtml)
  //   console.log(templateInnerHtml)

  //   document.getElementById("drop-target-innerHtml").innerHTML = templateInnerHtml;
  //   // document.getElementById("drop-target-innerHtml").insertAdjacentHTML('beforeend', templateInnerHtml);
  //   // const tempDiv = document.createElement('div');
  //   // tempDiv.innerHTML = templateInnerHtml;

  //   // while (tempDiv.childNodes.length > 0) {
  //   //   document.getElementById("drop-target-innerHtml").appendChild(tempDiv.childNodes[0]);
  //   // }
  // }





  var [styleList, setStyleList] = useState([
    {
      // id: 1,
      width: "45%",
      height: "70vh",
      backgroundColor: "red",
      color: "black",
      padding: "10px",
    },
  ]);

  // Object.defineProperty(styleList[0], 'width', {
  //   value: "42%",
  //   writable: true,
  //   configurable: false // Once set to false, the property cannot be redefined
  // });

  // Object.defineProperty(styleList[0], 'height', {
  //   value: "70vh",
  //   writable: true,
  //   configurable: false // Once set to false, the property cannot be redefined
  // });

  //   const stylechange=(index, key)=>{
  //     var himanshu = document.getElementById("himanshu").value;
  //     console.log(himanshu)

  //     setStyleList(pervArray =>{
  //       const newArray =[...pervArray]
  //       console.log(newArray);

  //       newArray.width = himanshu;
  //       console.log(himanshu);

  //       setStyleList(newArray);
  //       console.log(setStyleList)
  //     })

  //     console.log(styleList)

  //  }
  // if ((result = "")) {
  //   styleList[0].width = 0;
  // }

  const stylechange = (index, key, value) => {
    setStyleList((prevArray) => {
      const newArray = [...prevArray];
      console.log(index, key);
      newArray[index][key] = value;
      newArray[0]["width"] = value;
      console.log(newArray[0].widthinput);
      return newArray;
    });

    console.log(styleList);
  };
  // console.log(stylechange);

  let widthinput1 = "";
  let widthinput2 = "";
  const widthchange = () => {
    try {
      styleList.writable = true;
      if (styleList.length > 0) {
        console.log("setStyleList", setStyleList);
        if (typeof styleList[0].width !== "undefined") {
          console.log(styleList[0].width);

          styleList[0].width = result;
          console.log(styleList[0].width);
          widthinput2 = styleList[0].width + widthinput1;
          styleList[0]["width"] = widthinput2;
          console.log(styleList);
          styleList[0]["width"] = widthinput2;

          //  Object.defineProperty(styleList[0], 'width', { writable: true });widthinput
          //Object.assign(styleList[0],{width:widthinput2});
          /*
          Object.defineProperty(styleList[0], 'width', {
            value: widthinput2,
            writable: true
          });
          */
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  const widthinput = () => {
    setStyleList((prevArray) => {
      const newArray = [...prevArray];
      widthchange();
      widthinput2 = newArray[0].width + widthinput1;
      widthinput1 = document.getElementById("selectbox").value;
      widthinput2 = newArray[0].width + widthinput1;
      console.log(newArray);
      newArray[0]["width"] = widthinput2;
      return newArray;
    });
  };
  // console.log(widthinput);

  function extractNumericalValues(inputString) {
    // Define the regular expression pattern to match numerical values
    var pattern = /\d+(\.\d+)?/g; // This pattern will match both integers and floating-point numbers

    // Use match() to find all occurrences of the pattern in the input string
    var numericalValues = inputString.match(pattern);
    // console.log(numericalValues);

    if (numericalValues === null) {
      numericalValues = "0";
      let numericalValue = String(numericalValues); // This would result in "0"
      console.log(numericalValue);
      // numericalValues = numericalValues.map((value) =>
      //   value.includes(".") ? parseFloat(value) : parseInt(value, 10)
      // );
    } else {
      // Convert the array of strings to an array of numerical values (integers or floats)
      numericalValues = numericalValues.map((value) =>
        value.includes(".") ? parseFloat(value) : parseInt(value, 10)
      );
    }

    return numericalValues;
  }

  // Example usage:
  var result = extractNumericalValues(styleList[0].width);
  // console.log(result); // Output: [25.99, 10]

  const openedit = () => {
    debugger
    setEdit(!edit);
    setOpenAddComponent(false);
    setShowStructure(false);
    document.getElementById("open-edit").classList.toggle("selected-icon");
    document
      .getElementById("open-add-component")
      .classList.remove("selected-icon");
    document.getElementById("open-structure").classList.remove("selected-icon");
  };

  const openviewcode = () => {
    setViewcode(!viewcode);
  };
  const openAddcomponent = () => {
    setOpenAddComponent(!addComponent);
    setEdit(false);
    setShowStructure(false);
    document
      .getElementById("open-add-component")
      .classList.toggle("selected-icon");
    document.getElementById("open-edit").classList.remove("selected-icon");
    document.getElementById("open-structure").classList.remove("selected-icon");
  };

  const openstructure = () => {
    setShowStructure(!showstructure);
    setOpenAddComponent(false);
    setEdit(false);
    document.getElementById("open-structure").classList.toggle("selected-icon");
    document
      .getElementById("open-add-component")
      .classList.remove("selected-icon");
    document.getElementById("open-edit").classList.remove("selected-icon");
  };

  const openpreview = () => {
    document.getElementById("navbar").style.display = "none";
    document.getElementById("container-right").style.display = "none";
    document.getElementById("container-left").style.width = "100%";
    document.getElementById("container-left").style.marginTop = "50px";
    document.getElementById("remove-preview").classList.add("displayblock");
  };

  const removepreview = () => {
    document.getElementById("navbar").style.display = "flex";
    document.getElementById("container-right").style.display = "block";
    document.getElementById("container-left").style.width = "78%";
    document.getElementById("container-left").style.marginTop = "0px";
    document.getElementById("remove-preview").classList.remove("displayblock");
  };

  const openAbout = () => {
    setOpenAbout(!openabout);
  };

  // const ResponsiveIndex = (e) => {
  //   setScreenValue(e.target.value);
  // };

  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen().catch((err) => {
        console.log(
          "Error attempting to enable full-screen mode:",
          err.message
        );
      });
    }
  };

  const removemultisize1 = (value) => {

    setScreenValue(value);
  };

  const removemultisize2 = (value) => {

    setScreenValue(value);
  };

  const removemultisize3 = (value) => {

    setScreenValue(value);
  };
  const removemultisize4 = (value) => {


    setScreenValue(value);
  };

  let value1 = 1;
  let value2 = 2;
  let value3 = 3;
  let value4 = 4;

  /* **************************code for style change****************** */

  // const [widthValue, setWidthValue] = useState("");
  // const [heightValue, setHeightValue] = useState("");
  // const [widthUnit, setWidthUnit] = useState("");
  // const [heightUnit, setHeightUnit] = useState("");
  // const [divStyle, setDivStyle] = useState({});

  // const handleWidthChange = (event) => {
  //   setWidthValue(event.target.value);
  //   applyStyles("width", event.target.value, widthUnit);
  // };

  // const handleHeightChange = (event) => {
  //   setHeightValue(event.target.value);
  //   applyStyles("height", event.target.value, heightUnit);
  // };

  // const selectvalue = (event) => {
  //   setWidthUnit(event.target.value);
  //   applyStyles("width", widthValue, event.target.value);
  // };

  // const selectvalue1 = (event) => {
  //   setHeightUnit(event.target.value);
  //   applyStyles("height", heightValue, event.target.value);
  // };

  // const applyStyles = (property, value, unit) => {
  //   const newStyles = {
  //     ...divStyle,
  //     [property]: value + unit,
  //   };

  //   setDivStyle(newStyles);
  //   console.log(setDivStyle);
  // };

  /***************************code for style change****************** */

  return (
    <div className="hp-container">
      <div
        style={{
          position: "absolute",
          zIndex: "2",
          left: "65px",
          display: "flex",
          alignItems: "center",
          height: '53px'
        }}
      >
        {/* **************************code for style change****************** */}
        {/* <div>
          <label style={{ color: "white" }}>Width: </label>
          <input type="number" value={widthValue} onChange={handleWidthChange} />
          <select
            onChange={selectvalue}
            value={widthUnit}
            id="selectbox"
            name=""
            className="globalselector-select"
          >
            <option className="displaynone">-</option>
            <option value="px">px</option>
            <option value="%">%</option>
            <option value="em">em</option>
            <option value="rem">rem</option>
            <option value="vh">vh</option>
            <option value="vw">vw</option>
          </select>
        </div>
        <div>
          <label style={{ color: "white" }}>Height: </label>
          <input
            type="number"
            value={heightValue}
            onChange={handleHeightChange}
          />
          <select
            value={heightUnit}
            onChange={selectvalue1}
            id="selectbox"
            name=""
            className="globalselector-select"
          >
            <option className="displaynone">-</option>
            <option value="px">px</option>
            <option value="%">%</option>
            <option value="em">em</option>
            <option value="rem">rem</option>
            <option value="vh">vh</option>
            <option value="vw">vw</option>
          </select>
        </div> */}

        {/* **************************code for style change****************** */}

        {/* <input
          id="input-3"
          type="text"
          value={styleList[0].width}
          onChange={(e) => stylechange(0, "width", e.target.value)}
        />

        <select
          onChange={(e) => widthinput(0, "widthinput", e.target.value)}
          id="selectbox"
          name=""
          className="globalselector-select"
        >
          <option className="displaynone">-</option>
          <option value="px">px</option>
          <option value="%">%</option>
          <option value="em">em</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
          <option value="vw">vw</option>
        </select>

        <input
          type="text"
          value={styleList[0].height}
          onChange={(e) => stylechange(0, "height", e.target.value)}
        /> */}
        <button
          style={{ marginRight: "12px" }}
          onClick={() => {
            removemultisize1(value1);
          }}
          className="icon-button"
        >
          <i
            style={{ fontWeight: "600", color: "black", fontSize: "20px" }}
            className="fa-solid fa-display"
          ></i>
        </button>

        <button
          value={2}
          style={{ marginRight: "12px" }}
          onClick={() => {
            removemultisize2(value2);
          }}
          className="icon-button"
        >
          <i
            style={{ fontWeight: "600", color: "black", fontSize: "20px" }}
            className="fa-solid fa-tablet-screen-button"
          ></i>
        </button>

        <button
          style={{ marginRight: "12px" }}
          onClick={() => {
            removemultisize3(value3);
          }}
          className="icon-button"
        >
          <i
            style={{ fontWeight: "600", color: "black", fontSize: "20px" }}
            className="fa-solid fa-mobile-screen"
          ></i>
        </button>
        <button
          style={{ marginRight: "12px" }}
          onClick={() => {
            removemultisize4(value4);
          }}
          className="icon-button-emulator"
        >
          {/* <i
            style={{ fontWeight: "600", color: "black", fontSize: "20px" }}
            className="fa-solid fa-mobile-screen"
          ></i> */}
          VIEW IN PHONE/TABLET
        </button>
        <button
          id="remove-preview"
          onClick={removepreview}
          className="icon-button1"
        >
          <i className="fa-solid fa-eye-slash"></i>
        </button>
      </div>
      <div id="navbar" style={{ color: "red" }} className="navbar">
        <div className="nav-display-box">
          <select className="TemplateSelectBox" value={templateId} name="" id="" onChange={(e) => showTemplateData(e)}>
            <option value="default">---Select-Web-Template--- </option>
            {WebTempates.map(templates => (
              <option key={templates.id} value={templates.id}>{templates.name} -- {templates.id}</option>
            ))}
          </select>
          <button className="TemplateSaveButton" onClick={AddNewTemplate}><i class="fa-solid fa-plus"></i></button>
          <button className="TemplateSaveButton" onClick={UpdateTemplate}> UPDATE</button>

        </div>
        <div className="nav-option-box-main">
          <div className="nav-option-box">
            {/* <div onClick={removemultisize4} className="icon-box">
              <i className="fa-solid fa-gear homeicon"></i>
              <div className="icon-hover">
                <p>SETTING</p>
              </div>
            </div> */}
            <div className="icon-box" onClick={handleResizerView}>
              <i className="fa-solid fa-table-cells-large homeicon"></i>
              <div className="icon-hover">
                <p>VIEW COMPONENTS</p>
              </div>
            </div>
            <Link to={`/previewTemplate/${templateId}`} className="icon-box">
              <i className="fa-solid fa-eye homeicon"></i>
              <div className="icon-hover">
                <p>PREVIEW</p>
              </div>
            </Link>
            <div onClick={toggleFullScreen} className="icon-box">
              <i className="fa-solid fa-expand homeicon"></i>
              <div className="icon-hover">
                <p>FULL SCREEN</p>
              </div>
            </div>
            <div onClick={openviewcode} className="icon-box">
              <i className="fa-solid fa-code homeicon"></i>
              <div className="icon-hover">
                <p>VIEW CODE</p>
              </div>
            </div>
            <div className="icon-box">
              <i className="fa-solid fa-rotate-left homeicon"></i>
              <div className="icon-hover">
                <p>UNDO</p>
              </div>
            </div>
            <div className="icon-box">
              <i className="fa-solid fa-rotate-right homeicon"></i>
              <div className="icon-hover">
                <p>REDO</p>
              </div>
            </div>
            <div className="icon-box">
              <i className="fa-solid fa-download homeicon"></i>
              <div className="icon-hover">
                <p>IMPORT</p>
              </div>
            </div>
            <div>
              <div className="icon-box" onClick={openDeleteModalBox}>
                <i className="fa-solid fa-trash homeicon"></i>
                <div className="icon-hover">
                  <p>CLEAR CANVAS</p>
                </div>
              </div>

              {openDltModal && (
                <DeleteModal
                  handlebox={() => setOpenDltModal(false)} // Pass a function to close the modal
                />
              )}
            </div>
            <div onClick={openAbout} className="icon-box">
              <i className="fa-solid fa-question homeicon"></i>
              <div className="icon-hover">
                <p>ABOUT</p>
              </div>
            </div>
          </div>
          <div className="nav-option-box-2">
            <div className="icon-box-1">
              <i
                id="open-edit"
                onClick={openedit}
                className="fa-sharp fa-solid fa-paintbrush homeicon"
              ></i>
              <div className="icon-hover-1">
                <p>EDIT COMPONENT</p>
              </div>
            </div>
            <div onClick={openstructure} className="icon-box-1">
              <i
                id="open-structure"
                className="fa-solid fa-database homeicon"
              ></i>
              <div className="icon-hover-1">
                <p>SHOW STRUCTURE</p>
              </div>
            </div>
            <div onClick={openAddcomponent} className="icon-box-1">
              <i
                id="open-add-component"
                className="fa-solid fa-square-plus homeicon"
              ></i>
              <div className="icon-hover-1">
                <p>ADD COMPONENT</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-bottom">
        <div
          // style={divStyle}
          id="container-left"
          className="container-bottom-left"
        >
          {/* <IconLibrary/> */}
          <MultiScreen value={screenValue} data={styleList} />
        </div>
        <div
          id="container-right"
          style={{ paddingBottom: "60px" }}
          className="container-bottom-right"
        >
          {edit && <Edit />}
          {addComponent && (
            <AddComponent handleclick={openAddcomponent} data={styleList} />
          )}
          {showstructure && <ShowStructure droppedItems={droppedItems} />}
        </div>
      </div>

      {openabout && <About handleclick={openAbout} />}
      {viewcode && <Viewcode handleclick={openviewcode} />}
      {AddNewTemplateModal && <AddTemplate handleclick={OpenTemplateModal} fetchTemplates={fetchTemplates} />}

    </div>
  );
}

export default HomePage;
