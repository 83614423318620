import React, { useEffect, useState } from "react";


const GlobalSelector = (prop) => {
  const [globalValue, setglobalValue] = useState(0);
  const [globalUnit, setglobalUnit] = useState("");
  useEffect(() => {
    console.log("prop Val", prop.value);
    console.log("prop Unit", prop.unit);
  }, [prop.value, prop.unit]);


  const golbalSectorValue = (e) => {
    debugger
    const newValue = e.target.value;
    setglobalValue(newValue);
    if (prop.name === "border") {
      const value = newValue + globalUnit + " " + prop.displayStyl + " " + prop.color;
      prop.onChange(prop.name, value, "null", prop.id);
      return
    }
    if (typeof prop.onChange === 'function') {

      prop.onChange(prop.name, newValue, globalUnit, prop.id);

    } else {
      console.error("handleStyleChange is not a function");
    }
    // addBorderTop();
  };


  const golbalSectorUnit = (e) => {
    // setglobalUnit(e.target.value);
    debugger
    const newUnit = e.target.value;
    setglobalUnit(newUnit);
    prop.onChange(prop.name, globalValue, newUnit, prop.id);

    // addBorderTop();
  };
  // debugger
  // console.log(prop.style);
  console.log(prop.id);


  // const addBorderTop = () => {
  //   switch (prop.style) {
  //     case "top":
  //       var value1 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.top = value1;
  //       break;

  //     case "bottom":
  //       var value2 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.bottom = value2;
  //       break;

  //     case "left":
  //       var value3 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.left = value3;
  //       break;

  //     case "right":
  //       var value4 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.right = value4;
  //       break;

  //     case "borderTopLeftRadius":
  //       var value5 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.borderTopLeftRadius = value5;
  //       break;

  //     case "borderBottomRightRadius":
  //       var value6 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.borderBottomRightRadius = value6;
  //       break;

  //     case "borderTopRightRadius":
  //       var value7 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.borderTopRightRadius = value7;
  //       break;

  //     case "borderBottomLeftRadius":
  //       var value8 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.borderBottomLeftRadius = value8;
  //       break;

  //     case "width":
  //       var value9 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.width = value9;
  //       break;

  //     case "height":
  //       var value10 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.height = value10;
  //       break;

  //     case "MaxWidth":
  //       var value11 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.maxWidth = value11;
  //       break;

  //     case "MaxHeight":
  //       var value12 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.maxHeight = value12;
  //       break;

  //     case "MarginTop":
  //       var value13 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.marginTop = value13;
  //       break;

  //     case "MarginBottom":
  //       var value14 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.marginBottom = value14;
  //       break;

  //     case "MarginLeft":
  //       var value15 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.marginLeft = value15;
  //       break;

  //     case "MarginRight":
  //       var value16 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.MarginRight = value16;
  //       break;

  //     case "PaddingTop":
  //       var value17 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.PaddingTop = value17;
  //       break;

  //     case "PaddingBottom":
  //       var value18 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.PaddingBottom = value18;
  //       break;

  //     case "PaddingLeft":
  //       var value19 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.PaddingLeft = value19;
  //       break;

  //     case "PaddingRight":
  //       var value20 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.PaddingRight = value20;
  //       break;

  //     case "fontSize":
  //       var value21 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.fontSize = value21;
  //       break;

  //     case "letterSpacing":
  //       var value22 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.letterSpacing = value22;
  //       break;

  //     case "lineHeight":
  //       var value23 = globalValue + globalUnit;
  //       document.getElementById(prop.id).style.lineHeight = value23;
  //       break;

  //     case "border":
  //       var value24 =
  //         globalValue + globalUnit + " " + prop.displayStyl + " " + prop.color;
  //       console.log(value9);
  //       document.getElementById(prop.id).style.border = value24;
  //       break;

  //     case "perspective":
  //       var value25 = globalValue + globalUnit;
  //       console.log(value11);
  //       document.getElementById(prop.id).style.perspective = value25;
  //       break;
  //     default:
  //       return ("none")

  //   }
  // };




  return (
    <div className="globalselector-container">
      <p className="general-paragraph">{prop.name}</p>
      <input
        type="number"
        className="globalselector-input"
        placeholder={prop.placeholder}
        value={prop.value != undefined ? prop.value : globalValue}
        onChange={golbalSectorValue}
      />
      <select
        name=""
        className="globalselector-select"
        onChange={golbalSectorUnit}
        value={prop.unit != undefined ? prop.unit : globalUnit}
      >
        <option className="displaynone">-</option>
        <option value="px">px</option>
        <option value="%">%</option>
        <option value="em">em</option>
        <option value="rem">rem</option>
        <option value="vh">vh</option>
        <option value="vw">vw</option>
      </select>
    </div>
  );
};

export default GlobalSelector;
