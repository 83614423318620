import React, { useEffect, useRef, useState, Component } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Mainpage from "../AppRoutes";
import SmallSideBar from "./Components/SmallSideBar";
import "../css/new.css";
import SideBarNew from "./SideNavBar/SideBarNew";
import LogInPanel from "./LogInPage/LogInPanel";
import MyContext from "./MyContext";
import User from "../API/APIServices/User";
import Company from "../API/APIServices/Company";
import UserCredential from "../API/APIServices/UserCredential";
import Authentication from "../API/APIServices/Authentication";
import AlertBoxError from "./Props/AlertBoxError";
import SmsTemplate from "../API/APIServices/SmsTemplate";
import SMS from "../API/APIServices/SMS";
import jsPDF from "jspdf";
import pako from "pako";
import Compress from 'compress.js';
import html2canvas from 'html2canvas';
import { jsPDF as jsPDFInstance } from 'jspdf'; // Import jsPDF instance for compression
import { PDFDocument } from 'pdf-lib';
import html2pdf from 'html2pdf.js';
import OrderTakerBillPrint from "../Restaurant/OrderTaker/OrderTakerBillPrint";
import axios from "axios";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

function HomePage() {

  // ?????????????????????????????????????? web template ??????????????????????????????????????????????????????????
  const [droppedItems, setDroppedItems] = useState([]);
  const [TemplateData, setTemplateData] = useState([]);
  const [childComponentValue, setChildComponentValue] = useState([]);



  const [clickedItemId, setClickedItemId] = useState("");
  const [clickedItemName, setClickedItemName] = useState();

  // useEffect(() => {
  //   console.log("TemplateData", TemplateData);
  //   console.log("droppedItems", droppedItems);
  // }, [droppedItems, TemplateData,])

  // useEffect(() => {
  //   console.log("clickedItemId", clickedItemId);
  // }, [clickedItemId]);

  // {/* *******************Tab****************** */}
  const [leftContainerWidth, setLeftContainerWidth] = useState(55);
  const [alertBoxEdit, setAlertBoxEdit] = useState(false);
  const [alertBox, setAlertBox] = useState(false);
  const [alertBoxDelete, setAlertBoxDelete] = useState(false);
  const [alertBoxErrorInsert, setAlertBoxErrorInsert] = useState(false);
  const [alertBoxErrorUpdate, setAlertBoxErrorUpdate] = useState(false);
  const [openAlertItemExists, setOpenAlertItemExists] = useState(false);
  const [alertBoxCantDelete, setAlertBoxCantDelete] = useState(false)
  const [BusinessType, setBusinessType] = useState();
  const [UserDetails, setUserDetails] = useState([])
  const [stylesArray, setStylesArray] = useState([]);
  useEffect(() => {
    console.log("stylesArray", stylesArray);
  }, [stylesArray]);


  const updateStyle = (id, styleKey, styleValue) => {
    debugger
    const templateId = TemplateData.id
    setStylesArray(prevArray => {
      const index = prevArray.findIndex(item => item.id === id);
      const newStyle = { [styleKey]: styleValue };

      if (index !== -1) {
        // Update existing style object
        const clearedStyles = prevArray.filter(styleObj => styleObj.templateId !== templateId);
        // Update the existing style object
        // const updatedStyles = [...prevStyles];
        const updatedArray = clearedStyles;
        // const updatedArray = [...prevArray];
        updatedArray[index] = {
          ...updatedArray[index],
          style: {
            ...updatedArray[index].style,
            ...newStyle
          }
        };
        return updatedArray;
      } else {
        // Add new style object
        const clearedStyles = prevArray.filter(styleObj => styleObj.templateId !== templateId);

        return [
          // ...prevArray,
          ...clearedStyles,
          {
            id,
            style: newStyle,
            content: "", // You can set or update this field as needed
            link: "",
          }
        ];
      }
    });
  };

  const handleContentChange = (content, id, link) => {
    debugger
    const templateId = TemplateData.id
    setStylesArray((prevStyles) => {
      const existingStyleIndex = prevStyles.findIndex(styleObj => styleObj.id === id);

      if (existingStyleIndex !== -1) {
        const clearedStyles = prevStyles.filter(styleObj => styleObj.templateId !== templateId);
        const updatedStyles = clearedStyles;
        updatedStyles[existingStyleIndex] = {
          ...updatedStyles[existingStyleIndex],
          content: content,
        };

        return updatedStyles;
      } else {
        const clearedStyles = prevStyles.filter(styleObj => styleObj.templateId !== templateId);

        if (link !== undefined) {
          return [
            ...clearedStyles,
            {
              id,
              style: {},
              content: content,
              link: link,
            },
          ];
        } else {
          return [
            // ...prevStyles,
            ...clearedStyles,
            {
              id,
              style: {},
              content: content,
              link: "",
            },
          ];
        }
      }
    });
  };

  const handleStyleChange = (style, value, unit, id) => {
    debugger
    const templateId = TemplateData.id
    if (style === "border") {
      const newValue = value
      setStylesArray((prevStyles) => {
        const existingStyleIndex = prevStyles.findIndex(styleObj => styleObj.id === id);

        if (existingStyleIndex !== -1) {
          const clearedStyles = prevStyles.filter(styleObj => styleObj.templateId !== templateId);
          // Update the existing style object
          // const updatedStyles = [...prevStyles];
          const updatedStyles = clearedStyles;
          updatedStyles[existingStyleIndex] = {
            ...updatedStyles[existingStyleIndex],
            style: {
              ...updatedStyles[existingStyleIndex].style,
              [style]: newValue,
            },
          };

          return updatedStyles;
        } else {
          const clearedStyles = prevStyles.filter(styleObj => styleObj.templateId !== templateId);
          return [
            ...clearedStyles,
            {
              id,
              style: { [style]: newValue },
              content: "", // You can set or update this field as needed
              link: "",    // You can set or update this field as needed
            },
          ];
        }
      });
      return;
    }

    const valueWithUnit = unit === null ? `${value}` : `${value}${unit}`;
    setStylesArray((prevStyles) => {
      // Find existing style object by id
      const existingStyleIndex = prevStyles.findIndex(styleObj => styleObj.id === id);

      if (existingStyleIndex !== -1) {
        const clearedStyles = prevStyles.filter(styleObj => styleObj.templateId !== templateId);
        // Update the existing style object
        // const updatedStyles = [...prevStyles];
        const updatedStyles = clearedStyles;
        updatedStyles[existingStyleIndex] = {
          ...updatedStyles[existingStyleIndex],
          style: {
            ...updatedStyles[existingStyleIndex].style,
            [style]: valueWithUnit,
          },
        };

        return updatedStyles;
      } else {

        const clearedStyles = prevStyles.filter(styleObj => styleObj.templateId !== templateId);
        return [
          ...clearedStyles,
          {
            id,
            style: { [style]: valueWithUnit },
            content: "", // You can set or update this field as needed
            link: "",    // You can set or update this field as needed
          },
        ];
      }
    });
  };

  useEffect(() => {

    const styleObject = stylesArray.find(item => item.id === clickedItemId);

    if (styleObject && styleObject.id === clickedItemId) {
      const element = document.getElementById(clickedItemId);
      if (element) {

        Object.entries(styleObject.style).forEach(([key, value]) => {
          element.style[key] = value;
        });
      }
    }
  }, [stylesArray, clickedItemId]);

  useEffect(() => {

    // debugger;
    console.log(stylesArray);

    // Use a for loop to iterate over stylesArray
    for (let index = 0; index < stylesArray.length; index++) {
      const styleObject = stylesArray[index];
      // console.log(styleObject.id);

      // Find the element with the matching ID
      const element = document.getElementById(styleObject.id);

      if (element) {
        // Apply each style property to the element
        for (const [key, value] of Object.entries(styleObject.style)) {
          // Convert kebab-case to camelCase for inline styles
          const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
          element.style[camelCaseKey] = value;
        }
      }
    }
  }, [stylesArray]);

  // useEffect(() => {
  //   console.log(BusinessType);
  // }, [BusinessType]);

  // useEffect(() => {
  //   console.log("UserDetails", UserDetails);
  // }, [UserDetails])

  const [loginuser, setLoginUser] = useState(true);

  useEffect(() => {
    const url = window.location.href;
    const val = url.split("/")
    //alert(val[3])
    if (val[3] == "LoginPanel" || val[3] == "loginpanel")
      setLoginUser(false);
    else
      setLoginUser(true);
    // const storedArrayString = localStorage.getItem('loginAccountUserData');
    // console.log(storedArrayString);
    // if (storedArrayString != null && storedArrayString != undefined) {
    //   const loginUser = JSON.parse(storedArrayString);
    //   setLoginUser(true);
    // }
    // else {
    //   setLoginUser(false);
    // }
  }, [])

  const handleToggle = () => {
    if (leftContainerWidth === 55) {
      setLeftContainerWidth(260);
    } else {
      setLeftContainerWidth(55);
    }
  };

  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const sidebarClassName =
    openSidebar === true ? "small-sidebar" : "small-sidebar-hidden";
  const sidebarIconchnage =
    openSidebar === true ? "rotate-neg180icon" : "rotate-180icon";

  const [loginSuccess, setLoginSuccess] = useState(true);

  const logindone = async () => {
    const data = await Company.getLoginValidation();
    //alert(loginSuccess)
    setLoginSuccess(data);
  };

  useEffect(() => {
    //logindone();
  }, [loginSuccess])

  const OpenAlertBox = () => {
    setAlertBox(!alertBox);
  };

  const OpenAlertBoxDelete = () => {
    setAlertBoxDelete(!alertBoxDelete);
  };

  const OpenAlertBoxEdit = () => {
    setAlertBoxEdit(!alertBoxEdit);
  };
  const OpenAlertBoxErrorInsert = () => {
    setAlertBoxErrorInsert(!alertBoxErrorInsert);
  };

  const OpenAlertBoxErrorUpdate = () => {
    setAlertBoxErrorUpdate(!alertBoxErrorUpdate);
  };

  const OpenAlertBoxWhenItemExists = () => {
    setOpenAlertItemExists(!openAlertItemExists)
  }


  const OpenAlertBoxDeleteCant = () => {
    setAlertBoxCantDelete(false)
  }

  const imageBaseUrl = `${WebApiUrl}/api/ItemMaster/GetImage?D1ItemId=`;

  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();
  const input5 = useRef();
  const input6 = useRef();
  const input7 = useRef();

  const handleKeyPress = (event) => {
    if (event.altKey && event.key === "1") {
      if (input1.current) {
        input1.current.focus();
      }
    }
    if (event.altKey && event.key === "2") {
      if (input2.current) {
        input2.current.focus();
      }
    }
    if (event.altKey && event.key === "3") {
      if (input3.current) {
        input3.current.focus();
      }
    }
    if (event.altKey && event.key === "4") {
      if (input4.current) {
        input4.current.focus();
      }
    }
    if (event.altKey && event.key === "5") {
      if (input5.current) {
        input5.current.focus();
      }
    }
    if (event.altKey && event.key === "6") {
      if (input6.current) {
        input6.current.focus();
      }
    }
    if (event.altKey && event.key === "7") {
      if (input7.current) {
        input7.current.focus();
      }
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [routeLink, setRouteLink] = useState(location.search);

  // Effect to update routeLink based on location.search changes
  useEffect(() => {
    setRouteLink(location.search);
  }, [location.search]);

  useEffect(() => {
    console.log("formAuthentication");
    async function formAuthentication() {
      try {
        const url = window.location.pathname;
        const formName = (url.substring(url.lastIndexOf('/') + 1)).toLowerCase();

        if (formName != "previousorder" && formName != "loginpanel" && formName != "addupdateformname") {
          const storedArrayString = localStorage.getItem('loginAccountUserData');
          var loginUser;
          if (storedArrayString) {
            loginUser = JSON.parse(storedArrayString);
          }
          const filterData = [{
            fieldName: "formName",
            operatorName: "equal",
            compareValue: formName,
          },
          {
            fieldName: "UserId",
            operatorName: "equal",
            compareValue: loginUser.userId
          }];

          const formData = await Authentication.getAuthenticationWithFilter(filterData);
          const length = formData.result ? formData.result.length : 0;
          console.log("formData", formData)

          if (length > 0) {
            //navigate(`/${formName}`);
          } else {
            OpenAlertBoxErrorInsert();
            navigate("/PreviousOrder");
          }
        }
      } catch (error) {
        navigate("/PreviousOrder");
      }
    }
    if (routeLink) {
      formAuthentication();
    }
  }, [routeLink, navigate]);

  useEffect(() => {     // Set default routeLink if empty
    if (routeLink === "") {
      setRouteLink("/PreviousOrder");
    }
  }, [routeLink]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const [isShowTotalAmountInReport, setShowTotalAmountInReport] = useState(false);
  const [isAllowModificationOfVoucher, setAllowModificationOfVoucher] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const storedArrayString = localStorage.getItem('loginAccountUserData');
      const loginUser = JSON.parse(storedArrayString);
      if (loginUser) {
        const data = await UserCredential.getUserCredentialById(loginUser.userId);
        console.log("dataaaa", data)
        if (data) {
          console.log("userDataa", data.isTotalAmtInRpt)
          setShowTotalAmountInReport(data.isTotalAmtInRpt);
          setAllowModificationOfVoucher(data.canModifyVoucher);
        }
      }
    }
    fetchData();
  }, [])

  const [appToWebLink, setAppToWebLink] = useState("");
  const [webToAppLink, setWebToAppLink] = useState("");
  const [isAutoTransferToKitchen, setAutoTransferToKitchen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const storedArrayString = localStorage.getItem('loginAccountUserData');
      var loginUser;
      if (storedArrayString) {
        loginUser = JSON.parse(storedArrayString);
      }
      try {
        if (loginUser) {
          const data = await Company.GetCompanyById(loginUser.compId)
          setAppToWebLink(data.urL1)
          setWebToAppLink(data.urL2)
          setAutoTransferToKitchen(data.isAllowItemAutoTransferToKitchen)

          if (data) {
            const userData = await UserCredential.getUserCredentialById(loginUser.userId);
            console.log("dataaaa", userData)
            setUserDetails(userData)
            console.log("userDataa", userData.isTotalAmtInRpt)
            setShowTotalAmountInReport(userData.isTotalAmtInRpt);
          }
        }
      }
      catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [])

  // const compress = new Compress();

  const compressPdf = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (event) => {
        try {
          const pdfDoc = await PDFDocument.load(new Uint8Array(event.target.result));
          const compressedPdfBytes = await pdfDoc.save();
          resolve(new Blob([compressedPdfBytes], { type: 'application/pdf' }));
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  // const upload_image = async (uploadData) => {
  //   debugger;
  //   try {
  //     // const element = document.getElementById('pdf-content');

  //     const pdfBlob = await new Promise((resolve, reject) => {
  //       html2pdf()
  //         .from(element)
  //         .outputPdf('blob')
  //         .then(resolve)
  //         .catch(reject);
  //     });

  //     // Convert Blob to ArrayBuffer
  //     const arrayBuffer = await pdfBlob.arrayBuffer();

  //     // Compress the PDF (Optional)
  //     const pdfDoc = await PDFDocument.load(arrayBuffer);
  //     const compressedPdf = await pdfDoc.save({ useObjectStreams: false });
  //     const compressedBlob = new Blob([compressedPdf], { type: 'application/pdf' });

  //     // Create FormData and append the PDF file
  //     const formData = new FormData();
  //     formData.append('file', compressedBlob, 'document.pdf');

  //     const imageUpload = await SmsTemplate.uploadWhatsAppFile(formData);
  //     console.log(imageUpload);

  //     if (imageUpload) {
  //       console.log("Image uploaded successfully");
  //       return imageUpload;
  //     }
  //   } catch (error) {
  //     console.error("Error processing or uploading file:", error);
  //   }
  // };

  const pdfRef = useRef();
  const [billPdf, setBillPdf] = useState(false);

  const [blob, setBlob] = useState("")

  const upload_image = async (uploadData) => {
    // const file = uploadData;
    // console.log(file)
    const formData = new FormData();
    //if (file != undefined) {
    // const compressedBlob = await compressPdf(file);

    // const fileReader = new FileReader();
    // fileReader.onload = async (e) => {
    //   const pdfBytes = new Uint8Array(e.target.result);
    //   const pdfDoc = await PDFDocument.load(pdfBytes);
    //   const compressedPdfBytes = await pdfDoc.save(); // This does not compress, but manipulates

    //   const newFile = (new Blob([compressedPdfBytes], { type: 'application/pdf' }));
    // };
    // fileReader.readAsArrayBuffer(file);
    // formData.append('files', file);

    // const filePath = ``
    // const fileInput = document.querySelector(`input[type="file" path=${filePath}]`);
    // const file = fileInput.files[0];

    // const response = await axios.get('D:\\testFile.pdf', {
    //   responseType: 'blob', // Make sure to handle binary data
    // });

    formData.append('file', blob);
    console.log(formData);
    setBillPdf(false)

    try {
      const imageUpload = await SmsTemplate.uploadWhatsAppFile(formData);
      console.log(imageUpload);

      if (imageUpload) {
        console.log("image uploaded successfully");
        return imageUpload;
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
    // }
  }

  const generateAndUploadPDF = async () => {
    const element = pdfRef.current;
    if (!element) return;

    try {
      const canvas = await html2canvas(element, { scale: 0.5 });
      const imgData = canvas.toDataURL("image/jpeg", 0); // 0 is the quality factor

      const { width, height } = canvas;
      const pdf = new jsPDF({
        orientation: width > height ? 'l' : 'p',
        unit: 'px',
        format: [width, height]
      });

      pdf.addImage(imgData, "JPEG", 0, 0, width, height);

      const pdfBlob = pdf.output("blob");

      // Calculate the size in KB before compression
      const pdfSizeInKB = pdfBlob.size / 1024;
      console.log(`Original PDF size: ${pdfSizeInKB.toFixed(2)} KB`);

      // Upload the PDF using your upload_image function
      const imgId = await upload_image(pdfBlob);
      return imgId;
    } catch (error) {
      console.error("Error generating or uploading PDF:", error);
    }
  };

  const [smsData, setSmsData] = useState()
  const [mobileNo, setMobileData] = useState()
  const [orderId, setOrderId] = useState()

  const messageFunction = async (smsType, OrderId, AccId, mobileNo) => {
    // ******************** SMS/MSG **********************************
    setMobileData(mobileNo);
    setOrderId(OrderId);

    let smsData;
    try {
      smsData = await SmsTemplate.GetAssignTemplatesByName(smsType, OrderId, AccId)
      setSmsData(smsData);
      const sendDataSms = {
        to: mobileNo,
        message: smsData[0]?.templateForFormDetails,
        smsTempAttr: smsData[0]?.templateForSMSAPI,
        smsTempVal: smsData[0]?.templateId,
      }
      const sendSMS = await SMS.SendSMS(sendDataSms);
    }
    catch (error) {
      console.log(error)
    }
    // ******************** WhatsApp MSG **********************************
    try {
      let mediaId = "";

      if (smsData[0]?.messageHeaderSection_ != "" || smsData[0]?.messageHeaderSection_.includes("WhatsappInvPdfUpload")) {
        //  upload_image(file);
        // const imgId = await generateAndUploadPDF()
        // const imgId = await upload_image()
        setBillPdf(true)
        // mediaId = imgId;
        return false;
      }
      const sendDataWhatsApp = {
        to: mobileNo,
        templateName: smsData[0]?.templateForWhatsappAPI,
        templateLanguage: smsData[0]?.whatsappLanguage,
        headerSection: smsData[0]?.messageHeaderSection_,
        bodySection: smsData[0]?.messageBodySection_,
        fileMediaId: mediaId
      }
      const sendWhatsApp = await SMS.SendWhatsApp(sendDataWhatsApp)
    }
    catch (error) {
      console.log(error)
    }
    // ****************************************************************
  }

  return (
    <>
      <MyContext.Provider
        value={{
          alertBox,
          OpenAlertBox,
          OpenAlertBoxDelete,
          alertBoxDelete,
          setAlertBoxDelete,
          OpenAlertBoxEdit,
          alertBoxEdit,
          alertBoxErrorInsert,
          setAlertBoxErrorInsert,
          alertBoxErrorUpdate,
          setAlertBoxErrorUpdate,
          OpenAlertBoxErrorInsert, OpenAlertBoxErrorUpdate,
          OpenAlertBoxWhenItemExists,
          openAlertItemExists,
          setOpenAlertItemExists,
          input1,
          input2,
          input3,
          input4,
          input5,
          input6,
          input7,
          BusinessType,
          setBusinessType,
          setLoginSuccess,
          UserDetails,
          setUserDetails,
          imageBaseUrl,
          TemplateData,
          setTemplateData,
          droppedItems,
          setDroppedItems,
          setClickedItemId,
          clickedItemId,
          clickedItemName,
          childComponentValue,
          setChildComponentValue,
          setClickedItemName,
          setStylesArray,
          handleStyleChange,
          updateStyle,
          stylesArray,
          handleContentChange,
          setLoginUser,
          isShowTotalAmountInReport,
          appToWebLink,
          webToAppLink,
          isAllowModificationOfVoucher,
          isAutoTransferToKitchen,
          messageFunction,
          setAlertBoxCantDelete,
          alertBoxCantDelete,
          OpenAlertBoxDeleteCant,
          logindone,
          setAppToWebLink,
          setWebToAppLink,
        }}
      >
        {loginSuccess ? (
          <div ref={pdfRef} id="pdf-content">
            <>
              <div className="app-container">
                <div className="left-container">
                  {/* <SideBar class_name={`left-container ${leftContainerWidth === 230 ? 'expanded' : ''}`}
                      width={leftContainerWidth}
                      onMouseEnter1={handleLeftContainerHover}
                      onMouseLeave1={handleLeftContainerLeave}
                      handleToggle={handleToggle} /> */}
                  {loginuser == true ?
                    <SideBarNew
                      class_name={`left-container ${leftContainerWidth === 260 ? "expanded" : ""
                        }`}
                      width={leftContainerWidth}
                      handleToggle={handleToggle}
                    />
                    : <div></div>
                  }
                </div>
                <div className="right-container">
                  <div className="mastercomapny-container">
                    <Mainpage />
                  </div>
                </div>

                {loginuser == true ?
                  <div className={sidebarClassName}>
                    {openSidebar && <SmallSideBar />}
                    <div className="inout-smallbar" onClick={toggleSidebar}>
                      <i
                        className={`fa-solid fa-chevron-left ${sidebarIconchnage}`}
                        style={{ color: "black" }}
                      ></i>
                    </div>
                  </div>
                  :
                  <div></div>
                }
              </div>
            </>
          </div>
        ) : (
          <div >
            {!loginSuccess && (
              <div style={{ display: loginSuccess === null ? "none" : "block" }}>
                <LogInPanel logindone={logindone} setLoginUser={setLoginUser} loginuser={loginuser} />
              </div>
            )}
          </div>
        )}
        <div style={{ display: "none" }}>{/**/}
          {billPdf && (
            <OrderTakerBillPrint id={orderId} mobileNo={mobileNo} isSendMSG={true} setBlob={setBlob} smsData={smsData} setBillPdf={setBillPdf} />
          )}
        </div>
        {alertBoxErrorInsert && (
          <AlertBoxError
            HeadingTop="Warning"
            Heading="YOU DO NOT HAVE PERMISSION TO ACCESS THIS PAGE."
            onYesClick={OpenAlertBoxErrorInsert}
            singleButton={true}
            OpenAlertBox={OpenAlertBoxErrorInsert}
          />
        )}
      </MyContext.Provider>
    </>
  );
}

export default HomePage;
