import React, { useEffect, useState } from 'react';
import moment from "moment";

const PaymantModeModel = (props) => {
  const { closePaymentModal, PaymentModalData, orderList, orderListHomeDelivery, orderListPickUp, serviceTypeId } = props

  console.log(PaymentModalData);

  const [orderData, setOrderData] = useState([]);
  const [totalAmt, setTotalAmt] = useState(0);

  useEffect(() => {
    try {
      const total = PaymentModalData.reduce((total, item) => total + item.paymentAmount, 0)
      setTotalAmt(total)
      // let data;
      // if (serviceTypeId == 751)
      //   data = orderList.filter(item => item.ord1Id == PaymentModalData[0].ord1Id);
      // if (serviceTypeId == 752)
      //   data = orderListHomeDelivery.filter(item => item.ord1Id == PaymentModalData[0].ord1Id);
      // if (serviceTypeId == 753)
      //   data = orderListPickUp.filter(item => item.ord1Id == PaymentModalData[0].ord1Id);
      // setOrderData(data);
    } catch (error) {
      console.error(error);
    }
  }, [PaymentModalData])

  return (
    <div className="modal-back">
      <div className="info-modal" style={{}}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1px 10px",
          }}
        >
          <label className="fw-600" style={{ fontSize: "18px" }}>
            Payment Details
          </label>
          <i
            class="fa-solid fa-xmark xmark icon_ov1"
            onClick={closePaymentModal}
          ></i>
        </div>
        <hr />
        <div style={{ overflowX: 'auto' }}>
          <table className="tableStyle">
            <thead className="thead_scroll_orderlist table_headerSticky">
              <tr>
                <th
                  className="thStyle"
                  style={{}}
                >
                  S.No
                </th>
                <th
                  className="thStyle">
                  Order No
                </th>
                <th
                  className="thStyle">
                  Trans Id
                </th>
                <th
                  className="thStyle">
                  Payu Id
                </th>
                <th
                  className="thStyle">
                  Bank Ref No
                </th>
                <th
                  className="thStyle">
                  Amount
                </th>
                <th
                  className="thStyle">
                  Pay Mode
                </th>
                <th
                  className="thStyle">
                  Payment Time
                </th>
                <th
                  className="thStyle">
                  Payment Received By
                </th>
                <th
                  className="thStyle">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {PaymentModalData.map((data, index) => (
                console.log(PaymentModalData),
                <tr key={index}>
                  <td style={{ textAlign: "center" }} className="tdStyle">{index + 1}</td>
                  {/* <td style={{ textAlign: "center" }} className="tdStyle">{orderData && orderData[0] != undefined && orderData[0] != null ? orderData[0].orderNo : 0}</td> */}
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.orderNo}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.transId}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.payUId}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.bankRefNo}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">₹&nbsp;{data.paymentAmount}</td> {/* Assuming 'amount' is the key for the value 315 */}
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.paymentMode}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">{moment(data.paymentDoneOn).format('DD-MM-YYYY hh:mm A')}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.paymentDoneBy}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.status}</td>
                </tr>
              ))}
              <tr>
                <td style={{ textAlign: "end" }} colSpan={5} className="tdStyle">Total Amt:</td>
                <td style={{ textAlign: "center" }} className="tdStyle">₹&nbsp;{totalAmt}</td> {/* Assuming 'amount' is the key for the value 315 */}
                <td style={{ textAlign: "center" }} className="tdStyle"></td>
                <td style={{ textAlign: "center" }} className="tdStyle"></td>
                <td style={{ textAlign: "center" }} className="tdStyle"></td>
                <td style={{ textAlign: "center" }} className="tdStyle"></td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div
          className="mt-3"
          style={{
            display: "flex",
            justifyContent: "center",
            border: "2px solid grey",
            padding: "25px",
          }}
        > */}
        {/* <button
            className="btn"
            style={{
              fontWeight: "600",
              color: "white",
              backgroundColor: "#06aadf",
            }}
            onClick={props.handlePayment}
          >
            Online Payment Verify
          </button> */}
        {/* </div> */}
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <button
            className="redBtn"
            style={{ width: "17%" }}
            onClick={closePaymentModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default PaymantModeModel