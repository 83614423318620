import React, { useState, useEffect } from "react";

const Select = (props) => {

  const [getValueAndStrg, setGetValueAndStrg] = useState([])

  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    // Store only unique id values in localStorage
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);
  const deletebuttonshow = () => {
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };
  let getId = props.keyValueId;


  return (
    <div style={{ position: "relative" }} onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true" onClick={deletebuttonshow} id={getId}>
      <input className="" type="text" />
      <select className="" name="" id="">
        <option className="" value=""></option>
      </select>
    </div>
  )
}

export default Select