import React, { useEffect, useState } from "react";

const ImageSlider = (props) => {
  const [getValueAndStrg, setGetValueAndStrg] = useState([]);

  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    // Store only unique id values in localStorage
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);
  const deletebuttonshow = () => {
    setGetValueAndStrg(getId);
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };
  let getId = props.keyValueId;

  const images = [
    "https://images.unsplash.com/photo-1679678691006-d8a1484830c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    "https://images.unsplash.com/photo-1448375240586-882707db888b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80",
    "https://images.unsplash.com/photo-1511497584788-876760111969?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1032&q=80",
    "https://t4.ftcdn.net/jpg/05/35/33/39/360_F_535333922_tBGFT4qC3bLUrnKWWQhj8pXHS1cQIuFK.jpg",
    "https://images8.alphacoders.com/132/thumbbig-1325725.png",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);


  // console.log(props.widthV);
  let sliderwidth;
  switch (true) {
    case props.widthV > 0 && props.widthV <= 450:
      sliderwidth = "slider-mobile";
      break;
    case props.widthV > 450 && props.widthV <= 850:
      sliderwidth = "slider-tablet";
      break;
    default:
      sliderwidth = "";
      break;
  }





  useEffect(() => {
    const interval = setInterval(goToNextSlide, 3000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  const goToNextSlide = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const goToPreviousSlide = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  return (



    <div
      className={`image-slider-container-web-template ${sliderwidth} `}
      style={{
        backgroundImage: `url(${images[currentIndex]})`
      }}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true"
      onClick={deletebuttonshow}
      id={getId}
    >

      <button className="slider-button-left-web-template" onClick={goToPreviousSlide}>
        <a>
          <i className="fa-solid fa-caret-left"></i>
        </a>
      </button>
      <button className="slider-button-right-web-template" onClick={goToNextSlide}>
        <a>
          <i className="fa-solid fa-caret-right"></i>
        </a>
      </button>
    </div>

  );
};

export default ImageSlider;
