import React, { useState } from 'react'
import OpenIconLibrary from './OpenIconLibrary'


const IconLibrary = (props) => {
  const [iconOpen, setIconOpen] = useState(true)
  const handleCancel = () =>{
    setIconOpen(false);
  }
  
  return (
    <div className="iconlibrary-container" onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true" 
      >
       
      {iconOpen && <OpenIconLibrary  cancel={handleCancel}/>}
    </div>
  )
}

export default IconLibrary