// import React, { useState } from "react";
import { useEffect, useState, useRef } from "react";
import "./OrderTakerStylesheet.css";
import Order from "../../API/APIServices/Order";
import { Await, useLocation, useNavigate } from "react-router-dom";
// import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from "../../Inventory/Props/Loader";
import Company from "../../API/APIServices/Company";
import { use } from "i18next";
import Account from "../../API/APIServices/Account";
import GlobalFunction from "../../API/APIServices/GlobalFunction";
import html2pdf from 'html2pdf.js';
import SMS from "../../API/APIServices/SMS";
import SmsTemplate from "../../API/APIServices/SmsTemplate";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";

function OrderTakerBillPrint(props) {
  const location = useLocation();
  // const { id, paymentAmt, balanceAmt, grandTotal, paymentStatus, orderStatus } = location.state || {}

  let id, paymentAmt, balanceAmt, grandTotal, paymentStatus, orderStatus, isSendMSG, setBlob, smsData, setBillPdf, mobileNo;

  if (location.state && location.state.id) {
    ({ id } = location.state);
  } else {
    ({ id, isSendMSG, setBlob, smsData, setBillPdf, mobileNo } = props); {/*paymentAmt, balanceAmt, grandTotal, paymentStatus, orderStatus*/ }
  }
  console.log("location.state.id", id);

  const searchParams = new URLSearchParams(location.search);
  //const ord1Id = searchParams.get('id');

  // const [filterData, setFilterData] = useState({
  //   fieldName: "ord1Id",
  //   operatorName: "equal",
  //   compareValue: id,
  // });

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [Data, setData] = useState([]);

  const [userData, setUserData] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCancelAmount, setTotalCancelAmount] = useState(0);

  useEffect(() => {
    const fetchBillData = async () => {
      setLoading(true);
      try {
        const filterData = {
          fieldName: "ord1Id",
          operatorName: "equal",
          compareValue: id,
        };
        // if (id != 0 && id != null && id != undefined && id != "") {
        //   filterData = {
        //     fieldName: "ord1Id",
        //     operatorName: "equal",
        //     compareValue: id,
        //   };
        // }
        // else if (props.ord1Id != 0 && props.ord1Id != null && props.ord1Id != undefined && props.ord1Id != "") {
        //   filterData = {
        //     fieldName: "ord1Id",
        //     operatorName: "equal",
        //     compareValue: props.ord1Id,
        //   };
        // }
        if (id != 0 && id != null && id != undefined && id != "") {
          const data = await Order.getOrderListByOrd1Id(id);
          var billdata = data.item1
          setData(billdata);
          console.log("billdata", billdata);

          const total = billdata && billdata.reduce((sum, data) => sum + (data.qty * data.rate), 0);
          setTotalAmount(total);
          //alert(billdata[0].d1AccId);
          const UserData = await Account.getAccountById(billdata[0].d1AccId);
          console.log(UserData.item1[0])
          setUserData(UserData.item1[0]);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchBillData();
  }, []);

  let prevOrderId = null;

  const [currentDateTime, setCurrentDateTime] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const date = await getCurrentDateTime1();
      setCurrentDateTime(date);
    }
    fetchData();
  }, [])

  async function getCurrentDateTime1() {
    // setLoading(true);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const isoString = await CurrentDateAndTime.getCurrentDateTime();
    const now = new Date(isoString);

    const dayOfWeek = days[now.getDay()];
    const month = months[now.getMonth()];
    const dayOfMonth = now.getDate();
    const year = now.getFullYear();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    // setLoading(false);
    return `${dayOfWeek} ${month} ${dayOfMonth} ${year} - ${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
  }

  function formatTime(time) {
    return time < 10 ? `0${time}` : time;
  }

  const [companyData, setCompanyData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        const data = await Company.GetCompanyById(loginUser.compId)
        console.log(data);
        setCompanyData(data)
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    fetchData();
  }, [])

  const handlePrint = () => {
    window.print();
  }

  useEffect(() => {
    //handlePrint();
  }, [])

  const iframeRef = useRef(null);

  /* const generatePDF = () => {
     //const doc = new jsPDF();
     //doc.text('', 10, 10);
     //doc.save('Bill.pdf');
     //debugger;
     //alert();
     const content = document.getElementById('pdf-content');
 
     //const clonedDocument = document.documentElement.cloneNode(true);
 
 
     // Remove the button from the cloned document
     //const buttonToRemove = clonedDocument.querySelector('button');
 
     // Get the HTML content of the modified document
     //const modifiedPageData = "<!DOCTYPE html><html><head><style>body {  margin-left: 200px;  background: #5d9ab2 url('img_tree.png') no-repeat top left;}.center_div {  border: 1px solid gray;  margin-left: auto;  margin-right: auto;  width: 90%;  background-color: #d0f0f6;  text-align: left;  padding: 8px;}</style></head><body><div class='center_div'>  <h1>Hello World!</h1>  <p>This example contains some advanced CSS methods you may not have learned yet. But, we will explain these methods in a later chapter in the tutorial.</p></div></body></html>" //clonedDocument.outerHTML;
 
     // Create the data array
     // const data = [
     //   { key: 'Print', data: modifiedPageData }
     // ];
 
     // Send the modified data to React Native
     // window.ReactNativeWebView.postMessage(JSON.stringify(data));
 
     // // Print the modified content
     // window.print();
 
     const options = {
       margin: 10,
       filename: 'Invoice.pdf',
       image: { type: 'jpeg', quality: 0.98 },
       html2canvas: { scale: 2 },
       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
     };
 
     //html2pdf().from(content).set(options).save();
 
     html2pdf().from(content).toPdf().output('datauristring').then(pdf => {
       // Set the PDF content as the source of the iframe
       iframeRef.current.src = pdf;
     });
   };*/

  // const generatePDF = async () => {
  //   // GlobalFunction.generatePDF("displayBlock", "pdf-content");

  //   document.getElementById("displayBlock").style.display = "block";
  //   const element = document.getElementById("pdf_content");

  //   const opt = {
  //     margin: 0,
  //     filename: 'myfile.pdf',
  //     image: { type: 'png' },
  //     // image: { type: 'jpeg', quality: 1 },
  //     html2canvas: { scale: 4 },
  //     jsPDF: { unit: 'mm', format: [58, 297], orientation: 'portrait' } //[58mm by 297mm] page width and page height 
  //   };
  //   //   .from(element)
  //   //   .set(opt)
  //   //   .toPdf()
  //   //   .output('blob') // Change output to 'blob'
  //   //   .then(pdfBlob => {
  //   //     // Convert PDF blob to base64
  //   //     const reader = new FileReader();
  //   //     reader.readAsDataURL(pdfBlob);
  //   //     reader.onloadend = () => {
  //   //       const pdfBase64 = reader.result;
  //   //       // printPDF(pdfBase64);
  //   //       window.ReactNativeWebView.postMessage(pdfBase64);
  //   //       alert("Print")
  //   //     };
  //   //   })
  //   //   .catch(error => {
  //   //     console.error('Error generating PDF:', error);
  //   //   });

  //   html2pdf()
  //     .from(element)
  //     .set(opt)
  //     .toPdf()
  //     .output('datauristring')
  //     .then(pdf => {
  //       const pdfBase64 = pdf.split(',')[1];
  //       window.ReactNativeWebView.postMessage(pdfBase64);
  //       alert("Print")
  //     })
  //     .catch(error => {
  //       try{

  //       }
  //       catch(error){
  //         console.error('Error generating PDF:', error);
  //       }
  //     });

  // };


  // **************************************************

  const generatePDF = async () => {
    setLoading(true);
    const element = document.getElementById("pdf_content");

    if (!element) {
      console.log('Element with id "pdf_content" not found.');
      return;
    }
    // PDF generation options
    const opt = {
      margin: 0,
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: [58, 297], orientation: 'portrait' } // Page width and height in mm
    };

    try {
      const pdfDataUri = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .output('datauristring');

      const pdfBase64 = pdfDataUri.split(',')[1];

      let file;
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(pdfBase64);
      } else {
        // Create a blob from the base64 string
        const byteCharacters = atob(pdfBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        file = new File([blob], 'myfile.pdf', {
          type: 'application/pdf',
          lastModified: Date.now()
        });

        // Log file metadata
        console.log('File metadata:', {
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          name: file.name,
          size: file.size,
          type: file.type,
          webkitRelativePath: file.webkitRelativePath
        });

        if (!isSendMSG) {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'myfile.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Print the PDF directly from the web
          window.open(URL.createObjectURL(blob));
        }
      }
      // Ensure blob is not undefined before appending to FormData
      if (file) {
        setBlob(file)
        await sendMsg(file); // Assuming sendMsg is a function that sends the FormData
        setBillPdf(false)
      }
    } catch (error) {
      console.log('Error generating PDF:', error);
    }
    setLoading(false)
  };

  // const generatePDF = async () => {
  //   debugger;
  //   //document.getElementById("displayBlock").style.display = "block";
  //   const element = document.getElementById("pdf_content");

  //   let upBlob;
  //   const opt = {
  //     margin: 0,
  //     filename: 'myfile.pdf',
  //     image: { type: 'png' },
  //     html2canvas: { scale: 4 },
  //     jsPDF: { unit: 'mm', format: [58, 297], orientation: 'portrait' } // Page width and height in mm
  //   };


  //   const formData = new FormData();

  //   html2pdf()
  //   .from(element)
  //   .set(opt)
  //   .toPdf()
  //   .output('datauristring')
  //   .then(pdfDataUri => {
  //     const pdfBase64 = pdfDataUri.split(',')[1];

  //     let blob;
  //       // Check if running in a WebView
  //       if (window.ReactNativeWebView) {
  //         window.ReactNativeWebView.postMessage(pdfBase64);
  //       } else {
  //         // Create a blob from the base64 string
  //         const byteCharacters = atob(pdfBase64);
  //         const byteNumbers = new Array(byteCharacters.length);
  //         for (let i = 0; i < byteCharacters.length; i++) {
  //           byteNumbers[i] = byteCharacters.charCodeAt(i);
  //         }
  //         const byteArray = new Uint8Array(byteNumbers);
  //          const blob = new Blob([byteArray], { type: 'application/pdf' });


  //         // *************************************

  //         // Create a link element and click it to download the PDF
  //         const link = document.createElement('a');
  //         link.href = URL.createObjectURL(blob);
  //         link.download = 'myfile.pdf';
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);

  //         // Print the PDF directly from the web
  //         window.open(URL.createObjectURL(blob));
  //         // alert("Print");
  //       }
  //       if (blob) {
  //         const formData = new FormData();
  //         formData.append('file', blob, 'myfile.pdf');
  //         await sendMsg(formData); // Assuming sendMsg is a function that sends the FormData
  //       }
  //     })
  //     .catch(error => {
  //       alert('Error generating Print:', error);
  //     });

  //     formData.append('file', upBlob, 'myfile.pdf');
  //     sendMsg(formData)
  // };

  const sendMsg = async (blob) => {
    const imageUpload = await SmsTemplate.uploadWhatsAppFile(blob);

    const match = imageUpload.match(/\d+/);
    let mediaId;
    if (match) {
      mediaId = match[0];
      console.log(mediaId);
    } else {
      console.log('No number found in the string.');
    }
    if (isSendMSG) {
      const sendDataWhatsApp = {
        to: mobileNo,
        templateName: smsData[0]?.templateForWhatsappAPI,
        templateLanguage: smsData[0]?.whatsappLanguage,
        headerSection: smsData[0]?.messageHeaderSection_,
        bodySection: smsData[0]?.messageBodySection_,
        fileMediaId: mediaId
      }
      const sendWhatsApp11 = await SMS.SendWhatsApp(sendDataWhatsApp)
    }
  }

  function printPDF(base64String) {
    // Code to interface with your thermal printer API
    // This could involve Bluetooth, USB, Wi-Fi, etc.
    //window.ReactNativeWebView.postMessage(base64String);
  }

  // const generatePDF = () => {
  //   const element = document.getElementById('pdf-content');

  //   html2pdf()
  //     .from(element)
  //     .toPdf()
  //     .output('datauristring')
  //     .then(pdf => {
  //       // Convert the PDF Data URI to base64
  //       const pdfBase64 = pdf.split(',')[1];
  //       // Send the base64 string to the WebView
  //       window.ReactNativeWebView.postMessage(pdfBase64);
  //     })
  //     .catch(error => {
  //       console.error('Error generating PDF:', error);
  //     });
  // };

  // const generatePDF = () => {
  //   const element = document.getElementById('pdf-content');
  //   console.log(element);
  //   const pdfbase64 = html2pdf().from(element).toPdf().output('datauristring')
  //   console.log(pdfbase64)
  //   // const data = [
  //   //   { key: 'Print', data: pdfbase64 }
  //   // ];
  //   window.ReactNativeWebView.postMessage(JSON.stringify(pdfbase64));
  //   // .then(pdf => {
  //   //   iframeRef.current.src = pdf;
  //   // });
  // };

  //   return (
  //     <div>
  //       <div id="pdf-content">
  //         <h1>Hello, world!</h1>
  //         <p>This is JSX content.</p>
  //       </div>
  //       <button onClick={generatePDF}>Generate PDF</button>
  //       <iframe ref={iframeRef} style={{ width: '100%', height: '500px', border: '1px solid black' }} />
  //     </div>
  //   );
  // };

  const handleBackBtn = () => {
    if (props.backBtn == "modal") {
      props.closePrintModal();
    }
    else {
      window.history.back();
    }
  }

  const [filteredData, setFilteredData] = useState([]);

  //const filteredData = Data.filter(data => data.cancelApprovedQty !== 0 && data.cancelApprovedQty !== null);

  const fetchData = async () => {
    try {
      setLoading(true);
      // var ordId;
      // if (id != 0 && id != null && id != undefined && id != "") {
      //   ordId = id;
      // }
      // else {
      //   ordId = props.ord1Id;
      // }
      const data = await Order.getCancelItemByOrd1Id(id);
      const groupedData = data.reduce((acc, item) => {
        if (!acc[item.itemOrd2Id]) {
          acc[item.itemOrd2Id] = { ...item, totalApprovedQty: (item.cancelApprovedQty ? item.cancelApprovedQty : 0) };
        } else {
          acc[item.itemOrd2Id].totalApprovedQty += item.cancelApprovedQty;
        }
        return acc;
      }, {});

      const filteredData = Object.values(groupedData);

      const total = filteredData.reduce((sum, data) => sum + (data.totalApprovedQty * data.itemPrice), 0);
      setTotalCancelAmount(total);

      console.log(filteredData);
      setFilteredData(filteredData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const timeFormat = (twentyFourHourTime) => {
    const [startTime, endTime] = twentyFourHourTime.split('-');
    const convertedStartTime = convertTo12HourFormat(startTime.trim());
    const convertedEndTime = convertTo12HourFormat(endTime.trim());
    const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
    return convertedTimeRange;
  }

  function convertTimeTo12HourFormat(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(num => parseInt(num, 10));
    const period = hours >= 12 ? 'pm' : 'am';
    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;

    return formattedTime;
  }

  function convertTo12HourFormat(twentyFourHourTime) {
    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }
    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }
    if (minutes != undefined && minutes != null) {
      const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
      return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
    }
    else {
      return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
    }
  }

  function formatDate(inputDate) {
    const parts = inputDate.split('-');
    const dateObject = new Date(parts[0], parts[1] - 1, parts[2]);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const [hasCalledOnce, setHasCalledOnce] = useState(false);

  useEffect(() => {
    if (isSendMSG) {
      if (!hasCalledOnce) {
        generatePDF();
        setHasCalledOnce(true);
      } else {
        // generatePDF();
        //setHasCalledOnce(false);
      }
    }
  }, [isSendMSG, hasCalledOnce]);

  return (
    <div style={{ fontFamily: "courier", fontWeight: "700", fontSize: "16px" }}>
      {/* <div className="heading">
                <div className="head-display">
                    <div className="space-evenly">
                        <Link to="/OrderTakerView">
                            <i className="fa-solid fa-arrow-left icon-white" style={{ cursor: "pointer" }}></i>
                        </Link>
                        <h2 className="head-title">Feedback</h2>
                    </div>
                </div>
            </div> */}

      {Data && Data.filter((data) => {
        const isDifferentOrderId = data.ord1Id !== prevOrderId;
        prevOrderId = data.ord1Id;
        return isDifferentOrderId;
      }).map((order) => (
        <div
          className="MHut-Bg-border"
          style={{ backgroundColor: "rgb(33, 46, 79)" }}
        >
          <div className="flex-center-MHut" style={{ display: "flex", justifyContent: "space-between" }}>
            <i className="fa-solid fa-arrow-left icon-white" style={{ color: "white", cursor: "pointer", marginLeft: "10px" }} onClick={handleBackBtn}></i>
            <label className="MHut-text printLabel">
              <u className="MHut-underline printLabel" style={{ fontFamily: "courier" }}>{companyData && companyData?.printName}</u>
            </label>
            {/* <button onClick={generatePDF}
              style={{
                color: "#212e4f",
                backgroundColor: "#e4e8f9",
                borderRadius: "5px",
                padding: "5px",
                marginRight: "5px"
              }}
            > PRINT</button> */}
            <i className="fa-solid fa-print" onClick={generatePDF} style={{ color: "white", marginRight: "10px" }} />
          </div>
          <div id="pdf_content">
            <div className="MHut-GST-Border">
              <div style={{ display: "none" }} id="displayBlock">
                <div style={{ display: "flex", justifyContent: "center", marginBottom: "8px", borderBottom: "1px solid black" }}>
                  <label className="MHut-text" style={{ color: "black", textAlign: "center", fontSize: "20px" }}>
                    <label className="MHut-underline" style={{ fontFamily: "calibri" }}>{companyData && companyData?.printName}</label>
                  </label>
                </div>
              </div>
              <div className="space-between">
                <div className="flex-start">
                  <label className="ques-text-col printLabel-dark" style={{ fontFamily: "courier" }}>Company GST No : &nbsp;</label>
                  <label className="ans printLabel" style={{ fontFamily: "courier" }}>{companyData && companyData?.gstTinNo}</label>
                </div>
                {/* <div className="flex-start">
                            <label className="ques-text-col">Type : &nbsp;</label>
                            <label className="ans">Table</label>
                    </div> */}
              </div>
              <div className="flex-start">
                <label className="ques-text-col printLabel-dark">FSSAI No : &nbsp;</label>
                <label className="ans printLabel">{companyData && companyData?.cstNo}</label>
              </div>
            </div>
            <div className="MHut-GST-Border">
              <div className=""> {/*space-between*/}
                <div className="flex-start MHut-width-bill " style={{ width: "100%" }}>
                  <label className="ques-text-col printLabel-dark">Name : &nbsp;</label>
                  <label className="ans printLabel">{Data == undefined ? "" : userData.name}</label>
                </div>
                <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                  <label className="ques-text-col printLabel-dark">Mobile No : &nbsp;</label>
                  <label className="ans printLabel">{userData == undefined ? "" : userData.mobileNo}</label>
                </div>
              </div>
              {/***************/}
              <div className=""> {/*space-between*/}
                <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                  <label className="ques-text-col printLabel-dark">Order No : &nbsp;</label>

                  <label className="ans printLabel">{order.orderNo}</label>
                </div>
                <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                  <label className="ques-text-col printLabel-dark">Date : &nbsp;</label>
                  <label className="ans printLabel">{moment(order.orderDate).format('DD-MM-YYYY')}</label>
                </div>
              </div>
              <div className=""> {/*space-between*/}
              </div>
              <div className=""> {/*space-between*/}
                <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                  <label className="ques-text-col printLabel-dark">Service Type : &nbsp;</label>
                  <label className="ans printLabel">{order.serviceTypeName}</label>
                </div>
                <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                  <label className="ques-text-col printLabel-dark">Company&nbsp;Name:&nbsp;</label>
                  <label className="ans printLabel">{Data == undefined ? "" : Data[0].companyDetails}</label>
                </div>
                {/* <div className="flex-start MHut-width-bill">
              <label className="ques-text-col">Table Order : &nbsp;</label>
              <label className="ans">{order.tableName}</label>
            </div> */}
              </div>
              <div className=""> {/*space-between*/}
                <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                  <label className="ques-text-col printLabel-dark">Table : &nbsp;</label>
                  <label className="ans printLabel">{order.tableName}</label>
                </div>
                <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                  <label className="ques-text-col printLabel-dark">Gst No : &nbsp;</label>
                  <label className="ans printLabel">{Data == undefined ? "" : Data[0].companyGSTNo}</label>
                </div>
              </div>
              <div className=""> {/*space-between*/}
                <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                  <label className="ques-text-col printLabel-dark">Time Slot : &nbsp;</label>
                  <label className="ans printLabel">{Data && Data != null && Data != undefined && Data.length > 0 && formatDate(Data[0].deliveryDate)} , {Data && Data != null && Data != undefined && Data.length > 0 && (Data[0].timeSlotName)}</label>{/*deliveryTime*/}
                </div>
                {order.serviceTypeId == 752 && (
                  <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                    <label className="ques-text-col printLabel-dark">KDTT : &nbsp;</label>
                    <label className="ans printLabel">{Data && Data != null && Data != undefined && Data.length > 0 && convertTimeTo12HourFormat(Data[0].kotDispatchTargetTime)}</label>
                  </div>
                )}
              </div>
              {/*************************/}
            </div>
            {/**************************************************************************/}
            <div className="MHut-GST-Border">
              <table className="table-bill-hd">
                <thead className="thead-groupmaster">
                  <tr>
                    <th className="table-bill-MHut printLabel-dark" style={{ width: "10%" }}>
                      Sno
                    </th>
                    <th
                      className="table-bill-MHut item-left-bill printLabel-dark"
                      style={{ width: "40%" }}
                    >
                      Item
                    </th>
                    <th className="table-bill-MHut printLabel-dark" style={{ width: "15%" }}>
                      Qty
                    </th>
                    <th className="table-bill-MHut printLabel-dark" style={{ width: "15%" }}>
                      Rate
                    </th>
                    <th className="table-bill-MHut printLabel-dark" style={{ width: "15%" }}>
                      Amt
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Data && Data.map((data, index) => (
                    <tr key={data.id}>
                      {" "}
                      {/* Assuming each data item has a unique `id` */}
                      <td className="MHut-Bill-text printLabel">{index + 1}</td>
                      <td className="MHut-Bill-text item-left-bill printLabel">
                        {data.d2ItemName}
                      </td>
                      <td className="MHut-Bill-text printLabel">{data.qty}</td>{" "}

                      <td className="MHut-Bill-text printLabel">{data.rate}</td>{" "}
                      {/* Assuming `data.price` is the item's price */}
                      <td className="MHut-Bill-text printLabel">{data.qty * data.rate}</td>{" "}
                      {/* Assuming `data.finalPrice` is the calculated final price */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex-end mt-1" style={{ marginRight: "10px" }}>
                <label className="printLabel-dark" style={{ fontSize: "14px", fontWeight: "600" }}>Total Order Amt :&nbsp;</label>
                <label className="printLabel" style={{ fontSize: "14px", }}>₹ {totalAmount}</label>
              </div>
            </div>
            {/* ************************** Cancel Order Item *********************** */}
            <div className="MHut-GST-Border" style={{ display: filteredData.length == 0 ? "none" : "block" }}>
              <label className="MHut-Bill-text printLabel-dark"><u>Cancel Item Details :-</u></label>{" "}
              <table className="table-bill-hd">
                <thead className="thead-groupmaster">
                  <tr>
                    <th className="table-bill-MHut printLabel-dark" style={{ width: "10%" }}>
                      Sno
                    </th>
                    <th
                      className="table-bill-MHut item-left-bill printLabel-dark"
                      style={{ width: "40%" }}
                    >
                      Item
                    </th>
                    <th className="table-bill-MHut printLabel-dark" style={{ width: "15%" }}>
                      Qty
                    </th>
                    <th className="table-bill-MHut printLabel-dark" style={{ width: "15%" }}>
                      Rate
                    </th>
                    <th className="table-bill-MHut printLabel-dark" style={{ width: "15%" }}>
                      Amt
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData && filteredData
                    .filter(item => item.totalApprovedQty != 0)
                    .map((data, index) => (
                      <tr key={data.id}>
                        <td className="MHut-Bill-text printLabel">{index + 1}</td>
                        <td className="MHut-Bill-text item-left-bill printLabel">{data.itemName}</td>
                        <td className="MHut-Bill-text printLabel">{data.totalApprovedQty}</td>
                        <td className="MHut-Bill-text printLabel">{data.itemPrice}</td>
                        <td className="MHut-Bill-text printLabel">{data.totalApprovedQty * data.itemPrice}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="flex-end mt-1" style={{ marginRight: "10px" }}>
                <label className="printLabel-dark" style={{ fontSize: "14px", fontWeight: "600" }}>Total Cancellation Amt :&nbsp;</label>
                <label className="printLabel" style={{ fontSize: "14px", }}>₹ {totalCancelAmount}</label>
              </div>
            </div>
            {/****************/}
            <div className="MHut-GST-Border">
              {/* <div>
                        <label className="ques-text-col">Suggestion</label><br />
                        <textarea></textarea>
                    </div> */}
              <div className="" style={{ justifyContent: '' }}>
                <div>
                  <label className="ques-text-col" style={{ color: "darkorange" }}>
                    &#9733;
                  </label>
                  <label className="ques-text-col printLabel-dark">&nbsp;Bill include of GST &nbsp;</label>
                </div>
                <label className="ques-text-col printLabel-dark">FINAL BILL AMOUNT : ₹{totalAmount - totalCancelAmount}</label>
              </div>
              <div className="flex-start" style={{ justifyContent: 'space-between' }}>
                <label className="ques-text-col printLabel-dark" style={{ width: "100%" }}>
                  Print&nbsp;on&nbsp;{currentDateTime}&nbsp;
                </label>
                {/* <label className="ques-text-col">
                FINAL&nbsp;BILL&nbsp;AMOUNT:₹{order.grandTotal}
              </label> */}
              </div>
            </div>
            <div className="printLabel MHut-GST-Border" style={{ textAlign: "center", color: "black", padding: "10px", backgroundColor: "white" }}>We're grateful for your visit. Thank you !</div>
            {/* <button onClick={generatePDF}>Generate PDF</button> */}
            {/* <iframe ref={iframeRef} style={{ width: '100%', height: '0px', border: '1px solid transparent' }} /> */}
          </div>
        </div>
      ))}
      {loading && <Loader />}
    </div>
  );
}

export default OrderTakerBillPrint;
