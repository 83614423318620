
function Viewcode(props) {
  // console.log(props.width);

  //   const styles = {
  //     width: props.width + 'px',
  //   };
  //  console.log(styles);


  return (
    <div className="modal-back" onClick={props.handleclick}>
      <div  className="viewcode-modal">
        <div className="viewcode-modal-heading">Code</div>
        <i id="modal_box_close" onClick={props.handleclick} className="fa-sharp fa-solid fa-xmark"></i>
      <div className="viewcode-modal-main">
        <div className="viewcode-modal-left">
            <div className="viewcode-modal-left-heading"><p>HTML</p></div>
        </div>
        <div className="viewcode-modal-right">
            <div className="viewcode-modal-left-heading"><p>CSS</p></div>
        </div>
      </div>
    </div>
      </div>
  );
}

export default Viewcode;
