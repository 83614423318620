import React, { useEffect, useState } from "react";

const AlertBox = (props) => {
  const [getValueAndStrg, setGetValueAndStrg] = useState("");
  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    // Store only unique id values in localStorage
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);

  let getId = props.keyValueId;
  const alertboxid = () => {
    setGetValueAndStrg(getId);
  };

  const deletebuttonshow = () => {
    setGetValueAndStrg(getId);
    const deleteButton = document.getElementById(props.keyValueId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
    // document.getElementById("column-one").classList.toggle("element-border-web-template");
  };

  return (
    <div className="column-one-content-web-template" onClick={alertboxid}>

      <div
        // onClick={deletebuttonshow}
        style={{ position: "relative", maxWidth: "100%" }}
        onDragStart={props.handleDrag}
        onDrop={props.handleDrop}
        onDragOver={props.dragover}
        draggable="true"
        className="alert-box-web-template"
        id={getId}
        onClick={deletebuttonshow}

      >
        <h1 className="alert-box-heading-web-template">This is an Alert</h1>
        <p className="alert-box-paragraph-web-template">
          I am a description. Click the edit button to change this text.
        </p>
      </div>
    </div>
  );
};

export default AlertBox;
