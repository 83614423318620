import React from 'react';
import ColumnOne from './ColumnOne';

const ThreeColumn = (props) => {
  let getId1 = props.keyValueId;
  let getId2 = getId1 + "CHILD";
  let getId3 = getId2 + 1;

  let widthClass;
  switch (true) {
    case props.widthVa > 0 && props.widthVa <= 450:
      widthClass = "Three-column-mobile";
      break;
    case props.widthVa > 450 && props.widthVa <= 850:
      widthClass = "Three-column-tablet";
      break;
    default:
      widthClass = "";
      break;
  }
  const handleColumnClick = (id) => {
    props.onComponentClick(id);
  };
  return (
    <div
      id={getId1 + "innerHTML"}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      className={`three-column-web-template ${widthClass} `}
      style={{ display: 'flex', gap: "10px" }}
    >
      <ColumnOne
        keyValueId={getId1}
        handleDrag={props.handleDrag}
        handleDrop={props.handleDrop}
        dragover={props.dragover}
        onClick={() => handleColumnClick(getId1)}
      // initialWidthPercent={33.33}  // Adjust the initial width for three columns
      />
      <ColumnOne
        keyValueId={getId2}
        handleDrag={props.handleDrag}
        handleDrop={props.handleDrop}
        dragover={props.dragover}
        onClick={() => handleColumnClick(getId2)}
      // initialWidthPercent={33.33} // Adjust the initial width for three columns
      />
      <ColumnOne
        keyValueId={getId3}
        handleDrag={props.handleDrag}
        handleDrop={props.handleDrop}
        dragover={props.dragover}
        onClick={() => handleColumnClick(getId3)}
      // initialWidthPercent={33.33}  // Adjust the initial width for three columns
      />
    </div>
  );
};

export default ThreeColumn;
