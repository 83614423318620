import DateTime from "../../Components/DateTime";
import React, { useState, useRef, useEffect, useContext } from "react";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import ItemMaster from "../../../API/APIServices/ItemMaster";
import { useNavigate } from 'react-router-dom';
import MyContext from "../../MyContext";
import ImageUploadWithPreview from "../../Components/ImagePreview";

function AttributeField() {

  const navigate = useNavigate();

  useState(() => {
    document.title = "Attribute Field Details";
  }, []);

  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState([]);

  const fetchAttribute = async () => {
    var data1 = await ItemMaster.GetItemAttributeSettings();
    console.log("update data", data1);
    if (data1) {
      setInputValue(data1.length);
      handleInputChange(data1.length);

      const newData = Array.from({ length: parseInt(data1.length) }, (_, index) => ({
        id: index,
        input1: "",
        input2: "",
        input3: false,
        input8: "",
      }));

      const newData1 = Array.from({ length: parseInt(data1.length) }, (_, index) => ({
        sn: 0,
        attrId: data1[index].attributeId || "",
        attrName: data1[index].attributeName || "",
        isActive: data1[index].isActive || "",
        priority: data1[index].priority || "",
        attributeChilds: data1[index].attributeChilds || "",
      }));
      setData(newData)
      setInputValues(newData1)
      console.log("newData1", newData1)
    } else {
      console.log("error");
    }
  }


  const fetchAttribute1 = async (value) => {
    try {
      var data1 = await ItemMaster.GetItemAttributeSettings();
      if (data1) {
        console.log("update data", data1);
        // setInputValue(data1.length);
        handleInputChange(value);

        const newData1 = Array.from({ length: parseInt(value) }, (_, index) => {
          if (index < data1.length) {
            return {
              sn: 0,
              attrId: data1[index].attributeId || "",
              attrName: data1[index].attributeName || "",
              isActive: data1[index].isActive || "",
              priority: data1[index].priority || "",
              attributeChilds: data1[index].attributeChilds || "",
            };
          } else {
            return {
              id: index,
              input1: "",
              input2: "",
              input3: false,
              input8: "",
            };
          }
        });


        console.log(newData1)
        setData(newData1)
        setInputValues(newData1)
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchAttribute()
  }, []);

  const [setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };

  const [updateData, setUpdateData] = useState();

  const SaveBtn = async () => {
    console.log(inputValues);
    var data = await ItemMaster.SaveItemAttributeSettings(inputValues);
    if (data) {
      alert("your data is added");
      setUpdateData(data);
    } else {
      alert("error");
    }
  };

  // const [AttributeChild, setAttributeChild] = useState([
  //   // loop
  // ]);

  const [inputValues, setInputValues] = useState([]);

  //console.log(inputValues)

  const handleInputChangeAName = (index, event) => {
    const { name, value } = event.target; // Destructure name and value from event.target
    console.log(`Name - ${name} and value - ${value}`);

    setInputValues((prevValues) => {
      // Create a new copy of the array
      const updatedValues = [...prevValues];
      // Check if the object at the specified index exists
      if (updatedValues[index]) {
        // Update the specific attribute of the object
        updatedValues[index][name] = value;
      }
      return updatedValues; // Return the updated array
    });
    // Note: console.log right after setInputValues won't show the updated state due to async nature of setState
    console.log(inputValues);
  };

  const [ItemImage, setItemImage] = useState([]);
  const [imageIds, setImageIds] = useState([]);

  const ItemImageUpload = (e, indx) => {
    const newItemImage = [...ItemImage];
    // If indx is within bounds of the array, update it; otherwise, push it to the end
    if (indx >= 0 && indx < newItemImage.length) {
      newItemImage[indx] = e;
    } else {
      newItemImage.push(e);
    }
    setItemImage(newItemImage);
  };

  const UploadImage = async (e) => {
    e.preventDefault()
    debugger

    const formData = new FormData();
    ItemImage.forEach((file) => {
      formData.append(`files`, file);
    });
    formData.append('ItemAttributeId ', inputFile.ItemAttributeId)
    // formData.append('files', inputFile.Image)
    try {
      const response = await ItemMaster.SetAttributeImage(inputFile.ItemAttributeId, formData)
      console.log(response)
      if (response) {
        alert("Success")
        setInputFile((prevState) => ({
          ...prevState,
          Image: "",
        }));
        console.log(inputFile)
      }
    } catch (error) {
      console.error("Unable to upload", error)
    }
  }


  const handleInputChangeAValue = (index, event) => {
    const { name, value } = event.target; // Assuming 'name' is used somewhere or necessary for your logic.
    console.log(`Changing attribute at index ${index} to ${value}`);

    // Split the value by commas, trim spaces, and remove duplicates
    const parts = [...new Set(value.split(",").map((part) => part.trim()))];
    console.log(parts);

    // Map the parts to objects
    const updatedAttributeChilds = parts.map((part, i) => ({
      sn: i, // Assuming 'sn' is a sequence number you want to maintain.
      attrId: 0, // Assuming a new attribute ID should be assigned by some other process, or you will update this later.
      attrName: part,
    }));

    // Update the inputValues state directly with the new attributeChilds for the specific index
    setInputValues((prevValues) => {
      return prevValues.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            attributeChilds: updatedAttributeChilds,
          };
        }
        return item;
      });
    });
  };

  const createAttributeControles = (event) => {
    const value = event.target.value;
    //console.log(`value - ${value}`);
    handleInputChange(value);
    if (value === "") {
      setInputValue(value)
    } else {
      setInputValue(value)
      fetchAttribute1(value)
    }
  };

  const handleInputChange = (value) => {
    // const value = event.target.value;
    setInputValue(value);

    const newData = Array.from({ length: parseInt(value) }, (_, index) => ({
      id: index,
      input1: "",
      input2: "",
      input3: false,
      input8: "",
    }));

    const newData1 = Array.from({ length: parseInt(value) }, (_, index) => ({
      sn: 0,
      attrId: 0,
      attrName: "",
      isActive: "",
      priority: "",
      attributeChilds: [],
    }));

    if (newData.length > 50) {
      window.alert("You can add only 50 optional fields!!!");
      return;
    } else {
      setData(newData);
      setInputValues(newData1);
    }
  };

  const handleInputChangeActive = (index, e) => {
    const { name, checked } = e.target; // 'type' is not needed for checkboxes as we directly work with 'checked'
    setInputValues((prevValues) => {
      return prevValues.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            [name]: checked, // Directly use 'checked' to set the new state
          };
        }
        return item;
      });
    });
  };

  /**************{Color, modal, size, material}********************/
  const [, setInputValue2] = useState();
  const [suggestions1, setSuggestions1] = useState([]);

  const allSuggestions = ["Model", "Color", "Size", "Material", "Shape"];

  const [currentIndex, setCurrentIndex] = useState(null);

  const handleInputChange2 = (index, event) => {
    setCurrentIndex(index);
    const value = event.target.value;

    const updatedData = [...data];
    updatedData[index].input1 = value;
    setData(updatedData);

    setInputValue2(value);

    const filteredSuggestions = allSuggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions1(filteredSuggestions);
  };

  // const handleSuggestionClick = (suggestion) => {
  //   if (currentIndex !== null) {
  //     const updatedData = [...data];
  //     updatedData[currentIndex].input1 = suggestion;
  //     setData(updatedData);
  //     setCurrentIndex(null);
  //   }
  //   setInputValue2("");
  //   setSuggestions1([]);
  // };

  //***************{Enter Btn Click}***************//

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };
  const [attributeName, setAttributeName] = useState([])

  const [selectedAttribute, setSelectedAttribute] = useState('');
  const [image, setImage] = useState('');
  console.log(image);


  const handleAttributeChange = (event) => {
    const selectedAttrId = event.target.value;
    setSelectedAttribute(selectedAttrId);
    const attrname = inputValues.find((item) => item.attrId === Number(event.target.value))
    if (attrname) {
      console.log(attrname.attributeChilds)
      setAttributeName(attrname.attributeChilds)
    }
  };
  console.log(attributeName)

  const [inputFile, setInputFile] = useState({
    ItemAttributeId: "",
    Image: "",
  })

  // const handlefileChange = (e) => {
  //   const src = e.target.files[0];
  //   setInputFile((prevState) => ({
  //     ...prevState,
  //     Image: src,
  //   }));
  // }

  const handleAttributeVal = (e) => {
    const selectedValue = e.target.value;
    debugger
    setInputFile((prevState) => ({
      ...prevState,
      ItemAttributeId: selectedValue,
    }));
    if (attributeName && selectedValue) {
      const filterData = attributeName.filter(item => item.attrId === Number(selectedValue))
      if (filterData) {
        setImageIds([filterData[0].imageId]);
      }
    } else {
      setImageIds('')
    }
  }

  console.log(inputFile)





  return (
    <div>
      <PageTabs />
      <div></div>
      <PageTabs />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Attribute Field Details</h2>
        </div>

        {/* ********************************{Buttob}********************************* */}
        <MainButton SaveButton={SaveBtn} />
      </div>
      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-right control_flex_m1">
                    <div className="control_radius">
                      <div className="company_info-text-im">
                        Attribute Field
                      </div>
                      <div className="Note-Multi-Attribute">
                        <div className="button-m1 note-padding-left">
                          <label className="Note-text-font">
                            Note: Add multiple attribute values using comm(,)
                            seprated.
                          </label>
                        </div>
                      </div>
                      <div className="Note-Multi-Attribute">
                        <div className="button-m1 note-padding-left">
                          <label className="Note-text-font">
                            Example : Attribute Name=
                            <span style={{ color: "blue" }}> Color</span> And
                            Attribute Value=
                            <span style={{ color: "blue" }}>
                              {" "}
                              Red,Green,Dark_blue,DarkBlue.
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                ref={input1Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input2Ref)
                                }
                                type="text"
                                required
                              >
                                {/* <option>--Select--</option> */}
                                <option>Item</option>
                              </select>
                              <label class="modify_lbl_text3">
                                Select Master Category
                              </label>
                            </div>
                          </div>
                          <div className="Note-Multi-Attribute">
                            <div className="button-m1 note-padding-left">
                              <label className="Note-text-font">
                                *(Attribute detail comes from Shopping Portal
                                branch, if you set any branch.)
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <input
                                className="modify_im_input mode_category1"
                                ref={input2Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input3Ref)
                                }
                                type="text"
                                required
                                id="txtAttributeNo"
                                value={inputValue}
                                onChange={createAttributeControles}
                                name=""
                              />

                              {/* {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>} */}
                              <label class="modify_p_text2">
                                Enter Number of Attribute Field
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="company_info-text-im">Attribute List</div>
                    <div
                      className="table-container-s1"
                      style={{ height: "auto" }}
                    >
                      <table className="table-scrol2" style={{ width: "100%" }}>
                        <thead className="table-tbody">
                          <tr className="table-tr2 tabrow-head-padding">
                            <th className=" table-th-serialno tb_1 c-w priority-width">
                              <label className="c-w">SN</label>
                            </th>
                            <th className="mb_3_attributename c-w">
                              {" "}
                              <div className="thm2 tb_1 c-w">
                                <label id="itemName" className="c-w">
                                  Attribute Name
                                </label>
                              </div>{" "}
                            </th>
                            <th className="mb_4_attributevalue tb_1 c-w">
                              <div className="c-w">Attribute Value</div>
                            </th>
                            <th className="mb_5_unitname tb_1 c-w">
                              {" "}
                              <div className="c-w">Show Attribute (?)</div>
                            </th>
                            <th className="mb_6 tb_1 c-w priority-width">
                              {" "}
                              <div className="thm17 c-w">Priority</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          className="table-container-attribute AddNewItem_scroll addItemTable Field-attmargintop"
                          style={{}}
                        >

                          {(() => {
                            if (data !== null && Array.isArray(data)) {
                              return data.map((row, index) => (
                                <tr className="Table-Attribute-Field" key={index}>
                                  <td
                                    className="table-th-serialno srno-flex-attribute priority-width"
                                    style={{ textAlign: "right" }}
                                  >
                                    <div className="serialno_icon">{index + 1}</div>
                                  </td>
                                  <td className="mb_3_attributename">
                                    <input
                                      className="table-input-Barcode pd_1"
                                      type="text"
                                      // name={`input1_${index}`}
                                      // value={row.input1}
                                      onChange={(e) =>
                                        handleInputChangeAName(index, e)
                                      }
                                      name="attrName"
                                      value={(inputValues[index]?.attrName !== undefined) ? inputValues[index].attrName : ""}
                                    />
                                  </td>

                                  <td className="mb_4_attributevalue">

                                    <textarea
                                      className="table-input-Barcode Attribute_1_txtcenter atrbt-val-outline"
                                      type="text"
                                      id="tags"
                                      size="50"
                                      name="attributeChilds"
                                      // value="val"
                                      onChange={(e) =>
                                        handleInputChangeAValue(index, e)
                                      }
                                      // value={(inputValues[index].attributeChilds || []).map(item => item.attributeName).join(', ')}
                                      //{(inputValues[index]?.attributeName !== undefined) ? inputValues[index].attributeName : ""}
                                      value={inputValues[index]?.attributeChilds !== undefined ? (inputValues[index].attributeChilds?.map(item => item.attrName) || []).join(', ') : " "}
                                      placeholder=""
                                    />
                                    <input
                                      type="hidden"
                                      name=""
                                      id={`txtAttributeNoHidden_${index}`}
                                    />
                                    {/* {suggestions.length > 0 && (
                                  <ul className="suggestion_list sugg-Attrib-val">
                                    {suggestions.map((tag, index) => (
                                      <li
                                        key={index}
                                        onClick={() => handleTagClick(tag)}
                                      >
                                        {tag}
                                      </li>
                                    ))}
                                  </ul>
                                )} */}
                                  </td>

                                  <td className="mb_5_showattribute">
                                    <input
                                      className="table-input-Barcode pd_1_txtcenter Attribute-Checkbox"
                                      type="checkbox"
                                      // name={`input3_${index}`}
                                      name="isActive"
                                      value={(inputValues[index]?.isActive !== undefined) ? inputValues[index].isActive : ""}
                                      checked={(inputValues[index]?.isActive !== undefined) ? inputValues[index].isActive : ""}
                                      onChange={(e) =>
                                        handleInputChangeActive(index, e)
                                      }
                                    />
                                  </td>
                                  <td className="mb_10 priority-width">
                                    <input
                                      className="table-input-Barcode pd_1 border_barcode"
                                      type="number"
                                      // name={`input8_${index}`}
                                      // value={row.input8}
                                      onChange={(e) =>
                                        handleInputChangeAName(index, e)
                                      }
                                      name="priority"
                                      value={(inputValues[index]?.priority !== undefined) ? inputValues[index].priority : ""}
                                    />
                                  </td>
                                </tr>


                              ))
                            }
                          })()}
                        </tbody>
                      </table>
                    </div>
                    <div className="company_info-text-im" style={{ marginTop: "12px" }}>Attribute List Setting</div>


                    <div style={{
                      display: "flex",
                      width: '100%',
                      justifyContent: "center"
                    }}>

                      <div style={{ width: "600px" }}>
                        <div >
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                ref={input1Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input2Ref)
                                }
                                type="text"
                                onChange={handleAttributeChange}
                                required
                              >
                                {/* <option>--Select--</option> */}
                                <option value="">Select name</option>
                                {
                                  inputValues && inputValues.map((item, index) => {
                                    return (
                                      <option key={item.attrId} value={item.attrId}>
                                        {item.attrName}
                                      </option>
                                    )
                                  })
                                }
                              </select>
                              <label class="modify_lbl_text3">
                                Select Attribute
                              </label>
                            </div>
                          </div>

                        </div>


                        <div>
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                ref={input1Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input2Ref)
                                }
                                type="text"
                                onChange={(e) => handleAttributeVal(e)}
                                required
                              >
                                {/* <option>--Select--</option> */}
                                <option value="">Select Value</option>
                                {
                                  attributeName && attributeName.map((item) => {
                                    console.log(item)
                                    return (
                                      <option key={item.attrId} value={item.attrId}>
                                        {item.attrName}
                                      </option>
                                    )
                                  })
                                }
                              </select>
                              <label class="modify_lbl_text3">
                                Select Value
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column"
                      }}>
                        {/* <label class="picture" for="picture__input" tabindex="0" style={{ overflow: "visible" }}>
                          <span class="picture__image">
                          </span>
                          <div class="upload-button">
                            <i class="fa-solid fa-plus" style={{ fontSize: 18 }}>
                            </i>
                          </div>

                          <span className="picture__image"></span>
                          {image ? (

                            <img style={{ width: "100%" }} src={image} alt="" />
                          ) : (
                            <div className="upload-button">
                              <i style={{ fontSize: 18 }} className="fa-solid fa-plus"></i>
                            </div>
                          )}

                        </label>
                        <input
                          id="picture__input"
                          type="file"
                          className="left-side-img-upload"
                         
                          onChange={(e) => handlefileChange(e)}
                        /> */}
                        <ImageUploadWithPreview
                          id={"Image1"}
                          ItemImageUpload={(event) => ItemImageUpload(event, ItemImage.length)}
                          imageIds={imageIds}
                          imgIndex={0}
                          ItemImage={ItemImage}
                          setItemImage={setItemImage}
                        />
                        <div className="button-btn-1">
                          <button className="save-button-main-save" onClick={(e) => { UploadImage(e) }}>Upload</button>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3" onClick={showModal}>
                <i class="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttributeField;
