import { useState } from 'react';
import axios from '../axiosConfig';

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;


var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Authentication = {

    // ************************* Get All ***************************
    getAuthentication: async (formName) => {
        try {

            const apiUrl = `${WebApiUrl}/api/Authentication/UserAuthentication`;
            const response = await axios.get(apiUrl, { formName }, {
            withCredentials: true,
            credentials: 'include',
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    getAuthenticationWithFilter: async (filterData) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Authentication/GetUserAuthentication`;
            const response = await axios.post(apiUrl, {
                filter: filterData,
                order: [
                    {
                        propertyName: "string",
                        ascending: true
                    }
                ],
                pageNumber: 0,
                pageSize: 0
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },


};

export default Authentication;