import React, { useEffect, useState } from "react";
// import ReactDOM from 'react-dom';
// import { useNavigate } from 'react-router-dom';
import AddComponentChild from "./ChildComponents/AddComponentChild";
import ColumnOne from "../Components/DraggableComponent/ColumnOne";
import TwoColumn from "../Components/DraggableComponent/TwoColumn";
import ThreeColumn from "../Components/DraggableComponent/ThreeColumn";
// import ThreeAndSevenColumn from "../Components/DraggableComponent/ThreeAndSevenColumn";
import Text from "../Components/DraggableComponent/Text";
import Link from "../Components/DraggableComponent/Link";
import Image from "../Components/DraggableComponent/Image";
import Video from "../Components/DraggableComponent/Video";
import Map from "../Components/DraggableComponent/Map";
import LinkBlock from "../Components/DraggableComponent/LinkBlock";
import Quote from "../Components/DraggableComponent/Quote";
import TextSection from "../Components/DraggableComponent/TextSection";
import Form from "../Components/DraggableComponent/Form";
import Input from "../Components/DraggableComponent/Input";
import TextArea from "../Components/DraggableComponent/TextArea";
import Select from "../Components/DraggableComponent/Select";
import Button from "../Components/DraggableComponent/Button";
import Label from "../Components/DraggableComponent/Label";
import CheckBox from "../Components/DraggableComponent/CheckBox";
import Radio from "../Components/DraggableComponent/Radio";
import CountDown from "../Components/DraggableComponent/CountDown";
import Tabs from "../Components/DraggableComponent/Tabs";
import CustonCode from "../Components/DraggableComponent/CustonCode";
import ToolTip from "../Components/DraggableComponent/ToolTip";
import Typed from "../Components/DraggableComponent/Typed";
import ImageSlider from "../Components/DraggableComponent/ImageSlider"
import ToggleBox from "./DraggableComponent/ToggleBox";
import Calendar from "./DraggableComponent/Calender";
import IconBox from "./DraggableComponent/IconBox";
import AlertBox from "./DraggableComponent/AlertBox";
import SearchBox from "./DraggableComponent/SearchBox";
import IconLibrary from "./DraggableComponent/IconLibrary";
import NavBarTypes from "./DraggableComponent/NavBarTypes";
import LogoBox from "./DraggableComponent/LogoBox";
import Footer from "./DraggableComponent/Footer";
import Box from "./DraggableComponent/Box";
import Paragraph from "./DraggableComponent/Paragraph";
import Heading from "./DraggableComponent/Heading";



function AddComponent() {
  // const [, setDraggedItem] = useState(null);
  // console.log(draggedItem)

  // const handleDragStart = (e, item,content) => {
  //   setDraggedItem(item); // Set the draggedItem to the actual item id
  //   console.log(item);
  //   // Set the data to be transferred during drag
  //   e.dataTransfer.setData('text', item);
  //   e.dataTransfer.setData('code',content);
  // };
  // const [componentArray, setComponentArray] = useState([]);

  // const handleAddComponent = (component) => {
  //   setComponentArray((prevArray) => [...prevArray, component]);
  // };
  // console.log(componentArray);


  const [components] = useState([
    {
      id: 1,
      heading: "1 Column",
      icon: "fa-light fa-square ac-icon-1",
      visible: true,
      content: <ColumnOne />,
      type: "basic"
    },
    {
      id: 2,
      heading: "2 Column",
      icon: "fa-light fa-square ac-icon-1",
      visible: true,
      content: <TwoColumn />,
      type: "basic"
    },
    {
      id: 3,
      heading: "3 Column",
      icon: "fa-light fa-square ac-icon-1",
      visible: true,
      content: <ThreeColumn />,
      type: "basic"
    },
    // {
    //   id: 4,
    //   heading: "3/7 Column",
    //   icon: "fa-solid fa-table-cells-large ac-icon-1",
    //   visible: true,
    //   content : <ThreeAndSevenColumn/>

    // },
    {
      id: 5,
      heading: "Text",
      icon: "fa-solid fa-t ac-icon-1",
      visible: true,
      content: <Text />,
      type: "basic"
    },
    {
      id: 6,
      heading: "Link",
      icon: "fa-sharp fa-solid fa-link ac-icon-1",
      visible: true,
      content: <Link />,
      type: "basic"
    },
    {
      id: 7,
      heading: "Image",
      icon: "fa-solid fa-image ac-icon-1",
      visible: true,
      content: <Image />,
      type: "basic"
    },
    {
      id: 8,
      heading: "Video",
      icon: "fa-brands fa-youtube ac-icon-1",
      visible: true,
      content: <Video />,
      type: "basic"
    },
    {
      id: 9,
      heading: "Map",
      icon: "fa-solid fa-map ac-icon-1",
      visible: true,
      content: <Map />,
      type: "basic"
    },
    {
      id: 10,
      heading: "Link Block",
      icon: "fa-solid fa-link-slash ac-icon-1",
      visible: true,
      content: <LinkBlock />,
      type: "basic"
    },
    {
      id: 11,
      heading: "Quote",
      icon: "fa-solid fa-quote-left ac-icon-1",
      visible: true,
      content: <Quote />,
      type: "basic"
    },
    {
      id: 12,
      heading: "Text Section",
      icon: "fa-solid fa-align-left ac-icon-1",
      visible: true,
      content: <TextSection />,
      type: "basic"
    },

    {
      id: 13,
      heading: "Form",
      icon: "fa-solid fa-rectangle-list ac-icon-1",
      visible: true,
      content: <Form />,
      type: "Form"
    },
    {
      id: 14,
      heading: "Input",
      icon: "fa-solid fa-pallet ac-icon-1",
      visible: true,
      content: <Input />,
      type: "Form"
    },
    {
      id: 15,
      heading: "Textarea",
      icon: "fa-solid fa-keyboard ac-icon-1",
      visible: true,
      content: <TextArea />,
      type: "Form"
    },
    {
      id: 16,
      heading: "Select",
      icon: "fa-solid fa-grip-lines  ac-icon-1",
      visible: true,
      content: <Select />,
      type: "Form"
    },
    {
      id: 17,
      heading: "Button",
      icon: "fa-solid fa-toggle-on ac-icon-1",
      visible: true,
      content: <Button />,
      type: "Form"
    },
    {
      id: 18,
      heading: "Label",
      icon: "fa-solid fa-heading  ac-icon-1",
      visible: true,
      content: <Label />,
      type: "Form"

    },
    {
      id: 19,
      heading: "Checkbox",
      icon: "fa-solid fa-square-check ac-icon-1",
      visible: true,
      content: <CheckBox />,
      type: "Form"

    },
    {
      id: 20,
      heading: "Radio",
      icon: "fa-solid fa-circle-dot ac-icon-1",
      visible: true,
      content: <Radio />,
      type: "Form"

    },
    {
      id: 21,
      heading: "Countdown",
      icon: "fa-regular fa-clock ac-icon-1",
      visible: true,
      content: <CountDown />,
      type: "Form"

    },
    {
      id: 22,
      heading: "Tabs",
      icon: "fa-solid fa-bars-progress ac-icon-1",
      visible: true,
      content: <Tabs />,
      type: "Form"

    },
    {
      id: 23,
      heading: "Custom Code",
      icon: "fa-solid fa-code ac-icon-1",
      visible: true,
      content: <CustonCode />,
      type: "Form"

    },
    {
      id: 24,
      heading: "Tooltip",
      icon: "fa-regular fa-message ac-icon-1",
      visible: true,
      content: <ToolTip />,
      type: "Form"

    },
    {
      id: 25,
      heading: "Typed",
      icon: "fa-solid fa-text-height ac-icon-1",
      visible: true,
      content: <Typed />,
      type: "Form"

    },

    {
      id: 26,
      heading: "Silder",
      icon: "fa-regular fa-images ac-icon-1",
      visible: true,
      content: <ImageSlider />,
      type: "Extra"
    },
    {
      id: 27,
      heading: "Accordion Box",
      icon: "fa-solid fa-box ac-icon-1",
      visible: true,
      content: <ToggleBox />,
      type: "Extra"
    },

    {
      id: 28,
      heading: "Calender",
      icon: "fa-solid fa-calendar-days ac-icon-1",
      visible: true,
      content: <Calendar />,
      type: "Extra"
    },

    {
      id: 29,
      heading: "Alert Box",
      icon: "fa-regular fa-message ac-icon-1",
      visible: true,
      content: <AlertBox />,
      type: "Extra"
    },

    {
      id: 30,
      heading: "Icon Box",
      icon: "fa-solid fa-icons ac-icon-1",
      visible: true,
      content: <IconBox />,
      type: "Extra"
    },

    {
      id: 31,
      heading: "Search box",
      icon: "fa-solid fa-magnifying-glass ac-icon-1",
      visible: true,
      content: <SearchBox />,
      type: "Extra"
    },

    {
      id: 32,
      heading: "Icon Library",
      icon: "fa-solid fa-icons ac-icon-1",
      visible: true,
      content: <IconLibrary />,
    },

    {
      id: 33,
      heading: "NavBar",
      icon: "fa-regular fa-images ac-icon-1",
      visible: true,
      content: <NavBarTypes />,
      type: "Extra"
    },

    {
      id: 34,
      heading: "Logo",
      icon: "fa-regular fa-images ac-icon-1",
      visible: true,
      content: <LogoBox />,
      type: "Extra"
    },

    {
      id: 35,
      heading: "Footer",
      icon: "fa-regular fa-images ac-icon-1",
      visible: true,
      content: <Footer />,
      type: "Extra"
    },
    {
      id: 36,
      heading: "Box",
      icon: "fa-regular fa-images ac-icon-1",
      visible: true,
      content: <Box />,
      type: "basic"
    },
    {
      id: 37,
      heading: "Paragraph",
      icon: "fa-solid fa-t ac-icon-1",
      visible: true,
      content: <Paragraph />,
      type: "basic"
    },
    {
      id: 38,
      heading: "Heading",
      icon: "fa-solid fa-t ac-icon-1",
      visible: true,
      content: <Heading />,
      type: "basic"
    },

  ]);

  const [comopnentTemplate] = useState([]);

  const [basics, setBasics] = useState(false);
  const [forms, setForms] = useState(false);
  const [extra, setExtra] = useState(false);

  const basicsshow = () => {
    setBasics(!basics);
    document.getElementById("arrow1").classList.toggle("rotateicon");
  };

  const formsshow = () => {
    setForms(!forms);
    document.getElementById("arrow2").classList.toggle("rotateicon");
  };

  const extrashow = () => {
    setExtra(!extra);
    document.getElementById("arrow3").classList.toggle("rotateicon");
  };



  // const dragstart = (e ,id) => {
  //   console.log("drag start");
  //   console.log(e)
  //   console.log(id);

  //   e.dataTransfer.setData(id)

  // };


  const dragstart = (e, id) => {
    console.log("drag start");
    console.log(id);
    e.dataTransfer.setData("text", id);
    console.log(e);
  };

  const dragover = (e) => {
    e.preventDefault();
    console.log("drag over");
  };

  const dragStop = (e, dropid) => {

    console.log(e);
    console.log(dropid);

    // e.preventDefault();
    // let dragId = parseInt(e.dataTransfer.getData("text/plain"), 10);
    // console.log(dragId)
    // let dragIndex = components.findIndex(
    //   (components) => components.id === dragId
    // );
    // let dropIndex = components.findIndex(
    //   (components) => components.id === dropid
    // );
    // if (dragIndex === -1 || dropIndex === -1) {
    //   return;
    // }
    // const newArray = [...components];
    // const NewDragindex = components[dragIndex];
    // const NewDropindex = components[dropIndex];
    // newArray[dragIndex] = NewDropindex;
    // newArray[dropIndex] = NewDragindex;

    // setComponents(newArray);

  };
  // const [websites , setWebsites] = useState([]);

  const showcomponent = (comopnent) => {
    // console.log("show")
    // console.log(comopnent)

    var createcomponent = {};
    createcomponent["Component_id"] = comopnent.id;
    createcomponent["Component_name"] = comopnent.heading;
    createcomponent["Component_content"] = comopnent.content;


    comopnentTemplate.push(createcomponent);
    // console.log(comopnent.content)

    //  console.log(ReactDOM);
    //  ReactDOM.render(comopnent.content, document.getElementById('website-content-page'));

  };


  const basicComponents = components.filter(component => component.type === 'basic');
  const formComponents = components.filter(component => component.type === 'Form');
  const extraComponents = components.filter(component => component.type === 'Extra');

  useEffect(() => {
    // Your code here
    console.log(basicComponents);
  }, [basicComponents]);







  return (
    <div className="">
      <div onClick={basicsshow} className="addcomponent-main-box">
        <i
          id="arrow1"
          className="fa-solid fa-caret-right add-comopnent-icon"
        ></i>
        BASICS


      </div>
      <div className="basics-component-box">
        {basics &&
          basicComponents.map((component) => {
            if (component.visible === false) return null;
            // else if (component.id <= 12) {
            return (
              <AddComponentChild
                key={component.id}
                handleClick={() => {
                  showcomponent(component);
                  // handleAddComponent(component.content)
                }}
                handleDrag={(e) => {
                  dragstart(e, component.id);
                }}
                dragover={(e) => {
                  dragover(e);
                }}
                handleDrop={(e) => {
                  dragStop(e, component.id);
                  showcomponent(component)
                }}

                id={component.id}
                icon={component.icon}
                heading={component.heading}
              />
            );
            // }
            // return null;
          })}
      </div>

      <div onClick={formsshow} className="addcomponent-main-box">
        <i
          id="arrow2"
          className="fa-solid fa-caret-right add-comopnent-icon"
        ></i>
        FORMS
      </div>
      <div className="forms-component-box">
        {forms &&
          formComponents.map((component) => {
            if (component.visible === false) return null;
            // else if (component.id >= 14 && component.id <= 25) {
            return (
              <AddComponentChild
                key={component.id}
                handleClick={() => {
                  showcomponent(component);
                }}
                handleDrag={(e) => {
                  dragstart(e, component.id);
                }}
                dragover={(e) => {
                  dragover(e);
                }}
                handleDrop={(e) => {
                  dragStop(e, component.id);
                  showcomponent(component)
                }}
                id={component.id}
                icon={component.icon}
                heading={component.heading}
              />
              // <DraggableItem item={component.id} content={components.content} onDragStart={handleDragStart} />
            );
            // }
            // return null;
          })}
      </div>

      <div onClick={extrashow} className="addcomponent-main-box">
        <i
          id="arrow3"
          className="fa-solid fa-caret-right add-comopnent-icon"
        ></i>
        EXTRA

      </div>
      <div className="extra-component-box">
        {extra &&
          extraComponents.map((component) => {
            if (component.visible === false) return null;
            // else if (component.id >= 21) {
            return (
              <AddComponentChild
                key={component.id}
                handleClick={() => {
                  showcomponent(component);
                }}
                handleDrag={(e) => {
                  dragstart(e, component.id);
                }}
                dragover={(e) => {
                  dragover(e);
                }}
                handleDrop={(e) => {
                  dragStop(e, component.id);
                  showcomponent(component)
                }}
                id={component.id}
                icon={component.icon}
                heading={component.heading}
              />
            );
            // }
            // return null;

          })}
      </div>

    </div>
  );
}

export default AddComponent;
