import React, { useState } from 'react';
import useIntersectionObserver from '../../Restaurant/Orders/useIntersectionObserver ';

const ImageLoader = ({ imageUrl, altText }) => {
    const [imageSrc, setImageSrc] = useState('');

    const loadImage = () => {
        setImageSrc(imageUrl);
    };

    const imageRef = useIntersectionObserver(loadImage, { threshold: 0.1 });

    return (
        <img ref={imageRef} src={imageSrc} alt={altText} style={{ width: "80px", height: "80px" }} />
    );
};

export default ImageLoader;