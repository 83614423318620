import React, { useEffect, useState } from "react";
import "./OrderTakerStylesheet.css";
import { useNavigate, useLocation } from 'react-router-dom';
import FeedbackSettingApi from "../../API/APIServices/FeedbackSetting"
import Order from "../../API/APIServices/Order";
import moment from 'moment';
import Loader from "../../Inventory/Props/Loader";
import AppHeaderDetail from "../AppHeaderDetail";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";

function OrderFeedback(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const ord1Id = searchParams.get('ord1Id') || props.ord1Id;
    const [loading, setLoading] = useState(true);

    const [orderRating, setOrderRating] = useState();
    const [itemList, setItemList] = useState();
    const [itemRatings, setItemRatings] = useState([]);
    const [orderRatings, setOrderRatings] = useState([]);

    const fetchData = async () => {
        setLoading(true)
        try {
            var filters = [
                {
                    "filter": [
                        {
                            "fieldName": "serviceTypeId",
                            "operatorName": "equal",
                            "compareValue": props.serviceTypeId || 751
                        }
                    ],
                    "order": [
                        {
                            "propertyName": "string",
                            "ascending": true
                        }
                    ],
                    "pageNumber": 0,
                    "pageSize": 0
                }
            ]
            const orderRatingData = await FeedbackSettingApi.getFeedbackSetting(filters);
            setOrderRating(orderRatingData);
            setOrderRatings(Array(orderRatingData.length).fill(0));

            const filteredData = [{
                fieldName: "ord1Id",
                operatorName: "Equal",
                compareValue: ord1Id
            }]
            const OverallFeedback = await FeedbackSettingApi.getOrderWiseFeedbackSetting(filteredData);
            let newOrdRatings = [];
            console.log("OverallFeedback", OverallFeedback);

            for (let i = 0; i < orderRatingData.length; i++) {
                const feedbackId = orderRatingData[i].feedbackId;
                const correspondingFeedback = OverallFeedback.find(feedback => feedback.feedbackId === feedbackId);

                newOrdRatings[i] = {
                    rating: correspondingFeedback ? correspondingFeedback.feedbackValue : 0,
                    ord2Id: 0,
                    feedbackId: feedbackId,
                    feedbackCaption: orderRatingData[i].feedbackCaption
                };
            }
            console.log("rating-test", newOrdRatings);
            setOrderRatings(newOrdRatings);

            // *****************
            const newOrderRatings = newOrdRatings.map(newOrdRating => {
                const feedbackData = OverallFeedback.find(feedback => feedback.feedbackId === newOrdRating.feedbackId);
                return {
                    rating: feedbackData ? feedbackData.feedbackValue : 0,
                    ord1Id: feedbackData ? feedbackData.ord1Id : 0,
                    feedbackId: feedbackData ? feedbackData.feedbackId : 0,
                    feedbackCaption: feedbackData ? feedbackData.feedbackCaption : ''
                };
            });

            console.log("rating", newOrderRatings);
            //setOrderRatings(newOrderRatings);
            // *************************
            const filterData = [{
                fieldName: "ord1Id",
                operatorName: "Equal",
                compareValue: ord1Id
            }]
            //const orderItemData = await Order.getOrderItemWiseList(ord1Id);
            const data = await Order.getOrderListByOrd1Id(ord1Id);
            var orderItemData = data.item1
            console.log(orderItemData);
            setItemList(orderItemData);
            setItemRatings(Array(orderItemData.length).fill(0));

            let newRatings = [];
            for (var i = 0; i < orderItemData.length; i++) {

                newRatings[i] = { rating: orderItemData[i].itemFeedback, ord2Id: orderItemData[i].ord2Id };
            }
            console.log("rating", newRatings);
            setItemRatings(newRatings);

            // ******************************
            setItemWiseAverageRating(orderItemData[0].overallFoodRating);
            setOrderWiseAvarageRating(orderItemData[0].overallRestaurantRating);
            setSuggestion(orderItemData[0].feedbackSuggestion);
            // ****************************** Order Wise ****************************

        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [ord1Id]);

    const [itemWiseAverageRating, setItemWiseAverageRating] = useState(0);

    const handleItemStarClick = (itemIndex, starIndex, ord2Id) => {
        const newRatings = [...itemRatings];
        newRatings[itemIndex] = { rating: starIndex + 1, ord2Id: ord2Id };
        setItemRatings(newRatings);
        console.log(newRatings);

        // ********************* Get OverAll Item Rating ********************
        let totalRatings = 0;
        let sumOfRatings = 0;
        for (const ratingObj of newRatings) {
            if (typeof ratingObj === 'object' && ratingObj.rating !== null && ratingObj.rating !== 0) {
                sumOfRatings += ratingObj.rating;
                totalRatings++;
            }
        }
        const averageRating = totalRatings > 0 ? sumOfRatings / totalRatings : 0;
        const formattedAverageRating = averageRating.toFixed(1);
        console.log('Average Rating:', formattedAverageRating);
        setItemWiseAverageRating(formattedAverageRating);
    };

    // ******************************* Order wise Rating ***************************
    const [orderWiseAverageRating, setOrderWiseAvarageRating] = useState(0);
    const handleOrderStarClick = (orderIndex, starIndex, feedbackId, feedbackCaption) => {
        const newRatings = [...orderRatings];
        newRatings[orderIndex] = { rating: starIndex + 1, ord2Id: 1, feedbackId: feedbackId, feedbackCaption: feedbackCaption };
        setOrderRatings(newRatings);
        console.log(newRatings);

        // ********************* Get OverAll Item Rating ********************
        let totalRatings = 0;
        let sumOfRatings = 0;
        for (const ratingObj of newRatings) {
            if (typeof ratingObj === 'object' && ratingObj.rating !== null && ratingObj.rating !== 0) {
                sumOfRatings += ratingObj.rating;
                totalRatings++;
            }
        }
        const averageRating = totalRatings > 0 ? sumOfRatings / totalRatings : 0;
        const formattedAverageRating = averageRating.toFixed(1);
        console.log('Average Rating:', formattedAverageRating);
        setOrderWiseAvarageRating(formattedAverageRating);
    };

    const SaveFeedBackSetting = async () => {
        setLoading(true)
        const formattedDate = await CurrentDateAndTime.getCurrentDateTime();
        // const formattedDate = new Date(isoString);

        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        console.log(itemRatings)

        for (const itemRating of itemRatings) {
            const filteredData = [
                {
                    operationType: 0,
                    path: "ItemFeedback",
                    op: "Add",
                    from: "string",
                    value: itemRating.rating
                },
                {
                    operationType: 0,
                    path: "FeedbackUpdateBy",
                    op: "Add",
                    from: "string",
                    value: loginUser.userId
                },
                {
                    operationType: 0,
                    path: "FeedbackUpdateOn",
                    op: "Add",
                    from: "string",
                    value: formattedDate
                }
            ];
            await Order.updateItemPartially(filteredData, itemRating.ord2Id);  //ord2Id
        }
        // ************************** Order wise ***************************
        const filteredData1 = [
            {
                operationType: 0,
                path: "HasFeedbackDone",
                op: "Add",
                from: "string",
                value: true
            },
            {
                operationType: 0,
                path: "OverallFoodRating",
                op: "Add",
                from: "string",
                value: itemWiseAverageRating
            },
            {
                operationType: 0,
                path: "OverallRestaurantRating",
                op: "Add",
                from: "string",
                value: orderWiseAverageRating
            },
            {
                operationType: 0,
                path: "FeedbackSuggestion",
                op: "Add",
                from: "string",
                value: suggestion
            }
        ];
        //await FeedbackSettingApi.orderWiseFeedbackSetting(filteredData1);
        await Order.updateOrderPartially(filteredData1, ord1Id);

        // ************************************
        const orderWiseData = [];
        for (const orderRating of orderRatings) {
            orderWiseData.push({
                ord1Id: Number(ord1Id),
                feedbackId: orderRating.feedbackId,
                userId: loginUser.userId,
                d1AccountId: 0,
                d2AccountId: 0,
                feedbackValue: orderRating.rating,
                feedbackCaption: orderRating.feedbackCaption
            });
        }
        await FeedbackSettingApi.orderWiseFeedbackSetting(orderWiseData);
        setLoading(false)
        alert("Updated");
    };

    const [suggestion, setSuggestion] = useState("");
    const handleSuggestion = (event) => {
        const val = event.target.value;
        console.log(val);
        setSuggestion(val)
    }

    const feedbackBackBtn = () => {
        window.history.back();
    }

    return (
        <div>
            <div style={{ display: props.ord1Id != null && props.ord1Id != undefined && props.ord1Id != 0 ? "none" : "block" }}>
                <AppHeaderDetail title={`Feedback`} setLoading={setLoading} fetchData={fetchData} />
            </div>
            <div className="" style={{ marginTop: props.ord1Id != null && props.ord1Id != undefined && props.ord1Id != 0 ? "0px" : "45px", padding: "5px 0px", backgroundColor: "lightgray" }}>
                <div style={{ margin: "5px", marginTop: "10px", backgroundColor: "white", padding: "5px" }}>
                    <div className="space-between">
                        <div className="flex-start">
                            <label className="ques">Order No : &nbsp;</label>
                            <label className="ans">{itemList && itemList[0] && itemList[0].orderNo !== null ? itemList[0].orderNo : ''}</label>
                        </div>
                        <div className="flex-start">
                            <label className="ques">Type : &nbsp;</label>
                            {props.serviceTypeId == 752 && (
                                <label className="ans">Home Delivery</label>
                            )}
                            {props.serviceTypeId == 753 && (
                                <label className="ans">Pickup</label>
                            )}
                            {props.serviceTypeId == 754 && (
                                <label className="ans">POS</label>
                            )}
                            {props.serviceTypeId != 752 && props.serviceTypeId != 753 && props.serviceTypeId != 754 && (
                                <label className="ans">Table Order</label>
                            )}
                        </div>
                    </div>
                    <div className="flex-start">
                        <label className="ques">Order Date : &nbsp;</label>
                        <label className="ans">{itemList && itemList[0] && itemList[0].orderDate !== null ? moment(itemList[0].orderDate).format('DD-MM-YYYY') : ''}</label>
                    </div>
                </div>
                <div style={{ margin: "5px", backgroundColor: "white", padding: "5px" }}>
                    <div className="flex-center">
                        <label className="ques"><u>Items Feedback</u></label>
                    </div>
                    <hr />
                    {/* *********************************** Item List ******************************* */}
                    {itemList && Array.isArray(itemList) && itemList.length > 0 && itemList.map((data, index) => {
                        return (
                            <div key={index} className="space-between p-1">
                                <label className="ques">{(data.d2ItemName).toUpperCase()}</label>
                                <div>
                                    {[...Array(5)].map((_, starIndex) => (
                                        <span
                                            key={starIndex}
                                            onClick={() => handleItemStarClick(index, starIndex, data.ord2Id)}
                                            style={{
                                                color: starIndex < itemRatings[index].rating ? 'darkOrange' : 'grey',
                                                cursor: 'pointer',
                                                fontSize: "18px"
                                            }}
                                        >
                                            &#9733;
                                        </span>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
                {/**************************************************************************/}
                <div style={{ margin: "5px", backgroundColor: "white", padding: "5px" }}>
                    <div className="flex-center">
                        <label className="ques"><u>Feedback</u></label>
                    </div>
                    <hr />
                    {/* *********************************** Order Rating ******************************* */}
                    {orderRating && Array.isArray(orderRating) && orderRating.length > 0 && orderRating.map((data, index) => {
                        return (
                            <div key={index}>
                                <div className="space-between p-1">
                                    <label className="ques">{(data.feedbackCaption).toUpperCase()}</label>
                                    <div>
                                        {[...Array(data.maxFeedbackValue)].map((_, starIndex) => (
                                            console.log(orderRatings),
                                            <span
                                                key={starIndex}
                                                onClick={() => handleOrderStarClick(index, starIndex, data.feedbackId, data.feedbackCaption)}
                                                // style={{
                                                //     color: starIndex > 3 ? '#ffa500' : starIndex < orderRating ? 'green' : 'grey',
                                                //     cursor: 'pointer', fontSize: "18px"
                                                // }}
                                                style={{
                                                    color: starIndex < orderRatings[index].rating ? 'darkOrange' : 'grey',
                                                    cursor: 'pointer',
                                                    fontSize: "18px"
                                                }}
                                            >
                                                &#9733;
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {/****************/}
                <div style={{ margin: "5px", backgroundColor: "white", padding: "10px" }}>
                    <div>
                        <label className="ques">Suggestion</label><br />
                        <textarea value={suggestion} onChange={handleSuggestion}></textarea>
                    </div>
                    <div className="flex-start">
                        <label className="ques">OverAll Food Quality Rating &nbsp;</label>
                        <label className="ques" style={{ color: "darkorange" }}>{itemWiseAverageRating}&#9733;</label>
                    </div>
                    <hr />
                    <div className="flex-start">
                        <label className="ques">OverAll Rating &nbsp;</label>
                        <label className="ques" style={{ color: "darkorange" }}>{orderWiseAverageRating}&#9733;</label>
                    </div>

                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="greenBtn mt-3" style={{ width: "95%" }} onClick={SaveFeedBackSetting}>Submit</button>
            </div>
            {loading && <Loader />}
        </div>
    );
}


export default OrderFeedback;
