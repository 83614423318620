import React, { useContext, useEffect, useRef, useState } from 'react';
import MyContext from '../../../Inventory/MyContext';

const IconBox = (props) => {
  const { setDroppedItems, setTemplateData, handleContentChange, handleStyleChange, stylesArray } = useContext(MyContext);
  const [isEditing, setIsEditing] = useState(null);
  const divRef = useRef(null);
  const [links, setLinks] = useState({
    facebook: '',
    instagram: '',
    linkedin: '',
    whatsapp: '',
    twitter: ''
  });

  const deletebuttonshow = () => {

    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };

  const handleLinkChange = (platform, url) => {
    setLinks(prevLinks => ({
      ...prevLinks,
      [platform]: url
    }));
  };

  const handleEditClick = (platform) => {
    setIsEditing(isEditing === platform ? null : platform);
  };

  const handleBlur = () => {
    debugger
    setIsEditing(null);



    setTimeout(() => {
      if (divRef.current) {
        const updatedInnerHTML = divRef.current.outerHTML;
        handleContentChange("IconLinks", props.keyValueId, links);

        setDroppedItems(prevItems => {
          const updatedItems = prevItems.map(item => {
            if (item.id === getId) {
              return { ...item, textContents: updatedInnerHTML };
            }
            return item;
          });

          setTemplateData(prevState => ({
            ...prevState,
            jsonStructure: updatedItems,
          }));

          return updatedItems;
        });
      }
    }, 1000);

  };


  const handleFocus = (platform) => {
    setIsEditing(platform);
  };

  let getId = props.keyValueId;


  useEffect(() => {
    const matchingStyle = stylesArray.find(item => item.id === getId);

    if (matchingStyle) {
      setLinks(prevLinks => ({
        facebook: matchingStyle.link.facebook || prevLinks.facebook,
        instagram: matchingStyle.link.instagram || prevLinks.instagram,
        linkedin: matchingStyle.link.linkedin || prevLinks.linkedin,
        whatsapp: matchingStyle.link.whatsapp || prevLinks.whatsapp,
        twitter: matchingStyle.link.twitter || prevLinks.twitter,
      }));
    } else {

      setLinks({
        facebook: '',
        instagram: '',
        linkedin: '',
        whatsapp: '',
        twitter: ''
      });
    }
  }, [getId, stylesArray]);

  return (
    <div
      className="icon-icon-main-box-web-template"
      style={{
        position: "relative", display: "flex", justifyContent: "center"
      }}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true"
      id={getId}
      ref={divRef}
      onClick={deletebuttonshow}
    >
      {
        ['facebook', 'instagram', 'linkedin', 'whatsapp', 'twitter'].map((platform, index) => (
          <div
            key={index}
            style={{ marginBottom: '10px' }}
            onDoubleClick={() => handleEditClick(platform)}
          >
            {isEditing === platform ? (
              <input
                autoFocus
                type="text"
                placeholder={`Enter ${platform} link`}
                value={links[platform]}
                onChange={(e) => handleLinkChange(platform, e.target.value)}
                onBlur={() => handleBlur(platform)}
                onFocus={() => handleFocus(platform)}
                style={{
                  width: '100%',
                  padding: '8px',
                  boxSizing: 'border-box',
                  marginTop: '8px',
                }}
              />
            ) : (
              links[platform] ? (
                <a
                  href={links[platform]}
                  style={{ position: 'relative', textDecoration: 'none', color: 'inherit' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={`icon-icon-box-web-template fa-brands fa-${platform} `}></i>
                </a>
              ) : (
                <i className={`icon-icon-box-web-template fa-brands fa-${platform} `}></i>
              )
            )}
          </div>
        ))
      }
    </div >
  );
}

export default IconBox;


