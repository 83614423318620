import React, { useState } from 'react'
// import GlobalSelector from './GlobalSelector'

const FlexSection = (props) => {
  const [flexVal, setValue] = useState("");

  const handleButtons = (event) => {


    const { value, name } = event.currentTarget;
    const element = document.getElementById(props.id);
    if (element) {
      element.style.position = value;
      props.updateStyle(props.id, name, value);
    }
  }
  const handleChange = (event) => {

    const { value, name } = event.target;

    if (name === "display") {
      setValue(event.target.value);
    }

    const element = document.getElementById(props.id);
    if (element) {
      element.style.position = value;
      props.updateStyle(props.id, name, value);
    }
  }


  return (
    <div className="flex-container">
      <div className="flex-contparent">
        <div className="typography-sectionoption">
          <p className="general-paragraph">Flex Container</p>
          <select name="display" value={flexVal} className='selectorstyle' onChange={handleChange}>
            <option value="none">disable</option>
            <option value="flex">enable</option>
          </select>
        </div>
        <div className="flex-flex-parent">
          <p className="general-paragraph">Flex Parent</p>
          <input type="number" className='flex-input' />
        </div>
      </div>
      <div className='para-alignbutton'>
        <p className='general-paragraph'>Flex Direction</p>
        <div className="alignbox">
          <button className='align-button' name='flex-direction' value="column" onClick={handleButtons} ><i className="icon-edit fa-solid fa-arrow-right"></i></button>
          <button className='align-button' name='flex-direction' value="row" onClick={handleButtons} ><i className="icon-edit fa-solid fa-arrow-left"></i></button>
          <button className='align-button' name='flex-direction' value="column-reverse" onClick={handleButtons} ><i className="icon-edit fa-solid fa-arrow-down"></i></button>
          <button className='align-button' name='flex-direction' value="row-reverse" onClick={handleButtons} ><i className="icon-edit fa-solid fa-arrow-up"></i></button>
        </div>
        <p className='general-paragraph'>Justify</p>
        <div className="alignbox">
          <button className='align-button' name='justify-content' value="center" onClick={handleButtons}><i className="icon-edit fa-solid fa-xmark"></i></button>
          <button className='align-button' name='justify-content' value="start" onClick={handleButtons}><i className="icon-edit fa-solid fa-underline"></i></button>
          <button className='align-button' name='justify-content' value="space-between" onClick={handleButtons}><i className="icon-edit fa-solid fa-strikethrough"></i></button>
          <button className='align-button' name='justify-content' value="space-evenly" onClick={handleButtons}><i className="icon-edit fa-solid fa-underline"></i></button>
          <button className='align-button' name='justify-content' value="end" onClick={handleButtons}><i className="icon-edit fa-solid fa-strikethrough"></i></button>
        </div>
        <p className='general-paragraph'>Align Item</p>
        <div className="alignbox">
          <button className='align-button' name='align-items' value={"center"} onClick={handleButtons}><i className="icon-edit fa-solid fa-xmark"></i></button>
          <button className='align-button' name='align-items' value={"start"} onClick={handleButtons}><i className="icon-edit fa-solid fa-underline"></i></button>
          <button className='align-button' name='align-items' value={"space-between"} onClick={handleButtons}><i className="icon-edit fa-solid fa-strikethrough"></i></button>
          <button className='align-button' name='align-items' value={"end"} onClick={handleButtons}><i className="icon-edit fa-solid fa-underline"></i></button>

        </div>
       
        <div className='para-alignbutton'>
          <p className='general-paragraph'> flex-wrap</p>
          <div className="alignbox">
            {/* <button className='align-button'>Auto</button> */}
            <button className='align-button' name='flex-wrap' value={"nowrap"} onClick={handleButtons}><i className="icon-edit fa-solid fa-arrow-left"></i></button>
            <button className='align-button' name='flex-wrap' value={"wrap"} onClick={handleButtons}><i className="icon-edit fa-solid fa-arrow-down"></i></button>
            <button className='align-button' name='flex-wrap' value={"wrap-reverse"} onClick={handleButtons}><i className="icon-edit fa-solid fa-arrow-up"></i></button>
            <button className='align-button' name='flex-wrap' value={"initial"} onClick={handleButtons}><i className="icon-edit fa-solid fa-arrow-up"></i></button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default FlexSection
