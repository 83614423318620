import React, { useState, useEffect, useContext, useRef } from 'react';
import MyContext from '../../../Inventory/MyContext';

const Paragraph = (props) => {

    const [isEditing, setIsEditing] = useState(false);
    const { setDroppedItems, setTemplateData, handleContentChange, stylesArray, droppedItems } = useContext(MyContext)
    const divRef = useRef(null);
    const divRef1 = useRef(null);
    const textareaRef = useRef(null);
    const [localTextValue, setLocalTextValue] = useState('');

    const [hoveredComponentId, setHoveredComponentId] = useState(null);

    const handleMouseEnter = (id) => {
        setHoveredComponentId(id);
    };

    const handleMouseLeave = () => {
        setHoveredComponentId(null);
    };

    const getParentIdFromChildId = (keyValueId, droppedItems) => {
        for (const item of droppedItems) {
            for (const child of item.JSONChildComponents) {
                if (child.id === keyValueId) {
                    return child.parentJSONid;
                }
            }
        }
        return null; // Return null if the keyValueId is not found
    };
    const fetchInnerHtmlOfDroppedComponent = (componentId) => {
        console.log(componentId);
        debugger
        const getRemainingData = (str) => {
            if (str.endsWith("CHILD")) {
                return str.replace("CHILD", "");
            } else if (str.endsWith("CHILD1")) {
                return str.replace("CHILD1", "");
            }
            return str;
        };

        var newID = getRemainingData(componentId)

        if (componentId.endsWith("CHILD") || componentId.endsWith("CHILD1")) {
            const componentElement = document.getElementById(newID.toString() + "innerHTML");
            if (componentElement) {
                const innerHTML = componentElement.outerHTML;
                console.log(innerHTML);

                // Update the corresponding item with the inner HTML
                setDroppedItems(prevItems => {
                    debugger
                    const updatedItems = prevItems.map(item => {
                        if (item.id === newID) {
                            return { ...item, textContents: innerHTML };
                        }
                        return item;
                    });

                    // Update the template data with the new content
                    setTemplateData(prevState => ({
                        ...prevState,
                        jsonStructure: updatedItems,
                    }));

                    return updatedItems;
                });
            }
            return
        }
        // Find the component element using its ID
        const componentElement = document.getElementById(componentId.toString());


        console.log(componentElement);
        if (componentElement) {
            // Get the inner HTML
            const innerHTML = componentElement.outerHTML;
            console.log(innerHTML);

            setDroppedItems(prevItems => {
                debugger
                const updatedItems = prevItems.map(item => {
                    if (item.id === componentId) {
                        return { ...item, textContents: innerHTML };
                    }
                    return item;
                });

                setTemplateData(prevState => ({
                    ...prevState,
                    jsonStructure: updatedItems,
                }));

                return updatedItems;
            });
        }
    };


    useEffect(() => {
        // Check if divRef is properly set
        if (divRef.current) {
            console.log('divRef is set:', divRef.current);
        } else {
            console.log('divRef is not set');
        }
    }, [isEditing]);

    useEffect(() => {
        debugger
        const matchingStyle = stylesArray.find(item => item.id === props.keyValueId);

        if (matchingStyle) {
            setLocalTextValue(matchingStyle.content || '');
        } else {
            // setTextValue('');
        }
    }, [props.keyValueId, stylesArray]);

    // const handleTextClick = () => {
    //     setIsEditing(true);
    //     deletebuttonshow()
    // };

    const handleColumnClick = (id) => {
        setIsEditing(true);
        deletebuttonshow()
        props.onComponentClick(id);
    };


    const handleBlur = () => {
        setIsEditing(false);
        debugger
        // adjustHeight()

        setTimeout(() => {

            if (divRef.current) {
                console.log(divRef.current);
                const updatedInnerHTML = divRef.current.innerHTML;
                console.log('Updated innerHTML:', updatedInnerHTML);
                if (divRef1.current) {
                    const TextData = divRef1.current.innerHTML
                    handleContentChange(TextData, props.keyValueId)
                    console.log("text Data", TextData);
                    const parentId = getParentIdFromChildId(props.keyValueId, droppedItems)
                    if (parentId != null) {
                        fetchInnerHtmlOfDroppedComponent(parentId)
                    }
                }

                setDroppedItems(prevItems => {
                    const updatedItems = prevItems.map(item => {
                        if (item.id === props.keyValueId) {
                            return { ...item, textContents: updatedInnerHTML };
                        }
                        return item;
                    });
                    setTemplateData(prevState => ({
                        ...prevState,
                        jsonStructure: updatedItems,
                    }));
                    return updatedItems;
                });



            } else {
                console.error('divRef.current is null');
            }
        }, 1000);
    };
    const deletebuttonshow = () => {

        // setGetValueAndStrg(getId)
        const deleteButton = document.getElementById(props.keyValueId + "delete");
        if (deleteButton) {
            deleteButton.classList.toggle("column-one-delete-web-template");
        }
        // document.getElementById("column-one").classList.toggle("element-border");
    };

    const handleChange = (e) => {
        // setTextValue(e.target.value);
        setLocalTextValue(e.target.value);
    };

    const adjustHeight = () => {
        debugger
        console.log(textareaRef);
        console.log(textareaRef.current);

        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }
    useEffect(() => {
        // Adjust textarea height based on content
        adjustHeight()
    }, [localTextValue, textareaRef.current]);

    useEffect(() => {
        adjustHeight()
    }, []);


    const isHovered = hoveredComponentId === props.keyValueId;
    return (
        <div
            style={{ position: 'relative' }}
            onDragStart={props.handleDrag}
            onDrop={props.handleDrop}
            onDragOver={props.dragover}
            id={props.keyValueId}
            draggable="true"
            className="draggable-text-web-template"
            onClick={() => handleColumnClick(props.keyValueId)}
            ref={divRef}

        >
            {isEditing ? (
                <textarea
                    className="draggable-txt-textarea"
                    id={props.keyValueId}
                    value={localTextValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                    // rows={4}
                    ref={textareaRef}
                    style={{ width: '100%', resize: 'none' }}
                />
            ) : (
                <div id={props.keyValueId} ref={divRef1}
                    className={`component-web-template ${isHovered ? 'hover-border-web-template' : ''} `}
                    onMouseEnter={() => handleMouseEnter(props.keyValueId)}
                    onMouseLeave={handleMouseLeave}
                >
                    {localTextValue || 'Click to edit text...'}
                </div>
            )}
        </div>
    );
};

export default Paragraph;
