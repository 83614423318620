import React, { useContext, useRef } from "react";
import WebSiteContent from "../Pages/WebSiteContent";
import DeviceEmulator from "react-device-emulator";
import MyContext from "../../Inventory/MyContext";

const MultiScreen = (props) => {
  const screenRef = useRef(null);
  let value = parseInt(props.value);

  const { TemplateData } = useContext(MyContext)


  if (value === 1) {
    return (
      <div className="screen-laptop screen-size" ref={screenRef}>
        <WebSiteContent data1={props.data} widthValue={"1100"} />
      </div>
    );
  } else if (value === 2) {
    return (
      <div className="screen-tablet screen-size" ref={screenRef}>
        <WebSiteContent data1={props.data} widthValue={"820"} />

      </div>
    );
  } else if (value === 3) {
    return (
      <div className="screen-mobile screen-size" ref={screenRef}>
        <WebSiteContent data1={props.data} widthValue={"400"} />
      </div>
    );
  } else if (value === 4) {
    return (
      <div className="screen-laptop">
        <DeviceEmulator url={`http://localhost:44483/previewTemplate/${TemplateData.id}`} />
      </div>

    );
  } else {
    return (
      <div className="bottom-left default-screen">
        <WebSiteContent data1={props.data} />
      </div>
    );
  }
};

export default MultiScreen;
