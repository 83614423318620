import React from "react";

const About = (props) => {
  return (
    <div className="modal-back"  onClick={props.handleclick}>
      <div className="aboutus-modal">
        <i
          id="modal_box_close"
          onClick={props.handleclick}
          className="fa-sharp fa-solid fa-xmark"
        ></i>

        <p>
          GrapesJS Webpage Builder is a simple showcase of what is possible to
          achieve with the GrapesJS core library <br /> <br />


          For any hint about the demo check the Webpage Preset repository and
          open an issue. For problems with the builder itself, open an issue on
          the main GrapesJS repository <br /> <br />

          
          Being a free and open source project contributors and supporters are
          extremely welcome. If you like the project support it with a donation
          of your choice or become a backer/sponsor via Open Collective
        </p>

        <button className="know-more-button">Know MORE</button>
      </div>
    </div>
  );
};

export default About;
