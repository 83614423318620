import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import Imagemodal from "../ChildComponents/Imagemodal";
import MyContext from "../../../Inventory/MyContext";

const Image = (props, { initialWidthPercent = 100, initialHeight = 100 }) => {
  const [imageModal, setImageModal] = useState(false);
  const [valueFromModal, setValueFromModal] = useState(null);
  const { droppedItems, setDroppedItems, stylesArray, handleStyleChange } = useContext(MyContext);
  const [dimensions, setDimensions] = useState({
    widthPercent: initialWidthPercent,
    minHeight: initialHeight,
    top: 0,
    left: 0,
  })
  console.log("dimensions", dimensions);

  const [activeId, setActiveId] = useState('');
  const hasInitialized = useRef(false);
  const getId = props.keyValueId;

  useEffect(() => {
    if (!hasInitialized.current) {
      const item = droppedItems.find(item => item.id === props.keyValueId);
      if (item) {
        const parsedDimensions = parseTextContents(item.textContents);
        if (parsedDimensions) {
          setDimensions(parsedDimensions);
        }
      }
      hasInitialized.current = true;
    }
  }, [props.keyValueId, droppedItems]);

  const parseTextContents = (textContents) => {
    const regex = /Width:\s*(\d+)%.*Height:\s*(\d+)px.*Top:\s*(\d+)px.*Left:\s*(\d+)px/;
    const match = textContents.match(regex);
    if (match) {
      return {
        widthPercent: parseFloat(match[1]),
        minHeight: `${parseFloat(match[2])}px`,
        top: parseFloat(match[3]),
        left: parseFloat(match[4]),
      };
    }
    return null;
  };

  // Function to handle mouse down events for resizing
  const handleMouseDown = useCallback((e, direction) => {
    e.preventDefault();

    const originalWidthPercent = dimensions.widthPercent;
    const originalHeight = dimensions.minHeight;
    const originalMouseX = e.pageX;
    const originalMouseY = e.pageY;
    const container = document.getElementById(props.keyValueId);
    console.log(container);

    const containerWidth = container.offsetWidth; // Get container width in pixels

    const onMouseMove = (e) => {
      let newWidthPercent = originalWidthPercent;
      let newHeight = originalHeight;
      const containerWidth = container.offsetWidth; // Get container width in pixels

      const maxWidth = containerWidth; // Maximum width in pixels (100% of container width)
      const minWidthPercent = 5; // Minimum width percentage
      const minHeight = 100; // Minimum height in pixels

      if (direction === 'right') {
        const deltaX = e.pageX - originalMouseX;
        var newWidth = (originalWidthPercent / 100 * containerWidth) + deltaX;
        newWidthPercent = (newWidth / containerWidth) * 100;

        // Ensure newWidth doesn't exceed containerWidth
        if (newWidth > maxWidth) {
          newWidth = maxWidth;
        }
      } else if (direction === 'left') {
        const deltaX = originalMouseX - e.pageX;
        var newWidth = (originalWidthPercent / 100 * containerWidth) - deltaX;
        newWidthPercent = (newWidth / containerWidth) * 100;

        if (newWidth > maxWidth) {
          newWidth = maxWidth;
        }

      } else if (direction === 'top') {
        newHeight = originalHeight - (e.pageY - originalMouseY);
      } else if (direction === 'bottom') {
        newHeight = originalHeight + (e.pageY - originalMouseY);
      }
      // Update state with new dimensions
      if (newWidthPercent > 5) setDimensions(prev => ({ ...prev, widthPercent: newWidthPercent }));
      if (newHeight > 40) setDimensions(prev => ({ ...prev, minHeight: newHeight }));
      // Update droppedItems with new dimensions
      // updateDroppedItemsWithDimensions(newWidthPercent, newHeight);
      if (props.keyValueId) {
        const widthValue = `${newWidthPercent}`;
        const heightValue = `${newHeight}`;
        handleStyleChange("width", widthValue, "%", props.keyValueId);
        handleStyleChange("height", heightValue, "px", props.keyValueId);
      }
    };
    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  }, [dimensions, props.keyValueId]);

  useEffect(() => {
    if (props.keyValueId) {
      const styleObject = stylesArray.find(item => item.id === props.keyValueId);
      if (styleObject && styleObject.style) {
        const width = styleObject.style["width"];
        const height = styleObject.style["height"];
        const widthValue = width ? parseFloat(width) : 0;
        const heightValue = height ? parseFloat(height) : 0;
        if (widthValue !== dimensions.widthPercent || heightValue !== dimensions.minHeight) {
          setDimensions({ widthPercent: widthValue, minHeight: heightValue });
        }
      }
    }
  }, [stylesArray, props.keyValueId, dimensions]);

  const outlinebluemain = (e) => {
    const element = e.target.closest('.resizable');
    if (element) {
      const clickedId = element.id;
      setActiveId(prevId => {
        const deleteButton = document.getElementById(clickedId + "delete");
        if (deleteButton) {
          deleteButton.classList.toggle("column-one-delete-web-template");
        }
        const resizers = document.getElementsByClassName(clickedId); // Get all elements with the keyValueId class
        if (resizers.length > 0) {
          for (let i = 0; i < resizers.length; i++) {
            resizers[i].classList.toggle("displayblock"); // Toggle the class for each element
          }
        }
        const EditButton = document.getElementById(clickedId + "EditImage");
        if (EditButton.style.opacity) {
          if (EditButton.style.opacity === "0") {
            EditButton.style.opacity = "1";
          } else {
            EditButton.style.opacity = "0";
          }
        }
        else {
          EditButton.style.opacity = "1";
          console.log("value is blank");
        }
        return prevId === clickedId ? '' : clickedId;
      });
    }
  };

  const handleDropItem = (e) => {
    setImageModal(true);
    e.stopPropagation();
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };

  const openimagemodal = (e) => {
    e.stopPropagation();
    setImageModal(!imageModal);
  };

  const handleValueFromModal = (value) => {
    setValueFromModal(value);
  };

  return (
    <div className="image-box resizable-web-template"
      style={{
        backgroundSize: "100% 100%",
        margin: "8px auto",
        // backgroundRepeat: "no-repeat",
        width: `${dimensions.widthPercent}%`,
        minHeight: dimensions.minHeight,
      }}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true"
      id={getId}
      onClick={outlinebluemain}
    >
      <div className="resizers-web-template" style={{ minHeight: dimensions.minHeight, }} >
        <div className={`resizer-web-template ${props.keyValueId} top-web-template `} onMouseDown={(e) => handleMouseDown(e, 'top')}></div>
        <div className={`resizer-web-template ${props.keyValueId} right-web-template `} onMouseDown={(e) => handleMouseDown(e, 'right')}></div>
        <div className={`resizer-web-template ${props.keyValueId} bottom-web-template `} onMouseDown={(e) => handleMouseDown(e, 'bottom')}></div>
        <div className={`resizer-web-template ${props.keyValueId} left-web-template `} onMouseDown={(e) => handleMouseDown(e, 'left')}></div>

        <i className="fa-solid fa-pen-to-square editimagebutton-web-template" id={props.keyValueId + "EditImage"} style={{ margin: "10px 12px", fontSize: "16px" }} onClick={handleDropItem}></i>
        {imageModal && (
          <Imagemodal
            handleclick={openimagemodal}
            passValueToParent={handleValueFromModal}
            getId={getId}
          />
        )}
      </div>
    </div>
  );
};

export default Image;
