import { useEffect, useState } from "react";
import "./OrderTakerStylesheet.css";
import { Link, useNavigate } from 'react-router-dom';
import TableGroupApi from "../../API/APIServices/TableGroup";
import TableApi from "../../API/APIServices/Table";
import TableAllocationApi from "../../API/APIServices/TableAllocation";
import Loader from '../../Inventory/Props/Loader';
import AppHeaderDetail from "../AppHeaderDetail";

const TableOrderAllocation = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    // ************************* Login User Details ***********************
    const [loginUserDetails, setLoginUserDetails] = useState(null);

    useEffect(() => {
        const fetchLoginData = async () => {
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                if (storedArrayString !== null) {
                    const loginUser = JSON.parse(storedArrayString);
                    setLoginUserDetails(loginUser);
                    console.log(loginUser);
                } else {
                    console.log("No stored user data found.");
                }
            } catch (error) {
                console.error("Error parsing stored user data:", error);
            }
        };
        fetchLoginData();
    }, []);

    const [IsOpen, setIsOpen] = useState(false);

    const [selectedId, setSelectedId] = useState();

    const [table, setTable] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tableList = await TableApi.getTable();
                setTable(tableList);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [])

    const [openGroups, setOpenGroups] = useState({});

    const TableDetailOpen = async (clickedId) => {
        setOpenGroups(prevOpenGroups => {
            const updatedGroups = { ...prevOpenGroups };
            if (prevOpenGroups[clickedId]) {
                delete updatedGroups[clickedId];
            } else {
                for (const groupId in prevOpenGroups) {
                    delete updatedGroups[groupId];
                }
                updatedGroups[clickedId] = true;
            }

            return updatedGroups;
        });
        setIsOpen(selectedId === clickedId ? true : false);
        setSelectedId(clickedId);
        const data = await TableApi.getTableByTableGroupId(clickedId);
        setTable(data);
    };

    const getTableAllocationById = async () => {
        setLoading(true);
        try {
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);

            console.log("loginUserDetails", loginUserDetails);
            const data = await TableAllocationApi.getTableAllocationById(loginUser.userId);
            console.log("data", data);
            //setCheckboxData(data);
            //setCheckBoxesData(true, data[i].tableId, data[i].tableName);
            var length;
            if (Array.isArray(data)) {
                length = data.length;
                console.log("Number of items in data:", length);
            } else if (typeof data === "object") {
                length = Object(data).length;
                console.log("Number of keys in data:", length);
            }
            let updatedData = [];
            console.log(data.length);
            for (let i = 0; i < data.length; i++) {
                const filterData = [{
                    fieldName: "id",
                    operatorName: "Equal",
                    compareValue: data[i].tableId
                }]
                const tableGroupData = await TableApi.getTableGroupByTableId(filterData);
                setTableAllocation((prevCheckboxes) => [
                    ...prevCheckboxes,
                    {
                        "tableGroupId": tableGroupData[0].tableGroupId,
                        "userId": loginUser.userId,
                        "tableId": data[i].tableId,
                        "tableName": data[i].tableName,
                        "userName": loginUser.userName
                    }
                ]);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getTableAllocationById();
    }, []);

    const [tableAllocation, setTableAllocation] = useState([]);
    const [showBtn, setShowBtn] = useState(false)

    const handleDivClick = (event, tableId) => {
        setShowBtn(true);
        event.stopPropagation(); // Prevent checkbox event from firing
        const isChecked = !tableAllocation.some(item => item.tableId === tableId); // Toggle checkbox
        const tableItem = table.find(item => item.id === tableId);
        handleCheckboxChange({ target: { checked: isChecked } }, tableId, tableItem.tableName, tableItem.tableGroupId);
    };

    const handleCheckboxChange = async (event, tableId, tableName, tableGroupId) => {
        setShowBtn(true);
        const isChecked = event.target.checked;
        if (isChecked) {
            setTableAllocation((prevCheckboxes) => [
                ...prevCheckboxes,
                {
                    tableGroupId: tableGroupId,
                    userId: loginUserDetails.userId,
                    tableId: tableId,
                    tableName: tableName,
                    userName: loginUserDetails.userName
                }
            ]);
        } else {
            setTableAllocation((prevCheckboxes) =>
                prevCheckboxes.filter((item) => item.tableId !== tableId)
            );
        }
    };

    const handleSelectAll = (event, groupId) => {
        setShowBtn(true);
        const isChecked = event.target.checked;

        const tablesInGroup = table.filter(data => data.tableGroupId === groupId);

        if (isChecked) {
            const existingTableAllocations = tableAllocation.filter(item => item.tableGroupId === groupId);

            const existingTableIds = new Set(existingTableAllocations.map(item => item.tableId));

            const newTableAllocations = tablesInGroup
                .filter(data => !existingTableIds.has(data.id))
                .map(data => ({
                    tableGroupId: groupId,
                    userId: loginUserDetails.userId,
                    tableId: data.id,
                    tableName: data.tableName,
                    userName: loginUserDetails.userName
                }));

            setTableAllocation(prevAllocation => [
                ...prevAllocation,
                ...newTableAllocations
            ]);
        } else {
            setTableAllocation(prevAllocation =>
                prevAllocation.filter(item => item.tableGroupId !== groupId)
            );
        }
    };

    const [tableGroup, setTableGroup] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await TableGroupApi.getTableGroup();
                console.log("data");
                console.log(data);
                setTableGroup(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [])

    const SaveTableBtn = async () => {
        // ***************** Filter table for the same Table Id *********************
        const uniqueTableAllocation = tableAllocation.reduce((acc, current) => {
            const x = acc.find(item => item.tableId === current.tableId);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        console.log(uniqueTableAllocation);
        await TableAllocationApi.updateTableAllocation(uniqueTableAllocation);
        alert("Table Updated");

        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        if (loginUser.loginType === 563) {
            navigate("/OrderTakerView");
        }
        else if (loginUser.loginType === 565) {
            navigate("/OrderList")
        }
    }

    const backBtnClick = () => {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        if (loginUser.loginType === 563) {
            navigate("/OrderTakerView");
        }
        else if (loginUser.loginType === 565) {
            navigate("/OrderList")
        }
    }

    return (
        <div>
            <AppHeaderDetail title={`Table Allocation`} setLoading={setLoading} fetchData={getTableAllocationById} />
            <div style={{ padding: "5px", marginTop: "45px", marginBottom: "50px" }}>
                {tableGroup !== null && tableGroup.length > 0 && tableGroup.map((data, index) => (
                    <div key={index} style={{ border: "1px solid lightgrey", borderRadius: "5px", padding: "5px", marginTop: "5px", backgroundColor: "white" }}>
                        <div onClick={() => TableDetailOpen(data.id)}>
                            <div className="space-between">
                                <div>
                                    <div className="flex-start" style={{ alignItems: "center" }}>
                                        <i className="fa-solid fa-utensils" style={{ fontSize: "14px" }}></i>
                                        <label className="ques"> &nbsp; {data.name}</label><br />
                                    </div>
                                    <label className="ans" style={{ fontSize: "12px", padding: "0px" }}>{tableAllocation.filter(item => item.tableGroupId === data.id).length} Selected</label>
                                </div>
                                <div className="flex-center" style={{ padding: "5px" }}>
                                    <i className="fa-solid fa-square-plus" style={{ color: "darkgreen" }}></i>
                                </div>
                            </div>
                        </div>
                        {openGroups[data.id] && (
                            <div style={{ borderTop: "1px solid lightgrey" }}>
                                <div className="flex-start" style={{ backgroundColor: "white", padding: "8px" }}>
                                    <input
                                        type="checkbox"
                                        checked={
                                            table.filter(tableItem => tableItem.tableGroupId === data.id).length ===
                                            tableAllocation.filter(item => item.tableGroupId === data.id).length
                                        }
                                        onChange={(e) => handleSelectAll(e, data.id)}
                                        style={{ boxShadow: "none" }}
                                    />

                                    {/* <input
                                        type="checkbox"
                                        checked={tableAllocation.filter(item => item.tableGroupId === data.id).length === table.filter(tableItem => tableItem.tableGroupId === data.id).length}
                                        onChange={(e) => handleSelectAll(e, data.id)}
                                        style={{ boxShadow: "none" }}
                                    /> */}
                                    <label className="ques"> &nbsp; Select All</label>
                                </div>

                                {table !== null && table.length > 0 && table.filter(tableItem => tableItem.tableGroupId === data.id).map((data1, index) => (
                                    <div key={index} className="flex-start" style={{ backgroundColor: "white", padding: "8px" }}> {/*onClick={(e) => handleDivClick(e, data1.id)} */}
                                        <input
                                            type="checkbox"
                                            id={`checkbox-${index}`}
                                            value={data1.id}
                                            checked={tableAllocation.some(item => item.tableId === data1.id)}
                                            onChange={(e) => handleCheckboxChange(e, data1.id, data1.tableName, data.id)}
                                            style={{ boxShadow: "none" }}
                                        />
                                        <label className="ques"> &nbsp; {data1.tableName}</label>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div style={{ display: showBtn ? "block" : "none" }}>
                <div className="floating-button-container" style={{ width: "80%", margin: "0% 9%", bottom: "0px", right: "0px" }}>
                    <button className="orangeBtn" style={{ backgroundColor: "#e87735", border: "1px solid #e87735" }} onClick={SaveTableBtn}>Save Tables</button>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
};

export default TableOrderAllocation;