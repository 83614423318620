import React, { useState } from "react";
import GlobalSelector from "./GlobalSelector";

const TypographySection = (props) => {
  const [divList, setDivList] = useState([]);
  // const [value, setValue] = useState(false);
  const { value: fontSizeValue, unit: fontSizeUnit } = props.extractValueAndUnit(props.currentStyle?.style["font-size"] || 'auto');
  const { value: letterSpacingValue, unit: letterSpacingUnit } = props.extractValueAndUnit(props.currentStyle?.style["letter-spacing"] || 'auto');
  const { value: lineheightValue, unit: lineheightUnit } = props.extractValueAndUnit(props.currentStyle?.style["line-height"] || 'auto');

  const appendDiv = () => {
    setDivList((prevDivList) => [...prevDivList, { id: prevDivList.length }]);
  };

  const deleteDiv = (id) => {
    setDivList((prevDivList) => prevDivList.filter((item) => item.id !== id));
  };

  const input = document.getElementById("colorbox");
  const colorCode = document.getElementById("input-colorbox");

  function setColor() {
    console.log(input.value);
    colorCode.outerHTML = input.value;
  }

  console.log(props.id);

  const handleChange = (event) => {
    debugger
    const { value, name } = event.target;
    // const { value, name } = event.currentTarget;
    const element = document.getElementById(props.id);
    if (element) {
      element.style.position = value;
      props.updateStyle(props.id, name, value);
    }
  }

  const handleButtons = (event) => {
    debugger

    const { value, name } = event.currentTarget;
    const element = document.getElementById(props.id);
    if (element) {
      element.style.position = value;
      props.updateStyle(props.id, name, value);
    }
  }


  return (
    <div className="typography-container">
      <div className="typography-selection-globalseltcontainer">
        <div className="typography-selectioncontainer">
          <div className="typography-sectionoption">
            <p className="general-paragraph">Font Family</p>
            <select
              name="fontFamily"
              className="selectorstyle"
              onChange={handleChange}
            >
              <option value="" className="displaynone"></option>
              <option value="Arial">Arial</option>
              <option value="Arial Black">Arial Black</option>
              <option value="Brush Script MT">Brush Script MT</option>
              <option value="Comic Sans MS">Comic Sans MS</option>
              <option value="Courier New">Courier New</option>
              <option value="Georgia">Georgia</option>
              <option value="Helvetica">Helvetica</option>
              <option value="IBM Plex Serif">IBM Plex Serif</option>
              <option value="Impact">Impact</option>
              <option value="Lucida Sans Unicode">Lucida Sans Unicode</option>
              <option value="Poppins">Poppins</option>
              <option value="Tahoma">Tahoma</option>
              <option value="Times New Roman">Times New Roman </option>
              <option value="Trebuchet MS">Trebuchet MS</option>
              <option value="Verdana">Verdana</option>
              {/* Additional fonts */}
              {/* <option value="Montserrat">Montserrat</option>
              <option value="Open Sans">Open Sans</option>
              <option value="Roboto">Roboto</option>
              <option value="Lato">Lato</option>
              <option value="Nunito">Nunito</option>
              <option value="Source Sans Pro">Source Sans Pro</option>
              <option value="Raleway">Raleway</option>
              <option value="Merriweather">Merriweather</option>
              <option value="Oswald">Oswald</option>
              <option value="Roboto Condensed">Roboto Condensed</option>
              <option value="PT Sans">PT Sans</option>
              <option value="Droid Sans">Droid Sans</option>
              <option value="Quicksand">Quicksand</option>
              <option value="Playfair Display">Playfair Display</option>
              <option value="Fira Sans">Fira Sans</option>
              <option value="Bebas Neue">Bebas Neue</option> */}
            </select>
          </div>
          <div className="typography-sectionoption">
            <p className="general-paragraph">Font Weight</p>
            <select
              name="fontWeight"
              className="selectorstyle"
              onChange={handleChange}
            >
              <option value="Thin">Thin</option>
              <option value="Extra-Light">Extra-Light</option>
              <option value="Light">Light</option>
              <option value="Normal">Normal</option>
              <option value="Medium">Medium</option>
              <option value="Semi-Bold">Semi-Bold</option>
              <option value="Bold">Bold</option>
              <option value="Extra-Bold">Extra-Bold</option>
              <option value="Ultra-Bold">Ultra-Bold</option>
            </select>
          </div>
        </div>
        <div className="typography-globalsec">
          <GlobalSelector
            placeholder={"medium"}
            // style={styleValue.style1}
            id={props.id}
            name={"font-size"}
            onChange={props.handleStyleChange}
            value={fontSizeValue}
            unit={fontSizeUnit}
          />
          <GlobalSelector
            placeholder={"normal"}
            // style={styleValue.style2}
            id={props.id}
            name={"letter-spacing"}
            onChange={props.handleStyleChange}
            value={letterSpacingValue}
            unit={letterSpacingUnit}
          />
        </div>
      </div>
      <div className="typography-colorsection">
        <p className="general-paragraph"> Color</p>
        <div className="typography-colorchange">
          <code
            id="input-colorbox"
            style={{
              backgroundColor: "black",
              width: "80%",
              fontSize: "medium",
              color: "white",
              display: "flex",
              justifyContent: "center",
              border: "1px solid white",
              marginRight: "5px",
              borderRadius: "8px"
            }}
          ></code>
          <input
            type="color"
            // onInput={setColor}
            name="color"
            id="colorbox"
            onChange={handleButtons}
          />
        </div>
      </div>
      <div className="lineheight">
        <GlobalSelector
          placeholder={"Normal"}
          id={props.id}
          name={"line-height"}
          onChange={props.handleStyleChange}
          value={lineheightValue}
          unit={lineheightUnit}
        />
      </div>
      <div className="para-alignbutton">
        <p className="general-paragraph"> Text align</p>
        <div className="alignbox">
          <button
            // onClick={() => textdalignvalue("start")}
            className="align-button"
            value="start"
            name="text-align"
            onClick={handleButtons}
          >
            start
            {/* <i className="icon-edit fa-solid fa-align-left"></i> */}
          </button>
          <button
            // onClick={() => textdalignvalue("center")}
            className="align-button"
            value="center"
            name="text-align"
            onClick={handleButtons}
          >
            center
            {/* <i className="icon-edit fa-solid fa-align-center"></i> */}
          </button>
          <button
            // onClick={() => textdalignvalue("end")}
            className="align-button"
            value="end"
            name="text-align"
            onClick={handleButtons}
          >
            end
            {/* <i className="icon-edit fa-solid fa-align-right"></i> */}
          </button>
          <button
            // onClick={() => textdalignvalue("justify")}
            className="align-button"
            value="justify"
            name="text-align"
            onClick={handleButtons}
          >
            justify
            {/* <i className="icon-edit fa-solid fa-align-justify"></i> */}
          </button>
        </div>
        <p className="general-paragraph"> Text decoration</p>
        <div className="alignbox">
          <button
            // onClick={() => textdecoretionvalue("none")}
            className="align-button"
            value="none"
            name="text-decoration"
            onClick={handleButtons}
          >
            none
            {/* <i className="icon-edit fa-solid fa-xmark"></i> */}
          </button>
          <button
            // onClick={() => textdecoretionvalue("underline")}
            className="align-button"
            value="underline"
            name="text-decoration"
            onClick={handleButtons}
          >
            underline
            {/* <i className="icon-edit fa-solid fa-underline"></i> */}
          </button>
          <button
            // onClick={() => textdecoretionvalue("line-through")}
            className="align-button"
            value="line-through"
            name="text-decoration"
            onClick={handleButtons}
          >
            line-through
            {/* <i className="icon-edit fa-solid fa-strikethrough"></i> */}
          </button>
        </div>
      </div>
      <div className="typography-textshadow">
        <div className="text-plus">
          <p className="general-paragraph vertical-justify">Text shadow</p>
          <button className="typo-plus" onClick={appendDiv}>
            <i className="icon-edit fa-solid fa-plus small"></i>
          </button>
        </div>
        <div className="shadow-contain">
          {divList.map((div) => (
            <div className="firstcontent-container" key={div.id}>
              <div className="firstcontent">
                <div className="firstcontent-paragicon">
                  <i className="icon-edit fa-solid fa-maximize rotate typoicon"></i>
                  <p className="general-paragraph vertical-justify">000</p>
                </div>
                <div className="firstcontent-deleteicon">
                  <i className="icon-edit fa-solid fa-t typoicon"></i>
                  <button onClick={() => deleteDiv(div.id)}>
                    <i className="icon-edit fa-solid fa-xmark typoicon"></i>
                  </button>
                </div>
              </div>
              {
                <div className="secondcontent">
                  <div className="secondcon-XY">
                    <GlobalSelector placeholder={"0"} name={"X"} />
                    <GlobalSelector placeholder={"0"} name={"Y"} />
                  </div>
                  <GlobalSelector placeholder={"0"} name={"Blur"} />
                  <div className="typography-colorsection">
                    <p className="general-paragraph"> Color</p>
                    <div className="typography-colorchange">
                      <code
                        id="input-colorbox"
                        style={{
                          backgroundColor: "black",
                          width: "80%",
                          fontSize: "medium",
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          border: "1px solid white",
                          marginRight: "5px",
                          borderRadius: "8px"
                        }}
                      ></code>
                      <input type="color" onInput={setColor} id="colorbox" />
                    </div>
                  </div>
                </div>
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TypographySection;
