import DateTime from "./../../Inventory/Components/DateTime";
import React, { useState, useRef, useEffect, useContext } from "react";
import PageTabs from "./../../Inventory/Components/PageTabs";
import MainButton from "../../Inventory/Props/MainButton";
import TimeDateSlot from "../../Inventory/Props/TimeDateSlot";
import MyContext from "../../Inventory/MyContext";
import Loader from "../../Inventory/Props/Loader";
import DeliveryAndTimeSlot from "../../API/APIServices/DeliveryAndTimeSlot";
import MiscellaneousMaster from "../../API/APIServices/MiscellaneousMaster";
import AlertBox from "../../Inventory/Props/AlertBox";
import Order from '../../API/APIServices/Order';
import DeliveryPointName from "../../API/APIServices/DeliveryPointName";

function DeliveryTimeSlotSetting(props) {
  const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

  const [loading, setLoading] = useState(false);
  // const [deliveryTimeSlotsData, setDeliveryTimeSlotsData] = useState([
  //   {
  //     id: "",
  //     deliverySlotId: "",
  //     deliveryTimeAttribute: true,
  //     deliveryTimeSlotFrom: "10:10:10",
  //     deliveryTimeSlotTo: "10:10:10",
  //   },
  // ]);

  const [checkedIds, setCheckedIds] = useState([]);
  // const handleCheckboxChange = (event) => {
  //   const { name, checked } = event.target;
  //   setCheckedIds({ ...checkedIds, [name]: checked });
  // };

  const [inputValues, setInputValues] = useState({
    deliveryPointName: "",
    orderType: "",
    mobile: "",
    website: "",
    serviceTax: "",
    delvryDistRangeFromKM: "",
    delvryDistRangeToKM: "",
    minDelvryAmtInCart: "",
    deliveryCharges: "",
    estimateDelvryTime: "",
    noOfDelveriesTakenInPerTimeSlot: "",
    deliveryStatus: false,
    deliveryTimeSlots: [
      // {
      //   id: "",
      //   deliverySlotId: "",
      //   deliveryTimeAttribute: true,
      //   deliveryTimeSlotFrom: "10:10:10",
      //   deliveryTimeSlotTo: "10:10:10",
      // },
    ],
    isActive: true,
  });

  const [errorMessages, setErrorMessages] = useState({});

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (props.editData) {
  //       try {
  //         const data = await props.editData; // Assuming props.editData is a Promise
  //         console.log(data);
  //         setInputValues({
  //           id: data.id,
  //           deliveryPointName: data.deliveryPointName,
  //           orderType: data.orderType,
  //           mobile: data.mobile,
  //           website: data.website,
  //           serviceTax: data.serviceTax,
  //           delvryDistRangeFromKM: data.delvryDistRangeFromKM,
  //           delvryDistRangeToKM: data.delvryDistRangeToKM,
  //           minDelvryAmtInCart: data.minDelvryAmtInCart,
  //           deliveryCharges: data.deliveryCharges,
  //           estimateDelvryTime: data.estimateDelvryTime,
  //           noOfDelveriesTakenInPerTimeSlot: data.noOfDelveriesTakenInPerTimeSlot,
  //           deliveryStatus: data.deliveryStatus,
  //           // deliveryTimeSlots: [ checkedIds
  //           //   // {
  //           //   //   id: 0,
  //           //   //   deliverySlotId: 0,
  //           //   //   deliveryTimeAttribute: true,
  //           //   //   deliveryTimeSlotFrom: "string",
  //           //   //   deliveryTimeSlotTo: "string",
  //           //   // },
  //           // ],
  //           isActive: data.isActive
  //         });
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   };
  //   fetchData();
  // })

  useEffect(() => {
    // console.log(props.data);
    const GetDeliveryAndTimeSlotById = async () => {
      //if (props.data) {
      try {
        const data = await DeliveryAndTimeSlot.GetDeliveryAndTimeSlotById(props.editData); //props.data; // Assuming props.editData is a Promise
        console.log("getdelivery by id", data);
        setInputValues({
          id: data.id,
          deliveryPointName: data.deliveryPointName === null ? "" : data.deliveryPointName,
          orderType: data.orderType === null ? "" : data.orderType,
          mobile: data.mobile === null ? "" : data.mobile,
          website: data.website === null ? "" : data.website,
          serviceTax: data.serviceTax === null ? "" : data.serviceTax,
          delvryDistRangeFromKM: data.delvryDistRangeFromKM === null ? "" : data.delvryDistRangeFromKM,
          delvryDistRangeToKM: data.delvryDistRangeToKM === null ? "" : data.delvryDistRangeToKM,
          minDelvryAmtInCart: data.minDelvryAmtInCart === null ? "" : data.minDelvryAmtInCart,
          deliveryCharges: data.deliveryCharges === null ? "" : data.deliveryCharges,
          estimateDelvryTime: data.estimateDelvryTime === null ? "" : data.estimateDelvryTime,
          noOfDelveriesTakenInPerTimeSlot: data.noOfDelveriesTakenInPerTimeSlot === null ? "" : data.noOfDelveriesTakenInPerTimeSlot,
          deliveryStatus: data.deliveryStatus === null ? "" : data.deliveryStatus,
          // deliveryTimeSlots: [ checkedIds
          //   // {
          //   //   id: 0,
          //   //   deliverySlotId: 0,
          //   //   deliveryTimeAttribute: true,
          //   //   deliveryTimeSlotFrom: "string",
          //   //   deliveryTimeSlotTo: "string",
          //   // },
          // ],
          isActive: data.isActive
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      //}
    };
    GetDeliveryAndTimeSlotById();
  }, [props.editData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const validateInput = (fieldName) => {
    // console.log(fieldName);
    const fieldValue = inputValues[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }

    return true;
  };
  const fieldNames = [];

  const [timeSlotData, setTimeSlotData] = useState({});

  useEffect(() => {
    const fectchTimeSlot = async () => {
      const TimeSlotData = await MiscellaneousMaster.GetMiscellaneousMaster(170);
      setTimeSlotData(TimeSlotData);
    };
    fectchTimeSlot();
  }, []);

  const [ServiceType, setServiceType] = useState([]);
  const [selectedService, setSelectedService] = useState(751);
  //const [inputServiceTypeValues, setInputServiceTypeValues] = useState({
  //  ServiceType: '0' // Default value
  //});

  useEffect(() => {
    setLoading(true);
    const getServiceType = async () => {
      const data = await Order.getServiceType();
      console.log("getServiceType", data);
      if (data) {
        setServiceType(data);
        setSelectedService(`${data[0].id}`);
        setLoading(false);
        setInputValues((prevValues) => ({
          ...prevValues,
          "orderType": `${data[0].id}`,
        }));
      } else {
        setLoading(false);
      }
    };
    getServiceType();
  }, []);

  const SaveBtn = async () => {
    let allValid = true;
    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);
      if (!isValid) {
        allValid = false;
      }
    });
    if (allValid) {
      if (props.modelOpen) {
        setLoading(true);
        const isSuccess = await DeliveryAndTimeSlot.updateDeliveryAndTimeSlot(inputValues, checkedIds);
        // console.log(isSuccess);
        if (isSuccess) {
          setLoading(false);
          OpenAlertBoxEdit();
          props.fetchData();
          props.modelOpen(false);
        } else {
          alert("error while updating");
        }
      } else {
        setLoading(true);
        const isSuccess = await DeliveryAndTimeSlot.InsertDeliveryAndTimeSlot(inputValues, checkedIds);

        if (isSuccess) {
          setLoading(false);
          OpenAlertBox();
          setInputValues({
            deliveryPointName: "",
            orderType: "",
            mobile: "",
            website: "",
            serviceTax: "",
            delvryDistRangeFromKM: "",
            delvryDistRangeToKM: "",
            minDelvryAmtInCart: "",
            deliveryCharges: "",
            estimateDelvryTime: "",
            noOfDelveriesTakenInPerTimeSlot: "",
            deliveryStatus: false,
            deliveryTimeSlots: [
              // {
              //   id: "",
              //   deliverySlotId: "",
              //   deliveryTimeAttribute: true,
              //   deliveryTimeSlotFrom: "10:10:10",
              //   deliveryTimeSlotTo: "10:10:10",
              // },
            ],
            isActive: true,
          });
        } else {
          alert("Error inserting data");
        }
      }
    }
  };

  /***********title***************/
  useState(() => {
    document.title = "Delivery Time Slot Setting";
  }, []);

  ////************Image add******************///

  //************modal box************/ /
  const [, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };

  //***************{Enter Btn Click}***************//
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);
  const input8Ref = useRef(null);
  const input9Ref = useRef(null);
  const input10Ref = useRef(null);
  const input11Ref = useRef(null);
  const input12Ref = useRef(null);
  const input13Ref = useRef(null);
  const input14Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };
  useEffect(() => {
    input1Ref.current.focus();
  }, []);

  useEffect(() => {
    console.log(checkedIds);
  }, [checkedIds]);

  // ************** Fetch Delivery Point Name ************************
  const [deliveryPointName, setDeliveryPointName] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await DeliveryPointName.getDeliveryPointNames();
        console.log(data);
        setDeliveryPointName(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <PageTabs
        modelOpen={props.modelOpen}
        setSliderCallBack={props.setSliderCallBack}
        locationTab={"/DeliveryTimeSlotSettingList"}
      />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Delivery Time Slot Setting</h2>
        </div>
        {/* *******************Top Buttons****************** */}
        <MainButton
          modelOpen={props.modelOpen}
          SaveButton={SaveBtn}
          setSliderCallBack={props.setSliderCallBack}
        />
      </div>
      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-right control_flex_m1">
                    <div className="company-info-top-right control_flex">
                      <div className="control_radius">
                        <div className="company_info-text-im">
                          Delivery Time Slot Setting
                        </div>
                        <div className="company_control_DTSS">
                          <div className="stock-im-b1">
                            <div className="input-box-Imo1 input-height2">
                              <div className="input-box-mok1">
                                <select
                                  className="modify_im_input mode_category1"
                                  ref={input1Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input2Ref)
                                  }
                                  onChange={handleInputChange}
                                  value={inputValues.deliveryPointName}
                                  name="deliveryPointName"
                                  required
                                >
                                  <option value="0">--Select--</option>
                                  {deliveryPointName && deliveryPointName.map((data, index) => (
                                    <option value={data.id}>{data.name}</option>
                                  ))}
                                </select>
                                <label class="modify_lbl_text3">
                                  Delivery Point Name
                                </label>
                              </div>
                            </div>
                            <div className="input-box-Imo1 input-height2">
                              <div className="input-box-mok1">
                                <input
                                  className="modify_im_input mode_category1"
                                  ref={input2Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input3Ref)
                                  }
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.website}
                                  name="website"
                                />
                                <label class="modify_p_text2">Website</label>
                              </div>
                            </div>
                            <div className="input-box-Imo1 input-height2">
                              <div className="input-box-mok1">
                                {/* <textarea className="modify_im_textarea mode_category2" type="text" placeholder="10:00 To 10:30, 11:00 To 11:30" required />
                                                                <label class="modify_p_text-fix">Delivery Time Slots (Seprate with Comma)</label> */}
                                <TimeDateSlot checkedIds={checkedIds} setCheckedIds={setCheckedIds} data={timeSlotData} editData={props.editData} />
                              </div>
                            </div>
                          </div>
                          <div className="stock-im-b1">
                            <div className="input-box-Imo1 input-height2">
                              <div className="input-box-mok1">
                                <select
                                  className="modify_im_input mode_category1"
                                  ref={input3Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input4Ref)
                                  }
                                  type="text"
                                  onChange={handleInputChange}
                                  value={inputValues.orderType}
                                  name="orderType"
                                  required
                                >
                                  {ServiceType.map(Type => (
                                    <option key={Type.id} value={Type.id}>
                                      {Type.name}
                                    </option>
                                  ))}
                                </select>


                                <label class="modify_lbl_text3 LeftSide03">
                                  Service Type
                                </label>
                              </div>
                            </div>
                            <div className="input-box-Imo1 input-height2">
                              <div className="input-box-mok1">
                                <input
                                  className="modify_im_input mode_category1"
                                  ref={input4Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input6Ref)
                                  }
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.serviceTax}
                                  name="serviceTax"
                                />
                                <label class="modify_p_text2">
                                  Delivery Charge
                                </label>
                              </div>
                            </div>
                            <div className="input-box-Imo1 input-height2">
                              <div className="input-box-mok1">
                                <input
                                  className="modify_im_input mode_category1"
                                  ref={input6Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input7Ref)
                                  }
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.minDelvryAmtInCart}
                                  name="minDelvryAmtInCart"
                                />
                                <label class="modify_p_text2">
                                  Minimum Delivery Amount in Cart
                                </label>
                              </div>
                            </div>
                            <div className="input-box-Imo1 input-height2">
                              <div className="input-box-mok1">
                                <input
                                  className="modify_im_input mode_category1"
                                  ref={input7Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input8Ref)
                                  }
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.estimateDelvryTime}
                                  name="estimateDelvryTime"
                                />
                                <label class="modify_p_text2">
                                  Estimated Delivery Time (Minutes)
                                </label>
                              </div>
                            </div>
                            <div className="input-box-Imo1 input-height2">
                              <div className="input-box-mok1">
                                <select
                                  className="modify_im_input mode_category1"
                                  ref={input8Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input9Ref)
                                  }
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.deliveryStatus}
                                  name="deliveryStatus"
                                >
                                  <option value="false">Inactive</option>
                                  <option value="true">Active</option>
                                </select>
                                <label class="modify_lbl_text3">
                                  Delivery Status
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="stock-im-b1">
                            <div className="input-box-Imo1 input-height2">
                              <div className="input-box-mok1">
                                <input
                                  className="modify_im_input mode_category1"
                                  ref={input9Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input10Ref)
                                  }
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.mobile}
                                  name="mobile"
                                />
                                <label class="modify_p_text2">Mobile</label>{" "}
                              </div>
                            </div>
                            <div
                              style={{ display: "flex" }}
                              className="input-box-Imo1 input-height2"
                            >
                              <div className="input-box-mok1">
                                <input
                                  className="modify_im_input mode_category1"
                                  ref={input10Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input11Ref)
                                  }
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.delvryDistRangeFromKM}
                                  name="delvryDistRangeFromKM"
                                />
                                <label class="modify_lbl_text3 LeftSide03">
                                  Delivery Dist Range From
                                </label>
                              </div>
                              <div className="input-box-mok1">
                                <input
                                  className="modify_im_input mode_category1"
                                  ref={input11Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input12Ref)
                                  }
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.delvryDistRangeToKM}
                                  name="delvryDistRangeToKM"
                                />
                                <label class="modify_lbl_text4">To (Km)</label>
                              </div>
                            </div>
                            <div className="input-box-Imo1 input-height2">
                              <div className="input-box-mok1">
                                <input
                                  className="modify_im_input mode_category1"
                                  ref={input12Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input13Ref)
                                  }
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.deliveryCharges}
                                  name="deliveryCharges"
                                />
                                <label class="modify_p_text2">
                                  Delivery Charges
                                </label>
                              </div>
                            </div>

                            <div className="input-box-Imo1 input-height2">
                              <div className="input-box-mok1">
                                <input
                                  className="modify_im_input mode_category1"
                                  type="text"
                                  ref={input13Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input1Ref)
                                  }
                                  required
                                  onChange={handleInputChange}
                                  value={
                                    inputValues.noOfDelveriesTakenInPerTimeSlot
                                  }
                                  name="noOfDelveriesTakenInPerTimeSlot"
                                />
                                <label class="modify_p_text2">
                                  Numbers Of Deliveries Taken in Per Time Slot
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="control_radius">
                      <div className="company_info-text-im depart_top">
                        Delivery Time Slots List
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3" onClick={showModal}>
                <i class="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Delivery time is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}

      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Delivery time is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default DeliveryTimeSlotSetting;
