import { useContext, useEffect, useState } from "react";
import Order from "../../API/APIServices/Order";
import UserCredential from "../../API/APIServices/UserCredential";
import Loader from "../../Inventory/Props/Loader";
import moment from 'moment';
import MyContext from "../../Inventory/MyContext";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const TripWiseTrackingReport = () => {

    useState(() => {
        document.title = 'Trip Wise Delivery Boy Tracking Report';
    }, []);

    const { isShowTotalAmountInReport } = useContext(MyContext);

    //alert(isShowTotalAmountInReport);

    const [loading, setLoading] = useState(false);

    const [trackingReport, setTrackingReport] = useState();

    const getCurrentDate = async () => {
        const date = await CurrentDateAndTime.getCurrentDate();
        return date;
    };

    const [isDeliveryBoy, setIsDeliveryBoy] = useState(true);

    useEffect(() => {
        const fetchDelData = async () => {
            setLoading(true);
            try {
                let filteredData = [{
                    fieldName: "string",
                    operatorName: "string",
                    compareValue: "string"
                }];
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                let loginUser;
                console.log(storedArrayString)
                if (storedArrayString) {
                    loginUser = JSON.parse(storedArrayString);
                    console.log(loginUser)
                    console.log(loginUser.userId)
                    //alert(loginUser.loginType);
                    const date = await getCurrentDate();
                    if (loginUser.loginType == 562) {
                        setDeliveryBoyId(loginUser.userId)
                        setIsDeliveryBoy(false);
                        filteredData = [
                            {
                                fieldName: "delBoyId",
                                operatorName: "Equal",
                                compareValue: loginUser.userId
                            },
                            {
                                fieldName: "date",
                                operatorName: "Equal",
                                compareValue: date,
                            }
                        ]
                    }
                    else {
                        filteredData = [
                            {
                                fieldName: "string",
                                operatorName: "string",
                                compareValue: "string"
                            },
                            {
                                fieldName: "date",
                                operatorName: "Equal",
                                compareValue: date,
                            }
                        ]
                    }
                }
                const data = await Order.getDeliveryBoyTrip(filteredData)
                console.log(data);
                setTrackingReport(data);
                fetchData(data);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        }
        fetchDelData();;
    }, []);

    function convertTo12HourFormat(twentyFourHourTime) {
        if (!twentyFourHourTime) {
            return "";
        }
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        if (isNaN(hours) || isNaN(minutes)) {
            return "";
        }

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        return `${twelveHourHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const imageUrl = `${WebApiUrl}/api/Order/TripImage?imageId=`;
    // const imageUrl = ``;

    const timeFormat = (twentyFourHourTime) => {
        const [startTime, endTime] = twentyFourHourTime.split('-');
        const convertedStartTime = convertTo12HourFormat(startTime.trim());
        const convertedEndTime = convertTo12HourFormat(endTime.trim());
        const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
        return convertedTimeRange;
    }
    const [deliveryBoy, setDeliveryBoy] = useState({});

    useEffect(() => {
        const fetchDeliveryBoy = async () => {
            setLoading(true)
            const filterData = [
                {
                    fieldName: "userRole",
                    operatorName: "equal",
                    compareValue: 562,
                },
            ];

            const deliveryBoyData = await UserCredential.getFilteredUserCredential(
                filterData
            );
            if (deliveryBoyData) {
                setDeliveryBoy(deliveryBoyData);
                console.log(deliveryBoyData);
            } else {
                alert("No delivery boy found");
            }
            setLoading(false)
        };

        fetchDeliveryBoy();
    }, []);

    const [deliveryBoyId, setDeliveryBoyId] = useState("default");
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    useEffect(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        setFromDate(`${year}-${month}-${day}`);
        setToDate(`${year}-${month}-${day}`);
        // return `${year}-${month}-${day}`;
    }, [])

    const onDeliveryBoyChange = async (e) => {
        setDeliveryBoyId(e.target.value)
        if (e.target.value === "default") {
            const filterData = [
                {
                    fieldName: "string",
                    operatorName: "string",
                    compareValue: "string",
                },
            ];
            try {
                const data = await Order.getDeliveryBoyTrip(filterData);
                setTrackingReport(data);
                fetchData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        } else {
            const filterData = [
                {
                    fieldName: "delBoyId",
                    operatorName: "Equal",
                    compareValue: e.target.value,
                },
                {
                    fieldName: "date",
                    operatorName: "GreaterOrEqualThan",
                    compareValue: fromDate,
                },
                {
                    fieldName: "date",
                    operatorName: "LessOrEqualThan",
                    compareValue: toDate,
                },
            ];
            try {
                const data = await Order.getDeliveryBoyTrip(filterData);
                setTrackingReport(data);
                fetchData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    const onFromDateChange = async (e) => {
        setFromDate(e.target.value);
        const filterData = [
            {
                fieldName: "date",
                operatorName: "GreaterOrEqualThan",
                compareValue: e.target.value,
            },
            {
                fieldName: "date",
                operatorName: "LessOrEqualThan",
                compareValue: toDate,
            },
        ];
        if (deliveryBoyId != "default") {
            filterData.push(
                {
                    fieldName: "delBoyId",
                    operatorName: "Equal",
                    compareValue: deliveryBoyId,
                },
            )
        }
        try {
            const data = await Order.getDeliveryBoyTrip(filterData);
            setTrackingReport(data);
            fetchData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const onToDateChange = async (e) => {
        setToDate(e.target.value);
        const filterData = [
            {
                fieldName: "date",
                operatorName: "GreaterOrEqualThan",
                compareValue: fromDate,
            },
            {
                fieldName: "date",
                operatorName: "LessOrEqualThan",
                compareValue: e.target.value,
            },
        ];
        if (deliveryBoyId != "default") {
            filterData.push(
                {
                    fieldName: "delBoyId",
                    operatorName: "Equal",
                    compareValue: deliveryBoyId,
                },
            )
        }
        try {
            const data = await Order.getDeliveryBoyTrip(filterData);
            setTrackingReport(data);
            fetchData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const [tripWiseOrderModal, setTripWiseOrderModal] = useState(false);

    const [tripOrderData, setTripOrderData] = useState([]);

    const handleTripDetails = async (tripNo) => {
        const data = await trackingReport.filter(item => item.tripNo == tripNo);
        const orderData = data.sort((a, b) => {
            if (a.deliveryPreferenceNo < b.deliveryPreferenceNo) return -1;
            if (a.deliveryPreferenceNo > b.deliveryPreferenceNo) return 1;
            return 0;
        });
        setTripOrderData(orderData);
        setTripWiseOrderModal(true);
    }
    const handleCloseModal = async () => {
        setTripWiseOrderModal(false)
    }

    const handleBackBtn = () => {
        window.history.back();
    }

    const [orderTotalAmt, setOrderTotalAmt] = useState(0);
    const [readingKm, setReadingKm] = useState(0);
    const [fuelLtr, setFuelLtr] = useState(0);
    const [orderCount, setOrderCount] = useState(0);
    const [incentiveAmt, setIncentiveAmt] = useState(0);

    const fetchData = (trackingReport) => {
        const uniqueTrips = trackingReport.filter((data, index, self) =>
            index === self.findIndex((t) => (
                t.tripNo === data.tripNo
            ))
        );
        const total = trackingReport.reduce((sum, data) => sum + (data.totalAmt), 0);
        setOrderTotalAmt(total.toFixed(2));

        const totalReadingKm = uniqueTrips.reduce((sum, data) => sum + (data.closingKM - data.openingKM), 0);
        setReadingKm(totalReadingKm.toFixed(2));

        const fuelLtrPrice = uniqueTrips.reduce((sum, data) => sum + ((data.closingKM - data.openingKM) / data.fuelAvgLtrKM), 0);
        setFuelLtr(fuelLtrPrice.toFixed(2));

        const ordCount = uniqueTrips.reduce((sum, data) => sum + (data.noOfOrders), 0);
        setOrderCount(ordCount.toFixed(2));

        const incentAmt = uniqueTrips.reduce((sum, data) => sum + (data.tripIncentiveAmt), 0);
        setIncentiveAmt(incentAmt.toFixed(2));
    }


    return (
        <div>
            <div className="panel-heading">
                <i className="fa-solid fa-arrow-left icon-white display-none-500" onClick={handleBackBtn} style={{ color: "white", cursor: "pointer", paddingLeft: "15px" }} ></i>
                <h2 className="panel-title" style={{ width: "150%" }}>Trip Wise Delivery Tracking Report</h2>
            </div>
            <div
                className="scroll-container"
                style={{ height: "98vh", padding: "10px" }}>
                {/* ************** */}
                <div className="">
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} >
                        <label
                            className="fw-600 display-none-on-500"
                            style={{
                                fontSize: "18px",
                                // width: "150px",
                                textAlign: "center",
                                textDecoration: "underline",
                            }}
                        >
                            TRIP WISE DELIVERY BOY TRACKING REPORT :-
                        </label>
                        <div className="input-containerEffect display-none-on-500" style={{ margin: "1%" }}>
                            <select
                                className="modify_im_input mode_limit mode_category"
                                onChange={onDeliveryBoyChange}
                                style={{ width: "max-content" }}
                                value={deliveryBoyId}
                            >
                                <option value="default">Select Delivery Boy</option>
                                {(() => {
                                    if (deliveryBoy !== null && Array.isArray(deliveryBoy)) {
                                        return deliveryBoy.map((data, index) => (
                                            <option key={index} value={data.id}>
                                                {data.userName}
                                            </option>
                                        ));
                                    }
                                })()}
                            </select>
                        </div>
                        <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" value={fromDate} type="date"
                                onChange={onFromDateChange} name="orderDate" required />
                            <label className="labelEffect1">From Date</label>
                        </div>
                        <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" value={toDate} type="date"
                                onChange={onToDateChange} name="orderDate" required />
                            <label className="labelEffect1">To Date</label>
                        </div>
                    </div>
                </div>
                {/* ************** */}
                <div className="mt-2">
                    {/****************** Table Order ****************/}
                    <div className="table-resposive tb-scroll-width" id="tableorder" style={{ display: "block", padding: "0px", height: "510px" }}>
                        <table className="table_ord_mod table-bordered1 table-width-cancel">
                            <thead className="thead_scroll_orderlist table_headerSticky">
                                <tr>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>S.No</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Delivery Boy</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Del Boy Trip No</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Date</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Total Amt</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Opening Time</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Closing Time</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Order Count</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Vehicle Type</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Status</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Opening KM</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Closing KM</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Reading KM</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Fuel Price/KM</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Fuel Avg KM</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Fuel Ltr</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Price</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>&nbsp;&nbsp;Trip&nbsp;Incentive&nbsp;&nbsp; Amt</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Opening Km Img</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Closing Km Img</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Voucher</div></th>
                                    <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Receipt</div></th>
                                </tr>
                            </thead>
                            <tbody className="tbody_body_m1">
                                {(() => {
                                    if (trackingReport !== null && Array.isArray(trackingReport)) {
                                        console.log(trackingReport);
                                        const uniqueTrips = trackingReport.filter((data, index, self) =>
                                            index === self.findIndex((t) => (
                                                t.tripNo === data.tripNo
                                            ))
                                        );
                                        return uniqueTrips.slice().reverse().map((data, rowIndex) => {
                                            const totalAmt = trackingReport
                                                .filter(item => item.tripNo === data.tripNo)
                                                .reduce((total, item) => total + item.totalAmt, 0);
                                            return (
                                                <tr className="table_tbody_Order_List"
                                                    key={rowIndex}
                                                    style={{ cursor: "pointer" }} >
                                                    <td className="td_col6 td-padding-sn" style={{ width: "3%", textAlign: "center" }}>
                                                        <button onClick={() => handleTripDetails(data.tripNo)} style={{ padding: "5px 10px" }}>{rowIndex + 1}</button>
                                                    </td>
                                                    <td className="td_col6 td-padding-sn">{data.delBoyName}</td>
                                                    <td className="td_col6 td-padding-sn">{data.tripNo}</td>
                                                    <td className="td_col6 td-padding-sn" style={{ width: "7%", textAlign: "center" }} >{formatDate(data.date)} </td>
                                                    <td className="td_col6 td-padding-sn" style={{ width: "7%", textAlign: "center" }} >₹&nbsp;{totalAmt} </td>
                                                    <td className="td_col6 td-padding-sn">{data.openingTime === "00:00:00" ? "00:00:00" : convertTo12HourFormat(data.openingTime)}</td>
                                                    <td className="td_col6 td-padding-sn">{data.closingTime === "00:00:00" ? "00:00" : convertTo12HourFormat(data.closingTime)}</td>
                                                    <td className="td_col6 td-padding-sn">{data.noOfOrders}</td>
                                                    <td className="td_col6 td-padding-sn">{data.transportDetails}</td>
                                                    <td className="td_col6 td-padding-sn">{data.tripStatus}</td>
                                                    <td className="td_col6 td-padding-sn">{data.openingKM}</td>
                                                    <td className="td_col6 td-padding-sn">{data.closingKM}</td>
                                                    <td className="td_col6 td-padding-sn">{Number(data.closingKM - data.openingKM)}</td>
                                                    <td className="td_col6 td-padding-sn">{data.vehicleType == "Bike" || data.vehicleType == "Sccoty" ? 0 : data.fuelChargesKM}</td>  {/*Fuel price/Km */}
                                                    <td className="td_col6 td-padding-sn">{data.fuelAvgLtrKM}</td>  {/*Fuel Avg Km */}
                                                    <td className="td_col6 td-padding-sn">{((Number(data.closingKM - data.openingKM)) / (data.fuelAvgLtrKM)).toFixed(2)}</td> {/*Fuel Ltr */}
                                                    <td className="td_col6 td-padding-sn"> {/*Price */}
                                                        {data.vehicleType == "Bike" || data.vehicleType == "Sccoty" ? 0 :
                                                            // (parseFloat(data.closingKM - data.openingKM) / parseFloat(data.fuelChargesKM)).toFixed(2) :
                                                            (parseFloat(data.closingKM - data.openingKM) * parseFloat(data.fuelChargesKM)).toFixed(2)
                                                        }
                                                    </td>
                                                    <td className="td_col6 td-padding-sn">{data.tripIncentiveAmt}</td>
                                                    <td className="td_col6 td-padding-sn">
                                                        <img className="hover-enlarge-image" style={{ display: data.openingKMImg ? "block" : "none" }}
                                                            src={`${imageUrl}${data.openingKMImg}`} alt=""></img>
                                                    </td>
                                                    <td className="td_col6 td-padding-sn">
                                                        <img className="hover-enlarge-image" style={{ display: data.closingKMImg ? "block" : "none" }}
                                                            src={`${imageUrl}${data.closingKMImg}`} alt=""></img>
                                                    </td>
                                                    <td className="td_col6 td-padding-sn">
                                                        {/* <img className="hover-enlarge-image" style={{ display: data.closingKMImg != null && data.closingKMImg != undefined && data.closingKMImg != 0 ? "block" : "none" }}
                                                               src={`${imageUrl}${data.tripVoucher}`} alt=""></img> */}
                                                    </td>
                                                    <td className="td_col6 td-padding-sn">
                                                        {/* <img className="hover-enlarge-image" style={{ display: data.closingKMImg != null && data.closingKMImg != undefined && data.closingKMImg != 0 ? "block" : "none" }}
                                                                 src={`${imageUrl}${data.tripReceipt}`} alt=""></img> */}
                                                    </td>
                                                </tr>
                                            );
                                        });
                                    }
                                })()}
                                {isShowTotalAmountInReport && (
                                    <tr className="table_tbody_Order_List"
                                        style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={4} style={{ textAlign: "end" }}>Order Amt: &nbsp;</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>₹&nbsp;{orderTotalAmt == isNaN ? 0 : orderTotalAmt}</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={2} style={{ textAlign: "end" }}>Order Count: &nbsp;</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>{orderCount}</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={3} style={{ textAlign: "end" }}>Reading Km: &nbsp;</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>{readingKm == isNaN ? 0 : readingKm}</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={2} style={{ textAlign: "end" }}>Fuel Ltr: &nbsp;</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={1}>{fuelLtr == isNaN ? 0 : fuelLtr}</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={2}>Incentive&nbsp;Amt:₹&nbsp;{incentiveAmt}</td>
                                        <td className="td_col6 td-padding-sn td_footer" colSpan={4}></td>
                                    </tr>
                                )}
                                {/* <tr className="table_tbody_Order_List"
                                    style={{ cursor: "pointer", backgroundColor: "ghostwhite" }} >
                                    <td className="td_col6 td-padding-sn td_footer" colSpan={4}>Order Amt:- {orderTotalAmt}</td>
                                    <td className="td_col6 td-padding-sn td_footer" colSpan={4}>Reading Km:- {readingKm}</td>
                                    <td className="td_col6 td-padding-sn td_footer" colSpan={4}>Fuel Ltr:- {fuelLtr}</td>
                                    <td className="td_col6 td-padding-sn td_footer" colSpan={4}>Order Count:- {orderCount}</td>
                                    <td className="td_col6 td-padding-sn td_footer" colSpan={4}>Incentive Amt:- {incentiveAmt}</td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
            {/************************** Trip Wise Order Detail Modal ***************/}
            {
                tripWiseOrderModal && (
                    <div className="modal-back">
                        <div className="info-modal" style={{ maxHeight: "90%", height: "auto", overflow: "scroll", width: "95%" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                <label className="fw-600" style={{ fontSize: "18px" }}>Trip Order Detail</label>
                                <i className="fa-solid fa-xmark xmark" onClick={handleCloseModal}></i>
                            </div>
                            <hr />
                            <div className="">
                                <div className="flex-start">
                                    <div style={{ marginLeft: "10px" }}>
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <label>Trip No : {tripOrderData && tripOrderData.length > 0 ? tripOrderData[0].tripNo : ''}</label><br />
                                                <label>Trip Date : {tripOrderData && tripOrderData.length > 0 ? moment(tripOrderData[0].date).format('DD-MM-YYYY') : ''}</label><br />
                                            </div>
                                            <div className="mt-3">
                                                <div className="table-resposive tb-scroll-width" style={{ width: "88vw" }}>
                                                    <table>
                                                        <thead className="table_headerSticky">
                                                            <tr>
                                                                <th className="th">S No</th>
                                                                <th className="th">Del.Boy Name</th>
                                                                <th className="th">Order No</th>
                                                                <th className="th">Name</th>
                                                                <th className="th">Order Date</th>
                                                                <th className="th">Delivery Date & Time</th>
                                                                <th className="th">&nbsp;Time&nbsp;Slot&nbsp;</th>
                                                                <th className="th">Order Amt</th>
                                                                <th className="th">Address</th>
                                                                {isDeliveryBoy && (
                                                                    <th className="th" >Kot Dispatch Target&nbsp;Time</th>
                                                                )}
                                                                {isDeliveryBoy && (
                                                                    <th className="th" >Kot Dispatch Time</th>
                                                                )}
                                                                <th className="th">Del Dispatch Time</th>
                                                                <th className="th">Order Status & Delivered Time</th>
                                                                <th className="th">Delivery Preference No</th>
                                                                <th className="th">Payment Done Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {tripOrderData && tripOrderData.length > 0 && tripOrderData
                                                                .map((data, index) => {
                                                                    return (
                                                                        <tr key={index} style={{ cursor: "pointer" }}>
                                                                            <td className="td">{index + 1}.</td>
                                                                            <td className="td">{data.delBoyName}</td>
                                                                            <td className="td">{data.orderNo}</td>
                                                                            <td className="td">{data.accountName}</td>
                                                                            <td className="td">{moment(data.orderDate, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
                                                                            <td className="td">
                                                                                {moment(data.orderDate, "YYYY-MM-DD").format("DD-MM-YYYY")}<br />
                                                                                {moment(data.time, "HH:mm:ss.SSSSSSS").format("h:mm A")}<br />
                                                                            </td>
                                                                            <td className="td">{timeFormat(data.deliveryTimeSlot)}</td>
                                                                            <td className="td">₹&nbsp;{data.totalAmt}</td>
                                                                            <td className="td">{data.delAddress}</td>
                                                                            {isDeliveryBoy && (
                                                                                <td className="td">
                                                                                    {data.kotDispatchTargetTime != null && data.kotDispatchTargetTime != undefined && data.kotDispatchTargetTime != "" ?
                                                                                        moment(data.kotDispatchTargetTime, "HH:mm:ss").format("h:mm A") : ""} </td>
                                                                            )}
                                                                            {isDeliveryBoy && (
                                                                                <td className="td">
                                                                                    {data.kotDispatchTime != null && data.kotDispatchTime != undefined && data.kotDispatchTime != "" ?
                                                                                        moment(data.kotDispatchTime, "HH:mm:ss").format("h:mm A") : ""} </td>
                                                                            )}
                                                                            <td className="td">{data.deliveryBoyDispatchTime != null && data.deliveryBoyDispatchTime != undefined && data.deliveryBoyDispatchTime != "" ?
                                                                                moment(data.deliveryBoyDispatchTime, "HH:mm:ss").format("h:mm A") : ""}</td>
                                                                            <td className="td">{data.overallOrderStatus} {data.deliveredTime != null && data.deliveredTime != undefined && data.deliveredTime != "" ?
                                                                                moment(data.deliveredTime, "HH:mm:ss").format("h:mm A") : ""}</td>
                                                                            <td className="td">{data.deliveryPreferenceNo}</td>
                                                                            <td className="td">{moment(data.paymentDoneOn).format("DD-mm-YYYY h:mm A")}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="mt-3">
                                                    {/* <label><span style={{ fontWeight: "600" }}>Print Time -</span> {getCurrentDateTime()}</label><br />
                                                <label><span style={{ fontWeight: "600" }}>Delivery Time Slot - </span>{(itemList && itemList[0].deliveryTime)}</label><br />
                                                <label><span style={{ fontWeight: "600" }}>KDTT - </span>{(itemList && itemList[0].kotDispatchTargetTime)}</label><br />
                                                <label><span style={{ fontWeight: "600" }}>Remarks: </span></label> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                <button className="redBtn" style={{ maxWidth: "25%" }} onClick={handleCloseModal} >Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {loading && <Loader />}
        </div >
    );
};

export default TripWiseTrackingReport;
