import React, { useState, useEffect } from 'react'

const TextArea = (props) => {

  //   const deletebuttonshow = () => {
  //   document.getElementById("textarea-delete").classList.toggle("column-one-delete-web-template");
  //   document.getElementById("textarea").classList.toggle("element-border");
  // };

  const [getValueAndStrg, setGetValueAndStrg] = useState([])
  const [textAreaValue, setTextAreaValue] = useState("")



  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }

  }, []);

  const SaveTextAreaValue = (e) => {
    localStorage.setItem("TextAreaData", e.target.value);
    setTextAreaValue(e.target.value)
  }
  useEffect(() => {
    setTextAreaValue(localStorage.getItem("TextAreaData"))
  }, [textAreaValue])


  useEffect(() => {
    // Store only unique id values in localStorage
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);
  const deletebuttonshow = () => {
    debugger
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };
  let getId = props.keyValueId;


  return (
    <div style={{ position: "relative" }} onClick={deletebuttonshow} onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true" id={getId}>
      <textarea className="textarea-main-web-template" name="" cols="40" rows="4" id={getId} onChange={(e) => SaveTextAreaValue(e)} value={textAreaValue}></textarea>
    </div>
  )
}

export default TextArea