import { useEffect, useRef } from 'react';

const useIntersectionObserver = (callback, options) => {
    const elementRef = useRef(null);

    useEffect(() => {
        if (!elementRef.current) return;

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                callback(entry.target);
            }
        }, options);

        observer.observe(elementRef.current);

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, [callback, options]);

    return elementRef;
};

export default useIntersectionObserver;
