import React, { useState, useEffect } from "react";

const Tabs = (props) => {

  const [getValueAndStrg, setGetValueAndStrg] = useState([])

  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    // Store only unique id values in localStorage
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);
  const deletebuttonshow = () => {
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };
  let getId = props.keyValueId;

  const [tabcontent1, setTabContent1] = useState(true);
  const [tabcontent2, setTabContent2] = useState(false);
  const [tabcontent3, setTabContent3] = useState(false);


  const showtabs1 = () => {
    setTabContent1(true)
    setTabContent2(false)
    setTabContent3(false)
  }

  const showtabs2 = () => {
    setTabContent1(false)
    setTabContent2(true)
    setTabContent3(false)
  }

  const showtabs3 = () => {
    setTabContent1(false)
    setTabContent2(false)
    setTabContent3(true)
  }

  // const deletebuttonshow = () => {
  //   document.getElementById("tabs-delete").classList.toggle("column-one-delete-web-template");
  //   document.getElementById("tabs").classList.toggle("element-border");
  // };

  const [activeTab, setActiveTab] = useState(0); // Initialize the active tab index

  const handleTabClick = (index) => {
    setActiveTab(index);
  };


  return (
    <div style={{ position: "relative" }} onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true" onClick={deletebuttonshow} id={getId}>
      <div className="tab-btn-box-web-template">
        <div
          className={`tab-web-template ${activeTab === 0 ? 'active' : ''} `}
          onClick={() => {
            handleTabClick(0); showtabs1()
          }}
        >
          Tab 1
        </div>
        <div
          className={`tab-web-template ${activeTab === 1 ? 'active' : ''} `}
          onClick={() => {
            handleTabClick(1); showtabs2()
          }}
        >
          Tab 2
        </div>
        <div
          className={`tab-web-template ${activeTab === 2 ? 'active' : ''} `}
          onClick={() => {
            handleTabClick(2); showtabs3()
          }}
        >
          Tab 3
        </div>

      </div>
      <div className="tab-content-box-web-template">
        {tabcontent1 && (
          <div className="tab-content-11-web-template">Tab content 1</div>
        )
        }
        {tabcontent2 && (
          <div className="tab-content-2-web-template">Tab content  2</div>
        )
        }
        {tabcontent3 && (
          <div className="tab-content-3-web-template">Tab content 3</div>
        )
        }
      </div>
    </div>
  );
};

export default Tabs;
