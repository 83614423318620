import { useRef, useState } from "react";
import IconLibraryOption from "../ChildComponents/IconLibraryOption";

const OpenIconLibrary = (props) => {
  const uploadInp = useRef(null);
  const addImage = () => {
    uploadInp.current.click();
  };

  const [openLibrary, setOpenLib] = useState(true);
  const handleValuePassing = () => {
    console.log("cancel the box");
    props.cancel();
  };

  return (
    <div className="modal-back">
      <div className="iconlibrary-modal-web-template">
        <div className="iconmodal-header-web-template">
          <p className="icon-para-web-template">ICON LIBRARY</p>
          <i
            className="fa-solid fa-xmark large-red-web-template"
            onClick={() => {
              handleValuePassing();
            }}
          ></i>
        </div>
        <div className="leftright-flex-web-template">
          <div className="iconmodal-left-web-template">
            <div className="card-web-template" onClick={() => setOpenLib(true)}>
              <p className="card-title-web-template">  Upload SVG</p>
              {/* <div className="go-corner-web-template">
                <div className="go-arrow-web-template">→</div>
              </div> */}
            </div>

            <div className="card-web-template" onClick={() => setOpenLib(false)}>
              <p className="card-title-web-template">  Icon Library</p>
              {/* <div className="go-corner-web-template">
                <div className="go-arrow-web-template">→</div>
              </div> */}
            </div>

            {/* <p className="iconmodal-pgr-web-template" onClick={() => setOpenLib(true)}>
              Upload SVG
            </p>
            <p className="iconmodal-pgr-web-template" onClick={() => setOpenLib(true)}>
              {" "}
              Icon Library
            </p> */}
          </div>
          <div className="iconmodal-right-web-template">
            {openLibrary && (
              <div className="uploadsvg">
                <div className="image-modal-left m-aut-web-template" onClick={addImage}>
                  {/* <div className="image-left-heading"><p>HTML</p></div> */}
                  <p className="upload-image-txt">
                    Drop SVG file here or click to upload SVG
                  </p>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={uploadInp}
                  />
                </div>
                <button className="iconlib-btn-web-template">
                  <div className="iconlib-sign-web-template">
                    <svg viewBox="0 0 512 512">
                      <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
                    </svg>
                  </div>

                  <div className="iconlib-text-web-template">Upload</div>
                </button>
              </div>
            )}
            {!openLibrary && <IconLibraryOption />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenIconLibrary;
