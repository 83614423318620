import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const UnitMaster = {
    insertUnitMaster: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/UnitMaster/UnitMaster`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                unitName: data.name,
                printName: data.printName,
                eWayBillUnitName: data.eWayBillUnitName,
                //mainUnit: data.mainUnit,
                altUnit: Number(data.altUnit),
                //conversionUnit: data.conversionUnit,
                conversionFactor: Number(data.conversionFactor),
                conversionValue: Number(data.conversionValue),
                isDefautUnit: false,
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getUnitMaster: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/UnitMaster/GetUnitMasters`;
            const response = await axios.post(apiUrl, {
                filter: [],
                order: [
                    {
                        propertyName: "string",
                        ascending: false
                    }
                ],
                pageNumber: 0,
                pageSize: 50000
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
     // ************************* Get All ***************************
     getUnitMasterFilter: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/UnitMaster/GetUnitMasters`;
            const response = await axios.post(apiUrl, {
                filter: [],
                order: [
                    {
                        propertyName: "id",
                        ascending: false
                    }
                ],
                pageNumber: 0,
                pageSize: 500000
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteUnitMaster: async (unitId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/UnitMaster?unitId=${unitId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getUnitMasterById: async (unitId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/UnitMaster?unitId=${unitId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************
    updateUnitMaster: async (data) => {
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/UnitMaster?unitId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                name: data.name,
                printName: data.printName,
                eWayBillUnitName: data.eWayBillUnitName,
                mainUnit: data.mainUnit,
                altUnit: data.altUnit,
                conversionUnit: data.conversionUnit,
                conversionFactor: data.conversionFactor,
                conversionValue: data.conversionValue,
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

};

export default UnitMaster;
