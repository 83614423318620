import React from 'react'
import GlobalSelector from './GlobalSelector'
// import StylingComponent from '../StylingComponent';
const GeneralSection = (props) => {





  // const displaystyle = (event) => {
  //   document.getElementById(props.id).style.display = event.target.value;
  // }

  // const positionstyle = (event) => {
  //   document.getElementById(props.id).style.position = event.target.value;

  // }

  const displaystyle = (event) => {
    const value = event.target.value;
    const element = document.getElementById(props.id);
    if (element) {
      element.style.display = value;
      props.updateStyle(props.id, "display", value);
    }
  };

  const positionstyle = (event) => {
    const value = event.target.value;
    const element = document.getElementById(props.id);
    if (element) {
      element.style.position = value;
      props.updateStyle(props.id, "position", value);
    }
  };


  return (
    <>
      <div className="general-container">
        <p className='genral-heading'> Float</p>
        <div className="alignbox">
          <button className='align-button'><i className="icon-edit fa-solid fa-xmark"></i></button>
          <button className='align-button'><i className="icon-edit fa-solid fa-align-left"></i></button>
          <button className='align-button'><i className="icon-edit fa-solid fa-align-right"></i></button>
        </div>
        <div className="general-selectioncontainer">
          <div className="general-sectionoption">
            <p className="general-paragraph">Display</p>
            <select name="display" className='selectorstyle' onChange={displaystyle}>
              <option value="block">block</option>
              <option value="inline">inline</option>
              <option value="inline-block">inline-block</option>
              <option value="flex">flex</option>
              <option value="none">none</option>
            </select>
          </div>
          <div className="general-sectionoption">
            <p className="general-paragraph">Position</p>
            <select name="position" className='selectorstyle' onChange={positionstyle}>
              <option value="static">static</option>
              <option value="relative">relative</option>
              <option value="absolute">absolute</option>
              <option value="fixed">fixed</option>
            </select>
          </div>
        </div>
        <div className="global-selectbutton">
          <GlobalSelector placeholder={"auto"} name={"top"} id={props.id} onChange={props.handleStyleChange} />
          <GlobalSelector placeholder={"auto"} name={"right"} id={props.id} onChange={props.handleStyleChange} />
        </div>
        <div className="global-selectbutton">
          <GlobalSelector placeholder={"auto"} name={"left"} id={props.id} onChange={props.handleStyleChange} />
          <GlobalSelector placeholder={"auto"} name={"bottom"} id={props.id} onChange={props.handleStyleChange} />
        </div>
      </div>
    </>
  )
}

export default GeneralSection
