import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useContext, useEffect, useState, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import ImageUploadWithPreview from "../../Components/ImagePreview";
import MainButton from "../../Props/MainButton";
import ToolTipMaster from "../../Props/ToolTipMaster";
import ItemGroupApi from "../../../API/APIServices/ItemGroup";
import TaxCategoryApi from "../../../API/APIServices/TaxCategory";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import AlertBoxError from "../../Props/AlertBoxError";
import UnitConversionMasterList from "./UnitConversionMasterList";
import { useNavigate } from 'react-router-dom';

function ItemGroupMaster(props) {
  var modalOpen = props.modelOpen;
  const [loading, setLoading] = useState(false);
  const {
    OpenAlertBox,
    alertBox,
    OpenAlertBoxEdit,
    OpenAlertBoxWhenItemExists,
    openAlertItemExists,
    alertBoxEdit,
    OpenAlertBoxErrorInsert,
    alertBoxErrorInsert,
    OpenAlertBoxErrorUpdate,
    alertBoxErrorUpdate,
  } = useContext(MyContext);
  const [rows, setRows] = useState([{}]);
  const [imageIds, setImageIds] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    console.log(rows)
  }, [rows])



  // const [ItemImage, setItemImage] = useState(null);

  const [inputValues, setInputValues] = useState({
    d1_Id: "",
    d2_Id: "",
    name: "",
    printName: "",
    primaryGroup: true,
    parentGrp: "",
    useInEcom: false,
    itemIncentiveSalesman: "",
    taxCategory_Applyforall: "",
    saleDisc_Applyforall: "",
    purchaseDisc_Applyforall: "",
    hsnCode_Applyforall: "",
    loyaltyPoin_Applyforall: "",
    groupBarcodeEnable: "",
    saleDiscPer: "",
    purDiscPer: "",
    hsnCode: "",
    loyaltyPoint: "",
    alertNegativeQty: false,
    restrictNegativeQty: false,
    menuName: "",
    underMenu: "",
    showInMenu: false,
    displayOrder: "",
    showInCollection: false,
    showInHomePage: false,
    showInAddBanner: false,
    nameInHomePage: "",
    priorityToShowInHomePage: "",
    imageShowOnError: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    searchEnginFriendlyPageName: "",
    menuUrl: "",
    isActive: true,
    taxCategory: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    // menuName:"",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await props.data;

        if (props.data) {
          console.log(props.data);
          setImageIds(data.images);
          if (data.taxCategory.length === 0) {
            setRows([
              {
                changeApplyFromDate: "",
                tax1: "",
                tax1Range: "",
                tax2: "",
                isActive: "",
              },
            ]);
          } else {
            setRows(data.taxCategory);
          }

          try {
            const data = await props.data; // Assuming props.editData is a Promise
            setInputValues({
              d1_Id: data.d1_Id,
              d2_Id: data.d2_Id,
              name: data.name,
              printName: data.printName,
              primaryGroup: data.primaryGroup,
              parentGrp: data.parentGrp2,
              useInEcom: data.useInEcom,
              itemIncentiveSalesman: data.itemIncentiveSalesman,
              taxCategory_Applyforall: data.taxCategory_Applyforall,
              saleDisc_Applyforall: data.saleDisc_Applyforall,
              purchaseDisc_Applyforall: data.purchaseDisc_Applyforall,
              hsnCode_Applyforall: data.hsnCode_Applyforall,
              loyaltyPoin_Applyforall: data.loyaltyPoin_Applyforall,
              groupBarcodeEnable: data.groupBarcodeEnable,
              saleDiscPer: data.saleDiscPer,
              purDiscPer: data.purDiscPer,
              hsnCode: data.hsnCode,
              loyaltyPoint: data.loyaltyPoint,
              alertNegativeQty: data.alertNegativeQty,
              restrictNegativeQty: data.restrictNegativeQty,
              menuName: data.menuName,
              underMenu: data.underMenu,
              showInMenu: data.showInMenu,
              displayOrder: data.displayOrder,
              showInHomePage: data.showInHomePage,
              showInCollection: data.showInCollection,
              showInAddBanner: data.showInAddBanner,
              nameInHomePage: data.nameInHomePage,
              priorityToShowInHomePage: data.priorityToShowInHomePage,
              imageShowOnError: data.imageShowOnError,
              metaTitle: data.metaTitle,
              metaKeywords: data.metaKeywords,
              metaDescription: data.metaDescription,
              searchEnginFriendlyPageName: data.searchEnginFriendlyPageName,
              menuUrl: data.menuUrl,
              taxCategory: data.taxCategory,
            });

          } catch (error) {

          }
        }
      } catch (error) {

      }
    };

    fetchData();
  }, [props.data]);

  const [parentName, setParentName] = useState({});

  useEffect(() => {
    const fetchdata4 = async () => {
      try {
        const val = await ItemGroupApi.GetItemGroup();
        setParentName(val);
        console.log(val);
      } catch (error) {
        console.log(error);
      }
    }
    fetchdata4();
  }, []);

  /*****************New changes (shivendra 20032024)********************/
  const [taxCategoryName, setTaxCategoryName] = useState();

  useEffect(() => {
    const fetchTaxCategory = async () => {
      try {
        const val = await TaxCategoryApi.getTaxCategory();
        setTaxCategoryName(val);
        console.log(val);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTaxCategory();
  }, []);


  const GetParentGroupDetail = async (e) => {
    debugger
    handleInputChange(e);
    const grpId = e.target.value;
    const data = await ItemGroupApi.GetItemGroupByD2Id(grpId);

    setInputValues((prevValues) => ({
      ...prevValues,
      "parentGrp": grpId,
      "groupBarcodeEnable": data.groupBarcodeEnable,
      "saleDiscPer": data.saleDiscPer,
      "purDiscPer": data.purDiscPer,
      "hsnCode": data.hsnCode,
      "taxCategory": data.taxCategory,

    }));

    if (data.taxCategory.length === 0) {
      setRows([
        {
          changeApplyFromDate: "",
          tax1: "",
          tax1Range: "",
          tax2: "",
          isActive: ""
        },
      ]);
    } else {
      setRows(data.taxCategory);
    }

  };

  /**********************************************/

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
    // console.log(inputValues);
  };

  const handleInputChangeActive = (e) => {
    const { name, type, checked } = e.target;
    // console.log(`Changing ${name} to ${checked}`);
    const newValue = type === "checkbox" ? checked : e.target.value;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: type === "checkbox" ? !prevInputValues[name] : newValue,
    }));
    // console.log(inputValues)
  }

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const validateInput = (fieldName) => {
    // console.log(fieldName);
    const fieldValue = inputValues[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }

    return true;
  };
  const fieldNames = ["name"];

  /***********{title}***************/
  useState(() => {
    document.title = "Item Group Master";
  }, []);

  //**********{show hide}*****************//

  const [isInputVisiblam3, setInputVisiblam3] = useState(false);
  const handleToggleChangam3 = () => {
    setInputVisiblam3(!isInputVisiblam3);
  };

  //**********{show hide Y/N}*****************//
  const [isModalOpenEcom, setModalOpenEcom] = useState(false);

  // const showselectOption51 = () => {
  //   setModalOpenEcom(!isModalOpenEcom);
  // };

  // const [, sethandleInputChangeEcom] = useState(false)
  // const handleInputChangeEcom = (event) => {
  //     const selectedValue = event.target.value;
  //     sethandleInputChangeEcom(selectedValue);
  //     if (selectedValue === 'Yes') {
  //         setModalOpenEcom(true);
  //     }
  //     else if (selectedValue === 'No') {
  //         setModalOpenEcom(false);
  //     }
  // }
  const handleSelectChange01 = (event) => {
    const selectedValue = event.target.value;
    // setSelectedOption(selectedValue);
    if (selectedValue === "false") {
      setModalOpenEcom(false);
    } else if (selectedValue === "true") {
      setModalOpenEcom(true);
    }
  };

  const UseInEcom = (e) => {
    handleInputChange(e);
    handleSelectChange01(e);
  };

  useEffect(() => {
    try {
      if (inputValues.useInEcom == true) {
        setModalOpenEcom(true);
      } else if (inputValues.useInEcom == "true") {
        setModalOpenEcom(true);
      } else {
        setModalOpenEcom(false);
      }
      if (inputValues.primaryGroup == false) {
        setPerentGroup(true);
      } else if (inputValues.primaryGroup == "false") {
        setPerentGroup(true);
      } else {
        setPerentGroup(false);
      }
    } catch (error) {
      console.log(error);
    }
  });

  const ChangePrimaryGroup = (e) => {
    // Use strict equality for comparison
    handleInputChange(e);
    handleSelectChange02(e);

    // Assuming e.target.value is a string representation of a boolean
    if (e.target.value === 'true') {
      setInputValues((prevValues) => ({
        ...prevValues,
        parentGrp: "",
      }));
    }
  };

  const [isPerentGroup, setPerentGroup] = useState(false);
  const handleSelectChange02 = (event) => {
    const selectedValue = event.target.value;
    // setSelectedOption(selectedValue);
    if (selectedValue === "true") {
      setPerentGroup(false);
    } else if (selectedValue === "false") {
      setPerentGroup(true);
    }
  };

  //*******************{modal box}******************/ /

  const [isModalOpen, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(!isModalOpen);
  };
  const hideModal = () => {
    setModalOpen(false);
  };

  const [isPrintName, setPrintName] = useState(false);
  const showPrintName = () => {
    setPrintName(!isPrintName);
  };

  const [isTaxCategory2, setTaxCategory2] = useState(false);
  const showTaxCategory2 = () => {
    setTaxCategory2(!isTaxCategory2);
  };

  //****************{modal box}***************/ /

  /****************{Category box}****************/

  const handleAddRow = () => {
    setRows([...rows, {}]);
  };

  const [taxData, setTaxData] = useState({
    changeApplyFromDate: "",
    tax1: "",
    tax1Range: "",
    tax2: "",
  })
  useEffect(() => {
    console.log(taxData)
  }, [taxData])

  const handleInputChangeForTax = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setTaxData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  const handleAddRowWithData = () => {
    setRows([...rows, { ...taxData }]);
  };

  const handleInputChange1 = (rowIndex, e) => {
    const { name, value } = e.target;

    setRows((prevTableData) => {
      const updatedData = [...prevTableData];
      const updatedRow = { ...(updatedData[rowIndex] || {}), [name]: value };
      updatedData[rowIndex] = updatedRow;
      return updatedData;
    });

    setInputValues((prevInputValues) => {
      const updatedInputValues = {
        ...prevInputValues,
        taxCategory: Array.isArray(prevInputValues.taxCategory)
          ? [...prevInputValues.taxCategory]
          : [],
      };
      updatedInputValues.taxCategory[rowIndex] = {
        ...(updatedInputValues.taxCategory[rowIndex] || {}),
        [name]: value,
      };
      return updatedInputValues;
    });

  };

  const handleDeleteRow = (rowIndex) => {
    // Ensure there is at least one row remaining before deleting
    if (rows.length > 1) {
      setRows((prevTableData) => {
        const updatedData = [...prevTableData];
        updatedData.splice(rowIndex, 1);
        return updatedData;
      });
    } else {
      // You may want to show a message or take some other action
      alert("At least one row must be present.");
    }
  };

  const handleModalClick = (e) => {
    // Prevent the event from propagating to parent elements
    e.stopPropagation();
  };

  const [ItemGroupMaster] = useState(true);

  const [isSaleDiscount, setSaleDiscount] = useState(false);
  const showDiscount = () => {
    setSaleDiscount(!isSaleDiscount);
  };
  const [isPurchaseDiscount, setPurchaseDiscount] = useState(false);
  const showPurchase = () => {
    setPurchaseDiscount(!isPurchaseDiscount);
  };
  const [isLoyaltyPoint, setLoyaltypoint] = useState(false);
  const showLoyalty = () => {
    setLoyaltypoint(!isLoyaltyPoint);
  };
  const [isBarcodeEnable, setBarcodeEnable] = useState(false);
  const showBarCode = () => {
    setBarcodeEnable(!isBarcodeEnable);
  };
  const [isLimitUpto, setLimitUpto] = useState(false);
  const showUpto = () => {
    setLimitUpto(!isLimitUpto);
  };
  const [isOfferScheme, setOfferScheme] = useState(false);
  const showScheme = () => {
    setOfferScheme(!isOfferScheme);
  };

  //***************{Enter Btn Click}***************//
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);
  const input8Ref = useRef(null);
  const input9Ref = useRef(null);
  const input10Ref = useRef(null);
  const input11Ref = useRef(null);
  const input12Ref = useRef(null);
  const input13Ref = useRef(null);
  const input14Ref = useRef(null);
  const input15Ref = useRef(null);
  const input16Ref = useRef(null);
  const input17Ref = useRef(null);
  const input18Ref = useRef(null);
  const input19Ref = useRef(null);
  const input20Ref = useRef(null);
  const input21Ref = useRef(null);
  const input22Ref = useRef(null);
  const input23Ref = useRef(null);
  const input24Ref = useRef(null);
  const input25Ref = useRef(null);
  const input26Ref = useRef(null);
  const input27Ref = useRef(null);
  const input28Ref = useRef(null);
  const input29Ref = useRef(null);
  const input30Ref = useRef(null);
  const input31Ref = useRef(null);
  const input32Ref = useRef(null);
  const input33Ref = useRef(null);
  const input34Ref = useRef(null);
  const input35Ref = useRef(null);
  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };

  const [ItemImage, setItemImage] = useState([]);

  useEffect(() => {
    console.log("imageIds", imageIds);
    console.log("ItemImage", ItemImage);
  }, [ItemImage, imageIds])

  const ItemImageUpload = (e, indx) => {
    const newItemImage = [...ItemImage];
    // If indx is within bounds of the array, update it; otherwise, push it to the end
    if (indx >= 0 && indx < newItemImage.length) {
      newItemImage[indx] = e;
    } else {
      newItemImage.push(e);
    }
    setItemImage(newItemImage);
  };

  const upload_image = async (Id) => {

    debugger;
    const formData = new FormData();

    ItemImage.forEach((file) => {
      formData.append(`files`, file);
    });

    //formData.append(`files`, ItemImage);
    formData.append(`BranchId`, 1);
    formData.append(`ItemGroupId`, Id);
    console.log(formData);

    try {
      const imageUpload = await ItemGroupApi.ItemGroupImageUpload(formData);
      console.log(imageUpload);

      if (imageUpload) {
        console.log("image uploaded successfully");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const SaveBtn = async () => {
    let allValid = true;
    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);
      if (!isValid) {
        allValid = false;
      }
    });
    if (allValid) {
      setLoading(true);
      console.log(ItemImage);
      console.log(inputValues);

      if (props.modelOpen === true) {
        const data = await ItemGroupApi.updateItemGroup(inputValues, rows);

        if (data.isSuccess) {
          props.fetchData();
          upload_image(inputValues.d1_Id);
          props.setSliderCallBack(false);
          OpenAlertBoxEdit();
          setItemImage([])
          //setLoading(false);
        } else {
          OpenAlertBoxErrorUpdate()
          //setLoading(false)
        }
      } else {
        const data = await ItemGroupApi.InsertItemGroup(inputValues, rows);
        // console.log(data);
        if (data.isSuccess === true) {
          upload_image(data.result.d1_Id);
          setLoading(false);
          OpenAlertBox();
          setInputValues({
            name: "",
            printName: "",
            primaryGroup: "",
            parentGrp: "",
            useInEcom: "",
            itemIncentiveSalesman: "",
            taxCategory_Applyforall: "",
            saleDisc_Applyforall: "",
            purchaseDisc_Applyforall: "",
            hsnCode_Applyforall: "",
            loyaltyPoin_Applyforall: "",
            groupBarcodeEnable: "",
            saleDiscPer: "",
            purDiscPer: "",
            hsnCode: "",
            loyaltyPoint: "",
            alertNegativeQty: false,
            restrictNegativeQty: false,
            menuName: "",
            underMenu: "",
            showInMenu: "",
            displayOrder: "",
            showInHomePage: "",
            showInCollection: "",
            showInAddBanner: "",
            nameInHomePage: "",
            priorityToShowInHomePage: "",
            imageShowOnError: "",
            metaTitle: "",
            metaKeywords: "",
            metaDescription: "",
            searchEnginFriendlyPageName: "",
            menuUrl: "",
            taxCategory: [
              {
                changeApplyFromDate: "2024-01-23T07:21:18.320Z",
                tax1: "",
                tax1Range: "",
                tax2: "",
              },
            ],
          });
          setItemImage([])
        } else if (data.errorMessages[0] === "Item Group Already Exists!") {
          setLoading(false);
          OpenAlertBoxWhenItemExists();
        } else {
          OpenAlertBoxErrorInsert();
          setLoading(false)
        }
      }
      setLoading(false);

    }
  };

  // *********************** Group Master Setting *************************

  const [settingInputValues, setSettingInputValues] = useState({
    printName: false,
    taxCategory2: false,
    saleDiscount: false,
    purchaseDiscount: false,
    loyaltyPoint: false,
    allowBarcodeEnable: false,
    itemIncentive: false,
    allowCategoryLimitUpto: false,
    taxCategoryDatewiseDetails: false,
  });

  const handleSettingChange = (event) => {
    const { name, checked } = event.target;
    console.log(`Changing ${name} to ${checked}`);

    const updatedValues = {
      ...settingInputValues,
      [name]: checked,
    };

    setSettingInputValues(updatedValues);
    ItemGroupApi.insertItemGroupMasterSetting(updatedValues);

    switch (name) {
      case "printName":
        showPrintName();
        break;
      case "taxCategory2":
        {
          showTaxCategory2();
          showUpto();
        }
        break;
      case "saleDiscount":
        showDiscount();
        break;
      case "purchaseDiscount":
        showPurchase();
        break;
      case "loyaltyPoint":
        showLoyalty();
        break;
      case "allowBarcodeEnable":
        showBarCode();
        break;
      case "itemIncentive":
        showScheme();
        break;
      /*case "allowCategoryLimitUpto":
        showUpto();
        break;*/
      case "taxCategoryDatewiseDetails":
        handleToggleChangam3();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ItemGroupApi.getItemGroupMasterSetting();
        console.log(data);
        setSettingInputValues(data);
        setPrintName(data.printName);
        setSaleDiscount(data.saleDiscount);
        setPurchaseDiscount(data.purchaseDiscount);
        setLoyaltypoint(data.loyaltyPoint);
        setBarcodeEnable(data.allowBarcodeEnable);
        setOfferScheme(data.itemIncentive);
        setTaxCategory2(data.taxCategory2);
        setLimitUpto(data.taxCategory2);//allowCategoryLimitUpto
        setInputVisiblam3(data.taxCategoryDatewiseDetails);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    console.log(settingInputValues);
    fetchData();
  }, []);

  return (
    <div>
      <PageTabs showModal={showModal} locationTab={"/ItemGroupMaster"} />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Item Group Master</h2>
        </div>
        {/* *******************Save Button****************** */}

        {/* *******************Top Buttons****************** */}
        <MainButton
          isBtnSetting={true}
          showModal={showModal}
          SaveButton={SaveBtn}
          modelOpen={props.modelOpen}
          setSliderCallBack={props.setSliderCallBack}
        />

        {/* *******************Modal Box Open****************** */}
        <div>
          {isModalOpen && (
            <div onClick={hideModal} className="modal">
              <div onClick={handleModalClick} className="modal-content">
                <div className="modal-title">
                  <h3 className="modal-h">Item Group Setting</h3>
                  <span className="close" onClick={hideModal}>
                    &times;
                  </span>
                </div>
                <div className="modal-content-im">
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            name="printName"
                            value={settingInputValues.printName}
                            onChange={handleSettingChange}
                            checked={settingInputValues.printName}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Print Name
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            name="taxCategory2"
                            value={settingInputValues.taxCategory2}
                            onChange={handleSettingChange}
                            checked={settingInputValues.taxCategory2}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Tax Category 2
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            name="saleDiscount"
                            value={settingInputValues.saleDiscount}
                            onChange={handleSettingChange}
                            checked={settingInputValues.saleDiscount}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Sale Discount
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            name="purchaseDiscount"
                            value={settingInputValues.purchaseDiscount}
                            onChange={handleSettingChange}
                            checked={settingInputValues.purchaseDiscount}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Purchase Discount
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            name="loyaltyPoint"
                            value={settingInputValues.loyaltyPoint}
                            onChange={handleSettingChange}
                            checked={settingInputValues.loyaltyPoint}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Loyalty Point
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            name="allowBarcodeEnable"
                            value={settingInputValues.allowBarcodeEnable}
                            onChange={handleSettingChange}
                            checked={settingInputValues.allowBarcodeEnable}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Allow Barcode Enable
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            name="itemIncentive"
                            value={settingInputValues.itemIncentive}
                            onChange={handleSettingChange}
                            checked={settingInputValues.itemIncentive}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Item Incentive
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/*<div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            name="allowCategoryLimitUpto"
                            value={settingInputValues.allowCategoryLimitUpto}
                            onChange={handleSettingChange}
                            checked={settingInputValues.allowCategoryLimitUpto}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Allow Category Limit Upto
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            name="taxCategoryDatewiseDetails"
                            value={
                              settingInputValues.taxCategoryDatewiseDetails
                            }
                            onChange={handleSettingChange}
                            checked={
                              settingInputValues.taxCategoryDatewiseDetails
                            }
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Tax Category Date Wise Details
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* *******************Modal Box End****************** */}
      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-left">
                    <div className="company_info-text-im PageActiveFlex">
                      Group Details
                      <div className="input-box-checkbox1 ">
                        <div className=" td-2">
                          <div className="ToggleSwitchActive td-3">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                onChange={handleInputChangeActive}
                                value={inputValues.isActive}
                                checked={inputValues.isActive}
                                name="isActive"
                              />
                              <span></span>
                            </label>
                          </div>
                          <ToolTipMaster isActiveInactive={true} />
                        </div>
                      </div>
                    </div>
                    {ItemGroupMaster && (
                      <div className="HeadContentItemgroup">
                        <div className="input-box-m11">
                          <select
                            className="modify_im_input mode_category"
                            ref={input1Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input2Ref)
                            }
                            onChange={UseInEcom}
                            type="text"
                            required
                            value={inputValues.useInEcom}
                            name="useInEcom"
                          >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </select>
                          <label className="ItemMasterLabelFix">Use in E-com</label>
                        </div>
                        {(() => {
                          var Val = isPrintName ? input3Ref : input4Ref;
                          return (
                            <div className="input-box-m11">
                              <input
                                className="modify_im_input mode_limit mode_category"
                                type="text"
                                required
                                onChange={handleInputChange}
                                value={inputValues.name}
                                name="name"
                                ref={input2Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, Val)
                                }
                              />
                              <label className="modifyTextLabel">
                                Group Name
                                <span className="StarClr">
                                  *
                                  {errorMessages.name && (
                                    <label className="errorMessage">
                                      {errorMessages.name}
                                    </label>
                                  )}
                                </span>
                              </label>
                            </div>
                          );
                        })()}
                        {isPrintName && (
                          <div className="input-box-m11">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              value={inputValues.printName}
                              name="printName"
                              ref={input3Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input4Ref)
                              }
                            />
                            <label className="modifyTextLabel">
                              Print Name
                            </label>
                          </div>
                        )}

                        {(() => {
                          var val = isPerentGroup ? input5Ref : input12Ref;
                          return (
                            <div className="input-box-m11">
                              <select
                                className="modify_im_input mode_category"
                                ref={input4Ref}
                                // onChange={handleInputChange}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, val)
                                }
                                onChange={ChangePrimaryGroup}
                                type="text"
                                required
                                value={inputValues.primaryGroup}
                                name="primaryGroup"

                              //     ref={input1Ref}
                              // onKeyDown={(event) =>
                              //   handleKeyPress(event, input2Ref)
                              // }
                              // onChange={UseInEcom}
                              // type="text"

                              // value={inputValues.useInEcom}
                              // name="useInEcom"
                              >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                              <label className="ItemMasterLabelFix">
                                Primary Group{" "}
                              </label>
                            </div>
                          );
                        })()}

                        {isPerentGroup && (
                          // <div className="input-box-m11">
                          //   <input
                          //     className="modify_im_input mode_limit mode_category"
                          //     value={inputValues.parentGrp}
                          //     name="parentGrp"
                          //     onChange={handleInputChange}
                          //     ref={input5Ref}
                          //     onKeyDown={(event) =>
                          //       handleKeyPress(event, input6Ref)
                          //     }
                          //     type="text"
                          //     required
                          //   />
                          //   <label className="modifyTextLabel">Perent Group</label>
                          // </div>

                          // <div className="input-box-m11">
                          //   <input
                          //     list="browsers"
                          //     name="parentGrp"
                          //     id="browser"
                          //     type="text"
                          //     required
                          //     onChange={handleInputChange}
                          //     className="modify_im_input mode_limit mode_category"
                          //     value={inputValues.parentGrp}
                          //   />
                          //   <label className="modifyTextLabel">
                          //     Parant Group
                          //   </label>

                          //   <datalist id="browsers">
                          //     <datalist id="browsers">
                          //       {parentName.map((data) => (
                          //         <option value={data.d2_Id} key={data.d2_Id}>
                          //           {data.name}
                          //         </option>
                          //       ))}
                          //     </datalist>
                          //   </datalist>
                          // </div>

                          <div className="input-box-m11">
                            <select
                              className="modify_im_input mode_category"
                              ref={input4Ref}
                              onKeyDown={(event) => handleKeyPress(event)}
                              onChange={GetParentGroupDetail}
                              type="text"
                              required
                              value={inputValues.parentGrp}
                              name="parentGrp"

                            //     ref={input1Ref}
                            // onKeyDown={(event) =>
                            //   handleKeyPress(event, input2Ref)
                            // }
                            // onChange={UseInEcom}
                            // type="text"

                            // value={inputValues.useInEcom}
                            // name="useInEcom"
                            >
                              <option value="">--Select--</option>
                              {parentName ? (
                                parentName.map((data) => (
                                  <option value={data.d2_Id} key={data.d2_Id}>
                                    {data.name}
                                  </option>
                                ))
                              ) : (
                                <option value="" disabled></option>
                              )}
                            </select>
                            <label className="ItemMasterLabelFix">
                              Parent Name{" "}
                            </label>
                          </div>
                        )}

                        {(() => {
                          var val = isOfferScheme ? input7Ref : input8Ref;
                          return (
                            <>
                              {isBarcodeEnable && (
                                <div className="input-box-m11">
                                  <select
                                    className="modify_im_input mode_category"
                                    type="text"
                                    required
                                    onChange={handleInputChange}
                                    value={inputValues.groupBarcodeEnable}
                                    name="groupBarcodeEnable"
                                  >
                                    <option>--Select--</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                  </select>
                                  <label className="ItemMasterLabelFix">
                                    Group barCode Enable
                                  </label>
                                </div>
                              )}
                            </>
                          );
                        })()}

                        {(() => {
                          var Val = isSaleDiscount ? input8Ref : input9Ref;
                          return (
                            <>
                              {isOfferScheme && (
                                <div className="input-box-m11">
                                  <input
                                    className="modify_im_input mode_category"
                                    ref={input7Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, Val)
                                    }
                                    type="text"
                                    required
                                    onChange={handleInputChange}
                                    value={inputValues.itemIncentiveSalesman}
                                    name="itemIncentiveSalesman"
                                  />
                                  <label className="modifyTextLabel">
                                    Item Incentive (Salesman){" "}
                                  </label>
                                </div>
                              )}
                            </>
                          );
                        })()}
                      </div>
                    )}

                    {ItemGroupMaster && (
                      <div className="">
                        {isSaleDiscount && (
                          <div className="input-box-Im input-height3">
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_category"
                                type="number"
                                required
                                onChange={handleInputChange}
                                value={inputValues.saleDiscPer}
                                name="saleDiscPer"
                                ref={input9Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input10Ref)
                                }
                              />
                              <label className="modify_p_text2">
                                Sale Discount %
                              </label>
                            </div>
                            <div className="input-box-m1 ">
                              <div className="input-box2 check_box_b1">
                                <input
                                  style={{ boxShadow: "none" }}
                                  className="modify_product_checkbox"
                                  type="checkbox"
                                  onChange={handleInputChangeActive}
                                  value={inputValues.saleDisc_Applyforall}
                                  checked={inputValues.saleDisc_Applyforall}
                                  name="saleDisc_Applyforall"
                                />
                                <label className="modify_p_text_checkbox">
                                  {" "}
                                  Apply for all products
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {isPurchaseDiscount && (
                          <div className="input-box-Im input-height3">
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_category"
                                type="number"
                                required
                                onChange={handleInputChange}
                                value={inputValues.purDiscPer}
                                name="purDiscPer"
                                ref={input10Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input11Ref)
                                }
                              />
                              <label className="modify_p_text2">
                                Puchase Discount
                              </label>
                            </div>
                            <div className="input-box-m1 ">
                              <div className="input-box2 check_box_b1">
                                <input
                                  style={{ boxShadow: "none" }}
                                  className="modify_product_checkbox"
                                  type="checkbox"
                                  onChange={handleInputChangeActive}
                                  value={inputValues.purchaseDisc_Applyforall}
                                  checked={inputValues.purchaseDisc_Applyforall}
                                  name="purchaseDisc_Applyforall"
                                  ref={input11Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input12Ref)
                                  }
                                />
                                <label className="modify_p_text_checkbox">
                                  {" "}
                                  Apply for all products
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="input-box-Im input-height3">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              value={inputValues.hsnCode}
                              name="hsnCode"
                              ref={input12Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input13Ref)
                              }
                            />
                            <label className="modify_p_text2">HSN Code</label>
                          </div>
                          <div className="input-box-m1 ">
                            {(() => {
                              var val = isLoyaltyPoint
                                ? input14Ref
                                : input16Ref;
                              return (
                                <div className="input-box2 check_box_b1">
                                  <input
                                    style={{ boxShadow: "none" }}
                                    className="modify_product_checkbox"
                                    type="checkbox"
                                    onChange={handleInputChangeActive}
                                    value={inputValues.hsnCode_Applyforall}
                                    checked={inputValues.hsnCode_Applyforall}
                                    name="hsnCode_Applyforall"
                                    ref={input13Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, val)
                                    }
                                  />
                                  <label className="modify_p_text_checkbox">
                                    {" "}
                                    Apply for all products
                                  </label>
                                </div>
                              );
                            })()}
                          </div>
                        </div>
                        {isLoyaltyPoint && (
                          <div className="input-box-Im input-height3">
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_category"
                                type="number"
                                required
                                onChange={handleInputChange}
                                value={inputValues.loyaltyPoint}
                                name="loyaltyPoint"
                                ref={input14Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input15Ref)
                                }
                              />
                              <label className="modify_p_text2">
                                Loyalty Point
                              </label>
                            </div>
                            <div className="input-box-m1 ">
                              <div className="input-box2 check_box_b1">
                                <input
                                  style={{ boxShadow: "none" }}
                                  className="modify_product_checkbox"
                                  type="checkbox"
                                  onChange={handleInputChangeActive}
                                  value={inputValues.loyaltyPoin_Applyforall}
                                  checked={inputValues.loyaltyPoin_Applyforall}
                                  name="loyaltyPoin_Applyforall"
                                  ref={input15Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input16Ref)
                                  }
                                />
                                <label className="modify_p_text_checkbox">
                                  {" "}
                                  Apply for all products
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="input-box-Im input-height3">
                      <div className="input-box-m1">
                        <select
                          className="modify_im_input mode_limit mode_category"
                          type="text"
                          required
                          onChange={handleInputChangeForTax}
                          value={taxData.tax1}
                          name="tax1"
                        >
                          <option value="">--Select--</option>
                          {taxCategoryName ? (
                            taxCategoryName.map((data) => (
                              <option value={data.id} key={data.id}>
                                {data.name}
                              </option>
                            ))
                          ) : (
                            <option value="" disabled>Loading...</option>
                          )}
                        </select>
                        <label className="modify_lbl_text3">
                          Tax Category 1
                        </label>
                      </div>
                      <div className="input-box-m1 ">
                        <div className="input-box2 check_box_b1">
                          <input
                            style={{ boxShadow: "none" }}
                            className="modify_product_checkbox"
                            type="checkbox"
                            onChange={handleInputChangeActive}
                            value={inputValues.taxCategory_Applyforall}
                            checked={inputValues.taxCategory_Applyforall}
                            name="taxCategory_Applyforall"
                            ref={input8Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input9Ref)
                            }
                          />
                          <label className="modify_p_text_checkbox">
                            {" "}
                            Apply for all products
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ padding: 0 }}
                      className="HeadContentItemgroup"
                    >
                      {isLimitUpto && (
                        <div className="input-box-m11">
                          <input
                            className="modify_im_input mode_limit mode_category"
                            type="number"
                            onChange={handleInputChangeForTax}
                            value={taxData.tax1Range}
                            name="tax1Range"
                            ref={input18Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input19Ref)
                            }
                          />
                          <label className="modifyTextLabel ">
                            Categ.1 limit upto(?)
                          </label>
                        </div>
                      )}
                      {isTaxCategory2 && (
                        <div className="input-box-m11">
                          <select
                            className="modify_im_input mode_limit mode_category"
                            type="text"
                            required
                            onChange={handleInputChangeForTax}
                            value={taxData.tax2}
                            name="tax2"
                            ref={input19Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input20Ref)
                            }
                          >
                            <option value="">--Select--</option>
                            {taxCategoryName ? (
                              taxCategoryName.map((data) => (
                                <option value={data.id} key={data.id}>
                                  {data.name}
                                </option>
                              ))
                            ) : (
                              <option value="" disabled>Loading...</option>
                            )}
                          </select>
                          <label className="ItemMasterLabelFix">
                            Tax Category 2
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="HeadContentItemgroup" style={{ padding: "8px 0px" }}>

                      <div className="input-box-m11">
                        <input
                          className="modify_im_input mode_limit mode_category"
                          type="date"
                          style={{ width: "98%" }}
                          required
                          onChange={handleInputChangeForTax}
                          // value={taxData.changeApplyFromDate}
                          value={taxData.changeApplyFromDate.split("T")[0]}
                          name="changeApplyFromDate"
                          ref={input20Ref}
                          onKeyDown={(event) => handleKeyPress(event, input21Ref)}
                        />
                        <label className="ItemMasterLabelFix">
                          Applied Tax Date
                        </label>
                      </div>

                      <div className="input-box-m11" style={{ display: "flex", alignItems: "center" }}>
                        <div className="modify_product_checkbox" onClick={handleAddRowWithData}>
                          <i className="fa-solid fa-square-plus" style={{ marginRight: "10px" }}></i>
                        </div>
                        &nbsp;Add rows with value
                      </div>
                    </ div>

                    {/* ********************{old}****************** */}
                    {ItemGroupMaster && (
                      <div className="">
                        {isInputVisiblam3 && (
                          <div>
                            <div className="company_info-text-im">
                              Tax Category Date Wise Details
                            </div>
                            <div className="tableOverflowItemGroup">
                              <table className="table-t">
                                <thead
                                  style={{ background: "#314989" }}
                                  className="thead-groupmaster"
                                >

                                  <th
                                    // style={{ minWidth: "45px" }}
                                    className="table-thIG1"
                                  >
                                    Sn No
                                  </th>
                                  <th className="table-thIG1">Change From</th>
                                  <th className="table-thIG1">Tax Catg 1</th>
                                  <th className="table-thIG1">Range</th>
                                  <th className="table-thIG1">Tax Catg 2</th>
                                  <th
                                    // style={{ minWidth: "40px" }}
                                    className="table-thIG1"
                                  >
                                    Action
                                  </th>

                                </thead>
                                <tbody>
                                  {/* {rows.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                      <td style={{ textAlign: "center" }}>
                                        {rowIndex + 1}
                                      </td>
                                      <td>
                                        <input
                                          className="table-input-12"
                                          type="text"
                                          name={`changeFrom-${rowIndex}`}
                                          value={
                                            row[`changeFrom-${rowIndex}`] || ""
                                          }
                                          onChange={(e) =>
                                            handleInputChange1(rowIndex, e)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="table-input-12"
                                          type="text"
                                          name={`taxCatg1-${rowIndex}`}
                                          value={
                                            row[`taxCatg1-${rowIndex}`] || ""
                                          }
                                          onChange={(e) =>
                                            handleInputChange1(rowIndex, e)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="table-input-12"
                                          type="text"
                                          name={`range-${rowIndex}`}
                                          value={row[`range-${rowIndex}`] || ""}
                                          onChange={(e) =>
                                            handleInputChange1(rowIndex, e)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="table-input-12"
                                          type="text"
                                          name={`taxCatg2-${rowIndex}`}
                                          value={
                                            row[`taxCatg2-${rowIndex}`] || ""
                                          }
                                          onChange={(e) =>
                                            handleInputChange1(rowIndex, e)
                                          }
                                        />
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        <button
                                          className="btn-table1"
                                          onClick={() =>
                                            handleDeleteRow(rowIndex)
                                          }
                                        >
                                          <i className="fa-solid fa-trash delete-btn"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))} */}

                                  {rows.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                      <td style={{ textAlign: "center" }}>
                                        {rowIndex + 1}
                                      </td>
                                      <td>
                                        <input
                                          className="table-input-12"
                                          type="date"
                                          name="changeApplyFromDate"
                                          value={(rows[rowIndex]?.["changeApplyFromDate"] || "").split("T")[0]}
                                          onChange={(e) => handleInputChange1(rowIndex, e)}
                                        />
                                      </td>
                                      <td>
                                        <select
                                          className="modify_im_input mode_limit mode_category"
                                          required
                                          onChange={(e) =>
                                            handleInputChange1(rowIndex, e)}
                                          value={rows[rowIndex]?.["tax1"] || ""}
                                          name="tax1"
                                        >
                                          <option value="">--Select--</option>
                                          {taxCategoryName ? (
                                            taxCategoryName.map((data) => (
                                              <option value={data.id} key={data.id}>
                                                {data.name}
                                              </option>
                                            ))
                                          ) : (
                                            <option value="" disabled>Loading...</option>
                                          )}
                                        </select>

                                      </td>
                                      <td>
                                        <input
                                          className="table-input-12"
                                          type="number"
                                          name="tax1Range"
                                          value={
                                            rows[rowIndex]?.["tax1Range"] || ""
                                          }
                                          onChange={(e) =>
                                            handleInputChange1(rowIndex, e)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <select
                                          className="modify_im_input mode_limit mode_category"
                                          required
                                          onChange={(e) =>
                                            handleInputChange1(rowIndex, e)}
                                          value={rows[rowIndex]?.["tax2"] || ""}
                                          name="tax2"
                                        >
                                          <option value="">--Select--</option>
                                          {taxCategoryName ? (
                                            taxCategoryName.map((data) => (
                                              <option value={data.id} key={data.id}>
                                                {data.name}
                                              </option>
                                            ))
                                          ) : (
                                            <option value="" disabled>Loading...</option>
                                          )}
                                        </select>
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        <button
                                          style={{ marginLeft: "0" }}
                                          className="btn-table1"
                                          onClick={() =>
                                            handleDeleteRow(rowIndex)
                                          }
                                        >
                                          <i className="fa-solid fa-trash delete-btn"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div
                                className="input-box2 check_box_b1"
                                onClick={handleAddRow}
                              >
                                <div className="modify_product_checkbox">
                                  <i className="fa-solid fa-square-plus"></i>
                                </div>

                                <label className="modify_p_text_checkbox">
                                  ADD ROWS
                                </label>
                              </div>
                              <div className="input-box2 check_box_b1">
                                <input
                                  style={{ boxShadow: "none" }}
                                  className="modify_product_checkbox"
                                  type="checkbox"
                                />
                                <label className="modify_p_text_checkbox">
                                  {" "}
                                  Alert Negative Qty
                                </label>
                              </div>
                              <div className="input-box2 check_box_b1">
                                <input
                                  style={{ boxShadow: "none" }}
                                  className="modify_product_checkbox"
                                  type="checkbox"
                                />
                                <label className="modify_p_text_checkbox">
                                  {" "}
                                  Restrict Negative Qty
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {ItemGroupMaster && (
                    <div
                      className="company-info-top-right"
                      style={{ width: "50%" }}
                    >
                      {isModalOpenEcom && (
                        <div>
                          <div className="company_info-text-im">
                            Ecommerce Details
                          </div>
                          <div className="">
                            <div className="input-box-Im input-height2">
                              <div className="input-box-m1">
                                <input
                                  className="modify_im_input mode_category"
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.menuName}
                                  name="menuName"
                                  ref={input21Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input22Ref)
                                  }
                                />
                                <label className="modify_p_text2">
                                  Menu Name
                                  {/* <span className="StarClr">
                            *
                            {errorMessages.menuName && (
                              <label className="errorMessage">
                                {errorMessages.menuName}
                              </label>
                            )}
                          </span> */}
                                </label>
                              </div>

                              <div className="input-box-m1">
                                <select
                                  className="modify_im_input mode_limit mode_category"
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.showInMenu}
                                  name="showInMenu"
                                  ref={input22Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input23Ref)
                                  }
                                >
                                  <option value="false">No</option>
                                  <option value="true">Yes</option>
                                </select>
                                <label className="modify_lbl_text4">
                                  Show In Menu{" "}
                                </label>
                              </div>
                            </div>

                            <div className="input-box-Im input-height2">
                              <div className="input-box-m1">
                                <input
                                  className="modify_im_input mode_category"
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.displayOrder}
                                  name="displayOrder"
                                  ref={input23Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input24Ref)
                                  }
                                />
                                <label className="modify_p_text2">
                                  Display Order
                                </label>
                              </div>

                              <div className="input-box-m1">
                                <select
                                  className="modify_im_input mode_limit mode_category"
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.showInHomePage}
                                  name="showInHomePage"
                                  ref={input24Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input25Ref)
                                  }
                                >
                                  <option value="false">No</option>
                                  <option value="true">Yes</option>
                                </select>
                                <label className="modify_lbl_text4">
                                  Show In Home Page{" "}
                                </label>
                              </div>
                            </div>
                            <div className="input-box-Im input-height2">
                              <div className="input-box-m1">
                                <select
                                  className="modify_im_input mode_category"
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.showInCollection}
                                  name="showInCollection"
                                  ref={input25Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input26Ref)
                                  }
                                >
                                  <option value="false">No</option>
                                  <option value="true">Yes</option>
                                </select>
                              </div>
                              <label className="modify_lbl_text3">
                                Show In Collection (Y/N)
                              </label>
                              <div className="input-box-m1">
                                <select
                                  className="modify_im_input mode_limit mode_category"
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.showInAddBanner}
                                  name="showInAddBanner"
                                  ref={input26Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input27Ref)
                                  }
                                >
                                  <option value="false">No</option>
                                  <option value="true">Yes</option>
                                </select>
                                <label className="modify_lbl_text4">
                                  Show In Ad. banner
                                </label>
                              </div>
                            </div>
                            <div className="input-box-Im input-height2">
                              <div className="input-box-m1">
                                <input
                                  className="modify_im_input mode_category"
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.nameInHomePage}
                                  name="nameInHomePage"
                                  ref={input27Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input28Ref)
                                  }
                                />
                                <label className="modify_p_text2">
                                  Name In Home Page/Collection
                                </label>
                              </div>
                              <div className="input-box-m1 ">
                                <input
                                  className="modify_im_input mode_limit mode_category"
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.priorityToShowInHomePage}
                                  name="priorityToShowInHomePage"
                                  ref={input28Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input29Ref)
                                  }
                                />
                                <label className="modify_lbl_text2">
                                  Priority To Show In Home Page
                                </label>
                              </div>
                            </div>
                            <div className="input-box-Im input-height2">
                              <div className="input-box-m1">
                                <input
                                  className="modify_im_input mode_category"
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.imageShowOnError}
                                  name="imageShowOnError"
                                  ref={input29Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input30Ref)
                                  }
                                />
                                <label className="modify_p_text2">
                                  Menu Order (1/2)
                                </label>
                              </div>

                              {isPerentGroup && (
                                <div className="input-box-m1">
                                  <select
                                    className="modify_im_input mode_limit mode_category"
                                    type="text"
                                    required
                                    onChange={handleInputChange}
                                    value={inputValues.underMenu}
                                    name="underMenu"
                                    ref={input24Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, input25Ref)
                                    }
                                  >
                                    <option value="">Select Under Menu</option>
                                    {parentName.map((data) => (
                                      <React.Fragment key={data.d2_Id}>
                                        {data.parentGrp === 0 && (
                                          <option value={data.d2_Id}>
                                            {data.name}
                                          </option>
                                        )}

                                        {data.parentGrpName &&
                                          data.parentGrp !== 0 && (
                                            <option value={`${data.d2_Id}`}>
                                              {`${data.parentGrpName} > ${data.name}`}
                                            </option>
                                          )}
                                      </React.Fragment>
                                    ))}
                                  </select>
                                  <label className="modify_lbl_text4">
                                    Under Menu
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="company_info-text-im">
                            Meta Details
                          </div>
                          <div className="">
                            <div className="input-box-tab-head">
                              <div className="input-box-m2">
                                <textarea
                                  className="modify_im_textarea mode_category2"
                                  type="text"
                                  placeholder="title should less then 100 characters"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.metaTitle}
                                  name="metaTitle"
                                  ref={input30Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input31Ref)
                                  }
                                />
                                <label className="modify_p_text-fix">
                                  Meta Title
                                </label>
                              </div>
                            </div>
                            <div className="input-box-tab-head">
                              <div className="input-box-m2">
                                <textarea
                                  className="modify_im_textarea mode_category2"
                                  type="text"
                                  placeholder="20 different search keywords(Less then 1000 characters)"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.metaKeywords}
                                  name="metaKeywords"
                                  ref={input31Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input32Ref)
                                  }
                                />
                                <label className="modify_p_text-fix">
                                  Meta Keywords
                                </label>
                              </div>
                            </div>
                            <div className="input-box-tab-head">
                              <div className="input-box-m2">
                                <textarea
                                  className="modify_im_textarea mode_category2"
                                  type="text"
                                  placeholder="description should less then 200 characters"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.metaDescription}
                                  name="metaDescription"
                                  ref={input32Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input33Ref)
                                  }
                                />
                                <label className="modify_p_text-fix">
                                  Meta Description
                                </label>
                              </div>
                            </div>

                            <div className="input-box-Im input-height2">
                              <div className="input-box-m1">
                                <input
                                  className="modify_im_input mode_category"
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  value={
                                    inputValues.searchEnginFriendlyPageName
                                  }
                                  name="searchEnginFriendlyPageName"
                                  ref={input33Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input34Ref)
                                  }
                                />
                                <label className="modify_p_text2">
                                  Search Engine Friendly PageName
                                </label>
                              </div>
                              <div className="input-box-m1 ">
                                <input
                                  className="modify_im_input mode_limit mode_category"
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.menuUrl}
                                  name="menuUrl"
                                  ref={input34Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input35Ref)
                                  }
                                />
                                <label className="modify_lbl_text2">
                                  Menu Url Link
                                </label>
                              </div>
                            </div>
                            <div className="input-box-Im1">
                              <p
                                className="modify_p_2"
                                style={{ marginBottom: "17px" }}
                              >
                                Product Images
                              </p>
                              {/* <input
                                type="file"
                                src=""
                                alt=""
                                onChange={ItemImageUpload}
                              /> */}
                              <div className="radio-buttons-container1 m2 overstack">
                                <ImageUploadWithPreview
                                  id={"Image1"}
                                  ItemImageUpload={(event) => ItemImageUpload(event, ItemImage.length)}
                                  imageIds={imageIds}
                                  imgIndex={0}
                                  ItemImage={ItemImage}
                                  setItemImage={setItemImage}
                                />
                                <ImageUploadWithPreview
                                  id={"Image2"}
                                  ItemImageUpload={(event) => ItemImageUpload(event, ItemImage.length)}
                                  imageIds={imageIds}
                                  imgIndex={1}
                                  ItemImage={ItemImage}
                                  setItemImage={setItemImage}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3" onClick={showModal}>
                <i className="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Item Group Master is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}
      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Item Group Master is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}

      {alertBoxErrorUpdate && (
        <AlertBoxError
          HeadingTop="ERROR"
          Heading="Error While Updating Data"
          onYesClick={OpenAlertBoxErrorUpdate}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxErrorUpdate}
        />
      )}
      {alertBoxErrorInsert && (
        <AlertBoxError
          HeadingTop="ERROR"
          Heading="Error While Inserting Data"
          onYesClick={OpenAlertBoxErrorInsert}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxErrorInsert}
        />
      )}
      {openAlertItemExists && (
        <AlertBoxError
          HeadingTop="Item Group already exists"
          Heading="Try With Different Item Name"
          onYesClick={OpenAlertBoxWhenItemExists}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxWhenItemExists}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default ItemGroupMaster;
