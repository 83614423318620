import React, { useEffect, useState } from "react"

// import { Link } from 'react-router-dom'

const Footer = (props) => {


  const [getValueAndStrg, setGetValueAndStrg] = useState([])


  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    // Store only unique id values in localStorage
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);

  let getId = props.keyValueId;
  const footerview = () => {
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }

  }



  return (
    <div className="footer-website-content-web-template" id={getId} onClick={footerview}>
      <div className="footer-content-1-web-template">

      </div>
      <div className="footer-content-2-web-template">

      </div>
      <div className="footer-content-3-web-template">

      </div>
      <div className="footer-content-4-web-template">

      </div>
    </div>
  )
}

export default Footer