import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const ItemGroup = {


  InsertItemGroup: async (data , rows) => {
    console.log("InsertItemGroup"+data)
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      const apiUrl = `${WebApiUrl}/api/ItemGroup`;
      const response = await axios.post(
        apiUrl,
        {
          name: data.name,
          printName: data.printName,
          primaryGroup: parseBoolean(data.primaryGroup),
          parentGrp: Number(data.parentGrp),
          useInEcom: parseBoolean(data.useInEcom),
          itemIncentiveSalesman: Number(data.itemIncentiveSalesman),
          taxCategory_Applyforall: parseBoolean(data.taxCategory_Applyforall),
          saleDisc_Applyforall: parseBoolean(data.saleDisc_Applyforall),
          purchaseDisc_Applyforall: parseBoolean(data.purchaseDisc_Applyforall),
          hsnCode_Applyforall: parseBoolean(data.hsnCode_Applyforall),
          loyaltyPoin_Applyforall: parseBoolean(data.loyaltyPoin_Applyforall),
          groupBarcodeEnable: parseBoolean(data.groupBarcodeEnable),
          saleDiscPer: Number(data.saleDiscPer),
          purDiscPer: Number(data.purDiscPer),
          hsnCode: data.hsnCode,
          loyaltyPoint: Number(data.loyaltyPoint),
          alertNegativeQty: parseBoolean(data.alertNegativeQty),
          restrictNegativeQty: parseBoolean(data.restrictNegativeQty),
          menuName: data.menuName,
          underMenu: Number(data.underMenu),
          showInMenu: parseBoolean(data.showInMenu),
          displayOrder: Number(data.displayOrder),
          showInHomePage: parseBoolean(data.showInHomePage),
          showInCollection: parseBoolean(data.showInCollection),
          showInAddBanner: parseBoolean(data.showInAddBanner),
          nameInHomePage: data.nameInHomePage,
          priorityToShowInHomePage: Number(data.priorityToShowInHomePage),
          imageShowOnError: data.imageShowOnError,
          metaTitle: data.metaTitle,
          metaKeywords: data.metaKeywords,
          metaDescription: data.metaDescription,
          searchEnginFriendlyPageName: data.searchEnginFriendlyPageName,
          menuUrl: data.menuUrl,
          isActive: parseBoolean(data.isActive),
          taxCategory: rows,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return response.data;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetItemGroup: async () => {
    try {
 
      const apiUrl = `${WebApiUrl}/api/ItemGroup/GetItemGroups`;
      const response = await axios.post(
        apiUrl,
        { filter: [], order: [], attribute: [], pageNumber: 0, pageSize: 5000 },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetItemGroupFilter: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemGroup/GetItemGroups`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [],
          order: [
            {
              propertyName: "d1_Id",
              ascending: false,
            },
          ],
          pageNumber: 0,
          pageSize: 500000,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  //*******************************************Get Item Group With Filter********************************************************** */
  GetItemGroupWithFilter: async (filteredData) => {
    const filtData = filteredData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue,
    }));

    try {
      const apiUrl = `${WebApiUrl}/api/ItemGroup/GetItemGroups`;
      const response = await axios.post(
        apiUrl,
        {
          filter: filtData,
          order: [
            {
              propertyName: "d1_Id",
              ascending: false,
            },
          ],
          pageNumber: 0,
          pageSize: 500000,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  ItemGroupImageUpload: async (data) => {
    console.log(data)
    try {
      // Assuming 'data' is a FormData object with appended fields
      const apiUrl = `${WebApiUrl}/api/ItemGroup/SaveImage`;

      const response = await axios.post(apiUrl,
        data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',  // Set Content-Type for FormData
        },
      });

      console.log("Response:", response.data.isSuccess);
      return response.data.isSuccess;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetItemGroupById: async (ItemGroupId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemGroup?ItemGroupId=${ItemGroupId}`;
      const response = await axios.get(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      //setEditCallBack(response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  GetItemGroupByD2Id: async (ItemGroupId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemGroup/ForD2Id?ItemGroupId=${ItemGroupId}`;
      const response = await axios.get(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      //setEditCallBack(response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  deleteItemGroup: async (ItemGroupId) => {
    console.log(ItemGroupId)
    try {
      console.log("delete");
      const apiUrl = `${WebApiUrl}/api/ItemGroup?ItemGroupId=${ItemGroupId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.isSuccess);
      return response.data.isSuccess;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  updateItemGroup: async (data , rows) => {
    function parseBoolean(v) {
      if (typeof v === "boolean") {   
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    console.log(data);
    try {
      const apiUrl = `${WebApiUrl}/api/ItemGroup?ItemGroupId=${data.d1_Id}`;
      const response = await axios.put(
        apiUrl,
        {
          d1_Id: data.d1_Id,
          d2_Id: data.d2_Id,
          name: data.name,
          printName: data.printName,
          primaryGroup: parseBoolean(data.primaryGroup),
          parentGrp: Number(data.parentGrp),
          useInEcom: parseBoolean(data.useInEcom),
          itemIncentiveSalesman: Number(data.itemIncentiveSalesman),
          taxCategory_Applyforall: parseBoolean(data.taxCategory_Applyforall),
          saleDisc_Applyforall: parseBoolean(data.saleDisc_Applyforall),
          purchaseDisc_Applyforall: parseBoolean(data.purchaseDisc_Applyforall),
          hsnCode_Applyforall: parseBoolean(data.hsnCode_Applyforall),
          loyaltyPoin_Applyforall: parseBoolean(data.loyaltyPoin_Applyforall),
          groupBarcodeEnable: parseBoolean(data.groupBarcodeEnable),
          saleDiscPer: Number(data.saleDiscPer),
          purDiscPer: Number(data.purDiscPer),
          hsnCode: data.hsnCode,
          loyaltyPoint: Number(data.loyaltyPoint),
          alertNegativeQty: parseBoolean(data.alertNegativeQty),
          restrictNegativeQty: parseBoolean(data.restrictNegativeQty),
          menuName: data.menuName,
          underMenu: Number(data.underMenu),
          showInMenu: parseBoolean(data.showInMenu),
          displayOrder: Number(data.displayOrder),
          showInHomePage: parseBoolean(data.showInHomePage),
          showInCollection: parseBoolean(data.showInCollection),
          showInAddBanner: parseBoolean(data.showInAddBanner),
          nameInHomePage: data.nameInHomePage,
          priorityToShowInHomePage: Number(data.priorityToShowInHomePage),
          imageShowOnError: data.imageShowOnError,
          metaTitle: data.metaTitle,
          metaKeywords: data.metaKeywords,
          metaDescription: data.metaDescription,
          searchEnginFriendlyPageName: data.searchEnginFriendlyPageName,
          menuUrl: data.menuUrl,
          isActive: parseBoolean(data.isActive),
          taxCategory: rows,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return response.data;

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // ************************* Create Item Master Setting ***************************    
  insertItemGroupMasterSetting: async (settingData) => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemGroup/CreateItemGroupMasterSetting`;
      console.log(apiUrl);
      console.log(settingData);
      const response = await axios.post(apiUrl, {
        printName: settingData.printName,
        taxCategory2: settingData.taxCategory2,
        saleDiscount: settingData.saleDiscount,
        purchaseDiscount: settingData.purchaseDiscount,
        loyaltyPoint: settingData.loyaltyPoint,
        allowBarcodeEnable: settingData.allowBarcodeEnable,
        itemIncentive: settingData.itemIncentive,
        allowCategoryLimitUpto: settingData.allowCategoryLimitUpto,
        taxCategoryDatewiseDetails: settingData.taxCategoryDatewiseDetails
      }, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log("Response:", response.data);
      return true;

    } catch (error) {
      console.error("Error:", error.message);
      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // ************************* Get Account Master Setting ***************************
  getItemGroupMasterSetting: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemGroup/GetItemGroupMasterSettings`;
      const response = await axios.post(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
      console.error('Error:', error.message);
    }
  },
};

export default ItemGroup;
