import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import { FaBars, FaHome, FaLock, FaMoneyBill, FaUser } from "react-icons/fa";
import ShortCutModal from './ShortCutModal';
import DynamicShortcutButton from '../../API/APIServices/DynamicShortcutButton';
import { Link } from 'react-router-dom';
import { color } from 'framer-motion';
const SmallSideBar = () => {
  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(true)
  }


  const closeModel = () => {
    setModal(false)
  }


  const [DynamicButtons, setDynamicButtons] = useState([]);



  const GetButtons = async () => {
    debugger
    const arrayString = localStorage.getItem('loginUser');
    const arrayToStore = JSON.parse(arrayString);
    if (arrayToStore) {
      var data = await DynamicShortcutButton.getDynamicShortcutButton()
      setDynamicButtons(data)
    }
  }
  useEffect(() => {
    GetButtons()
  }, [])


  return (
    <>
      <div className="smallsbaricon-container">
        {DynamicButtons.map(items => (<div>
          <Link style={{ color: "black" }} to={`/${items.formName}`}>
          {/* <Link style={{ color: "black" }} to={items.formLink}> */}
            <button className='sidebar-button-dynamic'>
              <i className={items.description}></i>
              {items.name}
            </button>
          </Link >
        </div>
        )
        )}
        <AiOutlinePlus className="design-smallsbar" onClick={openModal} />
      </div >



      {modal && (<ShortCutModal close={closeModel} GetButtonsMainPage={GetButtons} />)
      }
    </>
  )
}

export default SmallSideBar
