import React, { useContext, useEffect, useState } from "react";
import ItemMasterApi from "../../../API/APIServices/ItemMaster";
import MyContext from "../../MyContext";
import ItemMaster from "./ItemMaster";
import Loader from "../../Props/Loader";
import PageTabs from "../../../Inventory/Components/PageTabs";
import ItemDepartmentApi from "../../../API/APIServices/ItemDepartment";
import "bootstrap/dist/css/bootstrap.min.css";
import MainButton from "../../Props/MainButton";
import ItemGroup from "../../../API/APIServices/ItemGroup";
import ImageLoader from "../../Components/ImageLoader";
import MiscellaneousMaster from "../../../API/APIServices/MiscellaneousMaster";

// import Loader from "../../Inventory/Props/Loader";

const ProductStock_Master = () => {

    const { UserDetails } = useContext(MyContext)


    console.log(UserDetails)
    const [itemMasterList, setItemMasterList] = useState([]);
    const [PreprationTimeList, setPreprationTimeList] = useState([]);
    const [ItemDepartmentList, setItemDepartmentList] = useState([]);
    const [isSliderOpen, setSliderOpen] = useState(false);
    const [ItemCategory, setItemCategory] = useState([]);
    const [inputValues, setInputValues] = useState({});

    const handleChange = (id, event) => {
        setInputValues({
            ...inputValues,
            [id]: event.target.value,
        });
    };
    const [DetailModal, setDetailModal] = useState(false);
    const [editData, setEditData] = useState("");

    const [loading, setLoading] = useState(false);
    const { imageBaseUrl } = useContext(MyContext);
    const toggleSlider = (value) => {
        //   console.log(editData);
        setSliderOpen(value);
    };

    const Edit = async (id) => {
        const data = await ItemMasterApi.GetItemMasterById(id);
        console.log(data);
        setEditData(data);
        toggleSlider(true);
    };


    useEffect(() => {
        // Initialize an empty object for storing available times by d2Id
        const initialAvailableTimes = {};

        // Loop through each item in itemMasterList
        itemMasterList.forEach(item => {
            // Check if item has a d2Id
            if (item.d2Id) {
                // Check if item.restoAvailableTime is an array and contains non-default values
                const isValidTimes = Array.isArray(item.restoAvailableTime) &&
                    item.restoAvailableTime.some(time =>
                        time.availableTimefrom !== "--:--:--" &&
                        time.availableTimeTo !== "--:--:--" &&
                        time.availableTimefrom !== null &&
                        time.availableTimeTo !== null
                    );

                if (isValidTimes) {
                    // Set initialAvailableTimes for each d2Id
                    initialAvailableTimes[item.d2Id] = item.restoAvailableTime;
                }
            }
        });

        // Update state with the transformed data
        setRestoAvailableTimes(initialAvailableTimes);
        const fetchItemDepartment = async () => {
            try {
                const val = await ItemDepartmentApi.getItemDepartment();
                setItemDepartmentList(val);
            } catch (error) {
                console.error("Error fetching account groups:", error);
            }
        };

        fetchItemDepartment()
    }, [itemMasterList]);


    const [restoAvailableTimes, setRestoAvailableTimes] = useState({});

    useEffect(() => {
        console.log(restoAvailableTimes);
    }, [restoAvailableTimes])


    const handleInputChangeTime2 = (d2Id, rowIndex, e) => {
        // Ensure the d2Id entry exists
        const updatedRows = restoAvailableTimes[d2Id] ? [...restoAvailableTimes[d2Id]] : [];

        // Update or initialize the row
        if (rowIndex >= updatedRows.length) {
            updatedRows.push({ availableTimefrom: "", availableTimeTo: "" });
        }
        updatedRows[rowIndex].availableTimefrom = e.target.value;

        // Update state
        setRestoAvailableTimes({ ...restoAvailableTimes, [d2Id]: updatedRows });
    };

    const handleInputChangeTime = (d2Id, rowIndex, e) => {
        // Ensure the d2Id entry exists
        const updatedRows = restoAvailableTimes[d2Id] ? [...restoAvailableTimes[d2Id]] : [];

        // Update or initialize the row
        if (rowIndex >= updatedRows.length) {
            updatedRows.push({ availableTimefrom: "", availableTimeTo: "" });
        }
        updatedRows[rowIndex].availableTimeTo = e.target.value;

        // Update state
        setRestoAvailableTimes({ ...restoAvailableTimes, [d2Id]: updatedRows });
    };

    const handleAddRowTime = (d2Id) => {
        // Ensure the d2Id entry exists
        const updatedRows = restoAvailableTimes[d2Id] ? [...restoAvailableTimes[d2Id]] : [];

        // Add a new row
        updatedRows.push({ availableTimefrom: "", availableTimeTo: "" });

        // Update state
        setRestoAvailableTimes({ ...restoAvailableTimes, [d2Id]: updatedRows });
    };

    const handleDeleteRowTime = (d2Id, index) => {
        // Ensure the d2Id entry exists
        const updatedRows = restoAvailableTimes[d2Id] ? [...restoAvailableTimes[d2Id]] : [];

        // Filter out the row to be deleted
        const filteredRows = updatedRows.filter((_, i) => i !== index);

        // Ensure there is at least one row
        const finalRows = filteredRows.length > 0 ? filteredRows : [{ availableTimefrom: "", availableTimeTo: "" }];

        // Update state
        setRestoAvailableTimes({ ...restoAvailableTimes, [d2Id]: finalRows });
    };



    useEffect(() => {
        const findGroup = async () => {
            setLoading(true);
            var itemGroupData = await ItemGroup.GetItemGroup();
            // debugger;
            if (itemGroupData.length > 0) {
                setItemCategory(itemGroupData);
                setLoading(false);
            }
        };
        findGroup();
        const fetchPreprationTime = async () => {
            try {
                const val = await MiscellaneousMaster.GetMiscellaneousMaster(171);
                // console.log(val);
                setPreprationTimeList(val);
            } catch (error) {
                console.error("Error fetching account groups:", error);
                // Optionally, you can set some state to indicate an error or display an error message.
            }
        };
        fetchPreprationTime();
        // setLoading(false)
    }, []);

    const [Filter1, setFilter1] = useState(0);

    const [Filter2, setFilter2] = useState(0);

    const [Filter3, setFilter3] = useState(0);

    const FilterDataset1 = (e) => {
        var value = e.target.value;
        setFilter1(value);
    };

    const FilterDataset2 = (e) => {
        var value = e.target.value;
        setFilter2(value);
    };

    const FilterDataset3 = (e) => {
        var value = e.target.value;
        setFilter3(value);
    };

    const fetchItemMasterList = async () => {
        setLoading(true);
        const filteredData = [];

        // Check if Filter1 is non-zero or non-empty
        if (Filter1 && Filter1 !== "0") {
            filteredData.push({
                fieldName: "parentGrpId",
                operatorName: "equal",
                compareValue: Filter1,
            });
        }

        // Check if Filter2 is non-zero or non-empty
        if (Filter2 && Filter2 !== "0") {
            filteredData.push({
                fieldName: "disableBuyButton",
                operatorName: "equal",
                compareValue: Filter2,
            });
        }

        // Check if Filter3 is non-zero or non-empty
        if (Filter3 && Filter3 !== "0") {
            filteredData.push({
                fieldName: "published",
                operatorName: "equal",
                compareValue: Filter3,
            });
        }

        try {
            const data = await ItemMasterApi.GetItemMasterWithFilter(filteredData);
            if (data.length >= 0) {
                setItemMasterList(data);
            }
        } catch (error) {
            console.error("Error fetching item master list:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchClick = () => {
        fetchItemMasterList();
    };

    const openDetailModal = () => {
        setDetailModal(!DetailModal);
    };

    const UpdateStock = async (D2Id, item, index) => {
        if (!UserDetails.canModifyMaster) {
            alert("You are not authorized to modify this data.");
            return;
        }

        const updateItemById = (itemList, ChildD2Id, updatedData) => {
            return itemList.map(item =>
                item.d2Id === ChildD2Id
                    ? { ...item, ...updatedData }
                    : item
            );
        };

        try {
            // Filter out child items
            const childItems = itemMasterList.filter(element => element.attributeParentId === D2Id);

            let updatedList = [...itemMasterList];

            if (childItems.length > 0) {
                // If child items exist, update them first
                const childUpdatePromises = childItems.map(async (child) => {
                    const isStockCheck = item.stockCheck === 0;
                    const filteredData = [
                        { operationType: 0, path: "StockCheck", op: "Add", from: "string", value: isStockCheck ? 1 : 0 },
                        { operationType: 0, path: "VirtualQuantity", op: "Add", from: "string", value: 0 }
                    ];

                    await ItemMasterApi.UpdateItemMasterPartially(filteredData, child.d2Id);

                    return { d2Id: child.d2Id, data: { stockCheck: isStockCheck ? 1 : 0, virtualQuantity: 0 } };
                });

                const childResults = await Promise.all(childUpdatePromises);

                // Update child items in the list
                childResults.forEach(result => {
                    updatedList = updateItemById(updatedList, result.d2Id, result.data);
                });
            }

            // Update the parent item
            const isStockCheck = item.stockCheck === 0;
            const parentFilteredData = [
                { operationType: 0, path: "StockCheck", op: "Add", from: "string", value: isStockCheck ? 1 : 0 },
                { operationType: 0, path: "VirtualQuantity", op: "Add", from: "string", value: "0" }
            ];

            await ItemMasterApi.UpdateItemMasterPartially(parentFilteredData, D2Id);

            // Update the parent item in the list
            updatedList[index] = { ...itemMasterList[index], stockCheck: isStockCheck ? 1 : 0, virtualQuantity: 0 };

            // Set the updated list in state
            setItemMasterList(updatedList);

            alert("Stock Updated Successfully");
            // Optionally call fetchItemMasterList here if needed

        } catch (error) {
            console.error("Error updating stock:", error);
            alert("An error occurred while updating the stock.");
        }
    };




    const UpdateItemDetail = async (D2Id, previousQty, quantitiyToAdd, index) => {
        const updatedList = [...itemMasterList];
        // if (UserDetails.canModifyMaster) {
        try {
            const filteredData = [
                {
                    operationType: 0,
                    path: "StockCheck",
                    op: "Add",
                    from: "string",
                    value: 1,
                },
                {
                    operationType: 0,
                    path: "VirtualQuantity",
                    op: "Add",
                    from: "string",
                    value: Number(quantitiyToAdd) + Number(previousQty),
                },
            ];

            const data = await ItemMasterApi.UpdateItemMasterPartially(
                filteredData,
                D2Id
            );

            updatedList[index] = {
                ...itemMasterList[index],
                stockCheck: 1,
                virtualQuantity: Number(quantitiyToAdd) + Number(previousQty),
                restoAvailableQuantity: Number(quantitiyToAdd) + Number(previousQty)
            };

            setItemMasterList(updatedList);

            if (data) {
                alert("Stock Updated Successfully");
                // Resetting the input value for the updated item
                setInputValues((prevValues) => ({ ...prevValues, [D2Id]: "" }));
                // fetchItemMasterList(); // Fetch the updated item list
            }
        } catch (error) {
            console.error("Error updating item detail:", error);
            alert("Failed to update stock. Please try again.");
        }


        // }
        // else {
        //     alert("You are not authorized to modify this data.")
        // }

    };

    const handleUpdate = (item, index) => {
        const { d2Id, restoAvailableQuantity } = item;
        const quantitiyToAdd = inputValues[d2Id] || 0;
        UpdateItemDetail(d2Id, restoAvailableQuantity, quantitiyToAdd, index);
    };

    const PartiallyupdateForHomedelivery = async (D2Id, item, index) => {
        let updatedList = [...itemMasterList]; // Use let to allow reassignment

        if (item.attributeParentId === 0 || item.isAttributeItem === true) {
            const childItems = itemMasterList.filter(element => element.attributeParentId === item.d2Id);

            if (childItems.length > 0) {
                const childUpdatePromises = childItems.map(async (child) => {
                    const newValue = !item.availableForHomeDelivery;
                    try {
                        const filteredData = [
                            {
                                operationType: 0,
                                path: "AvailableForHomeDelivery",
                                op: "Add",
                                from: "string",
                                value: newValue,
                            },
                        ];
                        await ItemMasterApi.UpdateItemMasterPartially(filteredData, child.d2Id);

                        return { d2Id: child.d2Id, availableForHomeDelivery: newValue };
                    } catch (error) {
                        console.error(`Error updating item ${child.d2Id} detail:`, error);
                        alert(`Failed to update item ${child.d2Id}. Please try again.`);
                    }
                });

                const childResults = await Promise.all(childUpdatePromises);

                // Update child items in the list
                childResults.forEach(result => {
                    if (result) {
                        updatedList = updatedList.map(item =>
                            item.d2Id === result.d2Id
                                ? { ...item, availableForHomeDelivery: result.availableForHomeDelivery }
                                : item
                        );
                    }
                });
            }
        }

        // Update the parent item
        const newValue = !item.availableForHomeDelivery;
        try {
            const filteredData = [
                {
                    operationType: 0,
                    path: "AvailableForHomeDelivery",
                    op: "Add",
                    from: "string",
                    value: newValue,
                },
            ];

            await ItemMasterApi.UpdateItemMasterPartially(filteredData, D2Id);

            updatedList[index] = {
                ...itemMasterList[index],
                availableForHomeDelivery: newValue,
            };

            setItemMasterList(updatedList);

            alert("Stock Updated Successfully");
            // Optionally call fetchItemMasterList here if needed

        } catch (error) {
            console.error("Error updating item detail:", error);
            alert("Failed to update stock. Please try again.");
        }
    };


    const PartiallyupdateForTableorder = async (D2Id, item, index) => {
        let updatedList = [...itemMasterList]; // Use let to allow reassignment

        if (item.attributeParentId === 0 || item.isAttributeItem === true) {
            const childItems = itemMasterList.filter(element => element.attributeParentId === item.d2Id);

            if (childItems.length > 0) {
                const childUpdatePromises = childItems.map(async (child) => {
                    const newValue = !item.availableForTableOrder;
                    try {
                        const filteredData = [
                            {
                                operationType: 0,
                                path: "AvailableForTableOrder",
                                op: "Add",
                                from: "string",
                                value: newValue,
                            },
                        ];
                        await ItemMasterApi.UpdateItemMasterPartially(filteredData, child.d2Id);

                        return { d2Id: child.d2Id, availableForTableOrder: newValue };
                    } catch (error) {
                        console.error(`Error updating item ${child.d2Id} detail:`, error);
                        alert(`Failed to update item ${child.d2Id}. Please try again.`);
                    }
                });

                const childResults = await Promise.all(childUpdatePromises);

                childResults.forEach(result => {
                    if (result) {
                        updatedList = updatedList.map(item =>
                            item.d2Id === result.d2Id
                                ? { ...item, availableForTableOrder: result.availableForTableOrder }
                                : item
                        );
                    }
                });
            }
        }

        const newValue = !item.availableForTableOrder;
        try {
            const filteredData = [
                {
                    operationType: 0,
                    path: "AvailableForTableOrder",
                    op: "Add",
                    from: "string",
                    value: newValue,
                },
            ];

            await ItemMasterApi.UpdateItemMasterPartially(filteredData, D2Id);

            updatedList[index] = {
                ...itemMasterList[index],
                availableForTableOrder: newValue,
            };

            setItemMasterList(updatedList);

            alert("Stock Updated Successfully");
            // Optionally call fetchItemMasterList here if needed

        } catch (error) {
            console.error("Error updating item detail:", error);
            alert("Failed to update stock. Please try again.");
        }
    };


    const PartiallyupdateForPos = async (D2Id, item, index) => {
        let updatedList = [...itemMasterList]; // Use let to allow reassignment

        if (item.attributeParentId === 0 || item.isAttributeItem === true) {
            const childItems = itemMasterList.filter(element => element.attributeParentId === item.d2Id);

            if (childItems.length > 0) {
                const childUpdatePromises = childItems.map(async (child) => {
                    const newValue = !item.availableForPOS;
                    try {
                        const filteredData = [
                            {
                                operationType: 0,
                                path: "AvailableForPOS",
                                op: "Add",
                                from: "string",
                                value: newValue,
                            },
                        ];
                        await ItemMasterApi.UpdateItemMasterPartially(filteredData, child.d2Id);

                        return { d2Id: child.d2Id, availableForPOS: newValue };
                    } catch (error) {
                        console.error(`Error updating item ${child.d2Id} detail:`, error);
                        alert(`Failed to update item ${child.d2Id}. Please try again.`);
                    }
                });

                const childResults = await Promise.all(childUpdatePromises);

                childResults.forEach(result => {
                    if (result) {
                        updatedList = updatedList.map(item =>
                            item.d2Id === result.d2Id
                                ? { ...item, availableForPOS: result.availableForPOS }
                                : item
                        );
                    }
                });
            }
        }

        const newValue = !item.availableForPOS;
        try {
            const filteredData = [
                {
                    operationType: 0,
                    path: "AvailableForPOS",
                    op: "Add",
                    from: "string",
                    value: newValue,
                },
            ];

            await ItemMasterApi.UpdateItemMasterPartially(filteredData, D2Id);

            updatedList[index] = {
                ...itemMasterList[index],
                availableForPOS: newValue,
            };

            setItemMasterList(updatedList);

            alert("Stock Updated Successfully");
            // Optionally call fetchItemMasterList here if needed

        } catch (error) {
            console.error("Error updating item detail:", error);
            alert("Failed to update stock. Please try again.");
        }
    };

    const PartiallyupdateForPickUp = async (D2Id, item, index) => {
        let updatedList = [...itemMasterList]; // Use let to allow reassignment

        if (item.attributeParentId === 0 || item.isAttributeItem === true) {
            const childItems = itemMasterList.filter(element => element.attributeParentId === item.d2Id);

            if (childItems.length > 0) {
                const childUpdatePromises = childItems.map(async (child) => {
                    const newValue = !item.availableForPickUp;
                    try {
                        const filteredData = [
                            {
                                operationType: 0,
                                path: "AvailableForPickUp",
                                op: "Add",
                                from: "string",
                                value: newValue,
                            },
                        ];
                        await ItemMasterApi.UpdateItemMasterPartially(filteredData, child.d2Id);

                        return { d2Id: child.d2Id, availableForPickUp: newValue };
                    } catch (error) {
                        console.error(`Error updating item ${child.d2Id} detail:`, error);
                        alert(`Failed to update item ${child.d2Id}. Please try again.`);
                    }
                });

                const childResults = await Promise.all(childUpdatePromises);

                childResults.forEach(result => {
                    if (result) {
                        updatedList = updatedList.map(item =>
                            item.d2Id === result.d2Id
                                ? { ...item, availableForPickUp: result.availableForPickUp }
                                : item
                        );
                    }
                });
            }
        }

        const newValue = !item.availableForPickUp;
        try {
            const filteredData = [
                {
                    operationType: 0,
                    path: "AvailableForPickUp",
                    op: "Add",
                    from: "string",
                    value: newValue,
                },
            ];

            await ItemMasterApi.UpdateItemMasterPartially(filteredData, D2Id);

            updatedList[index] = {
                ...itemMasterList[index],
                availableForPickUp: newValue,
            };

            setItemMasterList(updatedList);

            alert("Stock Updated Successfully");
            // Optionally call fetchItemMasterList here if needed

        } catch (error) {
            console.error("Error updating item detail:", error);
            alert("Failed to update stock. Please try again.");
        }
    };


    const handleUpdateRestroTime = async (item, D1ItemId, D2ItemId) => {
        // debugger
        if (UserDetails.canModifyMaster) {
            if (item.attributeParentId === 0 || item.isAttributeItem === true) {
                const childItems = itemMasterList.filter(element => element.attributeParentId === item.d2Id);
                if (childItems.length > 0) {
                    childItems.forEach(async (child) => {
                        var timeData = restoAvailableTimes[D2ItemId]
                        var data = await ItemMasterApi.UpdateItemAvailableTime(timeData, child.d1Id, child.d2Id)
                        if (data) {
                            // alert("Resto Available Time List Created Successfully");
                            fetchItemMasterList(); // Fetch the updated item list
                        }

                    });
                }
            }
            var timeData = restoAvailableTimes[D2ItemId]
            var data = await ItemMasterApi.UpdateItemAvailableTime(timeData, D1ItemId, D2ItemId)
            if (data) {
                alert("Resto Available Time List Created Successfully");
                fetchItemMasterList(); // Fetch the updated item list
            }

        }
        else {
            alert("You are not authorized to modify this data.")

        }
    }

    const UpdatePublish = async (D2Id, item, index) => {
        let updatedList = [...itemMasterList]; // Use let to allow reassignment

        if (UserDetails.canModifyMaster) {
            const newValue = !item.published;

            // Update child items if they exist
            if (item.attributeParentId === 0 || item.isAttributeItem === true) {
                const childItems = itemMasterList.filter(element => element.attributeParentId === item.d2Id);

                if (childItems.length > 0) {
                    const childUpdatePromises = childItems.map(async (child) => {
                        try {
                            const filteredData = [
                                {
                                    operationType: 0,
                                    path: "Published",
                                    op: "Add",
                                    from: "string",
                                    value: newValue,
                                },
                            ];
                            await ItemMasterApi.UpdateItemMasterPartially(filteredData, child.d2Id);

                            return { d2Id: child.d2Id, published: newValue };
                        } catch (error) {
                            console.error(`Error updating item ${child.d2Id} detail:`, error);
                            alert(`Failed to update item ${child.d2Id}. Please try again.`);
                        }
                    });

                    const childResults = await Promise.all(childUpdatePromises);

                    childResults.forEach(result => {
                        if (result) {
                            updatedList = updatedList.map(item =>
                                item.d2Id === result.d2Id
                                    ? { ...item, published: result.published }
                                    : item
                            );
                        }
                    });
                }
            }

            // Update the parent item
            try {
                const filteredData = [
                    {
                        operationType: 0,
                        path: "Published",
                        op: "Add",
                        from: "string",
                        value: newValue,
                    },
                ];

                await ItemMasterApi.UpdateItemMasterPartially(filteredData, D2Id);

                updatedList[index] = {
                    ...itemMasterList[index],
                    published: newValue,
                };

                setItemMasterList(updatedList);
                alert("Publish status updated successfully");
            } catch (error) {
                console.error("Error updating item detail:", error);
                alert("Failed to update publish status. Please try again.");
            }
        } else {
            alert("You are not authorized to modify this data.");
        }
    };

    const UpdateBuyButton = async (D2Id, item, index) => {
        let updatedList = [...itemMasterList]; // Use let to allow reassignment

        if (UserDetails.canModifyMaster) {
            const newValue = !item.disableBuyButton;

            // Update child items if they exist
            if (item.attributeParentId === 0 || item.isAttributeItem === true) {
                const childItems = itemMasterList.filter(element => element.attributeParentId === item.d2Id);

                if (childItems.length > 0) {
                    const childUpdatePromises = childItems.map(async (child) => {
                        try {
                            const filteredData = [
                                {
                                    operationType: 0,
                                    path: "DisableBuyButton",
                                    op: "Add",
                                    from: "string",
                                    value: newValue,
                                },
                            ];
                            await ItemMasterApi.UpdateItemMasterPartially(filteredData, child.d2Id);

                            return { d2Id: child.d2Id, disableBuyButton: newValue };
                        } catch (error) {
                            console.error(`Error updating item ${child.d2Id} detail:`, error);
                            alert(`Failed to update item ${child.d2Id}. Please try again.`);
                        }
                    });

                    const childResults = await Promise.all(childUpdatePromises);

                    childResults.forEach(result => {
                        if (result) {
                            updatedList = updatedList.map(item =>
                                item.d2Id === result.d2Id
                                    ? { ...item, disableBuyButton: result.disableBuyButton }
                                    : item
                            );
                        }
                    });
                }
            }

            // Update the parent item
            try {
                const filteredData = [
                    {
                        operationType: 0,
                        path: "DisableBuyButton",
                        op: "Add",
                        from: "string",
                        value: newValue,
                    },
                ];

                await ItemMasterApi.UpdateItemMasterPartially(filteredData, D2Id);

                updatedList[index] = {
                    ...itemMasterList[index],
                    disableBuyButton: newValue,
                };

                setItemMasterList(updatedList);
                alert("Buy button status updated successfully");
            } catch (error) {
                console.error("Error updating item detail:", error);
                alert("Failed to update buy button status. Please try again.");
            }
        } else {
            alert("You are not authorized to modify this data.");
        }
    };




    const UpdateimmediateTransferForHD = async (D2Id, item, index) => {
        let updatedList = [...itemMasterList]; // Use let to allow reassignment

        if (UserDetails.canModifyMaster) {
            const newValue = !item.immediateTransferForHD;

            // Update child items if they exist
            if (item.attributeParentId === 0 || item.isAttributeItem === true) {
                const childItems = itemMasterList.filter(element => element.attributeParentId === item.d2Id);

                if (childItems.length > 0) {
                    const childUpdatePromises = childItems.map(async (child) => {
                        try {
                            const filteredData = [
                                {
                                    operationType: 0,
                                    path: "immediateTransferForHD",
                                    op: "Add",
                                    from: "string",
                                    value: newValue,
                                },
                            ];
                            await ItemMasterApi.UpdateItemMasterPartially(filteredData, child.d2Id);

                            return { d2Id: child.d2Id, immediateTransferForHD: newValue };
                        } catch (error) {
                            console.error(`Error updating item ${child.d2Id} detail:`, error);
                            alert(`Failed to update item ${child.d2Id}. Please try again.`);
                        }
                    });

                    const childResults = await Promise.all(childUpdatePromises);

                    childResults.forEach(result => {
                        if (result) {
                            updatedList = updatedList.map(item =>
                                item.d2Id === result.d2Id
                                    ? { ...item, immediateTransferForHD: result.immediateTransferForHD }
                                    : item
                            );
                        }
                    });
                }
            }

            // Update the parent item
            try {
                const filteredData = [
                    {
                        operationType: 0,
                        path: "immediateTransferForHD",
                        op: "Add",
                        from: "string",
                        value: newValue,
                    },
                ];

                await ItemMasterApi.UpdateItemMasterPartially(filteredData, D2Id);

                updatedList[index] = {
                    ...itemMasterList[index],
                    immediateTransferForHD: newValue,
                };

                setItemMasterList(updatedList);
                alert("Immediate Transfer For HD updated successfully");
            } catch (error) {
                console.error("Error updating item detail:", error);
                alert("Failed to update buy button status. Please try again.");
            }
        } else {
            alert("You are not authorized to modify this data.");
        }
    };


    const UpdateItemDepartment = async (D2Id, item, index, event) => {
        let updatedList = [...itemMasterList]; // Use let to allow reassignment

        if (UserDetails.canModifyMaster) {
            const newValue = event.target.value;

            // Update child items if they exist
            if (item.attributeParentId === 0 || item.isAttributeItem === true) {
                const childItems = itemMasterList.filter(element => element.attributeParentId === item.d2Id);

                if (childItems.length > 0) {
                    const childUpdatePromises = childItems.map(async (child) => {
                        try {
                            const filteredData = [
                                {
                                    operationType: 0,
                                    path: "ItemDepartment",
                                    op: "Add",
                                    from: "string",
                                    value: newValue,
                                },
                            ];
                            await ItemMasterApi.UpdateItemMasterPartially(filteredData, child.d2Id);

                            return { d2Id: child.d2Id, itemDepartment: newValue };
                        } catch (error) {
                            console.error(`Error updating item ${child.d2Id} detail:`, error);
                            alert(`Failed to update item ${child.d2Id}. Please try again.`);
                        }
                    });

                    const childResults = await Promise.all(childUpdatePromises);

                    childResults.forEach(result => {
                        if (result) {
                            updatedList = updatedList.map(item =>
                                item.d2Id === result.d2Id
                                    ? { ...item, itemDepartment: result.itemDepartment }
                                    : item
                            );
                        }
                    });
                }
            }

            // Update the parent item
            try {
                const filteredData = [
                    {
                        operationType: 0,
                        path: "ItemDepartment",
                        op: "Add",
                        from: "string",
                        value: newValue,
                    },
                ];

                await ItemMasterApi.UpdateItemMasterPartially(filteredData, D2Id);

                updatedList[index] = {
                    ...itemMasterList[index],
                    itemDepartment: newValue,
                };

                setItemMasterList(updatedList);
                alert("Item Department updated successfully");
            } catch (error) {
                console.error("Error updating item detail:", error);
                alert("Failed to update buy button status. Please try again.");
            }
        } else {
            alert("You are not authorized to modify this data.");
        }
    };


    const UpdateItemPreparationTime = async (D2Id, item, index, event) => {
        let updatedList = [...itemMasterList]; // Use let to allow reassignment

        if (UserDetails.canModifyMaster) {
            const newValue = event.target.value;

            // Update child items if they exist
            if (item.attributeParentId === 0 || item.isAttributeItem === true) {
                const childItems = itemMasterList.filter(element => element.attributeParentId === item.d2Id);

                if (childItems.length > 0) {
                    const childUpdatePromises = childItems.map(async (child) => {
                        try {
                            const filteredData = [
                                {
                                    operationType: 0,
                                    path: "PreparationTime",
                                    op: "Add",
                                    from: "string",
                                    value: newValue,
                                },
                            ];
                            await ItemMasterApi.UpdateItemMasterPartially(filteredData, child.d2Id);

                            return { d2Id: child.d2Id, preparationTime: newValue };
                        } catch (error) {
                            console.error(`Error updating item ${child.d2Id} detail:`, error);
                            alert(`Failed to update item ${child.d2Id}. Please try again.`);
                        }
                    });

                    const childResults = await Promise.all(childUpdatePromises);

                    childResults.forEach(result => {
                        if (result) {
                            updatedList = updatedList.map(item =>
                                item.d2Id === result.d2Id
                                    ? { ...item, preparationTime: result.preparationTime }
                                    : item
                            );
                        }
                    });
                }
            }

            // Update the parent item
            try {
                const filteredData = [
                    {
                        operationType: 0,
                        path: "PreparationTime",
                        op: "Add",
                        from: "string",
                        value: newValue,
                    },
                ];
                await ItemMasterApi.UpdateItemMasterPartially(filteredData, D2Id);

                updatedList[index] = {
                    ...itemMasterList[index],
                    preparationTime: newValue,
                };

                setItemMasterList(updatedList);
                alert("Item Preparation Time updated successfully");
            } catch (error) {
                console.error("Error updating item detail:", error);
                alert("Failed to update buy button status. Please try again.");
            }
        } else {
            alert("You are not authorized to modify this data.");
        }
    };

    return (
        <div>
            <PageTabs />
            <div className="panel-heading">
                <i
                    className="fa-solid fa-arrow-left icon-white display-none-500"
                    style={{ color: "white", cursor: "pointer", paddingLeft: "15px" }}
                ></i>
                <h2 className="panel-title" >
                    Product&nbsp;Stock&nbsp;Master
                </h2>
                {/* *******************Top Buttons****************** */}
                <MainButton
                    isBtnSearch={true}
                    handleSearchClick={handleSearchClick}
                />{" "}
                {/*PrintIcon={true}*/}
            </div>
            <div>{/* <DateTime /> */}</div>
            <div className="content_box">
                <div className="tab-content-box tab-patient-top">
                    <div className="tab-content-1">
                        <div
                            className="company-info-top-left1 patient-padding patient-table-width"
                            style={{ width: "100%" }}
                        >
                            <div className="configure_radius">
                                <div className="company_info-text-im td-1">
                                    Product Stock Master
                                </div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        {/* <div className="input-containerEffect12 mt-2" style={{ margin: "1%", width: "50%" }}>
                                           <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                               style={{ fontSize: "14px", marginTop: "10px" }} required>
                                               <option>Mother's Hut</option>
                                           </select>
                                           <label id="input1" className="labelEffect1">Shop</label>
                                       </div>
                                       <div className="input-containerEffect12 mt-2 input-pur-invoice" style={{ margin: "1%" }}>
                                           <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                               style={{ fontSize: "14px", marginTop: "10px" }} required>
                                               <option>Mother's Hut</option>
                                           </select>
                                           <label id="input1" className="labelEffect1">Vendor&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </label>
                                       </div> */}
                                        <div
                                            className="input-containerEffect12 mt-2 input-pur-invoice"
                                            style={{ margin: "1%" }}
                                        >
                                            <select
                                                onChange={(e) => FilterDataset1(e)}
                                                id="BillType"
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                style={{ fontSize: "14px", marginTop: "10px" }}
                                                required
                                            >
                                                <option value="0">--Select--</option>
                                                {ItemCategory.map((item) => (
                                                    <option value={item.d1_Id}>{item.name}</option>
                                                ))}
                                            </select>
                                            <label id="input1" className="labelEffect1">
                                                Item Category&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                            </label>
                                        </div>
                                        <div
                                            className="input-containerEffect12 mt-2"
                                            style={{ margin: "1%", width: "50%" }}
                                        >
                                            <select
                                                onChange={(e) => FilterDataset2(e)}
                                                id="BillType"
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                style={{ fontSize: "14px", marginTop: "12px" }}
                                                required
                                            >
                                                <option value="0">--Select--</option>
                                                <option value="false">True</option>
                                                <option value="True">false</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">
                                                Item Availibility
                                            </label>
                                        </div>
                                        <div
                                            className="input-containerEffect12 mt-2"
                                            style={{ margin: "1%", width: "50%" }}
                                        >
                                            <select
                                                onChange={(e) => FilterDataset3(e)}
                                                id="BillType"
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                style={{ fontSize: "14px", marginTop: "12px" }}
                                                required
                                            >
                                                <option value="0">--Select--</option>
                                                <option value="True">True</option>
                                                <option value="false">false</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">
                                                Publish Status
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {itemMasterList && (
                                <div className="configure_radius config-margin-top">
                                    {/* <div className="company_info-text-im td-1">Search By Cancellation Detail</div> */}
                                    <div className="col-sm-12">
                                        <div
                                            className="table-resposive tb-scroll-width"
                                            style={{ width: "100%", height: "70vh" }}
                                        >
                                            <table
                                                id="tblTransactionList"
                                                className="table_ord_mod table-bordered1 table-width-cancel mt-2 Productstock_Master_table"
                                                frame="box"
                                            >
                                                <thead
                                                    textAlign="center"
                                                    className=" table_headerSticky thead_scroll_orderlist"
                                                >
                                                    <tr className="">
                                                        <th className="td_col6 td-padding-sn">
                                                            <div
                                                                className="table_head_mmb1"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                S.No.
                                                            </div>
                                                        </th>
                                                        <th className="td_col6">
                                                            {" "}
                                                            <div className="table_head_OrderList">
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    Images
                                                                </div>
                                                            </div>{" "}
                                                        </th>
                                                        <th className="td_col6"
                                                        // style={{ maxWidth: "220px" }}
                                                        >
                                                            {" "}
                                                            <div className="table_head_OrderList"
                                                            // style={{ width: "120px" }}
                                                            >
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    Item
                                                                </div>
                                                            </div>{" "}
                                                        </th>
                                                        {/* <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Details</div>
                                                        </div> </th> */}
                                                        <th className="td_col6"
                                                        // style={{ maxWidth: "270px" }}
                                                        >
                                                            {" "}
                                                            <div className="table_head_OrderList">
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    Enable Stock Time
                                                                </div>
                                                            </div>{" "}
                                                        </th>
                                                        <th className="td_col6">
                                                            {" "}
                                                            <div className="table_head_OrderList">
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    Price
                                                                </div>
                                                            </div>{" "}
                                                        </th>

                                                        <th className="td_col6">
                                                            {" "}
                                                            <div className="table_head_OrderList">
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    In-Stock
                                                                </div>
                                                            </div>{" "}
                                                        </th>

                                                        <th className="td_col6">
                                                            {" "}
                                                            <div className="table_head_OrderList">
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    Add Qty
                                                                </div>
                                                            </div>{" "}
                                                        </th>

                                                        {/* <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Is Out of stock</div>
                                                        </div> </th> */}
                                                        {/* <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Is Manage Inventory</div>
                                                        </div> </th> */}
                                                        {/* <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Update</div>
                                                        </div> </th> */}
                                                        <th className="td_col6"
                                                        //  style={{ Width: "100px" }}
                                                        >
                                                            {" "}
                                                            <div className="table_head_OrderList">
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    Allow Order Type
                                                                </div>
                                                            </div>{" "}
                                                        </th>
                                                        {UserDetails.canModifyMaster && (
                                                            <th className="td_col6">
                                                                {" "}
                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Edit
                                                                    </div>
                                                                </div>{" "}
                                                            </th>
                                                        )
                                                        }
                                                        {UserDetails.canDelMaster && (

                                                            <th className="td_col6">
                                                                {" "}
                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Delete
                                                                    </div>
                                                                </div>{" "}
                                                            </th>
                                                        )}
                                                    </tr>
                                                </thead>

                                                <tbody className="tbody_body_m1">
                                                    {(() => {
                                                        if (
                                                            itemMasterList !== null &&
                                                            itemMasterList.length > 0
                                                        ) {
                                                            return (
                                                                itemMasterList
                                                                    // .filter(item => item.attributeParentId !== 0 || item.isAttributeItem !== true)
                                                                    // .reverse()
                                                                    .map((item, index) => (
                                                                        <tr
                                                                            className="table_tbody_Order_List"
                                                                            style={{
                                                                                background:
                                                                                    item.attributeParentId !== 0 ||
                                                                                        item.isAttributeItem !== true
                                                                                        ? ""
                                                                                        : "rgb(234 234 234)",
                                                                            }}
                                                                        >
                                                                            <td>{index + 1}</td>
                                                                            <td
                                                                                style={{
                                                                                    width: "80px",
                                                                                    height: "80px",
                                                                                }}
                                                                            >
                                                                                {item.images &&
                                                                                    item.images.length > 0 && (
                                                                                        <ImageLoader
                                                                                            imageUrl={`${imageBaseUrl}${item.images[0]}`}
                                                                                            altText="No Image"
                                                                                        />
                                                                                    )}
                                                                            </td>
                                                                            <td className="productStock-name_box" >
                                                                                <h6 style={{ fontWeight: "600", textAlign: "left", width: "250px" }}>
                                                                                    {item.name}
                                                                                </h6>
                                                                                <p>
                                                                                    Published
                                                                                    <input
                                                                                        onChange={() =>
                                                                                            UpdatePublish(item.d2Id, item, index)
                                                                                        }
                                                                                        type="checkbox"
                                                                                        checked={
                                                                                            item.published == true
                                                                                        }
                                                                                        name=""
                                                                                        id=""
                                                                                    />

                                                                                </p>
                                                                                <p style={{ marginTop: "-3px" }}>
                                                                                    {/* {item.d2Id} <br />
                                                                                    isAttributeItem {item.isAttributeItem == true ? "true" : "false"} <br />
                                                                                    attributeParentId {item.attributeParentId}  <br /> */}
                                                                                    Out of stock{" "}
                                                                                    <input
                                                                                        onChange={() =>
                                                                                            UpdateStock(item.d2Id, item, index)
                                                                                        }
                                                                                        type="checkbox"
                                                                                        checked={
                                                                                            item.stockCheck === 1 &&
                                                                                            item.restoAvailableQuantity === 0
                                                                                        }
                                                                                        name=""
                                                                                        id=""
                                                                                    />
                                                                                </p>
                                                                                <p style={{ marginTop: "-3px" }}>
                                                                                    Disable-Buy-Button :
                                                                                    <input
                                                                                        onChange={() =>
                                                                                            UpdateBuyButton(item.d2Id, item, index)
                                                                                        }
                                                                                        type="checkbox"
                                                                                        checked={
                                                                                            item.disableBuyButton == true
                                                                                        }
                                                                                        name=""
                                                                                        id=""
                                                                                    />
                                                                                </p>
                                                                                <p style={{ marginTop: "-3px" }}>
                                                                                    Stock Check :
                                                                                    {item.stockCheck !== 0 ? (
                                                                                        <>
                                                                                            {" "}
                                                                                            True{" "}
                                                                                            <i
                                                                                                style={{ marginLeft: "5px" }}
                                                                                                class="fa-solid fa-check"
                                                                                            ></i>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {" "}
                                                                                            False
                                                                                            <i
                                                                                                style={{ marginLeft: "5px" }}
                                                                                                class="fa-solid fa-xmark"
                                                                                            ></i>
                                                                                        </>
                                                                                    )}
                                                                                </p>

                                                                                <p style={{ marginTop: "-3px" }}>
                                                                                    Immediate Transfer For HD
                                                                                    <input
                                                                                        onChange={() =>
                                                                                            UpdateimmediateTransferForHD(item.d2Id, item, index)
                                                                                        }
                                                                                        type="checkbox"
                                                                                        checked={
                                                                                            item.immediateTransferForHD == true
                                                                                        }
                                                                                        name=""
                                                                                        id=""
                                                                                    />
                                                                                </p>
                                                                                <p>Item Department
                                                                                    <select className="productstockmanagar-select" name="" id="" value={item.itemDepartment}
                                                                                        onChange={(e) => UpdateItemDepartment(item.d2Id, item, index, e)}>
                                                                                        <option value="">--Select--</option>
                                                                                        {Array.isArray(ItemDepartmentList) && ItemDepartmentList.length > 0 ? (
                                                                                            ItemDepartmentList.map((group, index) => (
                                                                                                <option key={index} value={group.id}>
                                                                                                    {group.name}
                                                                                                </option>
                                                                                            )
                                                                                            )
                                                                                        ) : (
                                                                                            <option value="">
                                                                                                No departments available
                                                                                            </option>
                                                                                        )}
                                                                                    </select>
                                                                                </p>

                                                                                <p>Item Preparation Time
                                                                                    <select className="productstockmanagar-select" name="" id="" value={item.preparationTime}
                                                                                        onChange={(e) => UpdateItemPreparationTime(item.d2Id, item, index, e)}>
                                                                                        <option value="">--Select--</option>
                                                                                        {PreprationTimeList !== undefined &&
                                                                                            PreprationTimeList.length > 0 ? (
                                                                                            PreprationTimeList.map(
                                                                                                (group, index) => (
                                                                                                    <option
                                                                                                        key={index}
                                                                                                        value={group.id}
                                                                                                    >
                                                                                                        {group.name}
                                                                                                    </option>
                                                                                                )
                                                                                            )
                                                                                        ) : (
                                                                                            <option value="">
                                                                                                No preparation times available
                                                                                            </option>
                                                                                        )}
                                                                                    </select>
                                                                                </p>
                                                                            </td>
                                                                            {/* <td className='ProductStock_details' onClick={openDetailModal}>Details</td> */}
                                                                            <td>
                                                                                <div style={{ display: "flex", alignItems: "center" }}>

                                                                                    <table className="table-t" style={{ margin: "0" }}>
                                                                                        <tbody>
                                                                                            {(restoAvailableTimes[item.d2Id] || []).length > 0 ? (
                                                                                                (restoAvailableTimes[item.d2Id] || []).map((row, rowIndex) => (
                                                                                                    <tr key={rowIndex}>
                                                                                                        <td style={{ textAlign: "center" }}>{rowIndex + 1}</td>
                                                                                                        <td style={{ textAlign: "center" }}>
                                                                                                            <input
                                                                                                                className="table-input-12"
                                                                                                                type="time"
                                                                                                                step="1"
                                                                                                                style={{ fontSize: "12px" }}
                                                                                                                name={`changeFrom-${rowIndex}`}
                                                                                                                value={row.availableTimefrom}
                                                                                                                onChange={(e) => handleInputChangeTime2(item.d2Id, rowIndex, e)}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td style={{ textAlign: "center" }}>
                                                                                                            <input
                                                                                                                className="table-input-12"
                                                                                                                type="time"
                                                                                                                step="1"
                                                                                                                style={{ fontSize: "12px" }}
                                                                                                                name={`changeTo-${rowIndex}`}
                                                                                                                value={row.availableTimeTo}
                                                                                                                onChange={(e) => handleInputChangeTime(item.d2Id, rowIndex, e)}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td style={{ textAlign: "center" }}>
                                                                                                            <div onClick={() => handleAddRowTime(item.d2Id)}>
                                                                                                                <i className="fa-solid fa-square-plus"></i>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td style={{ textAlign: "center" }}>
                                                                                                            <button
                                                                                                                className="btn-table1"
                                                                                                                onClick={() => handleDeleteRowTime(item.d2Id, rowIndex)}
                                                                                                            >
                                                                                                                <i style={{ fontSize: "13px" }} className="fa-solid fa-trash delete-btn"></i>
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            ) : (
                                                                                                <tr>
                                                                                                    <td style={{ textAlign: "center" }}>1</td>
                                                                                                    <td style={{ textAlign: "center" }}>
                                                                                                        <input
                                                                                                            className="table-input-12"
                                                                                                            type="time"
                                                                                                            step="1"
                                                                                                            name="changeFrom-new"
                                                                                                            value=""
                                                                                                            onChange={(e) => handleInputChangeTime2(item.d2Id, 0, e)}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td style={{ textAlign: "center" }}>
                                                                                                        <input
                                                                                                            className="table-input-12"
                                                                                                            type="time"
                                                                                                            step="1"
                                                                                                            name="changeTo-new"
                                                                                                            value=""
                                                                                                            onChange={(e) => handleInputChangeTime(item.d2Id, 0, e)}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td style={{ textAlign: "center" }}>
                                                                                                        <div onClick={() => handleAddRowTime(item.d2Id)}>
                                                                                                            <i className="fa-solid fa-square-plus"></i>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </tr>
                                                                                            )}
                                                                                        </tbody>
                                                                                    </table>


                                                                                    <button
                                                                                        className="update_buttoninProductstock"
                                                                                        onClick={() => handleUpdateRestroTime(item, item.d1Id, item.d2Id)}
                                                                                    >
                                                                                        Update

                                                                                    </button>
                                                                                </div>

                                                                            </td>

                                                                            <td>{item.salePrice}</td>
                                                                            <td>{item.restoAvailableQuantity}</td>
                                                                            <td>
                                                                                {item.attributeParentId !== 0 ||
                                                                                    item.isAttributeItem !== true ?
                                                                                    (
                                                                                        <>
                                                                                            <input
                                                                                                style={{
                                                                                                    width: "50px",
                                                                                                    padding: "3px",
                                                                                                    borderRadius: "8px",
                                                                                                }}
                                                                                                value={inputValues[item.d2Id] || ""}
                                                                                                type="text"
                                                                                                onChange={(e) =>
                                                                                                    handleChange(item.d2Id, e)
                                                                                                }
                                                                                            />
                                                                                            <button
                                                                                                className="update_buttoninProductstock"
                                                                                                onClick={() => handleUpdate(item, index)}
                                                                                            >
                                                                                                Update
                                                                                            </button>
                                                                                        </>
                                                                                    ) : (null)
                                                                                }

                                                                            </td>
                                                                            {/* <td><input type="checkbox" name="" id="" /></td> */}

                                                                            {/* <td><input type="checkbox" name="" id="" /></td> */}
                                                                            <td>
                                                                                <div className="ProductStock_managediv">
                                                                                    <div>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                                item.availableForHomeDelivery ===
                                                                                                true
                                                                                            }
                                                                                            onClick={(e) =>
                                                                                                PartiallyupdateForHomedelivery(
                                                                                                    item.d2Id,
                                                                                                    item, index
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        Home Delivery
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                                item.availableForTableOrder ===
                                                                                                true
                                                                                            }
                                                                                            onClick={(e) =>
                                                                                                PartiallyupdateForTableorder(
                                                                                                    item.d2Id,
                                                                                                    item, index
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        Table Order
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                                item.availableForPickUp === true
                                                                                            }
                                                                                            onClick={(e) =>
                                                                                                PartiallyupdateForPickUp(
                                                                                                    item.d2Id,
                                                                                                    item, index
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        Pick Up
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                                item.availableForPOS === true
                                                                                            }
                                                                                            onClick={(e) =>
                                                                                                PartiallyupdateForPos(
                                                                                                    item.d2Id,
                                                                                                    item, index
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        POS
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            {UserDetails.canModifyMaster && (
                                                                                <td
                                                                                    onClick={() => Edit(item.d2Id)}
                                                                                    style={{ fontSize: "17px" }}
                                                                                    className="td_col6"
                                                                                >
                                                                                    <i class="fa-solid fa-pen-to-square"></i>
                                                                                </td>
                                                                            )}
                                                                            {UserDetails.canDelMaster && (
                                                                                <td className="">
                                                                                    <i
                                                                                        style={{ fontSize: "15px" }}
                                                                                        class="fa-solid fa-trash delete-btn"
                                                                                    ></i>
                                                                                </td>)}
                                                                        </tr>
                                                                    ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/*********************** status Modal ********************/}
                                        {DetailModal && (
                                            <div className="modal">
                                                <div className="AddNewItem-modal modal-cancellation">
                                                    <div
                                                        className="modal-title"
                                                        style={{ margin: "5px 0px" }}
                                                    >
                                                        <h3
                                                            className="modal-OrderLIst modal-color-CAD"
                                                            style={{ color: "#807373" }}
                                                        >
                                                            &nbsp; &nbsp; Stock Details
                                                        </h3>
                                                        <span
                                                            className="close_modal"
                                                            onClick={openDetailModal}
                                                        >
                                                            &times;
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="ModalContentModalSettingP"
                                                        style={{ paddingTop: "0px" }}
                                                    ></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className="buttom-box"> */}
                <div
                    className="floating-button-container-500"
                    style={{ width: "95%", bottom: "20px" }}
                >
                    <button className="blueBtn">
                        <i class="fa-solid fa-search" style={{ color: "white" }}></i> Search
                    </button>
                </div>
                {/* <div className="button-btn-3" onClick={handleSearchClick} style={{ width: "100%", backgroundColor: "green" }}>
                <button className="btn-3" onClick={handleSearchClick}><i class="fa-solid fa-search " ></i></button>
            </div> */}
                {/* </div> */}
            </div>

            <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
                <div style={{ padding: "15px" }}>
                    <ItemMaster
                        data={editData}
                        modelOpen={true}
                        setSliderCallBack={setSliderOpen}
                        fetchData={fetchItemMasterList}
                        variable={isSliderOpen}
                        ItemMasterList={itemMasterList}
                    // toggleSlide={toggleSlider}
                    />
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
};

export default ProductStock_Master;
