import React, { useEffect, useState } from "react";
import ItemGroup from "../../../API/APIServices/ItemGroup";
import { Link, useNavigate } from 'react-router-dom';

const Dropdownnew = (props) => {
  const [ItemData, setItemData] = useState([]);


  const { setCheckedItemIds, catogorydata, variable, openSubCateIds, setOpenSubCateIds,
    openChildCateIds, setOpenChildCateIds,
    checkedCategories, setCheckedCategories } = props;

  console.log(catogorydata)
  console.log(ItemData);




  useEffect(() => {
    console.log("openSubCateIds", openSubCateIds);
  }, [openSubCateIds]);

  useEffect(() => {
    console.log("checkedCategories", checkedCategories);
  }, [checkedCategories]);

  useEffect(() => {
    console.log("openChildCateIds", openChildCateIds);
  }, [openChildCateIds]);



  const navigate = useNavigate();

  const toggleSubCategory = (parentId, event) => {
    event.stopPropagation(); // Prevent the checkbox click from triggering
    // openSubCateIds 
    if (checkedCategories.includes(parentId)) {
      return
    } else {
      setOpenSubCateIds((prevOpenSubCateIds) => {
        const isOpen = prevOpenSubCateIds[parentId];
        const newOpenSubCateIds = { ...prevOpenSubCateIds };
        if (isOpen) {
          delete newOpenSubCateIds[parentId];
        } else {
          newOpenSubCateIds[parentId] = true;
        }
        return newOpenSubCateIds;
      });
    }
  };

  const toggleChildCategory = (SubParentId, event, ParentId) => {
    // alert(ParentId)
    event.stopPropagation(); // Prevent the checkbox click from triggering

    if (checkedCategories.includes(SubParentId)) {
      return
    }
    else {
      setOpenChildCateIds((prevOpenChildCateIds) => {
        const isOpen = prevOpenChildCateIds[SubParentId];
        const newOpenChildCateIds = { ...prevOpenChildCateIds };
        if (isOpen) {
          delete newOpenChildCateIds[SubParentId];
        } else {
          newOpenChildCateIds[SubParentId] = true;
        }
        return newOpenChildCateIds;
      });
    }
  };

  const handleCheckboxChange = (categoryId, isParent, event) => {
    event.stopPropagation(); // Prevent the label click from triggering
    setCheckedCategories((prevCheckedCategories) => {
      let updatedCategories;
      if (prevCheckedCategories.includes(categoryId)) {
        updatedCategories = prevCheckedCategories.filter(
          (id) => id !== categoryId
        );
      } else {
        updatedCategories = [...prevCheckedCategories, categoryId];
      }
      setCheckedItemIds(updatedCategories);
      return updatedCategories;
    });
    if (isParent) {
      toggleSubCategory(categoryId, event); // Also toggle subcategory
    }
  };


  const handleCheckboxChangeChild = (categoryId, isParent, event, parentId) => {
    const parentGroup = ItemData.find(item => item.d2_Id === parentId);
    event.stopPropagation(); // Prevent the label click from triggering
    setCheckedCategories((prevCheckedCategories) => {
      let updatedCategories;

      if (prevCheckedCategories.includes(categoryId)) {
        // Remove categoryId from checked categories
        updatedCategories = prevCheckedCategories.filter(id => id !== categoryId);

        // If parentId is also checked and no more children are checked, remove parentId
        if (parentId && !updatedCategories.some(id => id === parentId)) {
          updatedCategories = updatedCategories.filter(id => id !== parentId);
        }
      } else {
        // Add categoryId to checked categories
        updatedCategories = [...prevCheckedCategories, categoryId];

        // Ensure parentId is also included if categoryId is being checked
        if (parentId && !updatedCategories.includes(parentId)) {
          updatedCategories = [...updatedCategories, parentId];
        }
      }

      // Always include parentGroup and parentGroup.parentGrp2 if they are valid
      if (parentId && !updatedCategories.includes(parentId)) {
        updatedCategories = [...updatedCategories, parentId];
      }
      if (parentGroup?.parentGrp2 && !updatedCategories.includes(parentGroup.parentGrp2)) {
        updatedCategories = [...updatedCategories, parentGroup.parentGrp2];
      }

      setCheckedItemIds(updatedCategories);
      return updatedCategories;
    });



    if (isParent) {
      toggleChildCategory(categoryId, event); // Also toggle child category
    }
  };


  const fetchData = async () => {
    try {
      const itemGroup = await ItemGroup.GetItemGroupFilter();
      setItemData(itemGroup);
      return itemGroup;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchCheckBoxData = async () => {
    if (variable === true) {
      // debugger
      try {
        if (catogorydata && catogorydata.length > 0) {
          setCheckedCategories(catogorydata);
          let initialOpenSubCateIds = {};
          catogorydata.forEach((categoryId) => {
            initialOpenSubCateIds[categoryId] = true;
          });
          setOpenSubCateIds(initialOpenSubCateIds);

          let initialOpenSubCateIds1 = {};
          catogorydata.forEach((categoryId) => {
            initialOpenSubCateIds1[categoryId] = true;
          });
          setOpenChildCateIds(initialOpenSubCateIds1);

          const data = await fetchData();

          for (let i = 0; i < catogorydata.length; i++) {
            let d1Id_parentId = data.filter(item => item.d2_Id === catogorydata[i]);
            if (d1Id_parentId[0].primaryGroup === false) {
              let d2Id_parentId = data.filter(item => item.d1_Id === d1Id_parentId[0].parentGrp);
              setOpenSubCateIds((prevOpenSubCateIds) => ({
                ...prevOpenSubCateIds,
                [d2Id_parentId[0].d2_Id]: true,
              }));
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchCheckBoxData();
  }, [variable]);

  return (
    <div>
      {ItemData &&
        ItemData.filter(
          (item) =>
            item.primaryGroup &&
            item.useInEcom === true &&
            item.showInMenu === true
        ).map((item, index) => (
          <div key={index}>
            <div className="category-main">
              <input
                type="checkbox"
                checked={checkedCategories.includes(item.d2_Id)}
                onChange={(event) => handleCheckboxChange(item.d2_Id, true, event)}
                id={`cate-${item.d2_Id}`}
              />
              <label
                className="CategoryBoxPaddingL"
                htmlFor=""
                onClick={(event) => toggleSubCategory(item.d2_Id, event)}
                style={{ color: "black", fontSize: "16px" }}
              >
                {item.menuName}
                {/* ---- {item.d1_Id} ---- {item.d2_Id} */}
              </label>
            </div>
            {openSubCateIds[item.d2_Id] && (
              <div className="sub-category-box">
                {ItemData.filter(
                  (subItem) =>
                    subItem.parentGrp === item.d1_Id &&
                    subItem.useInEcom === true &&
                    subItem.showInMenu === true
                ).map((subItem, subIndex) => (
                  <div key={subIndex}>
                    <div className="sub-category-txt">
                      <input
                        checked={checkedCategories.includes(subItem.d2_Id)}
                        onChange={(event) =>
                          handleCheckboxChangeChild(subItem.d2_Id, true, event, subItem.parentGrp2)
                        }
                        type="checkbox"
                        id={`sub-cate-${subItem.d2_Id}`}
                      />
                      <label
                        className="CategoryBoxPaddingL"
                        htmlFor=""
                        style={{ color: "#018786", fontSize: "15px" }}
                        onClick={(event) => toggleChildCategory(subItem.d2_Id, event, subItem.parentGrp2)}
                      >
                        {subItem.menuName}
                        {/* ---- {subItem.d1_Id} ---- {subItem.d2_Id} */}
                      </label>
                    </div>
                    {openChildCateIds[subItem.d2_Id] && (
                      <div className="sub-category-box">
                        {ItemData.filter(
                          (childItem) =>
                            childItem.parentGrp === subItem.d1_Id &&
                            childItem.useInEcom === true &&
                            childItem.showInMenu === true
                        ).map((childItem, childIndex) => (
                          <div key={childIndex} className="child-category-txt">
                            <input
                              checked={checkedCategories.includes(childItem.d2_Id)}
                              onChange={(event) =>
                                handleCheckboxChangeChild(childItem.d2_Id, false, event, childItem.parentGrp2)
                              }
                              type="checkbox"
                              id={`child-cate-${childItem.d2_Id}`}
                            />
                            <label
                              className="CategoryBoxPaddingL"
                              htmlFor=""
                              style={{ color: "#555555", fontSize: "14px" }}
                            >
                              {childItem.menuName}
                              {/* ---- {subItem.d1_Id} ---- {subItem.d2_Id} */}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default Dropdownnew;
