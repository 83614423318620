import React from 'react'

const Quote = (props) => {


  
  return (

    <div  style={{position:"relative" }} className="" 
    onDragStart={props.handleDrag}
    onDrop={props.handleDrop}
    onDragOver={props.dragover}
    draggable="true" >

      <div></div>
      
    </div>
  )
}

export default Quote