import React from "react";
// import SearchBox from './SearchBox'
import myLogo from "../../Assets/logo.jpg";
import { useState } from "react";
// import { NavLink } from 'react-router-dom';

const NavBarTypes = ({ selectedTab, onTabClick, ...props }) => {
  const [getValueAndStrg, setGetValueAndStrg] = useState([]);

  let navchange;
  switch (true) {
    case props.widthV > 0 && props.widthV <= 450:
      navchange = "display-none";
      break;
    case props.widthV > 450 && props.widthV <= 850:
      navchange = "display-flex";
      break;
    default:
      navchange = "";
      break;
  }

  let navMobile;
  switch (true) {
    case props.widthV > 0 && props.widthV <= 450:
      navMobile = "nav-mobile";
      break;
    case props.widthV > 450 && props.widthV <= 850:
      navMobile = "nav-tablet";
      break;
    default:
      navMobile = "";
      break;
  }




  const [isRight, setIsRight] = useState(false);

  const sidebarshow = () => {
    setIsRight(!isRight);
    console.log("sidebar")

  };

  const rightside = {
    transition: 'all 0.7s', // You can adjust the transition duration as needed
    transform: isRight ? 'translateX(250px)' : 'translateX(0)',
  };

  const deletebuttonshow = () => {
    setGetValueAndStrg(getId);
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };
  let getId = props.keyValueId;


  return (
    <>
      <div className={`navbar-types-web-template ${navchange} `} id={getId} onClick={deletebuttonshow}>
        <div className="navbar-logo-web-template">
          <img className="navbar-logo-img-web-template" src={myLogo} alt="" />
        </div>
        <div className="navbar-menu-box-web-template">
          <div
            className={`navbar-menu-web-template ${selectedTab === "home" ? "active" : ""} `}
            onClick={() => onTabClick("home")}
          >
            Home
          </div>
          <div
            className={`navbar-menu-web-template ${selectedTab === "about" ? "active" : ""} `}
            onClick={() => onTabClick("about")}
          >
            About
          </div>
          <div
            className={`navbar-menu-web-template ${selectedTab === "contact" ? "active" : "" } `}
            onClick={() => onTabClick("contact")}
          >
            Contact
          </div>
          <div
            className={`navbar-menu-web-template ${selectedTab === "career" ? "active" : "" } `}
            onClick={() => onTabClick("career")}
          >
            Career
          </div>
        </div>
      </div>

      <div className={`nav-mobile-screen-web-template ${navMobile} `}>
        <div className="nav-bars-box-web-template" onClick={sidebarshow}>
          <i className="fa-solid fa-bars nav-bars-web-template"></i>
        </div>
        <div className="nav-bars-box-img-web-template">
          <img className="navbar-mbile-logo-img-web-template" src={myLogo} alt="" />
        </div>
        <div className="nav-bars-box-web-template">
          {/* <i className="fa-solid fa-gear nav-bars-web-template"></i> */}
        </div>
      </div>

      <div style={rightside} className="sidebar-mobile-web-template">
        <div className="navbar-menu-box-mobile">
          <div className="logo-sidebar">
            <img
              className="navbar-mbile-logo-img-sidebar"
              src={myLogo}
              alt=""
            />
          </div>
          <div className="close-slider-box">
            <i className="fa-solid fa-xmark slider-close" onClick={sidebarshow}></i>
          </div>

          <div
            className={`navbar-menu-mobile ${selectedTab === "home" ? "active" : "" } `}
            onClick={() => onTabClick("home")}
          >
            Home
          </div>
          <div
            className={`navbar-menu-mobile ${selectedTab === "about" ? "active" : "" } `}
            onClick={() => onTabClick("about")}
          >
            About
          </div>
          <div
            className={`navbar-menu-mobile ${selectedTab === "contact" ? "active" : "" } `}
            onClick={() => onTabClick("contact")}
          >
            Contact
          </div>
          <div
            className={`navbar-menu-mobile ${selectedTab === "career" ? "active" : "" } `}
            onClick={() => onTabClick("career")}
          >
            Career
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBarTypes;
