import { useContext, useEffect, useState } from "react";
import "../Restaurant/OrderTaker/OrderTakerStylesheet.css"
import { useNavigate } from 'react-router-dom';
import LoginApi from "../API/APIServices/User";
import CompanyAPI from "../API/APIServices/Company";
import AlertBox from "../Inventory/Props/AlertBox";
import MyContext from "../Inventory/MyContext";
import Loader from "../Inventory/Props/Loader";

const LoginOrder = (props) => {

    const navigate = useNavigate();

    useState(() => {
        document.title = 'Login';
    }, []);

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    // **********************************************************************
    const [errorMessages, setErrorMessages] = useState({
        compCode: "",
        userName: "",
        password: ""
    });

    const [inputValues, setInputValues] = useState({

        compCode: "",
        userName: "",
        password: "",
        comClientRefId: ""
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };

    const branchchange = (e) => {
        const countryId = parseInt(e.target.value);
        // console.log(countryId);
        handleInputChange(e);
        //handleCountryChange(countryId);
    };

    const [branchName, setBranchName] = useState([]);
    // console.log(countryMain)

    useEffect(() => {
        const fetchData = async () => {
            //const branch = await CompanyAPI.GetCompany();
            const branch = await CompanyAPI.GetCompanyForLogin();
            setBranchName(branch);
        };
        fetchData();
    }, []);


    const fieldNames = ['compCode', 'userName', 'password'];

    const handleSubmit = async () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            const response = await LoginApi.loginUser(inputValues);
            if (response) {
                console.log(response);
                const arrayToStore = response;
                const arrayString = JSON.stringify(arrayToStore);
                localStorage.setItem('loginAccountUserData', arrayString);

                if (response.loginType == "562") {
                    OpenAlertBox();
                    navigate('/Delivery');
                }
                if (response.loginType == "563") {
                    OpenAlertBox();
                    navigate('/OrderTakerView');
                }
                else if (response.loginType == "565") {
                    OpenAlertBox();
                    navigate('/OrderList');
                }
                else
                    alert("Incorrect UserName or Password");
                //     navigate('/OrderList');
            } else {
                alert("Login error");//window.location.href =
            }
            setLoading(false);
        }
    }



    return (
        <div>
            <div className="heading login-padding-ord">
                <div className="head-display space-between">
                    <div className="space-evenly">
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "whitesmoke", overflowY: "scroll", height: "75%", marginBottom: "10px", marginTop: "45px" }}>

                <div className="Login-container">
                    <p className="Login-title" >Login</p>
                    <div className="form-login">
                        <div className="input-group-login">
                            <label for="userName">Company</label>
                            <select
                                id=""
                                className="modify_im_input mode_LoginOrder"
                                name="compCode"
                                type="text"
                                // onChange={handleInputChange}
                                onChange={branchchange}
                                value={inputValues.compCode}
                            >
                                <option value="">--Select--</option>
                                {branchName !== undefined &&
                                    branchName.length > 0 && (
                                        <>
                                            {branchName.map((data) => (
                                                <option key={data.id} value={data.branchCode}>
                                                    {data.name}
                                                </option>
                                            ))}
                                        </>
                                    )}
                            </select>

                        </div>
                        <div className="input-group-login">
                            <label for="compCode">Company Code</label>
                            <input type="text" name="compCode" id="compCode"
                                onChange={handleInputChange}
                                value={inputValues.compCode}
                            />
                        </div>
                        <div className="input-group-login">
                            <label for="userName">Username</label>
                            <input type="text" name="userName" id="username"
                                onChange={handleInputChange}
                                value={inputValues.userName}
                            />
                        </div>
                        <div className="input-group-login">
                            <label for="password">Password</label>
                            <input type="password" name="password" id="password"
                                onChange={handleInputChange}
                                value={inputValues.password}
                            />
                        </div>
                        {/* <Link to="/OrderTakerView"> */}
                        <button className="sign-login" onClick={handleSubmit}>Sign in</button>
                        {/* </Link> */}
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Login"
                    Heading="Login Successfull"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}

            {loading && <Loader />}

        </div>
    );
};

export default LoginOrder;
