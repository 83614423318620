import { useEffect, useRef, useState } from "react";

const LogoModal = (props) => {

    const [imageUrl, setImageUrl] = useState([]);
    const [, setLocalStrUrl] = useState("");
    const [urlInput, setUrlInput] = useState("");
    const [newImagesAdded, setNewImagesAdded] = useState(false); // New state to track if new images are added
    // const [imageModal, setImageModal] = useState(true)
    const inputRef = useRef(null);
    // const valuChange = useRef(null);
  
    // document.getElementById("body").style.;
  
  
    const addImage = () => {
      inputRef.current.click();
    };
  
    const handleImageUpload = (e) => {
      const file = e.target.files[0];
      console.log(file);
      // if(file){
      // const formData = new FormData();
      // formData.append('file', file);
  
      // // Replace 'uploadEndpoint' with the actual URL of your server's upload endpoint
      // axios.post('http://localhost:3000/upload', formData)
      //   .then((response) => {
      //     // Handle the response from the server after successful upload
      //     console.log('File uploaded successfully:', response.data);
      //   })
      //   .catch((error) => {
      //     // Handle errors if the upload fails
      //     console.error('Error uploading file:', error);
      //   });
    // }
      if (file) {
        const imageURL = URL.createObjectURL(file); 
        setImageUrl((prevUrls) => [...prevUrls, imageURL]);
        setLocalStrUrl(imageURL);
        setNewImagesAdded(true); // Set the flag to indicate new images are added
      }
    };
  
    const handleAddImageFromUrl = () => {
      setImageUrl((prevUrls) => [...prevUrls, urlInput]);
      setLocalStrUrl(urlInput);
      setNewImagesAdded(true); // Set the flag to indicate new images are added
    };
  
    useEffect(() => {
      // Update localStorage only if new images are added
      if (newImagesAdded) {
        localStorage.setItem("imageURL", JSON.stringify(imageUrl));
        setNewImagesAdded(false); // Reset the flag after updating localStorage
      }
    }, [imageUrl, newImagesAdded]);
  
    useEffect(() => {
      const savedImageUrl = JSON.parse(localStorage.getItem("imageURL"));
      if (savedImageUrl) {
        setImageUrl(savedImageUrl);
        setLocalStrUrl(savedImageUrl[0]); // Assuming you want to display the first image in the localStrUrl state
      }
    }, []);
  
    const handleInputChange = (e) => {
      setUrlInput(e.target.value);
    };
  
    const handleValuePassing = (localValue) => {
      
      console.log("image open")
      props.passValueToParent(localValue);
    
      
    };
    const handleValuePassing1  =()=>{
      props.passValueToParent1("false");
    }
  
    return (
      <div>
        <div className="modal-back">
          <div className="image-modal">
            <div className="viewcode-modal-heading">Logo-selector-box</div>
            <i 
              id="modal_box_close"
              onClick={props.handleclick}
              className="fa-sharp fa-solid fa-xmark"
            ></i>
            <div className="image-modal-main">
              <div className="image-modal-left" onClick={addImage}>
                {/* <div className="image-left-heading"><p>HTML</p></div> */}
                <p className="upload-image-txt">
                  Drop file here or click to upload
                </p>
                <input type="file" style={{display:"none"}} ref={inputRef} onChange={handleImageUpload}/>
              </div>
              <div className="image-modal-right">
                <div className="image-modal-rignt-top">
                  <div><input className="add-image-input" type="text" placeholder="Enter URL" value={urlInput}
                    onChange={handleInputChange}/></div>
                  <div><button className="add-image-btn" onClick={handleAddImageFromUrl} >Add Image</button></div>
                  {/* <div><button className="add-image-btn" onClick={handleValuePassing} style={{display:"none"}}></button></div> */}
                </div>
                <div className="image-modal-right-bottom">
                  { imageUrl.map((localValue,index) =>(
                  <div className="right-bottom-box" key={index}>
                    <div onClick={handleValuePassing1}><img src={localValue} alt="" style={{width:"200px",height:"100px",objectFit:"cover"}}onClick={() => handleValuePassing(localValue)}/> </div>
                    <div><p></p></div>
                  </div>
                  ))
  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  

export default LogoModal;
