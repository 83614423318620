import React, { useState } from 'react';


const IconLibraryOption = () => {
    const [searchText, setSearchText] = useState('');
    // const [selectedOptions, setSelectedOptions] = useState([]);
  
    // Your search options here (e.g., categories, filters, etc.)
   const [iconApi] = useState([
    {
        id:1,
        name:"search",
        content:"fa-solid fa-magnifying-glass",
    },
    {
        id:2,
        name:"search",
        content:"fa-solid fa-magnifying-glass",
    },
    {
        id:3,
        name:"search",
        content:"fa-solid fa-magnifying-glass",
    },
    {
        id:4,
        name:"search",
        content:"fa-solid fa-magnifying-glass",
    },
    {
        id:5,
        name:"search",
        content:"fa-solid fa-magnifying-glass",
    },
    {
        id:6,
        name:"search",
        content:"fa-solid fa-magnifying-glass",
    },
   ])
   
    const handleSearchChange = (e) => {
      setSearchText(e.target.value);
    };
  
 
  
    const handleSearchSubmit = (e) => {
      e.preventDefault();
      // Implement your search functionality here
      // You can use the searchText and selectedOptions to perform the search
    
    };
  
    return (
        <>
      <form className="iconsearch-bar" onSubmit={handleSearchSubmit}>
        
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={handleSearchChange}
          className='iconinput'
        />
       <div className="search-icon" >
        <i className="fa-solid fa-magnifying-glass "></i>
        </div>
       
        
        
      </form>
      <div className='icon-section'>
        {
            iconApi.map((icon,index) =>(
                <div className="icon-show" key={icon.id}>
                    {
                        icon.id
                    }
                </div>
            ))
        }
      </div>
      </>
  )
}

export default IconLibraryOption