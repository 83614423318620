import React, { useState, useEffect } from 'react';

import CountdownTimer from "./CountdownTimer";

const CountDown = (props) => {
  // const deletebuttonshow = () => {
  //   document
  //     .getElementById("countdown-delete")
  //     .classList.toggle("column-one-delete-web-template");
  //   document.getElementById("countdown").classList.toggle("element-border");
  // };




  const [getValueAndStrg, setGetValueAndStrg] = useState([])


  useEffect(() => {
    // Load saved items from localStorage on initial amount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    // Store only unique id values in localStorage
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);

  const deletebuttonshow = () => {
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };

  let getId = props.keyValueId;



  const convertToSeconds = ({ days = 0, hours = 0, minutes = 0, seconds = 0 }) => {
    return days * 86400 + hours * 3600 + minutes * 60 + seconds;
  };
  const initialTime = convertToSeconds({
    days: 2,
    hours: 5,
    minutes: 30,
    seconds: 0,
  });

  const handleTimerEnd = () => {
    console.log('Timer has ended.');
    // You can perform any actions you want when the timer ends.
  };
  return (
    <div
      style={{ position: "relative" }}
      onClick={deletebuttonshow}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true"
      // id="countdown"
      id={getId}
    >

      <div>
        <CountdownTimer widthV={props.widthVa} initialTime={initialTime} onTimerEnd={handleTimerEnd} />
      </div>
    </div>
  );
};

export default CountDown;
