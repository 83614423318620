import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import MyContext from '../../../Inventory/MyContext';

const Map = (props, { initialWidthPercent = 100, initialHeight = 100, }) => {

  const { TemplateData, setTemplateData, droppedItems, setDroppedItems, setStylesArray,
    handleStyleChange,
    updateStyle,
    stylesArray } = useContext(MyContext);



  const [getValueAndStrg, setGetValueAndStrg] = useState([])

  const [dimensions, setDimensions] = useState({
    widthPercent: initialWidthPercent,
    minHeight: initialHeight,
    top: 0,
    left: 0,
  });


  const handleMouseDown = useCallback((e, direction) => {
    e.preventDefault();

    const originalWidthPercent = dimensions.widthPercent;
    const originalHeight = dimensions.minHeight;
    const originalMouseX = e.pageX;
    const originalMouseY = e.pageY;
    const container = document.getElementById(props.keyValueId);

    if (!container) return;

    const containerWidth = container.offsetWidth; // Get container width in pixels

    const maxWidth = containerWidth; // Maximum width in pixels (100% of container width)
    const minWidthPercent = 5; // Minimum width percentage
    const minHeight = 100; // Minimum height in pixels

    const onMouseMove = (e) => {
      let newWidthPercent = originalWidthPercent;
      let newHeight = originalHeight;

      if (direction === 'right') {
        const deltaX = e.pageX - originalMouseX;
        let newWidth = (originalWidthPercent / 100 * containerWidth) + deltaX;

        // Ensure newWidth doesn't exceed containerWidth
        if (newWidth > maxWidth) {
          newWidth = maxWidth;
        }

        newWidthPercent = (newWidth / containerWidth) * 100;
      } else if (direction === 'left') {
        const deltaX = originalMouseX - e.pageX;
        let newWidth = (originalWidthPercent / 100 * containerWidth) - deltaX;

        // Ensure newWidth doesn't go below minimum width
        const minWidthInPixels = minWidthPercent / 100 * containerWidth;
        if (newWidth < minWidthInPixels) {
          newWidth = minWidthInPixels;
        }

        newWidthPercent = (newWidth / containerWidth) * 100;
      } else if (direction === 'top') {
        newHeight = originalHeight - (e.pageY - originalMouseY);
        if (newHeight < minHeight) {
          newHeight = minHeight;
        }
      } else if (direction === 'bottom') {
        newHeight = originalHeight + (e.pageY - originalMouseY);
      }

      // Update state with new dimensions
      if (newWidthPercent >= minWidthPercent && newWidthPercent <= 100) {
        setDimensions(prev => ({ ...prev, widthPercent: newWidthPercent }));
      }
      if (newHeight >= minHeight) {
        setDimensions(prev => ({ ...prev, minHeight: newHeight }));
      }

      // Update droppedItems with new dimensions
      // updateDroppedItemsWithDimensions(newWidthPercent, newHeight);

      if (props.keyValueId) {
        const widthValue = `${newWidthPercent}`;
        const heightValue = `${newHeight}`;

        handleStyleChange("width", widthValue, "%", props.keyValueId);
        // handleStyleChange("height", heightValue, "px", props.keyValueId);
        handleStyleChange("min-height", heightValue, "px", props.keyValueId);
      }
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  }, [dimensions, props.keyValueId]);


  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    // Store only unique id values in localStorage
    debugger
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);
  const deletebuttonshow = () => {
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
    const resizers = document.getElementsByClassName(props.keyValueId); // Get all elements with the keyValueId class
    if (resizers.length > 0) {
      for (let i = 0; i < resizers.length; i++) {
        resizers[i].classList.toggle("displayblock"); // Toggle the class for each element
      }
    }
  };
  let getId = props.keyValueId;



  console.log(props.widthVa)
  let heightmap;
  switch (true) {
    case props.widthVa > 0 && props.widthVa <= 450:
      heightmap = "map-mobile";
      break;
    case props.widthVa > 450 && props.widthVa <= 850:
      heightmap = "map-tablet";
      break;
    default:
      heightmap = "";
      break;
  }

  useLayoutEffect(() => {
    // Ensure height changes are applied correctly
    const container = document.getElementById(props.keyValueId);
    if (container) {
      container.style.minHeight = `${dimensions.minHeight}px`;
    }
  }, [dimensions.minHeight, props.keyValueId]);





  const position = [21.1909, 81.284221]; // Default map center
  return (<div className="resizable-web-template" style={{ minHeight: dimensions.minHeight, }}>
    <div id={getId} className={`map-size-web-template resizers-web-template ${heightmap} `} style={{ height: dimensions.minHeight, }} onClick={deletebuttonshow} >
      <div className={`resizer-web-template ${props.keyValueId} top-web-template `} onMouseDown={(e) => handleMouseDown(e, 'top')}></div>
      <div className={`resizer-web-template ${props.keyValueId} right-web-template `} onMouseDown={(e) => handleMouseDown(e, 'right')}></div>
      <div className={`resizer-web-template ${props.keyValueId} bottom-web-template `} onMouseDown={(e) => handleMouseDown(e, 'bottom')}></div>
      <div className={`resizer-web-template ${props.keyValueId} left-web-template `} onMouseDown={(e) => handleMouseDown(e, 'left')}></div>
      <MapContainer
        center={position}
        zoom={14}
        style={{ height: "100%", zIndex: "0", width: "100%" }}
        onDragStart={props.handleDrag}
        onDrop={props.handleDrop}
        onDragOver={props.dragover}
        draggable="true"

      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
      </MapContainer>

    </div>
  </div >
  );
};
export default Map;
