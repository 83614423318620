import React, { useEffect, useState } from "react";
const Button = (props) => {
  const [getValueAndStrg, setGetValueAndStrg] = useState([])


  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    // Store only unique id values in localStorage
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);
  const deletebuttonshow = () => {
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(props.keyValueId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
    // document.getElementById("column-one").classList.toggle("element-border");
  };
  let getId = props.keyValueId;

  return (
    <div onClick={deletebuttonshow} onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true" style={{ position: "relative" }} id={getId}>
      <button className='form-button-main-web-template'><input className='button-name-web-template' type="text" placeholder='Click Here' /></button>
    </div>
  )
}
export default Button;