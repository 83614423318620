import React from 'react'

const EditSetting = (props) => {
  return (
    <>
    <div className="editsetting-container">
    <div className="id-section">
        <p className='editsetting-textcolor'>ID :  </p>
        <p className='editsetting-textcolor'>Title :</p>
        
    </div>
    <div className="id-section">
    <input type="text" className='editsetting-input' value={props.id}/>
        <input type="text" className='editsetting-input'/>
    </div>
    </div>
    </>
  )
}

export default EditSetting