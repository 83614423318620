
import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Payment = {

  getPaymentOptions: async (ruppees) => {
    const options = {
      key: 'rzp_test_6gzv7z3KTwC4Gp',
      order_id: '1',
      amount: ruppees + "00",
      currency: 'INR',
      name: 'Techcherry',
      description: 'Software Company',
      handler: function (response) {

        alert("Payment is Successful\n" +
          "razorpay_payment_id: " + response.razorpay_payment_id +
          "\nStatus: " + response.status +
          "\nrazorpay_order_id: " + response.razorpay_order_id +
          "\nrazorpay_signature: " + response.razorpay_signature);
      },
      prefill: {
        name: 'Customer Name',
        email: 'customer@example.com',
        contact: '9039024004'
      },
      theme: {
        color: '#3009cc'
      }
    };
    try {
      const apiUrl = `${WebApiUrl}/api/PaymentGateway/getRazorpayOptions`;
      const response = await axios.post(apiUrl, options, {
        withCredentials: true,
        credentials: 'include',
      });
      //debugger;
      console.log('Response:', response.data.data);
      const rzp1 = await new window.Razorpay(response.data.data);
      console.log(rzp1);
      rzp1.open();
      return response.data.data;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  // ************************* Insert Payment Gateway ***************************
  insertPayment: async (data) => {

    try {
      const apiUrl = `${WebApiUrl}/api/PaymentGateway/PaymentGateway`;

      const response = await axios.post(apiUrl, {
        ord1Id: Number(data.ord1Id),
        orderNo: data.orderNo,
        date: data.date,
        orderRefNo: data.orderRefNo,
        amount: parseFloat(data.amount),
        apiId: Number(data.apiId),
        apiName: data.apiName,
        pgOrderId: data.pgOrderId,
        pgOrderStatus: data.pgOrderStatus,
        pgTrackingId: data.pgTrackingId,
        pgBankRefNo: data.pgBankRefNo,
        pgFailureMessage: data.pgFailureMessage,
        pgPaymentMode: data.pgPaymentMode,
        pgCardName: data.pgCardName,
        pgStatusCode: data.pgStatusCode,
        pgStatusMessage: data.pgStatusMessage,
        transactionDetail: data.transactionDetail,
        remarks: data.remarks,
      }, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log("Response:", response.data);
      return response.data;

    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ************************* Get All Payment Gateway Details ***************************
  getPaymentGatewayDetailsFilter: async (filteredData) => {

    const filtData = filteredData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue,
    }));

    try {
      const apiUrl = `${WebApiUrl}/api/PaymentGateway/GetAllPaymentGateway`;

      const response = await axios.post(
        apiUrl,
        {
          filter: filtData,
          order: [
            {
              propertyName: "id",
              ascending: false,
            },
          ],
          pageNumber: 0,
          pageSize: 500000,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  updatePaymentGatewayPartially: async (data, paymentGatewayId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/PaymentGateway/UpdatePaymentGatewayPartially?paymentGatewayId=${paymentGatewayId}`;
      console.log(apiUrl);
      const response = await axios.patch(apiUrl, data, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log("Response:", response.data);
      return response.data.isSuccess
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again. updateOrderPartially");
      }
    }
  },

};

export default Payment;



















// const express = require('express');
// const app = express();

// // Endpoint to fetch Razorpay options
// app.get('/getRazorpayOptions', (req, res) => {
//   // Replace this with your logic to generate Razorpay options
//   const options = {
//     key: 'rzp_test_6gzv7z3KTwC4Gp',
//     amount: '50000',
//     currency: 'INR',
//     name: 'Techcherry',
//     description: 'Software Company',
//     prefill: {
//       name: 'Customer Name',
//       email: 'customer@example.com',
//       contact: '8319871045'
//     },
//     theme: {
//       color: '#3009cc'
//     }
//   };

//   res.json({ options });
// });

// app.listen(3000, () => {
//   console.log('Server is running on port 3000');
// });
