import React, { useState, useEffect } from 'react';



// const deletebuttonshow = () => {
//   document.getElementById("form-delete").classList.toggle("column-one-delete-web-template");
//   document.getElementById("form").classList.toggle("element-border");
// };

const Form = (props) => {


  const [getValueAndStrg, setGetValueAndStrg] = useState([])


  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    // Store only unique id values in localStorage
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);
  const deletebuttonshow = () => {
    setGetValueAndStrg(getId);
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };
  let getId = props.keyValueId;




  return (
    <div style={{ position: "relative" }} onClick={deletebuttonshow} onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true" id={getId} >
      <div className="input-box-form-main-web-template" >
        <div className="input-box-form-web-template">
          <label className="label-form-web-template" htmlFor="">YOUR LABEL :</label>
          <input className="label-input-small-web-template" type="text" />
        </div>
        <div className="input-box-form-web-template">
          <label className="label-form-web-template" htmlFor="">YOUR LABEL :</label>
          <input className="label-input-small-web-template" type="text" />
        </div>
        <div className="input-box-form-web-template">
          <label className="label-form-web-template" htmlFor="">YOUR LABEL :</label>
          <input className="label-input-small-web-template" type="text" />
        </div>
        <div className="input-box-form-web-template">
          <label className="label-form-web-template" htmlFor="">YOUR LABEL :</label>
          <input className="label-input-small-web-template" type="text" />
        </div>
        <div className="input-box-form-web-template">
          <label className="label-form-web-template" htmlFor="">YOUR LABEL :</label>
          <input className="label-input-small-web-template" type="text" />
        </div>

        <div>
          <button className="form-button-web-template">Submit</button>
        </div>
      </div>
    </div>
  )
}

export default Form;