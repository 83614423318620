import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { saveAs } from 'file-saver';
import ChildComponent from './NewTemplateReact';
import ColumnOne from '../Components/DraggableComponent/ColumnOne';
import TwoColumn from '../Components/DraggableComponent/TwoColumn';

function ReportDesigner() {
    const [components, setComponents] = useState([
        { id: 1, content: <ColumnOne /> },
        { id: 2, content: <TwoColumn /> }
    ]);

    // Serialize state and HTML
    const serializeData = () => {
        const state = JSON.stringify(components);
        const html = components.map(comp =>
            ReactDOMServer.renderToString(<ChildComponent key={comp.id} content={comp.content} />)
        );
        return JSON.stringify({ state, html });
    };

    // Save state and HTML to a JSON file
    const saveToFile = () => {
          const data = serializeData();
          const blob = new Blob([data], { type: 'application/json' });
          saveAs(blob, 'reportDesigner.json');
    };

    // Load state and HTML from a JSON file
    const loadFromFile = async () => {
        try {
            const response = await fetch('/reportDesigner.json');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const text = await response.text();
            console.log('Fetched text:', text); // Log the raw response text
            const data = JSON.parse(text); // Parse the text as JSON
            const { state } = data;
            setComponents(JSON.parse(state));
        } catch (error) {
            console.error('Failed to load data:', error);
        }
    };

    useEffect(() => {
        // Simulate fetching the file
        // Uncomment below line to enable fetching from actual file
        // loadFromFile();
    }, []);

    return (
        <div>
            {components.map(component => (
                <ChildComponent key={component.id} content={component.content} />
            ))}
            <button onClick={saveToFile}>Save Report</button>
            <button onClick={loadFromFile}>Load Report</button>
        </div>
    );
}

export default ReportDesigner;
