import { useState, useEffect, useContext } from "react";
import i18n from "i18next";
import User from "../../API/APIServices/User";
import { useNavigate } from "react-router-dom";
import UserApi from '../../API/APIServices/User';
import UserCredential from "../../API/APIServices/UserCredential";
import Company from "../../API/APIServices/Company";
import MyContext from "../MyContext";

function MainButton(props) {

    const { setLoginSuccess } = useContext(MyContext)
    //*********************{Button Script}*********************//
    var modalOpen = props.modelOpen
    const [isHovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };
    const handleMouseLeave = () => {
        setHovered(false);
    };

    const [isHoveredPay, setHoveredPay] = useState(false);
    const handleMouseEnterPay = () => {
        setHoveredPay(true);
    };
    const handleMouseLeavePay = () => {
        setHoveredPay(false);
    };

    const [isHovered1, setHovered1] = useState(false);
    const handleMouseEnter1 = () => {
        setHovered1(true);
    };
    const handleMouseLeave1 = () => {
        setHovered1(false);
    };
    const [isHovered2, setHovered2] = useState(false);
    const handleMouseEnter2 = () => {
        setHovered2(true);
    };
    const handleMouseLeave2 = () => {
        setHovered2(false);
    };
    const [isHovered3, setHovered3] = useState(false);
    const handleMouseEnter3 = () => {
        setHovered3(true);
    };
    const handleMouseLeave3 = () => {
        setHovered3(false);
    };

    const [isHovered4, setHovered4] = useState(false);
    const handleMouseEnter4 = () => {
        setHovered4(true);
    };
    const handleMouseLeave4 = () => {
        setHovered4(false);
    };

    const [isHovered5, setHovered5] = useState(false);
    const handleMouseEnter5 = () => {
        setHovered5(true);
    };
    const handleMouseLeave5 = () => {
        setHovered5(false);
    };
    const [isHovered6, setHovered6] = useState(false);
    const handleMouseEnter6 = () => {
        setHovered6(true);
    };
    const handleMouseLeave6 = () => {
        setHovered6(false);
    };

    const [isHovered7, setHovered7] = useState(false);
    const handleMouseEnter7 = () => {
        setHovered7(true);
    };
    const handleMouseLeave7 = () => {
        setHovered7(false);
    };

    const [isHovered8, setHovered8] = useState(false);
    const handleMouseEnter8 = () => {
        setHovered8(true);
    };
    const handleMouseLeave8 = () => {
        setHovered8(false);
    };

    const [isHoveredSubmit, setHoveredSubmit] = useState(false);
    const handleMouseEnterSubmit = () => {
        setHoveredSubmit(true);
    };
    const handleMouseLeaveSubmit = () => {
        setHoveredSubmit(false);
    };


    const handleBack = () => {
        //debugger;
        if (Boolean(modalOpen) === true) {
            props.setSliderCallBack(false);
        }
        else {
            window.history.back();
        }

        // if (Boolean(props.modelOpen) === true) {
        //   props.setSliderCallBack(false);
        //  }
        //  else {
        //  window.history.back();
        // }
    };
    const handlePrint = () => {
        window.print();
    }
    // const [, setModalOpen] = useState('');
    // const [, setPartyDetailModal] = useState('');
    // const showModal = () => {
    //     setModalOpen(true);
    // };
    // const openPartyModal = () => {
    //     setPartyDetailModal(true);
    // };
    // const hideModal = () => {
    //     setModalOpen(false);
    // };

    const changeLanguage = (lng) => {
        console.log("Hello");
        i18n.changeLanguage(lng);
    };

    const [isEnlishLanguage,] = useState(props.isEnlishLanguage);
    const [isHindiLanguage,] = useState(props.isHindiLanguage);
    const [isBtnSetting,] = useState(props.isBtnSetting);
    const [isBtnSaleInvoiceSetting,] = useState(props.isBtnSaleInvoiceSetting);
    const [isBtnOrder,] = useState(props.isBtnOrder);
    const [isBtnPatient,] = useState(props.isBtnPatient);
    const [isBtnSearch,] = useState(props.isBtnSearch);
    const [isBtnChallan,] = useState(props.isBtnChallan);
    const [SavePay,] = useState(props.SavePay);
    const [isSubmit,] = useState(props.isSubmit);
    const [PrintIcon,] = useState(props.PrintIcon);

    const [loginValues] = useState(
        {
            id: 1,
            code: "C0001",
            UserName: "admin",
            Password: "admin",
        }
    )
    const login = () => {
        UserApi.login(loginValues);
    }

    const SaveBtn = () => {
        props.SaveButton();
        // if (props.setSliderCallBack(false) === true) {
        //     props.setSliderCallBack(false);
        // }
    }
    const [logout] = useState(true);  //props.loginbtn
    const navigate = useNavigate();

    const logoutpage = async () => {
        await User.Logout();
        setLoginSuccess(false)
        localStorage.clear()
        navigate("/loginpanel")
        window.location.reload();
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'F10') {
                SaveBtn();
                console.log("saveClick");
            }
        };
        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    const handleSearchClick = () => {
        props.handleSearchClick()
    };

    useEffect(() => {
        // Add the animation class to labels when component mounts
        const labels = document.querySelectorAll('.focus-label');
        labels.forEach(label => {
            label.classList.add('animate-focus');
        });

        // Clean up function to remove the animation class
        return () => {
            labels.forEach(label => {
                label.classList.remove('animate-focus');
            });
        };
    }, []); //

    const [firmName, setFirmName] = useState();
    const [userName, setUserName] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const arrayString = localStorage.getItem('loginUser');
            const arrayToStore = JSON.parse(arrayString);
            setUserName(arrayToStore.userName);
            const data = await Company.GetCompanyById(arrayToStore.compId);
            setFirmName(data.name);
        };

        fetchData();
    }, []);

    return (
        <div className="panel-head-button">
            <div className="navbar-right">
                {PrintIcon && (
                    <div className="Tablebutton5">
                        <button className={`save-button-main-small ${isHovered4 ? 'hovered' : ''}`} //onClick={props.handleExportToPDF}
                            onMouseEnter={handleMouseEnter4} onMouseLeave={handleMouseLeave4} onClick={handlePrint}>
                            <i id="step-2" class="fa-solid fa-print IconFontS1" ></i>
                            {isHovered4 && (
                                <div className="hoverSmallButton">
                                    <i class="fa-solid fa-sort-up ItemIconHover"></i>
                                    Print/F9
                                </div>
                            )}
                        </button>
                    </div>
                )}

                {isBtnPatient && (
                    <div className="button-m5">
                        <button id="SaveBtnMain" onClick={SaveBtn}
                            className={`save-button-main-save ${isHovered7 ? 'hovered' : ''}`}
                            onMouseEnter={handleMouseEnter7} onMouseLeave={handleMouseLeave7} >
                            New Patient
                            {/* <i id="step-2" className="fa-regular fa-floppy-disk im-icon-white"></i> */}
                            {/* <i id="step-2" className="fa-solid fa-file-export im-icon-white"></i> */}
                            {isHovered7 && (
                                <div className="hover-textSave">
                                    <i class="fa-solid fa-sort-up ItemIconHover"></i>
                                    New Patient
                                </div>
                            )}
                        </button>
                    </div>
                )}

                {isBtnSearch && (
                    <div className="button-m5">
                        <button id="SaveBtnMain" onClick={handleSearchClick}
                            className={`save-button-main-save ${isHovered8 ? 'hovered' : ''}`}
                            onMouseEnter={handleMouseEnter8} onMouseLeave={handleMouseLeave8} >
                            Search
                            {/* <i id="step-2" className="fa-regular fa-floppy-disk im-icon-white"></i> */}
                            {/* <i id="step-2" className="fa-solid fa-file-export im-icon-white"></i> */}
                            {isHovered8 && (
                                <div className="hover-textSave">
                                    <i class="fa-solid fa-sort-up ItemIconHover"></i>
                                    Search
                                </div>
                            )}
                        </button>
                    </div>
                )}

                <div className="button-m5">
                    <button id="SaveBtnMain" onClick={SaveBtn}
                        className={`save-button-main-save ${isHovered ? 'hovered' : ''}`}
                        onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                        Save/F10
                        <i id="step-2" className="fa-regular fa-floppy-disk im-icon-white"></i>
                        {isHovered && (
                            <div className="hover-textSave">
                                <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                Save/F10
                            </div>
                        )}
                    </button>
                </div>
                {SavePay && (

                    <div className="button-m5">
                        <button id="SaveBtnMain" onClick={SaveBtn}
                            className={`save-button-main-save ${isHoveredPay ? 'hovered' : ''}`}
                            onMouseEnter={handleMouseEnterPay} onMouseLeave={handleMouseLeavePay} >
                            Save & Pay
                            <i id="step-2" className="fa-regular fa-floppy-disk im-icon-white"></i>
                            {/* <i id="step-2" className="fa-solid fa-file-export im-icon-white"></i> */}
                            {isHoveredPay && (
                                <div className="hover-textSave">
                                    <i class="fa-solid fa-sort-up ItemIconHover"></i>
                                    Save & Pay
                                </div>
                            )}
                        </button>
                    </div>
                )}

                {isSubmit && (

                    <div className="button-m5">
                        <button id="SaveBtnMain" onClick={SaveBtn}
                            className={`save-button-main-save ${isHoveredSubmit ? 'hovered' : ''}`}
                            onMouseEnter={handleMouseEnterSubmit} onMouseLeave={handleMouseLeaveSubmit} >
                            Submit
                            <i id="step-2" className="fa-regular fa-floppy-disk im-icon-white"></i>
                            {/* <i id="step-2" className="fa-solid fa-file-export im-icon-white"></i> */}
                            {isHoveredSubmit && (
                                <div className="hover-textSave">
                                    <i class="fa-solid fa-sort-up ItemIconHover"></i>
                                    Submit
                                </div>
                            )}
                        </button>
                    </div>
                )}

                <div className="button-m5">
                    <button className={`save-button-main ${isHovered1 ? 'hovered' : ''}`}
                        onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1} onClick={() => window.location.reload()}>Reset
                        <i id="" className="fa-solid fa-rotate im-icon-2" ></i>
                        {isHovered1 && (
                            <div className="hover-textSave">
                                <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                Reset/F8
                            </div>
                        )}
                    </button>
                </div>
                {isBtnOrder && (
                    <div className="button-m5">
                        <button onClick={props.showModal1} className={`save-button-main ${isHovered5 ? 'hovered' : ''}`}
                            onMouseEnter={handleMouseEnter5} onMouseLeave={handleMouseLeave5}>Order
                            <i id="" className="fa-solid fa-rotate im-icon-2" ></i>
                            {isHovered5 && (
                                <div className="hover-textSave">
                                    <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                    Order
                                </div>
                            )}
                        </button>
                    </div>
                )}
                {isBtnChallan && (
                    <div className="button-m5">
                        <button onClick={props.showModal2} className={`save-button-main ${isHovered6 ? 'hovered' : ''}`}
                            onMouseEnter={handleMouseEnter6} onMouseLeave={handleMouseLeave6}>Challan
                            <i id="step-2" className="fa-solid fa-rotate im-icon-2" ></i>
                            {isHovered6 && (
                                <div className="hover-textSave">
                                    <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                    Challan
                                </div>
                            )}
                        </button>
                    </div>
                )}
                {isEnlishLanguage && (

                    <div onClick={() => changeLanguage('en')} className="button-m5">
                        <button className="save-button-main">Eng
                            <i id="step-1" className="fa-solid fa-rotate im-icon-2" ></i>
                        </button>
                    </div>
                )}
                {isHindiLanguage && (

                    <div onClick={() => changeLanguage('hin')} className="button-m5">
                        <button onClick={() => changeLanguage('hin')} className="save-button-main">Hindi
                            <i id="step-4" className="fa-solid fa-rotate im-icon-2" ></i>
                        </button>
                    </div>
                )}
                {isBtnSetting && (
                    <div onClick={() => {
                        props.showModal()
                        // ; props.OpenPartyModal();
                    }} className="button-m5">
                        <button className={`save-button-main ${isHovered2 ? 'hovered' : ''}`}
                            onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}>Setting
                            <i id="step-2" className="fa-solid fa-gear im-icon-2" ></i>
                            {isHovered2 && (
                                <div className="hover-textSave">
                                    <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                    Setting/F9
                                </div>
                            )}
                        </button>
                    </div>
                )}
                {isBtnSaleInvoiceSetting && (
                    <div onClick={() => { props.OpenPartyModal(); }} className="button-m5">
                        <button className={`save-button-main ${isHovered2 ? 'hovered' : ''}`}
                            onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}>Setting
                            <i id="step-2" className="fa-solid fa-gear im-icon-2" ></i>
                            {isHovered2 && (
                                <div className="hover-textSave">
                                    <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                    Setting/F9
                                </div>
                            )}
                        </button>
                    </div>
                )}
                <div onClick={handleBack} className="button-m5">
                    <button className={`save-button-main ${isHovered3 ? 'hovered' : ''}`}
                        onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3}>
                        <i id="step-2" className="fa-solid fa-arrow-left-long im-IconRight" onClick={handleBack}></i>
                        Back/ESC
                        {isHovered3 && (
                            <div className="hover-textSave">
                                <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                Back/ESC
                            </div>
                        )}
                    </button>
                </div>
                {/* <div className="button-m5">
                    <button onClick={login}>login</button>
                </div> */}
                <div className="button-m5">
                    {logout && <button onClick={logoutpage} style={{ marginLeft: "10px" }} className="save-button-main button-m5">Logout</button>}
                </div>

                <div className="loggedInDataDiv" >
                    <label className="loggedInDataLabel">Hi,{userName}</label>
                    <label className="loggedInDataLabel">{firmName}</label>
                </div>
            </div>
        </div>
    );
}

export default MainButton;