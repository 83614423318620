
import React, { useEffect, useState } from "react";

const CheckBox = (props) => {

  // const deletebuttonshow = () => {
  //   document.getElementById("column-one-delete").classList.toggle("column-one-delete");
  //   document.getElementById("column-one").classList.toggle("element-border");
  // };


  const [getValueAndStrg, setGetValueAndStrg] = useState([])


  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    // Store only unique id values in localStorage
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);
  const deletebuttonshow = () => {
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(props.keyValueId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };
  let getId = props.keyValueId;



  return (
    <div style={{ position: "relative" }} onClick={deletebuttonshow} onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover} id={getId}
      draggable="true"><input className='' type="checkbox" name="" /></div>
  )
}

export default CheckBox