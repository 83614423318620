import React from 'react'

const Radio = (props) => {
  return (
    <div   style={{position:"relative" }} onDragStart={props.handleDrag}
    onDrop={props.handleDrop}
    onDragOver={props.dragover}
    draggable="true"><input type="radio" name="" id="" /></div>
  )
}

export default Radio