import React, { useState, useEffect } from 'react';
import moment from 'moment';

const Calendar = (props) => {
  const [currentDate, setCurrentDate] = useState(moment());

  const startOfWeek = currentDate.clone().startOf('month').startOf('week');
  const endOfWeek = currentDate.clone().endOf('month').endOf('week');

  const calendarRows = [];
  let calendarDay = startOfWeek;

  while (calendarDay.isSameOrBefore(endOfWeek, 'day')) {
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.push(
        <div key={calendarDay.format('YYYY-MM-DD')} className="calendar-day-web-template">
          {calendarDay.format('D')}
        </div>
      );
      calendarDay = calendarDay.clone().add(1, 'day');
    }
    calendarRows.push(
      <div key={calendarDay.format('YYYY-MM-DD')} className="calendar-row-web-template">
        {weekDays}
      </div>
    );
  }

  const [getValueAndStrg, setGetValueAndStrg] = useState([]);

  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    // Store only unique id values in localStorage
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);

  const deletebuttonshow = () => {
    setGetValueAndStrg(getId);
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };

  let getId = props.keyValueId;

  return (
    <div
      className="calendar-web-template"
      onClick={deletebuttonshow}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true"
      id={getId}
    >
      <div className="calendar-header-web-template">
        <button onClick={() => setCurrentDate(currentDate.clone().subtract(1, 'month'))}>
          <i className="fa-solid fa-caret-left"></i>
        </button>
        <h2>{currentDate.format('MMMM YYYY')}</h2>
        <button onClick={() => setCurrentDate(currentDate.clone().add(1, 'month'))}>
          <i className="fa-solid fa-caret-right"></i>
        </button>
      </div>
      <div className="calendar-grid-web-template">{calendarRows}</div>
    </div>
  );
};

export default Calendar;
