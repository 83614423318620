import React, { useState, useEffect, useContext } from "react";
import './KotStylesheet.css';
import { Link } from 'react-router-dom';
import OrderApi from "../../API/APIServices/Order";
import Company from "../../API/APIServices/Company";
import moment from 'moment';
import Loader from "../../Inventory/Props/Loader";
import AppHeader from '../AppHeader';
// import ReactPullToRefresh from "react-pull-to-refresh";
import { useNavigate } from "react-router-dom";
import MyContext from "../../Inventory/MyContext";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";

const OrderList = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const { appToWebLink, webToAppLink } = useContext(MyContext);

    const sidebar = (event) => {
        event.preventDefault();
        try {
            document.getElementById("sidebar1").style.left = "0px";
            if (document.getElementById("sidebar1").style.left === "0px") {
                document.getElementById('scrollBar').classList.remove('scroll-container');
                document.getElementById('scrollBar').style.overflow = "hidden";
            }
        }
        catch { }
    }

    const sidebarclose = () => {
        try {
            document.getElementById("sidebar1").style.left = "-300px";
            document.getElementById("body").classList.toggle("back-blur");
            document.getElementById('scrollBar').classList.add('scroll-container');
            document.getElementById('scrollBar').style.overflow = "scroll";
        }
        catch { }
    }

    const [Modal, setModal] = useState(false);

    const openSearchModal = () => {
        setModal(true);
        console.log("click");
        document.getElementById('scrollBar').classList.remove('scroll-container');
        document.getElementById('scrollBar').style.overflow = "hidden";
    }

    const closeSearchModal = () => {
        setModal(false);
        document.getElementById("body").classList.toggle("back-blur");
        document.getElementById('scrollBar').classList.add('scroll-container');
        document.getElementById('scrollBar').style.overflow = "scroll";
    };

    const [OrderAllocateModal, setOrderAllocateModal] = useState(false);

    const openOrderAllocateModal = () => {
        setOrderAllocateModal(true);
    }

    const closeOrderAllocateModal = () => {
        setOrderAllocateModal(false);
        sidebarclose();
    };

    const [tableOrder, setTableOrder] = useState([]);
    const [serviceTypeId, setServiceTypeId] = useState();

    const fetchData = async (serviceId) => {
        const isoString = await CurrentDateAndTime.getCurrentDate();
        const formattedDate = isoString;
        try {
            // if (serviceId == undefined)
            //     serviceId = serviceTypeId;
            setServiceTypeId(serviceId)

            setLoading(true)
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);
            const filterData = [
                // {
                //     fieldName: "overallOrderStatus",
                //     operatorName: "NotEqual",
                //     compareValue: "Done"
                // },
                // {
                //     fieldName: "overallOrderStatus",
                //     operatorName: "NotEqual",
                //     compareValue: "Returned"
                // },
                {
                    fieldName: "serviceTypeId",
                    operatorName: "Equal",
                    compareValue: serviceId
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "Equal",
                    compareValue: "Confirm"
                },
                {
                    fieldName: "deliveryDate",
                    operatorName: "Equal",
                    compareValue: formattedDate
                },
                // {
                //     fieldName: "hasRunningOrder",
                //     operatorName: "Equal",
                //     compareValue: true
                // },
                // {
                //     fieldName: "overallOrderStatus",
                //     operatorName: "Equal",
                //     compareValue: "Confirm"
                // },
            ]
            // const data = await OrderApi.getOrderListByOrderNoFilter(filterData);
            // const data = await OrderApi.getOrderReportFilter(filterData);
            const data = await OrderApi.getRawOrderReport(filterData);
            console.log("data");
            console.log(data);
            const filteredData = data.sort((a, b) => new Date(a.orderConfirmedOn) - new Date(b.orderConfirmedOn))
            setTableOrder(filteredData);
            setHomePickup([]);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const serviceType = localStorage.getItem("KotServiceType");
        if (serviceType != 751 && serviceType != undefined && serviceType != null) {
            console.log("if");
            fetchHomeAndPickUp(serviceType)
        }
        else {
            console.log("else");
            fetchData(751);
        }
    }, [])

    const handleLogOutClick = () => {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        window.location.href = `${webToAppLink}/loginpanel`;
        // window.location.href = "http://122.168.117.143:44483/loginpage";
    }

    const [serviceType, setServiceType] = useState();

    useEffect(() => {          
        const fetchData = async () => {
            try {
                const data = await OrderApi.getServiceType();
                setServiceType(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);


    const handleOrderAllocateChange = (event) => {
        const val = event.target.value;
        console.log(val);
        setServiceTypeId(val);
    }

    const [homePickup, setHomePickup] = useState([]);

    const fetchHomeAndPickUp = async (serviceTypeId) => {
        const isoString = await CurrentDateAndTime.getCurrentDate();
        const formattedDate = isoString;

        try {
            setLoading(true)
            localStorage.setItem("KotServiceType", serviceTypeId);
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);
            const filterData = [
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Done"
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Returned"
                },
                {
                    fieldName: "serviceTypeId",
                    operatorName: "Equal",
                    compareValue: serviceTypeId
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Cancel"
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Pending"
                },
                {
                    fieldName: "deliveryDate",
                    operatorName: "Equal",
                    compareValue: formattedDate
                },
            ]
            // const data = await OrderApi.getOrderReportFilter(filterData);
            const data = await OrderApi.getRawOrderReport(filterData);
            console.log(data);
            setHomePickup(data);
            setTableOrder([]);
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    }

    const SaveOrderStatusClick = async () => {
        //fetchData(serviceTypeId);
        fetchHomeAndPickUp(serviceTypeId);
        closeOrderAllocateModal();
    }
    const [loginUserDetails, setLoginUserDetails] = useState({});
    const [firmName, setFirmName] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                setLoginUserDetails(loginUser);
                const data = await Company.GetCompanyById(loginUser.compId);
                setFirmName(data.name);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const [showRunningOrderColor, setRunningOrderColor] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Company.GetCompanyById(loginUserDetails.compId)
                setRunningOrderColor(data.isAllowDiffColorRunningOrder)
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    })

    function convertTo12HourFormat(timeString) {
        // Split the time string into hours, minutes, and seconds
        const [hours, minutes, seconds] = timeString.split(':').map(Number);

        // Create a new Date object to convert the time
        const date = new Date();
        date.setHours(hours, minutes, seconds);

        // Get the hours and minutes in 12-hour format
        const hours12 = (date.getHours() + 11) % 12 + 1; // Convert to 12-hour format
        const minutesStr = ('0' + date.getMinutes()).slice(-2); // Add leading zero if needed

        // Determine if it's AM or PM
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

        // Return the formatted time string
        return `${hours12}:${minutesStr} ${ampm}`;
    }

    return (
        // <ReactPullToRefresh onRefresh={handleRefresh}>
        <div style={{ backgroundColor: "white" }}>
            <AppHeader title={"KOT Manager"} link={"/OrderList"} setLoading={setLoading} fetchData={fetchData} sidebar={sidebar} />
            {/* <ReactPullToRefresh onRefresh={handleRefresh}> */}
            <div style={{ backgroundColor: "ghostwhite", marginTop: "50px" }} onClick={sidebarclose}>
                <div className="p-1">
                    {/*<label className="ques p-1">Order List</label>*/}
                    {tableOrder !== null && tableOrder != undefined && tableOrder.length > 0 ? tableOrder.slice().reverse()
                        .filter(data => (data.hasRunningOrder && data.hasRunningOrder == true) || data.overallOrderStatus === "Confirm")
                        .map((data, index) => {
                            let backgroundStyle = { backgroundColor: "white" }; // Default background color is white

                            if (data.runningOrderCounter && data.runningOrderCounter > 0) {
                                if (!data.isOpen) {
                                    // Apply gradient background if the order counter is > 0 and the item is not open
                                    backgroundStyle.backgroundImage = 'linear-gradient(45deg, rgba(215, 108, 108, 0.6), rgba(16, 208, 0, 0.25))';
                                } else if (showRunningOrderColor) {
                                    // Apply a lighter red background if runningOrderColor should be shown
                                    backgroundStyle.backgroundColor = "rgba(230, 159, 159, 0.42)"; // Or "rgb(234, 163, 163)";
                                }
                            } else if (!data.isOpen) {
                                // Apply a yellow background if the item is not open and no running order counter
                                backgroundStyle.backgroundColor = "rgba(33, 202, 19, 0.11)";
                            }
                            console.log("hello", showRunningOrderColor);
                            return (
                                <Link to={`/KotOrderDetail?ord1Id=${data.ord1Id}&ord2Id=${data.ord2Id}`} style={{ textDecoration: 'none' }}>
                                    {/* <Link to="/KotOrderDetail" style={{ textDecoration: "none" }}> */}
                                    <div className={`listDiv ${data.runningOrderCounter && data.runningOrderCounter > 0 ? 'blinking' : ''}`} style={{ ...backgroundStyle, marginTop: "00px" }}>
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">Order No : &nbsp;</label>
                                                <label className="ans">{data.orderNo}</label>
                                            </div>
                                            <div className="flex-start">
                                                <label className="ques">Type : &nbsp;</label>
                                                <label className="ans">{data.serviceTypeName}</label>
                                            </div>
                                        </div>
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">Order Date : &nbsp;</label>
                                                <label className="ans">{moment(data.orderDate).format('DD-MM-YYYY')}</label>
                                            </div>
                                            <div className="flex-start">
                                                <label className="ques">Table : &nbsp;</label>
                                                <label className="ans">{data.tableName}</label>
                                            </div>
                                        </div>
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">Order Time : &nbsp;</label>
                                                <label className="ans">{convertTo12HourFormat(data.orderTime)}</label>
                                            </div>
                                            <div className="flex-start">
                                                <label className="ques">Person : &nbsp;</label>
                                                <label className="ans">{data.noOfPerson} = C-{data.noChildPerson} A-{data.noAdultPerson}</label>
                                            </div>
                                        </div>
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">Confirm Date:&nbsp;</label>
                                                <label className="ans">{moment.utc(data.orderConfirmedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label>
                                            </div>
                                            <div className="flex-start">
                                                <label className="ques">R.Count :&nbsp;</label>
                                                <label className="ans">{data.runningOrderCounter !== null ? data.runningOrderCounter : 0}</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="space-between">
                                            <label className="ques" style={{ color: "darkred" }}>InQueue : {data.countQueue}</label>
                                            <label className="ques" style={{ color: "#7e348b" }}>Process : {data.countProcess}</label>
                                            <label className="ques" style={{ color: "#1b6a1b" }}>Compltd : {data.countComplete}</label>
                                            {/* <label className="ques" style={{ color: "#000880" }}>Pickup : {data.countDelivered}</label> */}
                                        </div>
                                    </div>
                                </Link>
                            );
                        }) :
                        // <p style={{ textAlign: "center" }}>No Order Available</p>
                        <div></div>
                    }

                    {/******* for homeDelivery and Pickup*********/}
                    {homePickup !== null && homePickup != undefined && homePickup.length > 0 ? homePickup.slice().reverse()
                        .map((data, index) => {
                            return (
                                <Link to={`/KotOrderDetail?ord1Id=${data.ord1Id}&ord2Id=${data.ord2Id}`} style={{ textDecoration: 'none' }}>
                                    {/* <Link to="/KotOrderDetail" style={{ textDecoration: "none" }}> */}
                                    <div className={`listDiv`} style={{ marginTop: "00px" }}>
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">Order No : &nbsp;</label>
                                                <label className="ans">{data.orderNo}</label>
                                            </div>
                                            <div className="flex-start">
                                                <label className="ques">Type : &nbsp;</label>
                                                <label className="ans">{data.serviceTypeName}</label>
                                            </div>
                                        </div>
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">Order Date : &nbsp;</label>
                                                <label className="ans">{moment(data.orderDate).format('DD-MM-YYYY')}</label>
                                            </div>
                                            <div className="flex-start">
                                                <label className="ques">Order Time : &nbsp;</label>
                                                <label className="ans">{convertTo12HourFormat(data.orderTime)}</label>
                                            </div>
                                        </div>
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">Confirm Date:&nbsp;</label>
                                                <label className="ans">{moment.utc(data.orderConfirmedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="space-between">
                                            <label className="ques" style={{ color: "darkred" }}>InQueue : {data.countQueue}</label>
                                            <label className="ques" style={{ color: "#7e348b" }}>Process : {data.countProcess}</label>
                                            <label className="ques" style={{ color: "#1b6a1b" }}>Compltd : {data.countComplete}</label>
                                            {/* <label className="ques" style={{ color: "#000880" }}>Pickup : {data.countDelivered}</label> */}
                                        </div>
                                    </div>
                                </Link>
                            );
                        }) : <div></div>}
                </div>
            </div>
            {/* </ReactPullToRefresh> */}
            {/************************** Order Allocate Modal ***************/}
            {OrderAllocateModal && (
                <div className="modal-back1">
                    <div className="kotStatus-modal">
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                            <label className="fw-600" style={{ fontSize: "18px" }}>Order Allocate</label>
                            <i className="fa-solid fa-xmark xmark" onClick={closeOrderAllocateModal}></i>
                        </div>
                        <hr />
                        <div className="">
                            <label className="ques">Service Type</label><br />
                            <select className="w-100 p-2" onChange={handleOrderAllocateChange} value={serviceTypeId}>
                                {(() => {
                                    if (serviceType !== null && Array.isArray(serviceType)) {
                                        return serviceType.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.name}
                                            </option>
                                        ))
                                    }
                                })()}
                            </select>
                        </div>
                        <hr />
                        <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                            <button className="greenBtn" style={{ width: "25%" }} onClick={SaveOrderStatusClick}>Save</button>
                            <button className="redBtn" style={{ width: "25%" }} onClick={closeOrderAllocateModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            {/*############################################### Side Bar #######################################*/}
            <div className='backbtn'>
                <div id="" className="wrapper1">
                    <div className="">
                        <div id="sidebar1" className="sidebar1">
                            <div className="space-between side-topbar">
                                <div className='flex-start'>
                                    <div className="img_icon">
                                        <img className="userProfileIcon" src="https://clipart-library.com/images/kTKo7BB8c.png" alt="user" />
                                    </div>
                                    <div className="" style={{ margin: "7px 10px auto" }}>
                                        <h5 className="c-w">{loginUserDetails.userName}</h5>
                                        <p className="c-w" style={{ marginTop: "-8px" }}>{firmName}</p>
                                    </div>
                                </div>
                                <p className="back_button" onClick={sidebarclose} style={{ color: "white", textAlign: "end", padding: "8PX" }}>
                                    <i className="fa-solid fa-xmark" style={{ color: "white", fontSize: "22px" }}></i>
                                </p>
                            </div>

                            <div className="side-bottomBar">
                                {/*<Link to="" style={{ textDecoration: "none" }}>*/}
                                {/*    <div className="flex-start sideBarDiv linksDiv" >*/}
                                {/*        <i className="fa-regular fa-circle-dot"></i>*/}
                                {/*        <label className=""> &nbsp; Order Allocate</label>*/}
                                {/*    </div>*/}
                                {/*</Link>*/}
                                {/*<hr />*/}
                                <Link to="/TableOrderAllocation" style={{ textDecoration: "none" }}>
                                    <div className="flex-start sideBarDiv linksDiv">
                                        <i className="fa-solid fa-chair"></i>
                                        <label className=""> &nbsp; Table Allocate</label>
                                    </div>
                                </Link>
                                <hr />
                                <div className="flex-start sideBarDiv linksDiv" onClick={openOrderAllocateModal}>
                                    <i className="fa-solid fa-rectangle-list"></i>
                                    <label className=""> &nbsp; Service Allocate</label>
                                </div>
                                <hr />
                                {/* <Link to="/OrderList" style={{ textDecoration: "none" }}> */}
                                <div className="flex-start sideBarDiv linksDiv" onClick={sidebarclose}>
                                    <i className="fa-solid fa-list"></i>
                                    <label className=""> &nbsp; Order List</label>
                                </div>
                                {/* </Link> */}
                                <hr />
                                <Link to="/PickupList" style={{ textDecoration: "none" }}>
                                    <div className="flex-start sideBarDiv linksDiv">
                                        <i className="fa-solid fa-rectangle-list"></i>
                                        <label className=""> &nbsp; Table Pickup List</label>
                                    </div>
                                </Link><hr />
                                <div className="flex-start sideBarDiv linksDiv" onClick={handleLogOutClick}>
                                    <i className="fa-solid fa-power-off"></i>
                                    <label className=""> &nbsp; Log Out</label>
                                </div><hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
        // </ReactPullToRefresh>
    );
};

export default OrderList;
