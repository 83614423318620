import { useEffect, useState } from "react";
import "./POSInvoice.css";
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import OrderApi from "../../API/APIServices/Order";
import Company from "../../API/APIServices/Company";

const KotPickUp = ({ orderList, openKotItemModal, handleViewBtn }) => {

    const navigate = useNavigate();
    const [progress] = useState(70);
    const [orderListPickup, setOrderListPickup] = useState();


    useEffect(() => {
        const fetchOrderData = async () => {
            try {
                // const filterData = [
                //          {
                //             fieldName: "serviceTypeId",
                //             operatorName: "Equal",
                //             compareValue: 753 //Pickup
                //         }
                // ]
                // const data = await OrderApi.getOrderReportFilter(filterData);
                // console.log(data);
                setOrderListPickup(orderList);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchOrderData();
    }, [orderList])

    const handleBillPrintButton = (id) => {
        navigate("/OrderTakerBillPrint", {
            state: {
                id
            }
        });
    }

    const [showRunningOrderColor, setRunningOrderColor] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                const data = await Company.GetCompanyById(loginUser.compId)
                setRunningOrderColor(data.isAllowDiffColorRunningOrder)
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    })

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);
        //console.log(hours + ":" + minutes);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00`;
        }
    }

    const timeFormat = (twentyFourHourTime) => {
        const [startTime, endTime] = twentyFourHourTime.split('-');
        const convertedStartTime = convertTo12HourFormat(startTime.trim());
        const convertedEndTime = convertTo12HourFormat(endTime.trim());
        const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
        return convertedTimeRange;
    }

    return (
        <div>
            <table className="tableStyle mt-4">
                <thead>
                    <tr>
                        <th className="thStyle">Select</th>
                        <th className="thStyle">Order No.</th>
                        <th className="thStyle">Mobile No.</th>
                        <th className="thStyle">Order Date</th>
                        <th className="thStyle">Order Time Slot</th>
                        <th className="thStyle">Pickup Date</th>
                        <th className="thStyle">Progress Status</th>
                        <th className="thStyle">KOT</th>
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        if (orderListPickup !== null && Array.isArray(orderListPickup)) {
                            return orderListPickup //.filter(data => (data.hasRunningOrder && data.hasRunningOrder == true) || data.overallOrderStatus === "Confirm")
                                // .slice().reverse()
                                .map((data, index) => {
                                    let backgroundStyle = { backgroundColor: "white" };
                                    if (data.runningOrderCounter && data.runningOrderCounter > 0) {
                                        if (showRunningOrderColor) {
                                            backgroundStyle.backgroundColor = "rgba(255, 192, 203, 0.64)"; // Or "rgb(234, 163, 163)";
                                        }
                                    }
                                    return (
                                        <tr className="tableHover" style={{ ...backgroundStyle, cursor: "pointer" }}>
                                            <td className="tdStyle" style={{ textAlign: "center" }}><button onClick={() => openKotItemModal(data.ord1Id, data.tableName)} style={{ padding: "5px 12px" }}>{index + 1}</button></td>
                                            <td className="tdStyle">{data.orderNo}</td>
                                            <td className="tdStyle">{data.accMobileNo}</td>
                                            <td className="tdStyle">{moment(data.orderDate).format('DD-MM-YYYY')}</td>
                                            {/* <td className="tdStyle">{timeFormat(data.deliveryTime)}</td> */}
                                            <td className="tdStyle">{(data.timeSlotName)}</td>
                                            <td className="tdStyle">{moment(data.deliveryDate).format('DD-MM-YYYY')}</td>
                                            <td className="tdStyle">
                                                <div style={{ width: '100%', backgroundColor: 'rgb(178 178 178)', height: '20px' }}>
                                                    <div
                                                        style={{
                                                            width: `${((orderListPickup[index].countQueue * 100) / (orderListPickup[index].countItems * 4)) +
                                                                ((orderListPickup[index].countProcess * 100) / (orderListPickup[index].countItems * 2)) +
                                                                ((orderListPickup[index].countComplete * 100) / (orderListPickup[index].countItems * 1.25)) +
                                                                ((orderListPickup[index].countDelivered * 100) / (orderListPickup[index].countItems * 1))}%`,
                                                            backgroundColor: '#00b900',
                                                            height: '100%',
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}
                                                    ><h6 style={{ fontWeight: "bold", color: "white", marginLeft: "30px" }}>
                                                            {Math.floor(
                                                                ((orderListPickup[index].countQueue * 100) / (orderListPickup[index].countItems * 4)) +
                                                                ((orderListPickup[index].countProcess * 100) / (orderListPickup[index].countItems * 2)) +
                                                                ((orderListPickup[index].countComplete * 100) / (orderListPickup[index].countItems * 1.25)) +
                                                                ((orderListPickup[index].countDelivered * 100) / (orderListPickup[index].countItems * 1))
                                                            )}%
                                                        </h6></div>
                                                </div>
                                            </td>
                                            <td className="tdStyle">
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <button className="viewBtn" onClick={() => handleBillPrintButton(data.ord1Id)}>Receipt</button>
                                                </div>
                                                <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                                                    <button className="viewBtn" onClick={() => handleViewBtn(data.ord1Id, data.tableName)}>View</button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                });
                        }
                        else {
                            //alert("else")
                        }
                    })()}
                </tbody>
            </table>
        </div>
    );
};

export default KotPickUp;
