import React, { useState, useEffect } from 'react'

const ToggleBox = (props) => {





  const [getValueAndStrg, setGetValueAndStrg] = useState([])


  useEffect(() => {
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);
  const deletebuttonshow = () => {
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
  };
  let getId = props.keyValueId;

  const [toggledetail, setToggleDetail] = useState(false)
  const [toggledetail1, setToggleDetail1] = useState(false)

  const toggleoneshow = () => {
    setToggleDetail(!toggledetail)
    document.getElementById("arrowtoggle").classList.toggle("rotateicon-toggle-web-template");
  }

  const toggleoneshow1 = () => {
    setToggleDetail1(!toggledetail1)
    document.getElementById("arrow1toggle").classList.toggle("rotateicon-toggle-web-template");
  }


  return (

    <div className="togglebox-web-template" style={{ position: "relative", maxWidth: "100%" }}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      id={getId}
      draggable="true" onClick={deletebuttonshow}>

      <div className="togglebox-1-web-template" onClick={toggleoneshow}>
        <p className="toggle-text-web-template">Accordion</p>
        <i
          id="arrowtoggle"
          className="fa-solid fa-caret-right add-comopnent-icon"
        ></i>
      </div>
      {toggledetail && (
        <div className="toggle-details-web-template"></div>
      )

      }

      <div className="togglebox-1-web-template" onClick={toggleoneshow1}>
        <p className="toggle-text-web-template"> Accordion</p>
        <i
          id="arrow1toggle"
          className="fa-solid fa-caret-right add-comopnent-icon"
        ></i>
      </div>
      {toggledetail1 && (
        <div className="toggle-details-web-template"></div>
      )

      }


    </div>
  )
}

export default ToggleBox