import React from 'react'
import ColumnOneMain from './ColumnOneMain';



const ThreeColumn = (props) => {

  let getId = props.keyValueId;

  const outlineblu2three1 = () => {

    document.getElementById("three-column-main").classList.toggle("element-border");
    document.getElementById("three-column-delete").classList.toggle("column-one-delete-web-template");
    document.getElementById(getId+ "resizerthree").classList.toggle("displayblock");
    document.getElementById(getId+ "resizerthree1").classList.toggle("displayblock");
    document.getElementById(getId+ "resizerthree2").classList.toggle("displayblock");
    document.getElementById(getId+ "resizerthree3").classList.toggle("displayblock");
  };
  
  
  const outlineblu2three2 = () => {
     document.getElementById("three-column-main").classList.toggle("element-border");
    document.getElementById("three-column-delete").classList.toggle("column-one-delete-web-template");
    document.getElementById(getId+ "resizerthree4").classList.toggle("displayblock");
    document.getElementById(getId+ "resizerthree5").classList.toggle("displayblock");
    document.getElementById(getId+ "resizerthree6").classList.toggle("displayblock");
    document.getElementById(getId+ "resizerthree7").classList.toggle("displayblock");
  };
  
  
  const outlineblu2three3 = () => {
    document.getElementById("three-column-main").classList.toggle("element-border");
    document.getElementById("three-column-delete").classList.toggle("column-one-delete-web-template");
    document.getElementById(getId+ "resizerthree8").classList.toggle("displayblock");
    document.getElementById(getId+ "resizerthree9").classList.toggle("displayblock");
    document.getElementById(getId+ "resizerthree10").classList.toggle("displayblock");
    document.getElementById(getId+ "resizerthree11").classList.toggle("displayblock");
  };

  return (
    <div  style={{position:"relative"}}  onDragStart={props.handleDrag}
    onDrop={props.handleDrop}
    onDragOver={props.dragover}
    draggable="true" className="three-column-web-template" id='three-column-main'>
        <div className="three-column-1-web-template">
        <div className="resizable-web-template column-one-content-web-template" id={getId} onClick={outlineblu2three1}>
        <div className="resizers-web-template">
          <div id={getId+ "resizerthree"} className="resizer-web-template top-web-template"></div>
          <div id={getId+ "resizerthree1"} className="resizer-web-template right-web-template"></div>
          <div id={getId+ "resizerthree2"} className="resizer-web-template bottom-web-template"></div>
          <div id={getId+ "resizerthree3"} className="resizer-web-template left-web-template"></div>
          <ColumnOneMain/>
          </div>
          </div>
        </div>


        <div className="three-column-1-web-template">
            <div className="resizable-web-template column-one-content-web-template" id={getId} onClick={outlineblu2three2}>
        <div className="resizers-web-template">
          <div id={getId+ "resizerthree4"} className="resizer-web-template top-web-template"></div>
          <div id={getId+ "resizerthree5"} className="resizer-web-template right-web-template"></div>
          <div id={getId+ "resizerthree6"} className="resizer-web-template bottom-web-template"></div>
          <div id={getId+ "resizerthree7"} className="resizer-web-template left-web-template"></div>
          <ColumnOneMain/>
          </div>
          </div>
        </div>
        <div className="three-column-1-web-template">
            <div className="resizable-web-template column-one-content-web-template" id={getId} onClick={outlineblu2three3}>
        <div className="resizers-web-template">
          <div id={getId+ "resizerthree8"} className="resizer-web-template top-web-template"></div>
          <div id={getId+ "resizerthree9"} className="resizer-web-template right-web-template"></div>
          <div id={getId+ "resizerthree10"} className="resizer-web-template bottom-web-template"></div>
          <div id={getId+ "resizerthree11"} className="resizer-web-template left-web-template"></div>

          <ColumnOneMain/>
          </div>
          </div>
        </div>
    </div>
  )
}

export default ThreeColumn