import React, { useContext, useEffect, useState } from "react";
import GlobalSelector from "./GlobalSelector";
import Image from "../DraggableComponent/Image";
import MyContext from "../../../Inventory/MyContext";
// import Imagemodal from './Imagemodal';

const DecorationSection = (props) => {
  const [value, setValue] = useState("");
  const [divList, setDivList] = useState([]);
  const [imageModal, setImageModal] = useState(false);
  const [borderColor, setBorderColor] = useState("#000")

  const [borderDisplayStyl, setBorderDisplayStyl] = useState("solid")

  const { value: borderTopLeftRadValue, unit: borderTopLeftRadUnit } = props.extractValueAndUnit(props.currentStyle?.style["border-top-left-radius"] || 'auto');
  const { value: borderBottomRightRadValue, unit: borderBottomRightRadUnit } = props.extractValueAndUnit(props.currentStyle?.style["border-bottom-right-radius"] || 'auto');
  const { value: borderTopRightRadValue, unit: borderTopRightRadUnit } = props.extractValueAndUnit(props.currentStyle?.style["border-top-right-radius"] || 'auto');
  const { value: borderBottomLeftRadValue, unit: borderBottomLeftRadUnit } = props.extractValueAndUnit(props.currentStyle?.style["border-bottom-left-radius"] || 'auto');
  const { value: borderValue, unit: borderUnit } = props.extractValueAndUnit(props.currentStyle?.style["border"] || 'auto');
  let values = borderUnit.split(" ");

  let unit = values[0];   // "px"
  let style = values[1];
  let color = values[2];

  const [colour, setColour] = useState('#ffffff');


  const handleColorChange = (event) => {

    const newColorValue = event.target.value;
    setColour(newColorValue);

    // Call handleStyleChange with the new color value
    props.handleStyleChange("background-color", newColorValue, null, props.id);
  };

  const openimagemodal = () => {
    setImageModal(!imageModal);
  };

  const appendDiv = () => {
    setDivList((prevDivList) => [...prevDivList, { id: prevDivList.length }]);
  };

  const deleteDiv = (id) => {
    setDivList((prevDivList) => prevDivList.filter((item) => item.id !== id));
  };
  const [divListsec, setDivListsec] = useState([]);

  const appendDivSec = () => {
    setDivListsec((prevDivListSec) => [
      ...prevDivListSec,
      { id: prevDivListSec.length },
    ]);
  };

  const deleteDivSec = (id) => {
    setDivListsec((prevDivListSec) =>
      prevDivListSec.filter((item) => item.id !== id)
    );
  };

  const input = document.getElementById("colorbox");
  const colorCode = document.getElementById("input-colorbox");

  const handleChange = (event) => {
    debugger
    const { value, name } = event.target;
    // const { value, name } = event.currentTarget;
    const element = document.getElementById(props.id);
    if (element) {
      element.style.position = value;
      props.updateStyle(props.id, name, value);
    }
  }

  function setColor() {
    debugger
    console.log("Input Color", input.value);
    colorCode.innerHTML = input.value;
  }

  const opacityvalue = (e) => {
    setValue(e.target.value);
    document.getElementById(props.id).style.opacity = e.target.value;
  };

  function setBDRColor() {
    console.log(input.value);
    colorCode.innerHTML = input.value;
    debugger
    setBorderColor(input.value)

    const currentStyle = props.stylesArray.find((styleObj) => styleObj.id === props.id);

    const currentBorder = currentStyle?.style["border"] || "1px solid #000000";
    const [currentBorderWidth, currentBorderStyle] = currentBorder.split(" ").slice(0, 2);

    const newBorderValue = `${currentBorderWidth} ${currentBorderStyle} ${input.value}`;

    props.handleStyleChange("border", newBorderValue, null, props.id);


  }



  const handleBorderStyleChange = (e) => {
    debugger
    const selectedBorderStyle = e.target.value;
    setBorderDisplayStyl(selectedBorderStyle);

    const currentStyle = props.stylesArray.find(styleObj => styleObj.id === props.id);

    const currentBorder = currentStyle?.style["border"];
    const [currentBorderWidth, , currentBorderColor] = currentBorder.split(" ");

    const newBorderValue = `${currentBorderWidth} ${selectedBorderStyle} ${currentBorderColor}`;

    props.handleStyleChange("border", newBorderValue, null, props.id);
  };

  useEffect(() => {
    if (style) {
      setBorderDisplayStyl(style)
    }
    if (color) {
      debugger
      // colorCode.innerHTML = color
      setBorderColor(color)
    }
  }, [style, color]);

  return (
    <>
      <div className="decoration-container">
        <p className="general-paragraph">opacity</p>
        <div className="opacity-range">
          <input
            className="range-input"
            type="range"
            name="opacity"
            min="0"
            max="1"
            step="0.01"
            onChange={handleChange}
          />
          <input
            type="number"
            name="opacity"
            value={value}
            onChange={handleChange}
            min="0"
            max="1"
            step="0.01"
            className="range-inputvalue"
          />
        </div>
        <div className="dimension-margin">
          <p className="genral-heading">Border radius</p>

          <div className="dimension-marginpaddingcomponent">
            <div className="global-selectbutton">
              <GlobalSelector
                placeholder={"0"}
                name={"border-top-left-radius"}
                id={props.id}
                // style={styleValue.style1}
                onChange={props.handleStyleChange}
                value={borderTopLeftRadValue}
                unit={borderTopLeftRadUnit}
              />
              <GlobalSelector
                placeholder={"0"}
                name={"border-bottom-right-radius"}
                id={props.id}
                // style={styleValue.style2}
                onChange={props.handleStyleChange}
                value={borderBottomRightRadValue}
                unit={borderBottomRightRadUnit}
              />
            </div>
            <div className="global-selectbutton">
              <GlobalSelector
                placeholder={"0"}
                name={"border-top-right-radius"}
                id={props.id}
                // style={styleValue.style3}
                onChange={props.handleStyleChange}
                value={borderTopRightRadValue}
                unit={borderTopRightRadUnit}
              />
              <GlobalSelector
                placeholder={"0"}
                name={"border-bottom-left-radius"}
                id={props.id}
                // style={styleValue.style4}
                onChange={props.handleStyleChange}
                unit={borderBottomLeftRadUnit}
                value={borderBottomLeftRadValue}
              />
            </div>
          </div>
        </div>


        <div className="dimension-margin">
          <p className='genral-heading'>Border</p>

          <div className="dimension-marginpaddingcomponent">
            <div className="global-selectbutton">
              <GlobalSelector placeholder={"0"} name={"border"} value={borderValue} unit={unit} id={props.id} color={borderColor} displayStyl={borderDisplayStyl} onChange={props.handleStyleChange} />
              <div className="decoration-sectionoption">
                <p className="general-paragraph">Style</p>
                <select name="display" className='selectorstyle' onChange={handleBorderStyleChange} value={borderDisplayStyl != undefined ? borderDisplayStyl : "solid"}>
                  <option value="none" >none</option>
                  <option value="solid" selected>solid</option>
                  <option value="dotted">dotted</option>
                  <option value="dashed">dashed</option>
                  <option value="double">double</option>
                  <option value="groove">groove</option>
                  <option value="ridge">ridge</option>
                  <option value="inset">inset</option>
                  <option value="outset">outset</option>

                </select>
              </div>
            </div>

            <div className="typography-colorsection">
              <p className="general-paragraph"> Color</p>
              <div className="typography-colorchange">
                <code
                  id="input-colorbox"
                  style={{
                    backgroundColor: "black",
                    width: "80%",
                    fontSize: "medium",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid white",
                    marginRight: "5px",
                    borderRadius: "8px"
                  }}
                ></code>
                <input type="color" onInput={setBDRColor} id='colorbox' value={borderColor != undefined ? borderColor : "#000"} />

              </div>
            </div>
          </div>
        </div>

        <div className="typography-textshadow">
          <div className="text-plus">
            <p className="general-paragraph vertical-justify">Box shadow</p>
            {/* <button className="typo-plus" onClick={appendDiv}>
              <i className="icon-edit fa-solid fa-plus small"></i>
            </button> */}
          </div>
          <div className="shadow-contain">
            <div className="firstcontent-container" >
              <div className="firstcontent">
                <div className="firstcontent-paragicon">
                  <i className="icon-edit fa-solid fa-maximize rotate typoicon"></i>
                  <p className="general-paragraph vertical-justify">
                    0 0 5px 0
                  </p>
                </div>
                <div className="firstcontent-deleteicon">
                  <i className="icon-edit fa-solid fa-t typoicon"></i>
                  {/* <button onClick={() => deleteDiv(div.id)}>
                    <i className="icon-edit fa-solid fa-xmark typoicon"></i>
                  </button> */}
                </div>
              </div>
              {
                <div className="secondcontent">
                  <div className="secondcon-XY">
                    <GlobalSelector placeholder={"0"} name={"X"} onChange={props.handleStyleChange} />
                    <GlobalSelector placeholder={"0"} name={"Y"} onChange={props.handleStyleChange} />
                  </div>
                  <div className="secondcon-XY">
                    <GlobalSelector placeholder={"0"} name={"Blur"} onChange={props.handleStyleChange} />
                    <GlobalSelector placeholder={"0"} name={"Spread"} onChange={props.handleStyleChange} />
                  </div>

                  <div className="typography-colorsection">
                    <p className="general-paragraph"> Color</p>
                    <div className="typography-colorchange">
                      <code
                        id="input-colorbox"
                        style={{
                          backgroundColor: "black",
                          width: "80%",
                          fontSize: "medium",
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          border: "1px solid white",
                          marginRight: "5px",
                          borderRadius: "8px"
                        }}
                      ></code>
                      <input type="color" onInput={setColor} id="colorbox" />
                    </div>
                  </div>
                  <div className="decoration-sectionoption">
                    <p className="general-paragraph">Type</p>
                    <select name="display" className="selectorstyle">
                      <option value="Outside">Outside</option>
                      <option value="Inside" selected>
                        Inside
                      </option>
                    </select>
                  </div>
                </div>
              }
            </div>
            {/* {divList.map((div) => (
              <div className="firstcontent-container" key={div.id}>
                <div className="firstcontent">
                  <div className="firstcontent-paragicon">
                    <i className="icon-edit fa-solid fa-maximize rotate typoicon"></i>
                    <p className="general-paragraph vertical-justify">
                      0 0 5px 0
                    </p>
                  </div>
                  <div className="firstcontent-deleteicon">
                    <i className="icon-edit fa-solid fa-t typoicon"></i>
                    <button onClick={() => deleteDiv(div.id)}>
                      <i className="icon-edit fa-solid fa-xmark typoicon"></i>
                    </button>
                  </div>
                </div>
                {
                  <div className="secondcontent">
                    <div className="secondcon-XY">
                      <GlobalSelector placeholder={"0"} name={"X"} />
                      <GlobalSelector placeholder={"0"} name={"Y"} />
                    </div>
                    <div className="secondcon-XY">
                      <GlobalSelector placeholder={"0"} name={"Blur"} />
                      <GlobalSelector placeholder={"0"} name={"Spread"} />
                    </div>

                    <div className="typography-colorsection">
                      <p className="general-paragraph"> Color</p>
                      <div className="typography-colorchange">
                        <code
                          id="input-colorbox"
                          style={{
                            backgroundColor: "black",
                            width: "80%",
                            fontSize: "medium",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            border: "1px solid white",
                            marginRight: "5px",
                            borderRadius: "8px"
                          }}
                        ></code>
                        <input type="color" onInput={setColor} id="colorbox" />
                      </div>
                    </div>
                    <div className="decoration-sectionoption">
                      <p className="general-paragraph">Type</p>
                      <select name="display" className="selectorstyle">
                        <option value="Outside">Outside</option>
                        <option value="Inside" selected>
                          Inside
                        </option>
                      </select>
                    </div>
                  </div>
                }
              </div>
            ))} */}
          </div>
          <div className="typography-textshadow">
            <div style={{ width: "props.width" }} className="text-plus">
              <p className="general-paragraph vertical-justify">Background</p>
              <button className="typo-plus" onClick={appendDivSec}>
                <i className="icon-edit fa-solid fa-plus small"></i>
              </button>
            </div>
            <div className="shadow-contain">
              <div className="firstcontent-container" >
                <div className="firstcontent">
                  <div className="firstcontent-paragicon">
                    <i className="icon-edit fa-solid fa-maximize rotate typoicon"></i>
                    <p className="general-paragraph vertical-justify">
                      image
                    </p>
                  </div>
                  <div className="firstcontent-deleteicon">
                    <i className="icon-edit fa-solid fa-t typoicon"></i>
                    {/* <button onClick={() => deleteDivSec(div.id)}>
                        <i className="icon-edit fa-solid fa-xmark typoicon"></i>
                      </button> */}
                  </div>
                </div>
                {
                  <div className="para-alignbutton">
                    <p className="general-paragraph"> Text decoration</p>
                    <div className="alignbox">
                      <button className="align-button">
                        <i className="icon-edit fa-solid fa-image"></i>
                      </button>
                      <button className="align-button">
                        <i className="icon-edit fa-solid fa-square"></i>
                      </button>
                      <button className="align-button">
                        <i className="icon-edit fa-solid fa-photo-film"></i>
                      </button>
                    </div>
                    <p className="general-paragraph ">image</p>
                    <div onClick={openimagemodal} className="open-imagemodal">
                      images
                    </div>
                    <div className="typography-colorsection">
                      <p className="general-paragraph"> Color</p>
                      <div className="typography-colorchange">
                        <code
                          id="input-colorbox"
                          style={{
                            backgroundColor: "black",
                            width: "80%",
                            fontSize: "medium",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            border: "1px solid white",
                            marginRight: "5px",
                            borderRadius: "8px"
                          }}

                        ></code>
                        <input
                          type="color"
                          // onInput={setColor}
                          id="colorbox"
                          onChange={handleColorChange}
                          value={colour != undefined ? colour : "#000"}
                        />
                      </div>
                    </div>
                    <div className="displayflex-decoration">
                      <div className="typography-sectionoption">
                        <p className="general-paragraph">Repeat</p>
                        <select name="display" className="selectorstyle">
                          <option value="Repeat">Repeat</option>
                          <option value="Repeat X">Repeat X</option>
                          <option value="Repeat Y">Repeat Y</option>
                          <option value="Not Repeat">Not Repeat</option>
                          <option value="Space">Space</option>
                          <option value="Round">Round</option>
                        </select>
                      </div>
                      <div className="typography-sectionoption">
                        <p className="general-paragraph">Position</p>
                        <select name="display" className="selectorstyle">
                          <option value="left top">left top</option>
                          <option value="left center">left center</option>
                          <option value="left bottom">left bottom</option>
                          <option value="right Center">right Center</option>
                          <option value="right top">right top</option>
                          <option value="right bottom">right bottom</option>
                          <option value="center top">center top</option>
                          <option value="center center">center center</option>
                          <option value="center bottom">center bottom</option>
                        </select>
                      </div>
                    </div>
                    <div className="displayflex-decoration">
                      <div className="typography-sectionoption">
                        <p className="general-paragraph">Attachment</p>
                        <select name="display" className="selectorstyle">
                          <option value="Arial">scroll</option>
                          <option value="fixed">fixed</option>
                          <option value="local">local</option>
                        </select>
                      </div>
                      <div className="typography-sectionoption">
                        <p className="general-paragraph">Size</p>
                        <select name="display" className="selectorstyle">
                          <option value="auto">auto</option>
                          <option value="cover">cover</option>
                          <option value="contain">contain</option>
                        </select>
                      </div>
                    </div>
                  </div>
                }
              </div>
              {/* {divListsec.map((div) => (
                <div className="firstcontent-container" key={div.id}>
                  <div className="firstcontent">
                    <div className="firstcontent-paragicon">
                      <i className="icon-edit fa-solid fa-maximize rotate typoicon"></i>
                      <p className="general-paragraph vertical-justify">
                        image
                      </p>
                    </div>
                    <div className="firstcontent-deleteicon">
                      <i className="icon-edit fa-solid fa-t typoicon"></i>
                      <button onClick={() => deleteDivSec(div.id)}>
                        <i className="icon-edit fa-solid fa-xmark typoicon"></i>
                      </button>
                    </div>
                  </div>
                  {
                    <div className="para-alignbutton">
                      <p className="general-paragraph"> Text decoration</p>
                      <div className="alignbox">
                        <button className="align-button">
                          <i className="icon-edit fa-solid fa-image"></i>
                        </button>
                        <button className="align-button">
                          <i className="icon-edit fa-solid fa-square"></i>
                        </button>
                        <button className="align-button">
                          <i className="icon-edit fa-solid fa-photo-film"></i>
                        </button>
                      </div>
                      <p className="general-paragraph ">image</p>
                      <div onClick={openimagemodal} className="open-imagemodal">
                        images
                      </div>
                      <div className="typography-colorsection">
                        <p className="general-paragraph"> Color</p>
                        <div className="typography-colorchange">
                          <code
                            id="input-colorbox"
                            style={{
                              backgroundColor: "black",
                              width: "80%",
                              fontSize: "medium",
                              color: "white",
                              display: "flex",
                              justifyContent: "center",
                              border: "1px solid white",
                              marginRight: "5px",
                              borderRadius: "8px"
                            }}
                          ></code>
                          <input
                            type="color"
                            onInput={setColor}
                            id="colorbox"
                          />
                        </div>
                      </div>
                      <div className="displayflex-decoration">
                        <div className="typography-sectionoption">
                          <p className="general-paragraph">Repeat</p>
                          <select name="display" className="selectorstyle">
                            <option value="Repeat">Repeat</option>
                            <option value="Repeat X">Repeat X</option>
                            <option value="Repeat Y">Repeat Y</option>
                            <option value="Not Repeat">Not Repeat</option>
                            <option value="Space">Space</option>
                            <option value="Round">Round</option>
                          </select>
                        </div>
                        <div className="typography-sectionoption">
                          <p className="general-paragraph">Position</p>
                          <select name="display" className="selectorstyle">
                            <option value="left top">left top</option>
                            <option value="left center">left center</option>
                            <option value="left bottom">left bottom</option>
                            <option value="right Center">right Center</option>
                            <option value="right top">right top</option>
                            <option value="right bottom">right bottom</option>
                            <option value="center top">center top</option>
                            <option value="center center">center center</option>
                            <option value="center bottom">center bottom</option>
                          </select>
                        </div>
                      </div>
                      <div className="displayflex-decoration">
                        <div className="typography-sectionoption">
                          <p className="general-paragraph">Attachment</p>
                          <select name="display" className="selectorstyle">
                            <option value="Arial">scroll</option>
                            <option value="fixed">fixed</option>
                            <option value="local">local</option>
                          </select>
                        </div>
                        <div className="typography-sectionoption">
                          <p className="general-paragraph">Size</p>
                          <select name="display" className="selectorstyle">
                            <option value="auto">auto</option>
                            <option value="cover">cover</option>
                            <option value="contain">contain</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              ))} */}
            </div>
          </div>
        </div>

        {imageModal && <Image handleclick={openimagemodal} />}
      </div>
    </>
  );
};

export default DecorationSection;
