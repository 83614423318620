import axios from '../axiosConfig'; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const UserCredential = {

    insertUserCredential: async (data) => {
        debugger;
        // let integerArray = 0;
        // console.log(data.userTables)

        // if (data.userTables != undefined) {
        //     if ((data.userTables).length > 0) {
        //         const stringArray = data.userTables.split(',');
        //         integerArray = stringArray.map(str => parseInt(str, 10));
        //         console.log(integerArray);
        //     }
        // }
        // function formatIntegerArray(integerArray) {
        //     if (integerArray === 0) {
        //         return [0];
        //     } else {
        //         return integerArray;
        //     }
        // }
        function parseBoolean(v) {
            if (typeof v === "boolean") {
                return v;
            } else if (typeof v === "string") {
                v = v.toLowerCase().trim();
                return v === "true";
            } else {
                console.log("error", v);
                return false;
            }
        }
        try {
            const apiUrl = `${WebApiUrl}/api/UserCredential/UserCredential`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                branchId: Number(data.branchId),
                businessTypes: data.businessTypes,
                userName: data.userName,
                password: data.password,
                confirmPassword: data.confirmPassword,
                mobileNo: data.mobileNo,
                fixAgentBookedByForTransaction: Number(data.fixAgentBookedByForTransaction),
                loginType: Number(data.loginType),
                isAllowModifyCompany: parseBoolean(data.isAllowModifyCompany),
                isAllowModifyMaster: parseBoolean(data.isAllowModifyMaster),
                isAllowModifyVoucher: parseBoolean(data.isAllowModifyVoucher),
                isAllowDeleteMaster: parseBoolean(data.isAllowDeleteMaster),
                isAllowDeleteVoucher: parseBoolean(data.isAllowDeleteVoucher),
                isAllowEmailReporting: parseBoolean(data.isAllowEmailReporting),
                isAllowSMSReporting: parseBoolean(data.isAllowSMSReporting),
                isAllowExportReport: parseBoolean(data.isAllowExportReport),
                isShwTotalAmtInRpt: parseBoolean(data.isShwTotalAmtInRpt),
                isAllowPrntOfRpt: parseBoolean(data.isAllowPrntOfRpt),
                isShowPurchasePriceForItem: parseBoolean(data.isShowPurchasePriceForItem),
                isAllowDataBackup: parseBoolean(data.isAllowDataBackup),
                isAllowBackdateFurtherEntries: parseBoolean(data.isAllowBackdateFurtherEntries),
                isAllowLast3DigitNo: parseBoolean(data.isAllowLast3DigitNo),
                isRestrictionToAccessOtherSystem: parseBoolean(data.isRestrictionToAccessOtherSystem),
                isAllowChangeInSalePriceDisc: parseBoolean(data.isAllowChangeInSalePriceDisc),
                isAllowUseCancelOrdrFeature: parseBoolean(data.isAllowUseCancelOrdrFeature),
                isEnableBirthdayPopup: parseBoolean(data.isEnableBirthdayPopup),
                isAllowTableGuest: parseBoolean(data.isAllowTableGuest),
                isAllowBiometricAttendance: parseBoolean(data.isAllowBiometricAttendance),
                isAllowCheckAttendance: parseBoolean(data.isAllowCheckAttendance),
                userLoginStartTime: data.userLoginStartTime, //10:20:30
                userLoginEndTime: data.userLoginEndTime,
                allowSpecificComputerName: data.allowSpecificComputerName,
                isAllowNoOfLoginsAsSameUser: Number(data.isAllowNoOfLoginsAsSameUser),
                userRoleCreateBtnVisible: parseBoolean(data.userRoleCreateBtnVisible),
                isAllowStaticIP: parseBoolean(data.isAllowStaticIP),
                ipAddress: data.ipAddress,
                selectFirmToAllowAccess: data.selectFirmToAllowAccess,
                selectFormToShwSettngBtn: data.selectFormToShwSettngBtn,
                recordVisibility: parseBoolean(data.recordVisibility),
                itemDepartment: Number(data.itemDepartment),
                userWiseBillSeries: Number(data.userWiseBillSeries),
                canRestAllowDelvryApp: parseBoolean(data.canRestAllowDelvryApp),
                canRestAllowKOTApp: parseBoolean(data.canRestAllowKOTApp),
                canRestUseChefApp: parseBoolean(data.canRestUseChefApp),
                canRestUseOrderApp: parseBoolean(data.canRestUseOrderApp),
                vehicleNo: Number(data.vehicleNo),
                deliveryBoyIncentive: Number(data.deliveryBoyIncentive),
                isActive: parseBoolean(data.isActive),
                // userTables: formatIntegerArray(integerArray),
                serviceType: data.serviceType,
                userTables: [0],
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data.result.result);
            return response.data.result.result;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getUserCredential: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/UserCredential/GetUserCredentials`;
            const response = await axios.post(apiUrl,   {
                filter: [],
                order: [
                  {
                    propertyName: "d1Id",
                    ascending: false
                  }
                ],
                pageNumber: 0,
                pageSize: 50000
              }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteUserCredential: async (userId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/UserCredential?userCredentialId=${userId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getUserCredentialById: async (id) => {
        try {
            const apiUrl = `${WebApiUrl}/api/UserCredential?userCredentialId=${id}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************
    updateUserCredential: async (data) => {
        // let integerArray = 0;
        // //console.log(data.userTables)
        // // if ((data.userTables) !== 0) {
        // if (data.userTables != undefined) {
        //     if ((data.userTables).length > 0) {
        //         const stringArray = data.userTables.split(',');
        //         integerArray = stringArray.map(str => parseInt(str, 10));
        //         console.log(integerArray);
        //     }
        // }
        // console.log(integerArray);
        // debugger;

        function parseBoolean(v) {
            if (typeof v === "boolean") {
                return v;
            } else if (typeof v === "string") {
                v = v.toLowerCase().trim();
                return v === "true";
            } else {
                console.log("error", v);
                return false;
            }
        }

        try {
            const apiUrl = `${WebApiUrl}/api/UserCredential?userCredentialId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                branchId: Number(data.branchId),
                businessTypes: data.businessTypes,
                userName: data.userName,
                password: data.password,
                mobileNo: data.mobileNo,
                confirmPassword: data.confirmPassword,
                fixAgentBookedByForTransaction: Number(data.fixAgentBookedByForTransaction),
                loginType: Number(data.loginType),
                isAllowModifyCompany: parseBoolean(data.isAllowModifyCompany),
                isAllowModifyMaster: parseBoolean(data.isAllowModifyMaster),
                isAllowModifyVoucher: parseBoolean(data.isAllowModifyVoucher),
                isAllowDeleteMaster: parseBoolean(data.isAllowDeleteMaster),
                isAllowDeleteVoucher: parseBoolean(data.isAllowDeleteVoucher),
                isAllowEmailReporting: parseBoolean(data.isAllowEmailReporting),
                isAllowSMSReporting: parseBoolean(data.isAllowSMSReporting),
                isAllowExportReport: parseBoolean(data.isAllowExportReport),
                isShwTotalAmtInRpt: parseBoolean(data.isShwTotalAmtInRpt),
                isAllowPrntOfRpt: parseBoolean(data.isAllowPrntOfRpt),
                isShowPurchasePriceForItem: parseBoolean(data.isShowPurchasePriceForItem),
                isAllowDataBackup: parseBoolean(data.isAllowDataBackup),
                isAllowBackdateFurtherEntries: parseBoolean(data.isAllowBackdateFurtherEntries),
                isAllowLast3DigitNo: parseBoolean(data.isAllowLast3DigitNo),
                isRestrictionToAccessOtherSystem: parseBoolean(data.isRestrictionToAccessOtherSystem),
                isAllowChangeInSalePriceDisc: parseBoolean(data.isAllowChangeInSalePriceDisc),
                isAllowUseCancelOrdrFeature: parseBoolean(data.isAllowUseCancelOrdrFeature),
                isEnableBirthdayPopup: parseBoolean(data.isEnableBirthdayPopup),
                isAllowTableGuest: parseBoolean(data.isAllowTableGuest),
                isAllowBiometricAttendance: parseBoolean(data.isAllowBiometricAttendance),
                isAllowCheckAttendance: parseBoolean(data.isAllowCheckAttendance),
                userLoginStartTime: data.userLoginStartTime,
                userLoginEndTime: data.userLoginEndTime,
                allowSpecificComputerName: data.allowSpecificComputerName,
                isAllowNoOfLoginsAsSameUser: Number(data.isAllowNoOfLoginsAsSameUser),
                userRoleCreateBtnVisible: parseBoolean(data.userRoleCreateBtnVisible),
                isAllowStaticIP: parseBoolean(data.isAllowStaticIP),
                ipAddress: data.ipAddress,
                selectFirmToAllowAccess: data.selectFirmToAllowAccess,
                selectFormToShwSettngBtn: data.selectFormToShwSettngBtn,
                recordVisibility: parseBoolean(data.recordVisibility),
                itemDepartment: Number(data.itemDepartment),
                userWiseBillSeries: Number(data.userWiseBillSeries),
                canRestAllowDelvryApp: parseBoolean(data.canRestAllowDelvryApp),
                canRestAllowKOTApp: parseBoolean(data.canRestAllowKOTApp),
                canRestUseChefApp: parseBoolean(data.canRestUseChefApp),
                canRestUseOrderApp: parseBoolean(data.canRestUseOrderApp),
                vehicleNo: Number(data.vehicleNo),
                deliveryBoyIncentive: Number(data.deliveryBoyIncentive),
                isActive: parseBoolean(data.isActive),
                serviceType: data.serviceType,
                // userTables: integerArray,
                userTables: [0],
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get filtered User Credential ***************************
    getFilteredUserCredential: async (filterData) => {
        const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
        }));
        try {
            const apiUrl = `${WebApiUrl}/api/UserCredential/GetUserCredentials`;
            const response = await axios.post(apiUrl, {
                filter: filteredData,
                order: [
                    {
                        propertyName: "string",
                        ascending: true
                    }
                ],
                pageNumber: 0,
                pageSize: 0
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
};

export default UserCredential;
