import React, { useEffect, useState } from 'react';


// const deletebuttonshow = () => {
//   document.getElementById("column-one-delete").classList.toggle("column-one-delete-web-template");
//   document.getElementById("input-b").classList.toggle("element-border");
// };

const Input = (props) => {


  const [getValueAndStrg, setGetValueAndStrg] = useState([])


  useEffect(() => {
    // Load saved items from localStorage on initial mount
    const savedItems = localStorage.getItem("style");
    if (savedItems) {
      setGetValueAndStrg(savedItems);
    }
  }, []);



  useEffect(() => {
    // Store only unique id values in localStorage
    // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
    localStorage.setItem("style", getValueAndStrg);
  }, [getValueAndStrg]);
  const deletebuttonshow = () => {
    setGetValueAndStrg(getId)
    const deleteButton = document.getElementById(getId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }
    // document.getElementById("column-one").classList.toggle("element-border");
  };
  let getId = props.keyValueId;

  return (
    <div style={{ position: "relative" }} onClick={deletebuttonshow} onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover} id={getId}
      draggable="true"  >     <input className="label-input-small-main-web-template" type="text" /></div>
  )
}



export default Input