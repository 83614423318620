// import React, { useState } from "react";
import { useEffect, useState } from "react";
import Order from "../../API/APIServices/Order";
import { useLocation } from "react-router-dom";
// import { Link } from 'react-router-dom';
import Loader from "../../Inventory/Props/Loader";
import Company from "../../API/APIServices/Company";
import GlobalFunction from "../../API/APIServices/GlobalFunction";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";

function MothersHutBill(props) {
  const location = useLocation();
  const { id } = location.state || {};

  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // debugger
    setLoading(true)
    const fetchBillData = async () => {
      try {
        let filterData = [];
        let ord1Id;
        if (id != 0 && id != null && id != undefined && id != "") {
          filterData = {
            fieldName: "ord1Id",
            operatorName: "equal",
            compareValue: id,
          };
          ord1Id = id;
        }
        else {
          filterData = {
            fieldName: "ord1Id",
            operatorName: "equal",
            compareValue: props.ord1Id,
          };
          ord1Id = props.ord1Id;
        }
        const billdata = await Order.getOrderListByOrd1Id(ord1Id);
        console.log(billdata);
        setData(billdata);
        setLoading(false);
      }
      catch (error) {
        console.log(error);
      }
    };
    fetchBillData();
  }, []);

  const [companyData, setCompanyData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const storedArrayString = localStorage.getItem('loginAccountUserData');
      const loginUser = JSON.parse(storedArrayString);
      const data = await Company.GetCompanyById(loginUser.compId)
      setCompanyData(data)
    }
    fetchData();
  }, [])

  let prevOrderId = null;

  const printBtn = async () => {
    GlobalFunction.generatePDF();
  };

  const [currentDateTime, setCurrentDateTime] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const date = await getCurrentDateTime1();
      setCurrentDateTime(date);
    }
    fetchData();
  }, [])

  async function getCurrentDateTime1() {
    // setLoading(true);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const isoString = await CurrentDateAndTime.getCurrentDateTime();
    const now = new Date(isoString);
    const dayOfWeek = days[now.getDay()];
    const month = months[now.getMonth()];
    const dayOfMonth = now.getDate();
    const year = now.getFullYear();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    // setLoading(false);
    return `${dayOfWeek} ${month} ${dayOfMonth} ${year} - ${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
  }

  function formatTime(time) {
    return time < 10 ? `0${time}` : time;
  }

  return (
    <div>
      {Data.filter((data) => {
        const isDifferentOrderId = data.ord1Id !== prevOrderId;
        prevOrderId = data.ord1Id;
        return isDifferentOrderId;
      }).map((order) => (
        <div
          className="MHut-Bg-border"
          style={{ backgroundColor: "rgb(33, 46, 79)" }}
        >
          <div className="flex-center-MHut">
            <label className="MHut-text">
              <u className="MHut-underline">{companyData.printName}</u>
            </label>
            <i className="fa-solid fa-print" onClick={printBtn} style={{ color: "white", marginRight: "10px" }} />
          </div>
          <div className="MHut-GST-Border">
            <div className="space-between">
              <div className="flex-start">
                <label className="ques-text-col">GST No : &nbsp;</label>
                <label className="ans">{companyData.gstTinNo}</label>
              </div>
            </div>
            <div className="flex-start">
              <label className="ques-text-col">FSSAI No : &nbsp;</label>
              <label className="ans">{companyData.cstNo}</label>
            </div>
          </div>
          <div className="MHut-GST-Border">
            <div className="space-between">
              <div className="flex-start MHut-width-bill">
                <label className="ques-text-col">Name : &nbsp;</label>
                <label className="ans">{order.accName}</label>
              </div>
              <div className="flex-start MHut-width-bill">
                <label className="ques-text-col">Mobile No : &nbsp;</label>
                <label className="ans">{order.accMobileNo}</label>
              </div>
            </div>
            {/***************/}
            <div className="space-between">
              <div className="flex-start MHut-width-bill">
                <label className="ques-text-col">Order No : &nbsp;</label>
                <label className="ans">{order.orderNo}</label>
              </div>
              <div className="flex-start MHut-width-bill">
                <label className="ques-text-col">Date : &nbsp;</label>
                <label className="ans">{order.orderDate}</label>
              </div>
            </div>
            <div className="space-between"></div>
            <div className="space-between">
              <div className="flex-start MHut-width-bill">
                <label className="ques-text-col">Service Type : &nbsp;</label>
                <label className="ans">{order.serviceTypeName}</label>
              </div>
              <div className="flex-start MHut-width-bill">
                <label className="ques-text-col">Order Time : &nbsp;</label>
                <label className="ans">{order.orderTime}</label>
              </div>
              {/* <div className="flex-start MHut-width-bill">
              <label className="ques-text-col">Table Order : &nbsp;</label>
              <label className="ans">{order.tableName}</label>
            </div> */}
            </div>
            <div className="space-between">
              <div className="flex-start MHut-width-bill">
                <label className="ques-text-col">Table : &nbsp;</label>
                <label className="ans">{order.tableName}</label>
              </div>
              <div className="flex-start MHut-width-bill">
                <label className="ques-text-col">Time : &nbsp;</label>
                <label className="ans">{order.orderTime}</label>
              </div>
            </div>
            {/*************************/}
          </div>
          {/**************************************************************************/}
          <div className="MHut-GST-Border">
            <table className="table-bill-hd">
              <thead className="thead-groupmaster">
                <tr>
                  <th className="table-bill-MHut" style={{ width: "10%" }}>Sno</th>
                  <th className="table-bill-MHut item-left-bill" style={{ width: "40%" }} >Item</th>
                  <th className="table-bill-MHut" style={{ width: "15%" }}>Qty</th>
                  <th className="table-bill-MHut" style={{ width: "15%" }}>Rate</th>
                  <th className="table-bill-MHut" style={{ width: "15%" }}>Amt</th>
                </tr>
              </thead>
              <tbody>
                {Data.map((data, index) => (
                  <tr key={data.id}>{" "}
                    <td className="MHut-Bill-text">{index + 1}</td>
                    <td className="MHut-Bill-text item-left-bill">{data.d2ItemName}</td>
                    <td className="MHut-Bill-text">{data.qty}</td>{" "}
                    <td className="MHut-Bill-text">₹{data.rate}</td>{" "}
                    <td className="MHut-Bill-text">₹{data.qty * data.rate}</td>{" "}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/****************/}
          <div className="MHut-GST-Border">
            {/* <div>
                        <label className="ques-text-col">Suggestion</label><br />
                        <textarea></textarea>
                    </div> */}
            <div
              className="flex-start"
              style={{ justifyContent: "space-between" }}
            >
              <div>
                <label
                  className="ques-text-col"
                  style={{ color: "darkorange" }}
                >
                  &#9733;
                </label>
                <label className="ques-text-col">
                  Bill incluse Of GST &nbsp;
                </label>
              </div>
              <label className="ques-text-col">
                TOTAL ORDER VALUE : {order.grandTotal}₹
              </label>
            </div>
            <div
              className="flex-start"
              style={{ justifyContent: "space-between" }}
            >
              <label className="ques-text-col">
                Print&nbsp;on&nbsp;{currentDateTime}&nbsp;
              </label>
              <label className="ques-text-col">
                FINAL BILL AMOUNT : {order.grandTotal}₹
              </label>
            </div>
          </div>
          <div style={{ textAlign: "center", color: "white", padding: "10px" }}>
            We're grateful for your visit. Thank you !
          </div>
        </div>
      ))}
      {loading && <Loader />}
    </div>
  );
}

export default MothersHutBill;
