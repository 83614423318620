import React, { useState, useEffect, useContext, useRef } from 'react';
import MyContext from '../../../Inventory/MyContext';

const Heading = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [contentValue, setContentValue] = useState();
    const [hoveredComponentId, setHoveredComponentId] = useState(null);
    const { setDroppedItems, setTemplateData, handleContentChange, stylesArray } = useContext(MyContext);
    const divRef = useRef(null);
    const divRef1 = useRef(null);

    // const defaultStyles = {
    //     fontSize: '24px',
    //     fontWeight: 'bold',
    //     color: '#333',
    //     marginBottom: '10px',
    // };

    const handleMouseEnter = (id) => {
        setHoveredComponentId(id);
    };

    const handleMouseLeave = () => {
        setHoveredComponentId(null);
    };

    useEffect(() => {
        // Check if divRef is properly set
        if (divRef.current) {
            console.log('divRef is set:', divRef.current);
        } else {
            console.log('divRef is not set');
        }
    }, [isEditing]);
    const decodeHtmlEntities = (text) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    };
    useEffect(() => {
        const matchingStyle = stylesArray.find(item => item.id === props.keyValueId);

        if (matchingStyle) {
            setContentValue(decodeHtmlEntities(matchingStyle.content || ''));
        } else {
            setContentValue('');
        }
    }, [props.keyValueId, stylesArray, setContentValue]);

    const handleTextClick = (getId) => {
        setIsEditing(true);
        deletebuttonshow(getId)
    };

    const handleBlur = () => {
        setIsEditing(false);
        debugger

        setTimeout(() => {
            if (divRef.current) {
                console.log(divRef.current);
                const updatedInnerHTML = divRef.current.innerHTML;
                console.log('Updated innerHTML:', updatedInnerHTML);
                if (divRef1.current) {
                    const textData = divRef1.current.innerHTML;
                    console.log("Text Data:", textData);
                    handleContentChange(textData, props.keyValueId);
                }

                setDroppedItems(prevItems => {
                    const updatedItems = prevItems.map(item => {
                        if (item.id === props.keyValueId) {
                            return { ...item, textContents: updatedInnerHTML };
                        }
                        return item;
                    });
                    setTemplateData(prevState => ({
                        ...prevState,
                        jsonStructure: updatedItems,
                    }));
                    return updatedItems;
                });


            } else {
                console.error('divRef.current is null');
            }
        }, 1000); // Small delay 
    };

    const handleChange = (e) => {
        setContentValue(e.target.value);
    };

    const deletebuttonshow = (getId) => {

        // setGetValueAndStrg(getId)
        const deleteButton = document.getElementById(props.keyValueId + "delete");
        if (deleteButton) {
            deleteButton.classList.toggle("column-one-delete-web-template");
        }
        // document.getElementById("column-one").classList.toggle("element-border");
    };
    const isHovered = hoveredComponentId === props.keyValueId;
    const handleColumnClick = (id) => {
        handleTextClick(id)
        props.onComponentClick(id);
    };

    return (
        <div
            style={{ position: 'relative' }}  // Apply default styles here
            onDragStart={props.handleDrag}
            onDrop={props.handleDrop}
            onDragOver={props.dragover}
            id={props.keyValueId}
            draggable="true"
            className="draggable-heading"
            onClick={() => handleColumnClick(props.keyValueId)}
            ref={divRef}
        >
            {isEditing ? (
                <input
                    className="draggable-heading-input"
                    type="text"
                    value={contentValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                    style={{ width: "100%" }}
                />
            ) : (
                <div id={props.keyValueId} ref={divRef1}
                    className={`component-web-template ${isHovered ? 'hover-border-web-template' : ''} `}
                    onMouseEnter={() => handleMouseEnter(props.keyValueId)}
                    onMouseLeave={handleMouseLeave}
                >
                    {contentValue || 'Click to edit heading...'}
                </div>
            )
            }
        </div >
    );
};

export default Heading;
