import React, { useEffect, useState } from 'react'

const CustomCode = (props) => {

  
const [getValueAndStrg,setGetValueAndStrg] = useState([])
  
  
useEffect(() => {
  // Load saved items from localStorage on initial mount
  const savedItems = localStorage.getItem("style");
  if (savedItems) {
    setGetValueAndStrg(savedItems) ;
  }
}, []);
 
useEffect(() => {
  // Store only unique id values in localStorage
  // const uniqueIds = Array.from(new Set(getValueAndStrg.map(item => item.id)));
  localStorage.setItem("style", getValueAndStrg);
}, [getValueAndStrg]);
const deletebuttonshow = () => {
  setGetValueAndStrg(getId)
  document.getElementById("column-one-delete").classList.toggle("column-one-delete-web-template");
  document.getElementById("column-one").classList.toggle("element-border");
};
let getId = props.keyValueId;   

  return (
    <div   style={{position:"relative" }} onDragStart={props.handleDrag}
    onDrop={props.handleDrop}
    onDragOver={props.dragover}
    draggable="true" onClick={deletebuttonshow}>Custom Code</div>
  )
}

export default CustomCode