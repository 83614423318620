import { useState } from 'react';
import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const CurrentDateAndTime = {

    // ************************* Get Current Datetime ***************************
    getCurrentDateTime: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/CurrentDateAndTime/CurrentDateTime`;
            const response = await axios.post(apiUrl, {},
                // {
                //     withCredentials: true,
                // }
            );
            console.log('Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Get Current Date ***************************
    getCurrentDate: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/CurrentDateAndTime/CurrentDate`;
            const response = await axios.post(apiUrl, {},
                // {
                //     withCredentials: true,
                // }
            );
            console.log('Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Get Current Time ***************************
    getCurrentTime: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/CurrentDateAndTime/CurrentTime`;
            const response = await axios.post(apiUrl, {},
                // {
                //     withCredentials: true,
                // }
            );
            console.log('Response:', response);
            return response.data;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
};

export default CurrentDateAndTime;
