import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ initialTime, onTimerEnd , ...props} ) => {

  // console.log(props.widthV);
  let countdownwidth;
  switch (true) {
    case props.widthV > 0 && props.widthV <= 450:
      countdownwidth = "countdownwidth-mobile";
      break;
      case props.widthV > 450 && props.widthV <= 850:
        countdownwidth = "countdownwidth-tablet";
      break;
    default:
      countdownwidth = "";
      break;
  }

  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining(prevTime => prevTime - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    } else {
      onTimerEnd();
    }
  }, [timeRemaining, onTimerEnd]);

  const formatTime = () => {
    const days = Math.floor(timeRemaining / 86400);
    const hours = Math.floor((timeRemaining % 86400) / 3600);
    const minutes = Math.floor((timeRemaining % 3600) / 60);
    const seconds = timeRemaining % 60;
    // return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    return(
        <div className="countdown-box-web-template">
      <div className={`countdown-box-small  ${countdownwidth}` }>{days}-Day</div>
        <div className={`countdown-box-small  ${countdownwidth}` }>{hours}-Hr</div>
        <div className={`countdown-box-small  ${countdownwidth}` }>{minutes}-Min</div>
        <div className={`countdown-box-small  ${countdownwidth}` }>{seconds}-Sec</div>
      </div>
    )
  };

  return (
    <div>
      
      <p>{formatTime()}</p>
    </div>
  );
};

export default CountdownTimer