
import DateTime from "../../Inventory/Components/DateTime";
import React, { useState, useRef, useEffect, useContext } from "react";
import PageTabs from "../../Inventory/Components/PageTabs";
import MainButton from "../../Inventory/Props/MainButton";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import ModalItemFeedback from "../../Inventory/ModalBox/ModalItemFeedback";
import ModalFeedbackFollowUp from "../../Inventory/ModalBox/ModalFeedbackFollowUp";
import Order from "../../API/APIServices/Order";
import UserCredential from "../../API/APIServices/UserCredential";
import FeedbackSetting from "../../API/APIServices/FeedbackSetting";
import Loader from "../../Inventory/Props/Loader";
import moment from "moment";
import MyContext from "../../Inventory/MyContext";
import ItemDetailModalBox from "./ItemDetailModalBox";
import OrderFeedback from "../OrderTaker/OrderFeedback";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";

function OrderReport() {
    /***********title***************/
    useState(() => {
        document.title = 'Feedback Report';
    }, []);

    const navigate = useNavigate()

    const { isShowTotalAmountInReport } = useContext(MyContext);
    const [loading, setLoading] = useState(true);

    //***************{From Date and To Date}***************//

    const getCurrentDate = async () => {
        const date = await CurrentDateAndTime.getCurrentDate();
        return date;
    }

    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const date = await getCurrentDate();
    //         setFromDate(date)
    //         setToDate(date)
    //     }
    //     fetchData();
    // }, [])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);
    };

    const [isModalOpenCustomerInfo, setModalOpenCustomerInfo] = useState(false);

    const showModalCustomerInfo = () => {
        setModalOpenCustomerInfo(true);
    };

    const hideModalCustomerInfo = () => {
        setModalOpenCustomerInfo(false);
    };

    // ***************** Get Service Type***********************
    const [serviceType, setServiceType] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                // const data = await UserCredentialApi.getUserCredential();
                const data = await Order.getServiceType();
                setServiceType(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // ***************** Get Assign user from user master ***********************
    const [assignUser, setAssignUser] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await UserCredential.getUserCredential();
                setAssignUser(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // ***************** Get Rating star from user master ***********************
    const [ratingOn, setRatingOn] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await FeedbackSetting.getFeedbackSetting();
                setRatingOn(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);


    //************{modal box}************/ /
    const [ItemDetail, setItemDetail] = useState({});
    const [isModalOpenItemFeedback, setModalOpenItemFeedback] = useState(false);

    useEffect(() => {
        console.log(ItemDetail);
    }, [ItemDetail]);

    const showModalItemFeedback = async (id) => {
        setLoading(true);
        await handleGetItem(id);
        setModalOpenItemFeedback(true);
        setLoading(false);
    };

    const handleGetItem = async (id) => {
        setLoading(true);
        var filterData = {
            fieldName: "ord1Id",
            operatorName: "equal",
            compareValue: id,
        };
        const billdata = await Order.getOrderListByOrd1Id(id);
        // console.log(billdata)
        setItemDetail(billdata.item1);
        setLoading(false);
        // console.log(billdata);
    };

    const hideModalItemFeedback = () => {
        setModalOpenItemFeedback(false);
    };

    const [isModalOpenEdit, setModalOpenEdit] = useState(false);

    const showModalEdit = async (ord1Id) => {
        setLoading(true);
        await handleGetItem(ord1Id);
        setModalOpenEdit(true);
        setLoading(false);
    };

    const hideModalEdit = () => {
        setModalOpenEdit(false);
    };

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);
    const input11Ref = useRef(null);
    const input12Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    //***************{Array Data}***************//
    // const dataArray = Array.from({ length: 10 }, (_, index) => ({
    //     id: index,
    // }));

    // **************Get Order List************************

    const [feedbackReportShow, setfeedbackReportShow] = useState([]);


    const [dataArray, setDataArray] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const filteredData = [{
                fieldName: "string",
                operatorName: "string",
                compareValue: "string"
            }]
            try {
                // const val = await Order.getOrderListReport(filteredData);
                // const val = await Order.getOrderListFilter(filteredData);
                const val = await Order.getRawOrderList(filteredData, 1);
                console.log(val);
                setDataArray(val);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    // **************Get Feedback List************************

    const [feedback, setFeedback] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                //   const val = await Order.getOrderList();
                const val = await FeedbackSetting.getFeedbackSetting();
                console.log(val);
                setFeedback(val);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }

    const timeFormat = (twentyFourHourTime) => {
        const [startTime, endTime] = twentyFourHourTime.split('-');
        const convertedStartTime = convertTo12HourFormat(startTime.trim());
        const convertedEndTime = convertTo12HourFormat(endTime.trim());
        const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
        return convertedTimeRange;
    }

    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [addFilter, setFilter] = useState([]);

    const handleFilterChange = async (event) => {
        setLoading(true);
        setPageIndex(1);
        setStartIndex(0);
        const { name, value } = event.target;

        let updatedFilter = [...addFilter];

        if (value === "default") {
            updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
        } else {
            let newFilter;
            if (name === "fromDate" || name === "toDate") {
                const formattedValue = formatDate(value);
                newFilter = {
                    fieldName: "orderDate",
                    operatorName: name === "fromDate" ? "GreaterOrEqualThan" : "LessOrEqualThan",
                    compareValue: formattedValue
                };
                if (name === "fromDate") {
                    setFromDate(formattedValue)
                }
                else if (name === "toDate") {
                    setToDate(formattedValue)
                }
            }
            else if (name == "accMobileNo" || name == "accName") {
                newFilter = {
                    fieldName: name,
                    operatorName: "Contains",
                    compareValue: value
                };
            }
            else if (name === "feedBackList") {
                if (value === "feedbackDone") {
                    newFilter = {
                        fieldName: "hasFeedbackDone",
                        operatorName: "Equal",
                        compareValue: true
                    };
                }
                else if (value === "feedbackNotDone") {
                    newFilter = {
                        fieldName: "hasFeedbackDone",
                        operatorName: "NotEqual",
                        compareValue: true
                    };
                }
            }
            else if (name === "feedbackFromDate" || name === "feedbackToDate") {
                const formattedValue = formatDate(value);
                newFilter = {
                    fieldName: "feedbackUpdateOn",
                    operatorName: name === "feedbackFromDate" ? "GreaterOrEqualThan" : "LessOrEqualThan",
                    compareValue: formattedValue
                };
                // if (name === "fromDate") {
                //     setFromDate(formattedValue)
                // }
                // else if (name === "toDate") {
                //     setToDate(formattedValue)
                // }
            }
            else {
                newFilter = {
                    fieldName: name,
                    operatorName: "Equal",
                    compareValue: value
                };
            }
            const orderDateFilters = updatedFilter.filter(filter => filter.fieldName === name);
            const existingFilterWithSameOperator = updatedFilter.find(filter => filter.operatorName === newFilter.operatorName);

            if (existingFilterWithSameOperator) {
                const indexToUpdate = updatedFilter.findIndex(filter => filter === existingFilterWithSameOperator);
                if (indexToUpdate !== -1) {
                    updatedFilter[indexToUpdate] = {
                        ...updatedFilter[indexToUpdate],
                        compareValue: newFilter.compareValue
                    };
                }
            } else {
                updatedFilter.push(newFilter);
            }
        }
        setFilter(updatedFilter);
        // const val = await Order.getOrderListFilter(updatedFilter);
        const val = await Order.getRawOrderList(updatedFilter, pageIndex);
        setDataArray(val);
        setLoading(false);
    };

    const [ItemDetailModal, setItemDetailModal] = useState(false);
    const [serviceTypeId, setServiceTypeId] = useState(751);

    const openItemDetailModal = async (id, serviceType) => {
        //alert(serviceType)
        await handleGetItemByItemWise(id);
        setServiceTypeId(serviceType)
        setItemDetailModal(true);
    };

    const handleGetItemByItemWise = async (id) => {
        var filterData = {
            fieldName: "ord1Id",
            operatorName: "equal",
            compareValue: id,
        };
        //const orderlistdata = await Order.getOrderListWithFilter(filterData);
        const orderlistdata = await Order.getOrderItemWiseList(id);
        setItemDetail(orderlistdata);
    };

    const closeItemDetailModal = () => {
        setItemDetailModal(false);
    };

    const Modal = ({ isOpen, onClose, children }) => {
        if (!isOpen) return null;

        return (
            <div className="modal">
                <div className="modal-content">
                    <span className="close" onClick={onClose}>&times;</span>
                    {children}
                </div>
            </div>
        );
    };
    const [modalOpen, setModalOpen] = useState(false);
    const [ord1Id, setOrd1Id] = useState(null);
    const [serviceId, setServiceId] = useState(751);

    // Function to open modal with OrderFeedback component
    const openOrderFeedbackModal = (ord1Id, serviceTypeId) => {
        setOrd1Id(ord1Id);
        setModalOpen(true);
        setServiceId(serviceTypeId)
    };

    // Function to close the modal
    const closeModal = () => {
        setModalOpen(false);
        setOrd1Id(null); // Clear ord1Id when modal is closed
    };

    const [pageIndex, setPageIndex] = useState(1);
    const [startIndex, setStartIndex] = useState(0);

    const previousPage = async () => {
        setLoading(true);
        const index = pageIndex - 1;
        if (index != 0) {
            setPageIndex(index)
            const val = await Order.getRawOrderList(addFilter, index);
            setStartIndex(Number(50 * index) - 50) //Number(val.length * index) - 50
            if (val.length > 0) {
                setDataArray(val);
                setLoading(false);
            }
            else {
                alert("No More Data")
                setLoading(false);
            }
        }
        else {
            //alert("Not Found")
            setLoading(false);
        }
    }

    const nextPage = async () => {
        setLoading(true);
        const index = pageIndex + 1;
        const val = await Order.getRawOrderList(addFilter, index);

        if (val.length > 0) {
            setStartIndex(Number(50 * index) - 50)  //Number(val.length * index) - 50
            setDataArray(val);
            setPageIndex(index)
            setLoading(false);
        }
        else {
            alert("No More Data")
            setLoading(false);
        }
    }

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Feedback Report</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton PrintIcon={true} isBtnSearch={false} /> {/*handleSearchClick={handleSearchClick} */}
            </div>
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <div className="tab-content-box tab-patient-top">
                    <div className="tab-content-1">
                        <div className="company-info-top-left1 patient-padding patient-table-width feedback-report-width" style={{ width: "91vw" }}>
                            <div className="configure_radius_add_update">
                                <div className="company_info-text-im td-1">Feedback Report</div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input1Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input2Ref)}
                                                onChange={handleFilterChange}
                                                name="accMobileNo" type="text" required />
                                            <label id="input1" className="labelEffect1">Mobile No.</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input2Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input3Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="serviceTypeId"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (serviceType !== null && Array.isArray(serviceType)) {
                                                        return serviceType.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Service</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input3Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input4Ref)} style={{ fontSize: "14px" }}
                                                //value={inputValues.ratingStar}
                                                name="ratingStar"
                                                onChange={handleInputChange}
                                                required>
                                                <option>N/A</option>
                                                {(() => {
                                                    if (ratingOn !== null && Array.isArray(ratingOn)) {
                                                        return ratingOn.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.feedbackCaption}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Rating On</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                onChange={handleFilterChange} name="feedBackList" ref={input4Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)} style={{ fontSize: "14px" }} required>
                                                <option value="default">All</option>
                                                <option value="feedbackDone">Feedback Done</option>
                                                <option value="feedbackNotDone">Feedback Not Done</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">List</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input5Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input6Ref)} type="date" required
                                                onChange={handleFilterChange} value={fromDate}
                                                name="fromDate" style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Order From Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input6Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input7Ref)} type="date" required
                                                onChange={handleFilterChange} value={toDate}
                                                name="toDate" style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Order To Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input7Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input8Ref)} style={{ fontSize: "14px" }} required>
                                                <option value="default">N/A</option>
                                                <option>1</option>
                                                {/* <option>1.5</option> */}
                                                <option>2</option>
                                                {/* <option>2.5</option> */}
                                                <option>3</option>
                                                {/* <option>3.5</option> */}
                                                <option>4</option>
                                                {/* <option>4.5</option> */}
                                                <option>5</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">Rating Star</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input8Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input9Ref)} style={{ fontSize: "14px" }}
                                                //value={inputValues.confirmUser}
                                                onChange={handleFilterChange}
                                                name="orderConfirmedById"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (assignUser !== null && Array.isArray(assignUser)) {
                                                        return assignUser.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.userName}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Confirmed By</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-feedback-left01 input_copy_stock feedback-date">
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "23%" }}>
                                            <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input9Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input10Ref)} style={{ fontSize: "14px" }}
                                                //value={inputValues.feedbackUser}
                                                onChange={handleFilterChange}
                                                name="feedbackUpdateBy"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (assignUser !== null && Array.isArray(assignUser)) {
                                                        return assignUser.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.userName}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Feedback Done By</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "23%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input10Ref}
                                                onChange={handleFilterChange}
                                                name="feedbackFromDate" onKeyDown={(event) => handleKeyPress(event, input11Ref)} type="date" required style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Feedback From Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "23%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input11Ref}
                                                onChange={handleFilterChange}
                                                name="feedbackToDate" onKeyDown={(event) => handleKeyPress(event, input12Ref)} type="date" required style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Feedback To Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="configure_radius_add_update config-search-top">
                                <div className="company_info-text-im td-1">Feedback Details</div>
                                <div id="tabs">
                                    <div className="col-sm-12 order-rpt-top">
                                        {/* ************************** Page Index *********************** */}
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "flex-end",
                                                width: "100%",
                                                margin: "5px 0px"
                                            }}
                                        >
                                            <button
                                                onClick={() => previousPage()}
                                                //disabled={!canPreviousPage}
                                                className="table-list-button"
                                            >
                                                <i className="fa-solid fa-chevron-left"></i>
                                            </button>
                                            <span className="table-list-page-txt">
                                                Page -
                                                <strong style={{ margin: "0px 3px" }}>
                                                    {pageIndex}
                                                </strong>
                                            </span>
                                            <button
                                                onClick={() => nextPage()}
                                                //disabled={!canNextPage}
                                                className="table-list-button"
                                            >
                                                <i className="fa-solid fa-chevron-right"></i>
                                            </button>
                                        </div>
                                        <div className="table-resposive" style={{ height: "500px", overflow: "scroll" }}> {/*tb-scroll-width */}
                                            <table id="tblTransactionList" className="table_ord_mod table-bordered1 table-width-cancel" frame="box">
                                                <thead textAlign="center" className="table_headerSticky thead_scroll_orderlist th-bg-color" >
                                                    <tr className="">
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}></div>
                                                        </div> </th>
                                                        <th className="td_col6">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                                        </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order No</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Customer Name</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Mobile No</div>
                                                        </div> </th>
                                                        {/* <th className="td_col6">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order No</div>
                                                        </th> */}
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order Date</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Confirmed By</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Amount</div>
                                                        </div> </th>
                                                        {feedback && feedback.map((item, index) => (
                                                            <th className="td_col6"> <div className="table_head_OrderList">
                                                                <div className="table_head_mmb1" style={{ textAlign: "center" }}>{item.feedbackCaption} </div>
                                                            </div> </th>
                                                        ))}
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Delivery Time Slot</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Delivery Boy</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Delivery Time</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Delivery Boy Behaviour</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Behaviour At Reception</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Food Packaging</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Overall Food Rating</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Overall MH Rating</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>User Remarks</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Follow Up</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Feedback Done By</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Feedback Date</div>
                                                        </div> </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="tbody_body_m1">
                                                    {dataArray != null && dataArray != undefined && dataArray.length > 0 && dataArray
                                                        .filter((item, index, self) =>
                                                            index === self.findIndex((t) => (
                                                                t.ord1Id === item.ord1Id
                                                            ))
                                                        )
                                                        .slice().reverse()
                                                        .map((item, index) => (
                                                            // console.log("item.feedbacks", (item.feedbacks).length, "length", item.feedbacks),
                                                            <tr key={index} className="table_tbody_Order_List">
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>
                                                                    <div>
                                                                        <div><button className="copyBtn-chaitan select-btn" onClick={() => showModalItemFeedback(item.ord1Id)}>Show</button></div>
                                                                        <div><button className="copyBtn-chaitan select-btn" onClick={() => openItemDetailModal(item.ord1Id, item.serviceTypeId)}>Item Details</button></div>
                                                                        <div><Link to="/CustomerInformation" className="copyBtn-chaitan select-btn select-decoration" onClick={showModalCustomerInfo}>Customer Info</Link></div>
                                                                    </div>
                                                                </td>
                                                                {/* <td className="td_col6 td_sno-btn feed-flex-edit">{index + 1}<button className="edit_btn edit-feed-bottom" onClick={() => showModalEdit(item.ord1Id)}><i class="fa-solid fa-pen-to-square"></i></button></td>  */}
                                                                {/* <td className="td_col6 td_sno-btn feed-flex-edit">{index + 1}<button className="edit_btn edit-feed-bottom" onClick={item.hasFeedbackDone ? () => showModalEdit(item.ord1Id) : () => navigate(`/OrderFeedback?ord1Id=${item.ord1Id}`)}><i class="fa-solid fa-pen-to-square"></i></button></td> */}
                                                                <td className="td_col6 td_sno-btn feed-flex-edit">{startIndex + index + 1}<button className="edit_btn edit-feed-bottom" onClick={item.hasFeedbackDone ? () => showModalEdit(item.ord1Id) : () => openOrderFeedbackModal(item.ord1Id, item.serviceTypeId)}><i class="fa-solid fa-pen-to-square"></i></button></td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.orderNo}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.accName}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.accMobileNo}</td>
                                                                {/* <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.orderNo}</td> */}
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.orderDate} {item.orderTime ? convertTo12HourFormat(item.orderTime) : "00"}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.orderConfirmedByName}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>₹&nbsp;{item.grandTotal}</td>

                                                                {feedback && feedback.map((data, index) => {
                                                                    let feedbacks = [];
                                                                    try {
                                                                        feedbacks = item?.feedbacks ? JSON.parse(item.feedbacks) : [];
                                                                    } catch (e) {
                                                                        console.error("Failed to parse feedbacks:", e);
                                                                    }

                                                                    // Find the relevant rating based on category
                                                                    const ratingItem = feedbacks.ratings.find(rating => rating.category === data.feedbackCaption);

                                                                    return (
                                                                        <td key={index} className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>
                                                                            {ratingItem ? (
                                                                                <div>
                                                                                    {ratingItem.rating} <span style={{ color: "darkorange" }}>&#9733;</span>
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    0 <span style={{ color: "grey" }}>&#9733;</span>
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                    );
                                                                })}




                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.deliveryTime ? item.timeSlotName : "00"}</td>{/*timeFormat(item.deliveryTime)*/}
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.deliveryBoyName}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>
                                                                    {/* {item.deliveredTime ? convertTo12HourFormat(item.deliveredTime) : "00"} */}
                                                                    <label>
                                                                        <span className="ques"> DDT:</span> {item.deliveryBoyDispatchTime != "" && item.deliveryBoyDispatchTime != "00:00:00" ? convertTo12HourFormat(String(item.deliveryBoyDispatchTime)) : "00:00:00"}
                                                                    </label>
                                                                    <label>
                                                                        <span className="ques"> DT:</span> {item.overallOrderStatus == "Cancel" ? "00:00:00" : (item.deliveredTime == "" ? "00:00:00" : item.deliveredTime)}
                                                                    </label>
                                                                </td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>0</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>0</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>0</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.overallFoodRating}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.overallRestaurantRating}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.remarks}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>Testing</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.feedbackUpdateBy}</td>
                                                                <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.feedbackUpdateOn ? moment.utc(item.feedbackUpdateOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A') : ""}</td>
                                                            </tr>
                                                        ))}

                                                    {isShowTotalAmountInReport && (
                                                        <tr className="table_tbody_Order_List"
                                                            style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Total :- </td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                                                                {(() => {
                                                                    const sum = dataArray && dataArray.length > 0
                                                                        ? dataArray
                                                                            .filter((item, index, self) =>
                                                                                index === self.findIndex((t) => t.ord1Id === item.ord1Id)
                                                                            )
                                                                            .reduce((acc, data) => {
                                                                                const totalAmt = data.grandTotal || 0;
                                                                                return acc + totalAmt;
                                                                            }, 0)
                                                                        : 0;

                                                                    return (
                                                                        <span>
                                                                            ₹ {sum.toFixed(2)}
                                                                        </span>
                                                                    );
                                                                })()}
                                                            </td>

                                                            {feedback && feedback.map((item, rowIndex) => (
                                                                <td key={rowIndex} className="td_col6 td-padding-sn td_footer" colSpan={1}>
                                                                    {(() => {
                                                                        const sum = dataArray
                                                                            .filter((item, index, self) =>
                                                                                index === self.findIndex((t) => t.ord1Id === item.ord1Id)
                                                                            )
                                                                            .reduce((sum, data) => {
                                                                                const feedbackValue = data?.feedbacks?.[rowIndex]?.feedbackValue || 0;
                                                                                return sum + feedbackValue;
                                                                            }, 0);
                                                                        return (
                                                                            <span>
                                                                                {sum} <span style={{ color: "darkorange" }}>&#9733;</span>
                                                                            </span>
                                                                        );
                                                                    })()}
                                                                </td>
                                                            ))}

                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/*********************** Kot Item Detail Modal ********************/}
                                {isModalOpenItemFeedback && (
                                    <div className="modal" >
                                        <div className="AddNewItem-modal modal-payment" style={{ minHeight: "50%" }}>
                                            <div className="">
                                                <h3 className="modal-OrderLIst modal-color-CAD">Item Feedback: Order No - {ItemDetail[0]?.orderNo}</h3>
                                                <span className="close_modal" onClick={hideModalItemFeedback}>
                                                    &times;
                                                </span>
                                            </div>
                                            <div className="ModalContentModalSettingP modal-overflow-pymt">
                                                <ModalItemFeedback
                                                    ItemDetail={ItemDetail} />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/*********************** Payment Detail Modal ********************/}
                                {isModalOpenEdit && (
                                    <div className="modal" >
                                        <div className="AddNewItem-modal modal-feedback">
                                            <div className="">
                                                <h3 className="modal-OrderLIst modal-color-CAD">Feedback Follow-Up</h3>
                                                <span className="close_modal" onClick={hideModalEdit}>
                                                    &times;
                                                </span>
                                            </div>
                                            <hr style={{ margin: "0px", padding: "0px" }} />
                                            <div className="ModalContentModalSettingP modal-overflow-pymt">
                                                <ModalFeedbackFollowUp ItemDetail={ItemDetail} hideModalEdit={hideModalEdit} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/*********************** Feedback Detail Modal ********************/}
                                {modalOpen &&
                                    <div className="modal" >
                                        <div className="AddNewItem-modal modal-feedback" style={{ height: "90%", width: "30%" }}>
                                            <div className="">
                                                <h3 className="modal-OrderLIst modal-color-CAD">Feedback</h3>
                                                <span className="close_modal" onClick={closeModal}>
                                                    &times;
                                                </span>
                                            </div>
                                            <hr style={{ margin: "0px", padding: "0px" }} />
                                            <div className="feedBackModal_Scroll">
                                                {/* <Modal isOpen={modalOpen} onClose={closeModal}> */}
                                                <OrderFeedback ord1Id={ord1Id} onClose={closeModal} serviceTypeId={serviceId} />
                                                {/* </Modal> */}
                                            </div>
                                        </div>
                                    </div>

                                }
                                {/*********************** Item Detail Modal ********************/}
                                {ItemDetailModal && (
                                    <ItemDetailModalBox
                                        closeItemDetailModal={closeItemDetailModal}
                                        ItemDetail={ItemDetail}
                                        setItemDetail={setItemDetail}
                                        serviceTypeId={serviceTypeId}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
}


export default OrderReport;