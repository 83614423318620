import React, { useEffect, useState } from 'react'
import FormNameInfo from '../../API/APIServices/FormNameInfo';
import DynamicShortcutButton from '../../API/APIServices/DynamicShortcutButton';

const ShortCutModal = (props) => {


    const [EditMode, setEditMode] = useState(false);
    const [FormName, setFormame] = useState([])
    const [DynamicButtons, setDynamicButtons] = useState([]);
    const [inputValues, setInputValues] = useState({
        id: 0,
        name: "",
        formId: 0,
        description: ""
    });

    const iconClasses = [
        "fa-solid fa-house",
        "fa-solid fa-image",
        "fa-solid fa-user",
        "fa-solid fa-cog",
        "fa-solid fa-bell",
        "fa-solid fa-envelope",
        "fa-solid fa-calendar",
        "fa-solid fa-shopping-cart",
        "fa-solid fa-comments",
        "fa-solid fa-star",
        "fa-solid fa-camera",
        "fa-solid fa-map",
        "fa-solid fa-cloud",
        "fa-solid fa-lock",
        "fa-solid fa-phone",
        "fa-solid fa-sync",
        "fa-solid fa-truck",
        "fa-solid fa-globe",
        "fa-solid fa-gift",
        "fa-solid fa-book",
        "fa-solid fa-calculator",         // Business and calculations
        "fa-solid fa-chart-line",         // Business and calculations
        "fa-solid fa-chart-bar",          // Business and calculations
        "fa-solid fa-dollar-sign",        // Financial transactions
        "fa-solid fa-piggy-bank",         // Savings or investments
        "fa-solid fa-credit-card",        // Payment or transactions
        "fa-solid fa-briefcase",          // Business or work
        "fa-solid fa-tachometer-alt",     // Performance metrics
        "fa-solid fa-suitcase",           // Travel or business trips
        "fa-solid fa-handshake",          // Agreements or partnerships
        "fa-solid fa-calendar-day",       // Daily schedules
        "fa-solid fa-calendar-week",      // Weekly schedules
        "fa-solid fa-users",              // Teams or collaboration
        "fa-solid fa-money-bill-wave",    // Cash flow or transactions
        "fa-solid fa-pie-chart",          // Data analysis or metrics
        "fa-solid fa-project-diagram",    // Project management
        "fa-solid fa-receipt",            // Invoices or receipts
        "fa-solid fa-wallet",             // Personal finance or wallets
        "fa-solid fa-file-invoice-dollar", // Invoicing or financial documents
        "fa-solid fa-clipboard-list",     // Task lists or checklists
        "fa-solid fa-search-dollar",      // Financial research or analysis
        "fa-solid fa-edit",              // Editing or modifying documents
        "fa-solid fa-barcode",
        "fa-solid fa-list",
        "fa-solid fa-lock",
        "fa-solid fa-file",
        "fa-solid fa-sliders",
        "fa-solid fa-briefcase",
        "fa-solid fa-folder",
        "fa-regular fa-address-book",
        "fa-solid fa-pen-to-square",
        "fa-regular fa-credit-card",
        "fa-solid fa-signal",
        "fa-solid fa-chart-simple",
    ];


    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Select Icon');

    const handleSelect = (iconClass) => {
        setSelectedOption(iconClass);
        setIsOpen(false);
        setInputValues((prevValues) => ({
            ...prevValues,
            description: iconClass || "",
        }));
    };

    useEffect(() => {
        console.log(inputValues);
    }, [inputValues])


    useEffect(() => {
        const GetFormName = async () => {
            var data = await FormNameInfo.getFormNameInfo()
            setFormame(data)
        }

        GetFormName()

    }, [])



    const GetButtons = async () => {
        var data = await DynamicShortcutButton.getDynamicShortcutButton()
        setDynamicButtons(data)
    }
    useEffect(() => {
        GetButtons()
    }, [])

    console.log(DynamicButtons);




    const handleInputChange = (event) => {
        debugger
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const saveData = async () => {

        if (EditMode) {
            var data = await DynamicShortcutButton.updateDynamicShortcutButton(inputValues)
            if (data) {
                GetButtons()
                props.GetButtonsMainPage()
                alert("Updates")
            }

        } else {
            var data = await DynamicShortcutButton.insertDynamicShortcutButton(inputValues)
            if (data) {
                GetButtons()
                props.GetButtonsMainPage()
                alert("saved")
            }
        }
    };


    const resetData = async () => {
        setEditMode(false)
        setInputValues({
            name: "",
            formId: "",
            description: ""
        });
        setSelectedOption()
    };
    const EditDynamicButton = async (id) => {
        setEditMode(true);
        const data = await DynamicShortcutButton.getDynamicShortcutButtonById(id);
        if (data) {
            //   setImageIds(data.images);
            setInputValues({
                id: data.id || "",
                name: data.name || "",
                formId: data.formId || "",
                description: data.description || ""

            });
            setSelectedOption(data.description || "")

        }
        // setLoading(false);


    }

    const DeleteDynamicButton = async (id) => {
        const data = await DynamicShortcutButton.deleteDynamicShortcutButton(id);
        if (data) {
            GetButtons()
            props.GetButtonsMainPage()
            alert("deleted")
        }
    }



    return (
        <div className='modal-back'>
            <div className="shortcutModal">
                <i onClick={props.close} class="fa-solid fa-xmark"></i>
                <h3>Create Shortcut Button</h3>
            
                {/* <div className='heading-shortcutModal'>
                    <div> <h6>
                        Write Button Name
                    </h6>
                    </div>
                    <div> <h6>
                        Select Page Name From List
                    </h6>
                    </div>
                    <div>
                        <h6>
                            icon
                        </h6>
                    </div>
                    <div>

                    </div>
                </div> */}

                <div className='heading-shortcutModal'>
                    <div>
                        <input type="text" onChange={handleInputChange}
                            value={inputValues.name}
                            name="name"
                            placeholder='Write Button Name'
                        />
                    </div>
                    <div>
                        <select id="" onChange={handleInputChange}
                            value={inputValues.formId}
                            name="formId" >
                            <option value="">Select Form Name</option>
                            {FormName.map(forms => (
                                <option value={forms.id}>{forms.formName}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <span className="custom-select">
                            <span className="select-selected" onClick={() => setIsOpen(!isOpen)}>
                                {selectedOption && selectedOption != "Select Icon" ? <i style={{ fontSize: "17px" }} className={`${selectedOption}`}></i> : "Select icon"}
                            </span>
                            {isOpen && (
                                <span className="select-items">
                                    {iconClasses.map(iconClass => (
                                        <span key={iconClass} onClick={() => handleSelect(iconClass)}>
                                            <i style={{ fontSize: "17px" }} className={`${iconClass}`}></i>
                                        </span>
                                    ))}
                                </span>
                            )}
                        </span>

                    </div>
                    <div>
                        {/* <i class="fa-solid fa-trash"></i> */}
                        <button className="redBtn" onClick={resetData}>Reset</button>
                        <button className="greenBtn" onClick={saveData}> {EditMode ? "Update" : "Save"}</button>
                    </div>
                </div>

                <table className='DynamicButtontable'>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }}>Sno</th>
                            <th>Name</th>
                            <th>Form Name</th>
                            <th>Form Link</th>
                            <th>Form Icon</th>
                            <th style={{ textAlign: "center" }}>Edit</th>
                            <th style={{ textAlign: "center" }}>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {DynamicButtons.map((items, index) => (
                            <tr key={items.id}>
                                <td style={{ textAlign: "center" }}>{index + 1}</td>
                                <td>{items.name}</td>
                                <td>{items.formName}</td>
                                <td>{items.formLink}</td>
                                <td><i className={items.description} style={{ fontSize: "18px" }}></i> </td>
                                <td
                                    onClick={() => EditDynamicButton(items.id)}
                                    style={{ textAlign: "center", cursor: "pointer" }}
                                >
                                    <i style={{ fontSize: "16px" }} className="fa-regular fa-pen-to-square"></i>
                                </td>
                                <td
                                    style={{ textAlign: "center", cursor: "pointer" }}
                                    onClick={() => DeleteDynamicButton(items.id)}
                                >
                                    <i style={{ fontSize: "16px" }} className="fa-solid fa-trash"></i>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>

            </div>



        </div>
    )
}

export default ShortCutModal